const modelData = {
    "models": {
        "total_labor_cost_expensive_regions": {
            "versions": {
                "1.0": {
                    "deployed_model_id": "2318623732374962176",
                    "endpoint_id": "3147532354415755264",
                    "arec": 0.825,
                    "prediction_interval": null,
                    "standard_score_95": null,
                    "stdv": 0.198,
                    "mean": 0.193,
                    "median": 0.098,
                    "data_points": 77,
                    "fred_adjustment_series": "CES2000000003",
                    "adjustment_date": "2013-01-01",
                    "regions": ["Northern California", "Texas", "South West", "Utah", "Mountain West", "Southern California"],
                    "warn_on_regions": ["Utah", "Mountain West"],
                    "features": [
                        {
                            "type": "number",
                            "name": "Gross Square Footage",
                            "allowed_values": null
                        }
                    ]
                },
                "1.1": {
                    "deployed_model_id": "3155730313112453120",
                    "endpoint_id": "525311471379283968",
                    "arec": 0.825,
                    "prediction_interval": 0.445,
                    "standard_score_95": 2.1,
                    "stdv": 0.21,
                    "mean": 0.204,
                    "median": 0.143,
                    "data_points": 74,
                    "fred_adjustment_series": "CES2000000003",
                    "adjustment_date": "2013-01-01",
                    "regions": ["Northern California", "Texas", "South West", "Utah", "Mountain West", "Southern California"],
                    "states": ["TX", "AZ", "CA", "WA", "UT", "OK", "LA", "ID", "NV", "CO"],
                    "warn_on_regions": ["Utah", "Mountain West"],
                    "warn_on_states": ["UT", "ID", "CO"],
                    "features": [
                        {
                            "type": "number",
                            "name": "Total Square Footage (Gross Square Footage + Porch Square Footage)",
                            "allowed_values": null
                        }
                    ]
                }
            }
        },
        "total_labor_cost_medium_regions": {
            "versions": {
                "1.0": {
                    "deployed_model_id": "5126055150087045120",
                    "endpoint_id": "5953274922267574272",
                    "arec": 0.88,
                    "prediction_interval": null,
                    "standard_score_95": null,
                    "stdv": 0.163,
                    "mean": 0.144,
                    "median": 0.1,
                    "data_points": 51,
                    "fred_adjustment_series": "CES2000000003",
                    "adjustment_date": "2013-01-01",
                    "regions": ["Mid-Atlantic", "South East"],
                    "warn_on_regions": [],
                    "features": [
                        {
                            "type": "number",
                            "name": "Gross Square Footage",
                            "allowed_values": null
                        }
                    ]
                },
                "1.1": {
                    "deployed_model_id": "3203018109199843328",
                    "endpoint_id": "982145358580678656",
                    "arec": 0.88,
                    "prediction_interval": 0.347,
                    "standard_score_95": 2.25,
                    "stdv": 0.086,
                    "mean": 0.121,
                    "median": 0.093,
                    "data_points": 45,
                    "fred_adjustment_series": "CES2000000003",
                    "adjustment_date": "2013-01-01",
                    "regions": ["Mid-Atlantic", "South East"],
                    "states": ["MD", "DC", "VA", "FL", "GA", "PA", "NC", "OH", "TN"],
                    "warn_on_states": [],
                    "warn_on_regions": [],
                    "features": [
                        {
                            "type": "number",
                            "name": "Total Square Footage (Gross Square Footage + Porch Square Footage)",
                            "allowed_values": null
                        }
                    ]
                }
            }
        },
        "total_labor_cost_cheap_regions": {
            "versions": {
                "1.0": {
                    "deployed_model_id": "505361932404916224",
                    "endpoint_id": "8180304938002284544",
                    "arec": 0.8,
                    "prediction_interval": null,
                    "standard_score_95": null,
                    "stdv": 0.22,
                    "mean": 0.23,
                    "median": 0.15,
                    "data_points": 49,
                    "fred_adjustment_series": "CES2000000003",
                    "adjustment_date": "2013-01-01",
                    "regions": ["North East"],
                    "warn_on_regions": [],
                    "features": [
                        {
                            "type": "number",
                            "name": "Gross Square Footage",
                            "allowed_values": null
                        }
                    ]
                },
                "1.1": {
                    "deployed_model_id": "5508861118413537280",
                    "endpoint_id": "8883992379778924544",
                    "arec": 0.81,
                    "prediction_interval": 0.479,
                    "standard_score_95": 2.1,
                    "stdv": 0.104,
                    "mean": 0.182,
                    "median": 0.152,
                    "data_points": 43,
                    "fred_adjustment_series": "CES2000000003",
                    "adjustment_date": "2013-01-01",
                    "regions": ["North East"],
                    "states": ["MA", "CT", "NJ", "ME", "NY"],
                    "warn_on_regions": [],
                    "warn_on_states": [],
                    "features": [
                        {
                            "type": "number",
                            "name": "Total Square Footage (Gross Square Footage + Porch Square Footage)",
                            "allowed_values": null
                        }
                    ]
                }
            }
        },
        "total_material_cost_panel_jobs": {
            "versions": {
                "1.0": {
                    "deployed_model_id": "1989298009623494656",
                    "endpoint_id": "5881217328229646336",
                    "arec": 0.85,
                    "prediction_interval": 0.505,
                    "standard_score_95": 2.2,
                    "stdv": 0.154,
                    "mean": 0.18,
                    "median": 0.131,
                    "data_points": 47,
                    "fred_adjustment_series": "WPU081",
                    "adjustment_date": "2013-01-01",
                    "is_panel": false,
                    "features": [
                        {
                            "type": "number",
                            "name": "Total Square Footage (Gross Square Footage + Porch Square Footage)",
                            "allowed_values": null
                        }
                    ]
                }
            }
        },
        "total_material_cost_stick_jobs": {
            "versions": {
                "1.0": {
                    "deployed_model_id": "1261966669803159552",
                    "endpoint_id": "3575374319015952384",
                    "arec": 0.87,
                    "prediction_interval": 0.55,
                    "standard_score_95": 2.3,
                    "stdv": 0.17,
                    "mean": 0.168,
                    "median": 0.112,
                    "data_points": 94,
                    "fred_adjustment_series": "WPU081",
                    "adjustment_date": "2013-01-01",
                    "is_panel": true,
                    "features": [
                        {
                            "type": "number",
                            "name": "Total Square Footage (Gross Square Footage + Porch Square Footage)",
                            "allowed_values": null
                        }
                    ]
                }
            }
        },
        "total_material_cost": {
            "versions": {
                "0.1.0-alpha.3": {
                    "deployed_model_id": "2797412667759788032",
                    "endpoint_id": "8170734788794122240",
                    "adjusted_arec_score": 0.839227600169395,
                    "arec_std": 0.73,
                    "arec_mean": 0.32,
                    "arec_median": 0.13,
                    "fred_adjustment_series": "WPU081",
                    "adjustment_date": "2013-01-01",
                    "features": [
                        {
                            "type": "number",
                            "name": "Gross Square Footage",
                            "allowed_values": null
                        },
                        {
                            "type": "string",
                            "name": "Is Panel Type",
                            "allowed_values": [
                                "True",
                                "False"
                            ]
                        }
                    ]
                }
            }
        }
    }
};
export default modelData;
