import navStates from '../../constants/nav-state.constants.mjs';

export default {
	namespaced: true,
	state: {
		navState: navStates.FULL,
	},
	getters: {},
	mutations: {
		toggleLeftNav(state) {
			state.navState = state.navState === navStates.FULL ? navStates.NONE : navStates.FULL;
		},
		setNav(state, payload) {
			state.navState = payload;
		},
	},
	actions: {
	},
};
