<template>
<div class="settings">
	<router-view />
</div>
</template>
<script>

export default {
	data() {
		return {

		};
	},
};
</script>
