<template>
<div>
	<div class="container py-5 py-sm-7">
		<a
			class="d-flex justify-content-center mb-5"
			href="/"
		>
			<img
				class="z-index-2"
				src="/img/coltLogo.png"
				alt="Colt Builders Logo"
				style="max-height: 12rem;"
			>
		</a>

		<div class="row justify-content-center">
			<div class="col-md-7 col-lg-5">
				<!-- Card -->
				<div class="card card-lg mb-5">
					<div class="card-body">
						<!-- Form -->
						<form
							class="js-validate"
							novalidate="novalidate"
						>
							<div class="text-center">
								<div class="mb-5">
									<h1 class="display-4">
										Sign in
									</h1>
								</div>

								<a
									id="sign-in"
									class="btn btn-lg btn-block btn-white mb-4"
									href="javascript:void(0)"
									@click="signInWithAzure"
								>
									<span class="d-flex justify-content-center align-items-center">
										<img
											class="avatar avatar-xss mr-2"
											src="/svg/brands/outlook.svg"
											alt="Microsoft Outlook"
										>
										Sign in with Microsoft
									</span>
								</a>
							</div>
						</form>
						<!-- End Form -->
					</div>
				</div>

				<!-- End Card -->
				<div class="text-center">
					<a
						href="#"
						@click="goBack"
					>
						<!-- <small class="text-cap mb-4">Go back</small> -->
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
	methods: {
		...mapActions('authentication', ['signInWithAzure']),
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>
