export default {
	namespaced: true,
	state: {
		query: {
			page: 1,
			limit: 50,
			totalRows: 0,
			from: 0,
			to: 50,
			sort: {
				by: '',
				desc: false,
			},
		},
		architects: [],
		takeOffRows: [],
		historicalProjects: [],
		estimators: [],
		columns: [
			{
				key: 'project',
				sortable: true,
				filterName: 'name',
				selectionType: String,
			},
			{
				key: 'takeoff_url',
				sortable: true,
				filterName: 'name',
				selectionType: String,
			},
			{
				key: 'region',
				sortable: true,
				filterName: 'name',
				selectionType: String,
			},
			{
				key: 'levels',
				sortable: true,
				filterName: 'name',
				selectionType: String,
			},
		],
	},
	getters: {},
	mutations: {
		setArchitects(state, architects) {
			architects.sort((a, b) => {
				if (a.name < b.name) { return -1; }
				if (a.name > b.name) { return 1; }
				return 0;
			});

			state.architects = architects;
		},
		setEstimators(state, estimators) {
			state.estimators = estimators;
		},
		setHistoricalProjects(state, historicalProjects) {
			state.historicalProjects = historicalProjects;
		},
		setTakeOffRows(state, takeOffRows) {
			state.takeOffRows = takeOffRows;
		},
		setTotalRows(state, count) {
			state.query.totalRows = count;
		},
		setPage(state, page) {
			const cursor = page - 1;
			state.query.page = page;
			state.query.from = cursor * state.query.limit;
			state.query.to = state.query.from + state.query.limit;
		},
	},
	actions: {
		async getHistoricalProjects({ commit, state }) {
			const { to, historicalProjectRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(historicalProjectRepo.getAllHistoricalProjects(state.query));
			if (err) {
				logger.error(err.message);
				commit('setHistoricalProjects', []);
				throw err;
			} else {
				const { data: historicalProjects, count } = data;

				commit('setTotalRows', count);
				commit('setHistoricalProjects', historicalProjects);
			}
		},
	},
};
