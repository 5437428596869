export default {
	namespaced: true,
	state: {
		takeOffRows: [],
	},
	getters: {},
	mutations: {
		setTakeOffRows(state, takeOffRows) {
			state.takeOffRows = takeOffRows;
		},
	},
	actions: {

		async getTakeOffRows({ commit }) {
			const { to, takeOffRowRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(takeOffRowRepo.getAllTakeOffRows());
			if (err) {
				logger.error(err.message);
				commit('setTakeOffRows', []);
				throw err;
			} else {
				commit('setTakeOffRows', data);
			}
		},
	},
};
