export default class UserRepo {
	constructor({ supaTo, supabaseClientFactory, _ }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getAllUsers() {
		const [err, response] = await this.supaTo(
			this.supabase.from('user').select('*'),
		);
		if (err) {
			throw err;
		}
		return response.data;
	}

	async getUserById(id) {
		const [err, response] = await this.supaTo(
			this.supabase.from('user').select().match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return response?.data?.[0];
		}
	}

	async getUserByEmail(email) {
		const [err, response] = await this.supaTo(
			this.supabase.from('user').select().match({ email }),
		);
		if (err) {
			throw err;
		} else {
			return response?.data?.[0];
		}
	}

	async createUser(user) {
		if (user.name.length === 0) {
			return null;
		}

		const [err, response] = await this.supaTo(
			this.supabase.from('user').insert(user),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', null);
		}
	}
}
