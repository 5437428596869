export default {
	namespaced: true,
	state: {
		totalLaborCost: 0,
		totalSubCost: 0,
		material: [
			{
				type: '<div class="cs-type">Material</div>',
				cost_code: '006-130',
				component: 'Trusses - Roof',
				variable: 'Truss Fab -- 3.9.22',
				cost: 305459,
				tax: '0',
				cost_tax: '=E1+F1',
				margin_percent: '.12',
				margin_dollar: '=G1/(1-H1)-G1',
				percent_of_job: '0',
				total: '=ROUND(SUM(G1,I1), 2)',
			},
			{
				type: '',
				cost_code: '',
				component: '',
				variable: '',
				cost: '0',
				tax: '0',
				cost_tax: '=E2+F2',
				margin_percent: '.12',
				margin_dollar: '=G2/(1-H2)-G2',
				percent_of_job: '.12',
				total: '=ROUND(SUM(G2,I2), 2)',
			},
		],
		colHeaders: [
			'',
			'Cost Code',
			'Component', 'Variable',
			'Cost', 'Tax',
			'Cost + Tax',
			 'Margin%',
			 'Margin $$',
			 '% Of Job',
			 'Total Amt',
			 'Cost/Sqft',
			 'Sale/Sqft',
		],
		comments: [],
	},
	getters: {
		material: (state, getters, rootState) => {
			const sqft = rootState.activeBid.bid.bid_revision.square_footage;
			const { taxRate, total } = rootState.bidCs;
			const mainMaterial = state.material.map((row, index) => {
				row.cost_per_gross_sqft = `=E${index + 1}/${sqft}`;
				row.tax = `=E${index + 1}*${taxRate / 100}`;
				if (row.variable) {
					const supplier = rootState.supplier.bidSuppliersList.find(({ supplier_name: supplierName }) => supplierName === row.variable);
					if (supplier) {
						row.component = supplier.category;
						row.cost = supplier.amount;
					}
					if (!Number.isNaN(Number(row.variable))) {
						row.cost = row.variable * sqft;
					}
				}
				row.cost_per_gross_sqft = `=E${index + 1}/${sqft}`;
				row.sale_per_gross_sqft = `=K${index + 1}/${sqft}`;
				row.percent_of_job = `=ROUND(K${index + 1}/${total}*100, 2)/100`;
				return row;
			});

			const currentRow = mainMaterial.length;
			const totalRow = currentRow + 1;

			mainMaterial.push({
				type: '',
				cost_code: '',
				component: 'MATERIAL TOTALS',
				variable: '',
				cost: `=SUM(E1:E${currentRow})`,
				tax: `=SUM(F1:F${currentRow})`,
				cost_tax: `=E${totalRow}+F${totalRow}`,
				margin_percent: '.12',
				margin_dollar: `=SUM(I1:I${currentRow})`,
				percent_of_job: `=SUM(J1:J${currentRow})`,
				total: `=SUM(K1:K${currentRow})`,
				cost_per_gross_sqft: `=SUM(L1:L${currentRow})`,
				sale_per_gross_sqft: `=SUM(M1:M${currentRow})`,
			});

			return mainMaterial;
		},
		columns: (state, getters, rootState) => {
			const suppliers = rootState.supplier.bidSuppliersList.map(({ supplier_name: supplierName }) => supplierName);
			return [
				{ data: 'type', renderer: 'html', readOnly: true },
				{
					data: 'cost_code',
					type: 'autocomplete',
					source: ['006-130', '006-100', '006-110', '006-060', '006-170', '006-120', '006-070', '006-080'],
					strict: true,
				},
				{
					data: 'component',
					type: 'autocomplete',
					source: ['Trusses - Roof', 'Trussed Shear Blocking', 'Beams/ Eng Wood Products', 'Wall Panels', 'Holdown Materials'],
					strict: false,
				},
				{
					data: 'variable',
					type: 'autocomplete',
					source: suppliers,
					strict: true,
				},
				{
					data: 'cost',
					type: 'numeric',
					numericFormat: {
						pattern: '$0,0.00',
						culture: 'en-US',
					},
				},
				{
					data: 'tax',
					type: 'numeric',
					numericFormat: {
						pattern: '$0,0.00',
						culture: 'en-US',
					},
					readOnly: true,
				},
				{
					data: 'cost_tax',
					type: 'numeric',
					numericFormat: {
						pattern: '$0,0.00',
						culture: 'en-US',
					},
					readonly: true,
				},
				{
					data: 'margin_percent',
					type: 'numeric',
					numericFormat: {
						pattern: '0.00%',
						culture: 'en-US',
					},
					readOnly: true,
				},
				{
					data: 'margin_dollar',
					type: 'numeric',
					numericFormat: {
						pattern: '$0,0.00',
						culture: 'en-US',
					},
					readOnly: true,
				},
				{
					data: 'percent_of_job',
					type: 'numeric',
					numericFormat: {
						pattern: '0.00%',
						culture: 'en-US',
					},
					readOnly: true,
				},
				{
					data: 'total',
					type: 'numeric',
					numericFormat: {
						pattern: '$0,0.00',
						culture: 'en-US',
					},
					readOnly: true,
				},
				{
					data: 'cost_per_gross_sqft',
					type: 'numeric',
					numericFormat: {
						pattern: '$0,0.00',
						culture: 'en-US',
					},
					readOnly: true,
				},
				{
					data: 'sale_per_gross_sqft',
					type: 'numeric',
					numericFormat: {
						pattern: '$0,0.00',
						culture: 'en-US',
					},
					readOnly: true,
				},
			];
		},
		totalItems(state, getters) {
			return getters.labor.length;
		},
	},
	mutations: {
		updateMaterial(state, payload) {
			state.material = payload;
		},
		updateMaterialCell(state, { row, prop, value }) {
			state.material[row][prop] = value;
		},
		addRow(state, reload) {
			const currentRow = state.material.length + 1;
			state.material.push({
				type: '',
				cost_code: '',
				component: '',
				variable: '',
				cost: '0',
				tax: '0',
				cost_tax: `=E${currentRow}+F${currentRow}`,
				margin_percent: '.12',
				margin_dollar: `=G${currentRow}/(1-H1)-G${currentRow}`,
				percent_of_job: '.12',
			});

			reload();
		},
		setComments(state, payload) {
			state.comments = payload;
		},
		removeRow(state, { row, reload }) {
			state.material.splice(row, 1);

			if (state.comments) {
				for (let i = 0; i < state.comments.length; i++) {
					if (state.comments[i].row === row) {
						state.comments.splice(i, 1);
					}
					if (state.comments[i].row > row) {
						state.comments[i].row -= 1;
					}
				}
			}
			reload();
		},
		handleComment(state, data) {
			const existingCommentIndex = state.comments.findIndex(({ row, col }) => row === data.row && col === data.col);

			if (existingCommentIndex > -1) {
				if (data.comment === '') {
					state.comments.splice(existingCommentIndex, 1);
					return;
				}
				const existingComment = state.comments[existingCommentIndex];
				existingComment.comment = data.comment;
			} else {
				if (data.comment === '') return;
				state.comments.push(data);
			}
		},
	},
	actions: {
	},
};
