// TODO: debounce these requests?
export default {
	beforeCreate() {
	},
	mounted() {
		window.addEventListener('resize', (event) => { this.checkIfMobileOrTablet(event); });
		this.checkIfMobileOrTablet({ target: window });
	},
	data() {
		return {
			isMobileOrTablet: false,
		};
	},
	destroyed() {
		window.removeEventListener('resize', this.checkIfMobileOrTablet);
	},
	methods: {
		checkIfMobileOrTablet(event) {
			this.isMobileOrTablet = event.target.innerWidth < 1200;
			return this.isMobileOrTablet;
		},
	},
};
