export default {
	namespaced: true,
	state: {
		laborPrediction: {},
		laborLoaded: false,
		laborError: '',
	},
	getters: {},
	mutations: {
		setPrediction(state, laborPrediction) {
			state.laborPrediction = laborPrediction;
		},
		setLoadingStatus(state, status) {
			state.laborLoaded = status;
		},
		setError(state, laborError) {
			state.laborError = laborError.message;
		},
		resetStates(state) {
			state.laborPrediction = {};
			state.laborLoaded = false;
			state.laborError = '';
		},
	},
	actions: {
		async getLaborPrediction({ commit, state }, predObj) {
			if (state.laborLoaded) {
				return;
			}
			const { to, laborPredictionRepo } = this.$cradle;
			const [err, data] = await to(laborPredictionRepo.getLaborPrediction(predObj));
			if (err) {
				commit('setPrediction', {});
				commit('setError', err);
				commit('setLoadingStatus', true);
			} else {
				commit('setPrediction', data);
				commit('setLoadingStatus', true);
			}
		},

		async resetLaborPredictionStates({ commit }) {
			commit('resetStates');
		},
	},
};
