export default class MocksService {
	constructor({ registry, libAsValue, libAsFunction, libAsClass }) {
		this.registry = registry;
		this.asValue = libAsValue;
		this.asFunction = libAsFunction;
		this.asClass = libAsClass;
		this.activeMocks = {};
		this._registrations = { ...this.registry.registrations };
	}

	mock(name, mock, opts) {
		this.mockAsValue(name, mock, opts);
	}

	mockAsValue(name, mock, opts) {
		this.registry.dispose(name);
		this.registry.register(name, this.asValue(mock, opts));
		this.activeMocks[name] = true;
	}

	mockAsClass(name, mock, opts) {
		this.registry.dispose(name);
		this.registry.register(name, this.asClass(mock, opts));
		this.activeMocks[name] = true;
	}

	mockAsFunction(name, mock, opts) {
		this.registry.dispose(name);
		this.registry.register(name, this.asFunction(mock, opts));
		this.activeMocks[name] = true;
	}

	resetMocks() {
		Object.keys(this.activeMocks).forEach((name) => {
			this.restore(name);
		});
	}

	restore(name) {
		const original = this._registrations[name];
		this.registry.dispose(name);
		this.registry.register(name, original);
		delete this.activeMocks[name];
	}

	loadMock(path) {
		const { configService, libPath, libFS } = this.registry.cradle;
		return JSON.parse(libFS.readFileSync(
			libPath.join(configService.get('paths.testSrc'), 'data', path), 'utf8',
		));
	}
}
