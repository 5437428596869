export default class GeneralContractorRepo {
	constructor({ sbTo, supabaseClientFactory, _, supaTo }) {
		this.sbTo = sbTo;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getAllGeneralContractors() {
		const [err, response] = await this.sbTo(
			this.supabase.from('general_contractor').select(),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async getGeneralContractorById(id) {
		if (!id) {
			throw new Error('id is required for get general contractor by id');
		}
		const [error, data] = await this.sbTo(
			this.supabase
				.from('general_contractor')
				.select('*')
				.match({ id }),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data[0]', null);
		}
	}

	async getBidsGeneralContractors(bidId) {
		const [err, response] = await this.sbTo(
			this.supabase.from('bid_general_contractor').select().match({ bid_id: bidId }),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async getGeneralContractorsByNames(names) {
		const [err, response] = await this.sbTo(
			this.supabase.from('general_contractor').select('id, name').in('name', names),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async addContractorToBid(bidRevisionId, generalContractor) {
		if (!generalContractor) {
			throw new Error('generalContractor is required to add a generalContractor to bid.');
		}

		if (!bidRevisionId) {
			throw new Error('bidRevisionId is required to add a generalContractor to bid.');
		}

		const [existingGCError, existingGC] = await this.supaTo(
			this.supabase.from('bid_general_contractor').select('bid_revision_id')
				.match({ bid_revision_id: bidRevisionId, general_contractor_id: generalContractor.id }),
		);

		if (existingGCError) {
			throw existingGCError;
		}

		if (existingGC.data.length > 0) {
			return null;
		}

		const [err, response] = await this.supaTo(
			this.supabase.from('bid_general_contractor').insert({ bid_revision_id: bidRevisionId, general_contractor_id: generalContractor.id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async createGeneralContractor(generalContractor) {
		if (!generalContractor) {
			throw new Error('generalContractor is required to create generalContractor.');
		}
		const [err, response] = await this.sbTo(
			this.supabase.from('general_contractor').insert(generalContractor),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async updateGeneralContractor(generalContractor) {
		if (!generalContractor) {
			throw new Error('generalContractor is required to update generalContractor.');
		}
		const [err, response] = await this.sbTo(
			this.supabase
				.from('general_contractor')
				.update(generalContractor)
				.match({ id: generalContractor.id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async upsertGeneralContractor(gc) {
		if (!gc) {
			throw new Error('gc is required to upsert General Contractor.');
		}

		const [err, response] = await this.sbTo(this.supabase
			.from('general_contractor')
			.upsert(gc, { onConflict: 'name' }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async upsertBidGeneralContractor(gc) {
		if (!gc) {
			throw new Error('gc is required to upsert Bid General Contractor.');
		}

		const [err, response] = await this.sbTo(this.supabase
			.from('bid_general_contractor')
			.upsert(gc));
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}
}
