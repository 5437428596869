export default {
	namespaced: true,
	state: {
		bids: {},
		selectedRegions: [],
		shownData: [],
		loading: true,
	},
	mutations: {
		setBidsInRegion(state, { bids, region }) {
			state.bids[region] = bids.map((item) => {
				item.estimator = item.estimator || 'None Assigned';
				item.structural_engineer = item.structural_engineer || 'None Assigned';
				item.non_standard = item.non_standard ? 'Non-Standard' : 'Standard';
				item.architect = item.architect || 'None Assigned';
				return item;
			});
		},
		setRegions(state, regions) {
			state.selectedRegions = regions;
		},
		setLoading(state, loading) {
			state.loading = loading;
		},
		setShownData(state) {
			const data = [];
			for (const r of state.selectedRegions) {
				if (state.bids[r]) {
					data.push(...state.bids[r]);
				}
			}
			state.shownData = data;
		},
	},
	actions: {
		async getBidsByRegion({ commit, state }, region) {
			if (state.bids[region]) {
				commit('setShownData');
				return;
			}
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;
			const [err, bids] = await to(bidRepo.getBidsByRegion(region));
			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('setBidsInRegion', { bids, region });
				commit('setShownData');
			}
		},
		async toggleRegion({ dispatch, commit }, regions) {
			commit('setRegions', regions);
			const { libAsync } = this.$cradle;
			commit('setLoading', true);
			await libAsync.eachSeries(regions, async (region) => dispatch('getBidsByRegion', region));
			commit('setLoading', false);
		},
	},
};
