export default {
	namespaced: true,
	state: {
		subvsmhs: [],
	},
	getters: {},
	mutations: {
		setSubVsMh(state, subVsMhs) {
			state.subvsmhs = subVsMhs;
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
		},
	},
	actions: {
		async getSubVsMH({ commit }, csFileId) {
			const { to, csSubVsMHRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(csSubVsMHRepo.getAllSubVsMHByFileId(csFileId));
			if (err) {
				logger.error(err.message);
				commit('setSubVsMh', []);
				throw err;
			} else {
				commit('setSubVsMh', data);
				commit('setLoadingStatus', true);
				return data;
			}
		},
		async setSubVsMHBidId({ dispatch }, { subVsMHID, bidId }) {
			const { to, csSubVsMHRepo, logDnaFactory: logger } = this.$cradle;

			const [errClear] = await to(csSubVsMHRepo.clearBidId(bidId));
			if (errClear && errClear.message !== 'Item not found') {
				logger.error(errClear.message);
				throw errClear;
			}

			const [err] = await to(csSubVsMHRepo.setBidId(subVsMHID, bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			const [associationLogErr] = await to(dispatch('createAssociationLogEntry', {
				bidId: this.bid.bid_id,
				type: 'cs_sub_vs_mh',
				subVsMHID,
			}, { root: true }));

			if (associationLogErr) {
				logger.error(associationLogErr.message);
				throw associationLogErr;
			}
		},
	},
};
