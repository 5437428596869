<template>
<div>
	<transition-group name="fade">
		<div
			v-for="alert of alerts"
			:key="alert.id"
			class="alert"
			:class="{'alert-soft-danger': alert.type === 'error' || alert.type === 'danger', 'alert-soft-success': alert.type === 'success' }"
			role="alert"
		>
			{{ alert.message }}
			<button
				type="button"
				class="close"
				@click="closeAlert(alert.id)"
			>
				<i class="tio-clear tio-lg" />
			</button>
		</div>
	</transition-group>
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
	computed: {
		...mapState('alert', ['alerts']),
	},
	methods: {
		...mapActions('alert', ['closeAlert']),
	},
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.alert {
	margin-bottom: -48px;
	z-index: 100;
	position: absolute;
	top: 70px;
    z-index: 100;
    width: calc(100% - 406px);
}

.alert-soft-danger {
	background-color: rgb(253 237 241);
}
.alert-soft-success {
	background-color: rgb(229 249 246);
}

</style>
