export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		async createFeedback(_, feedback) {
			const { to, feedbackRepo, logDnaFactory: logger } = this.$cradle;
			const [err] = await to(feedbackRepo.createFeedback(feedback));
			if (err) {
				logger.error(err.message);
			}
		},
	},
};
