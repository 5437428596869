export default {
	namespaced: true,
	state: {
		projectManagers: [],
	},
	getters: {
		getProjectManagers: (state) => state.projectManagers.map((item) => item.name),
	},
	mutations: {
		setProjectManagers(state, projectManagers) {
			state.projectManagers = projectManagers;
		},
	},
	actions: {
		async getProjectManagers({ commit }) {
			const { to, projectManagerRepo, logDnaFactory: logger } = this.$cradle;
			const [err, projectManagers] = await to(projectManagerRepo.getProjectManagers());
			if (err) {
				logger.error(err.message);
				throw err;
			}
			commit('setProjectManagers', projectManagers);
		},
	},
};
