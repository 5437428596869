export default class CSSubVsMHRepo {
	constructor({ supaTo, supabaseClientFactory, _, to, sbTo }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
		this.to = to;
		this.sbTo = sbTo;
	}

	async upsertSubVsMH(subVsMH) {
		const [error, response] = await this.supaTo(this.supabase.from('cs_sub_vs_mh').upsert(subVsMH));

		if (error) {
			throw error;
		} else {
			return this._.get(response, 'data[0]', {});
		}
	}

	async getSubVsMH(csFileId) {
		const [error, response] = await this.sbTo(this.supabase.from('cs_sub_vs_mh').select('*').match({ cs_file_id: csFileId }));

		if (error) {
			throw error;
		} else {
			return this._.get(response, '[0]', {});
		}
	}

	async getAllSubVsMHByFileId(csFileId) {
		const [error, response] = await this.supaTo(this.supabase.from('cs_sub_vs_mh').select('*').match({ cs_file_id: csFileId }));

		if (error) {
			throw error;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async associateSubVsMH(subVsMHID, bidId) {
		const [err, sub] = await this.sbTo(this.supabase.from('cs_sub_vs_mh').select().match({ bid_id: bidId }));

		if (err) {
			throw err;
		}

		if (sub.length) {
			const [err1] = await this.to(this.clearBidId(bidId));
			if (err1) {
				throw err1;
			}
		}

		const [err2] = await this.to(this.setBidId(subVsMHID, bidId));
		if (err2) {
			throw err2;
		}
	}

	async setBidId(subVsMHID, bidId) {
		const [error] = await this.supaTo(
			this.supabase
				.from('cs_sub_vs_mh')
				.update({ bid_id: bidId })
				.match({ id: subVsMHID }),
		);
		if (error) {
			throw error;
		}
	}

	async clearBidId(bidId) {
		if (!bidId) {
			throw new Error('bidId is required to update cs_sheet');
		}
		const [error] = await this.supaTo(
			this.supabase
				.from('cs_sub_vs_mh')
				.update({ bid_id: null })
				.match({ bid_id: bidId }),
		);
		if (error) {
			throw error;
		}
	}
}
