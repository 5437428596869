export default class CommentRepo {
	constructor({ supaTo, supabaseClientFactory, _ }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getCommentsByBidId(bidId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('comment').select(`
				comment_delta,
				user(
					name,
					avatar
				),
				resource_id,
				resource_sub_id,
				date
			`)
				.match({ resource_id: bidId, resource_type: 'BID' })
				.order('date', { ascending: false }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getBidCommentsList(bidId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('bid_comment').select()
				.match({ bid_id: bidId })
				.order('date', { ascending: false }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getProjectCommentsList(projectId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('project_comment').select()
				.match({ project_id: projectId })
				.order('date', { ascending: false }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getCommentsByBidIdInList(bidIds) {
		const [err, response] = await this.supaTo(
			this.supabase.from('comment').select(`
				comment_delta,
				user(
					name,
					avatar
				),
				resource_id,
				resource_sub_id,
				date
			`)
				.in('resource_id', bidIds)
				.match({ resource_type: 'BID' })
				.order('date', { ascending: false }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getCommentsByProjectId(projectId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('comment').select(`
				comment_delta,
				user(
					name,
					avatar
				),
				resource_id,
				resource_sub_id,
				date
			`)
				.match({ resource_id: projectId, resource_type: 'PROJECT' })
				.order('date', { ascending: false }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getCommentById(id) {
		const [err, response] = await this.supaTo(
			this.supabase.from('comment').select(`
			comment_delta,
			user(
				name,
				avatar
			),
			resource_id,
			resource_sub_id,
			date
		`).match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', []);
		}
	}

	async createComment(comment, resourceType) {
		comment.resource_type = resourceType;
		const [err, response] = await this.supaTo(
			this.supabase.from('comment').insert(comment),
		);
		if (err) {
			throw err;
		} else {
			const { id } = this._.get(response, 'data[0]', {});
			return this.getCommentById(id);
		}
	}
}
