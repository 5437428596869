export default {
	namespaced: true,
	state: {
		rgms: [],
	},
	getters: {
		getRGMS: (state) => state.rgms.map((item) => item.name),
	},
	mutations: {
		setRGMS(state, rgms) {
			state.rgms = rgms;
		},
	},
	actions: {
		async getRGMS({ commit }) {
			const { to, rgmRepo, logDnaFactory: logger } = this.$cradle;
			const [err, rgms] = await to(rgmRepo.getRGMS());
			if (err) {
				logger.error(err.message);
				throw err;
			}
			commit('setRGMS', rgms);
		},
	},
};
