<template>
<div>
	<div
		v-if="display"
		class="hs-unfold-overlay"
		style="display: block;"
		@click.prevent="toggleDisplay"
	/>
	<div
		v-if="display"
		class="hs-unfold-content sidebar sidebar-bordered sidebar-box-shadow
		hs-unfold-content-initialized hs-unfold-css-animation animated fadeInRight"
		style="width: 35rem; animation-duration: 300ms;"
		data-hs-target-height="0"
		data-hs-unfold-content=""
		data-hs-unfold-content-animation-in="fadeInRight"
		data-hs-unfold-content-animation-out="fadeOutRight"
	>
		<div class="card card-lg border-0 h-100 rounded-0">
			<div class="card-header">
				<div class="mr-2">
					<h3 class="card-header-title">
						Comments
					</h3>
				</div>
				<a
					class="js-hs-unfold-invoker btn btn-icon btn-xs btn-ghost-dark"
					href="javascript:void(0);"
					@click.prevent="toggleDisplay"
				>
					<i class="tio-clear tio-lg" />
				</a>
			</div>
			<div class="card-body comments-log-wrapper">
				<new-comment class="mt-2" />
				<div class="list-comment">
					<comment
						v-for="(comment, index) of comments"
						:key="index"
						:comment="comment"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import Comment from './comment.vue';
import NewComment from './new-comment.vue';

export default {
	components: {
		Comment,
		NewComment,
	},
	computed: {
		...mapState('comment', ['comments', 'display']),
	},
	methods: {
		...mapActions('comment', ['toggleDisplay']),
	},
};
</script>
<style scoped>
	.comments-log-wrapper {
		overflow-y: auto;
	}
</style>
