export default class CSStatusRepo {
	constructor({ supaTo, supabaseClientFactory, _, to, libDateFns }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
		this.to = to;
		this.libDateFns = libDateFns;
	}

	async createCSStatus(status) {
		const [err, response] = await this.supaTo(
			this.supabase.from('cs_status').insert(status),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async handleIssues(status) {
		const [err, existingStatuses] = await this.to(this.getIssuesByCSFileId(status.cs_file_id));
		if (err) {
			throw err;
		}

		if (existingStatuses?.length > 0) {
			const existingStatus = existingStatuses[0];
			const age = this.libDateFns.differenceInDays(this.libDateFns.subDays(new Date(), 1), new Date(existingStatus.modified_at));

			if (age < 2) {
				const issues = [...existingStatus.issues, ...status.issues];

				const cleanedIssues = issues.filter(
					({ issueName, tab }, index) => issues.findIndex((i) => i.issueName === issueName && i.tab === tab) === index,
				);

				const [mergeErr, result] = await this.to(this.updateStatus(existingStatus.id, { issues: cleanedIssues }));

				if (mergeErr) {
					throw mergeErr;
				}

				return result;
			}

			const [archiveErr] = await this.to(this.archiveStatus(csFileId));

			if (archiveErr) {
				throw archiveErr;
			}
		}

		const [createErr, result] = await this.to(this.createCSStatus(status));

		if (createErr) {
			throw createErr;
		}

		return result;
	}

	async archiveStatus(csFileId) {
		const [err, response] = await this.to(
			this.supabase.from('cs_status').update({ archived: true }).match({ cs_file_id: csFileId }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async updateStatus(statusId, updates) {
		const [err, response] = await this.supaTo(
			this.supabase.from('cs_status').update(updates).match({ id: statusId }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async getIssuesByCSFileId(csFileId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('cs_status').select('*').match({ cs_file_id: csFileId, archived: false }),
		);
		if (err) {
			throw err;
		}
		return response.data;
	}

	generateIssue(issueName, details, tab) {
		return {
			issueName,
			details,
			tab,
		};
	}
}
