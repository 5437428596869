export default {
	namespaced: true,
	state: {
		overheadMarginPerMH: 1.70,
		overheadCostPerMH: 0,
		total: 0,
		totalOverhead: 0,

		coltGLUmbr: 0.019838598, // Temporary value
		subUmbrella: 0.0038, // Temporary value

		manHours: 0,
		subManHours: 0,
		totalManHours: 0,

		manHourRate: 35.00,
		subManHourRate: 27.00,

		sfPerWeek: 10000,

		totalJobDuration: 0,
		framingDuration: 0,
		totalResUnits: 0,
		totalPubUnits: 0,
		taxRate: 6,

		totalLaborCost: 0,
		totalSubCost: 0,
		totalMaterialCost: 0,

		ohCostPerMH: 0,
		ohCostPerSF: 0,
	},
	getters: {
	},
	mutations: {
		setOverheadMarginPerMH(state, payload) {
			state.overheadMarginPerMH = payload;
		},
		setOverheadCostPerMH(state) {
			if (!state.totalOverhead || !state.totalManHours) return;
			state.overheadCostPerMH = state.totalOverhead / state.totalManHours;
		},
		setTotalOverhead(state, payload) {
			state.totalOverhead = payload;
		},
		setTotal(state, payload) {
			state.total = payload;
		},
		setTotalLaborCost(state, payload) {
			state.totalLaborCost = payload;
		},
		setTotalMaterialCost(state, payload) {
			state.totalMaterialCost = payload;
		},
		updateOverhead(state, payload) {
			state.overhead = payload;
		},
		setOHCostPerMH(state, payload) {
			state.ohCostPerMH = payload;
		},
		setOHCostPerSF(state, payload) {
			state.ohCostPerSF = payload;
		},
		setManHours(state, payload) {
			if (state.manHours === payload) return;
			state.manHours = Number(payload);
			state.totalManHours = state.manHours + state.subManHours;
		},
		setSubManHours(state, payload) {
			if (state.subManHours === payload) return;
			state.subManHours = Number(payload);
			state.totalManHours = state.manHours + state.subManHours;
		},
		setFramingDuration(state, payload) {
			state.framingDuration = payload;
		},
		setTotalJobDuration(state, payload) {
			state.totalJobDuration = payload;
		},
		setFromLoad(state, payload) {
			Object.assign(state, payload);
		},
	},
	actions: {
		async save({ rootState }, payload) {
			const { bidCSRepo, logDnaFactory: logger, to } = this.$cradle;
			const { bid } = rootState.activeBid;

			const [err] = await to(bidCSRepo.upsertBidCS(bid.id, bid.bid_revision.id, payload));

			if (err) {
				logger.log(err);
				throw err;
			}
		},
		async load({ rootState }) {
			const { bidCSRepo, logDnaFactory: logger, to } = this.$cradle;

			const { bid } = rootState.activeBid;

			const [err, csSheet] = await to(bidCSRepo.getBidCS(bid.id));

			if (err) {
				logger.log(err);
				throw err;
			}

			return csSheet;
		},
	},
};
