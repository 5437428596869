<template>
<div class="w-100">
	<li
		v-if="activity.divider"
		class="step-item"
	>
		<div class="step-content-wrapper">
			<small class="step-divider">{{ activity.divider }}</small>
		</div>
	</li>
	<li class="step-item">
		<div class="step-content-wrapper">
			<span class="step-icon step-icon-soft-dark">
				<div
					v-if="activity.user && activity.user.avatar"
					class="avatar avatar-sm avatar-circle mr-2"
				>
					<img
						class="avatar-img"
						:href="activity.user.avatar"
					>
				</div>
				<span
					v-else-if="userInitials"
					class="avatar avatar-light avatar-sm avatar-circle"
				>
					<span class="avatar-initials">{{ userInitials }}</span>
				</span>
				<span
					v-else
					class="icon icon-primary icon-circle"
				>
					<i class="tio-settings-outlined" />
				</span>
			</span>

			<div class="step-content">
				<h5 class="mb-1">
					<a
						class="text-dark"
						href="javascript:void(0);"
					>{{ username }}</a>
				</h5>

				<p class="font-size-sm">
					{{ types[activity.type] }}
				</p>
			</div>
		</div>
	</li>
</div>
</template>
<script>
export default {
	props: {
		activity: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			types: {
				REBID: 'Created a rebid',
				CREATE: 'Created',
				CLONE: 'Cloned',
				ARCHIVED: 'Archived',
				WON: 'Set to "Won"',
				STATUS_CHANGE: 'Changed the status',
				PREDICTION: 'Created a new prediction',
				ADD_BID_SUPPLIER: 'Added a supplier',
			},
		};
	},
	computed: {
		userInitials() {
			return this.activity?.user?.name.split(' ').slice(0, 2).map((item) => item[0]).join('');
		},
		username() {
			return this.activity?.user?.name || 'System';
		},
	},
};
</script>
