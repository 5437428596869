import createBreadcrumbs from '../services/breadcrumbs-service.mjs';

function generateBreadcrumbs(route, bidId, archived, bidName) {
	const startPath = [{
		name: `${archived ? 'Archived ' : ''}Bid Log`,
		destination: archived ? '/archived-bid-log' : '/bid-log',
	},
	{
		name: bidName,
		destination: `/bid-form/${bidId}/general`,
	}];

	return createBreadcrumbs(route, startPath);
}

// eslint-disable-next-line max-len
const bidSlug = '/bid-form/:bidId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:bidRevisionId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?/';

export default {
	path: bidSlug,
	component: () => import('../views/BidForm.vue'),
	meta: { authenticate: true },
	props: true,
	children: [
		{
			name: 'Bid Form',
			path: '',
			props: true,
			redirect: `${bidSlug}general`,
		},
		{
			name: 'Buildings',
			path: 'buildings',
			component: () => import('../views/Buildings.vue'),
			meta: {
				breadcrumbs() {
					const { archived, id: bidId } = this.bid;
					return generateBreadcrumbs([{
						name: 'Buildings',
						destination: `/bid-form/${bidId}/buildings`,
					}], bidId, archived, this.briefBidName);
				},
			},
		},
		{
			name: 'Building',
			path: 'building/:buildingId',
			component: () => import('../views/Building.vue'),
			props: true,
			children: [
				{
					name: 'Building General',
					path: 'building-general',
					component: () => import('../views/BuildingGeneralForm.vue'),
					props: true,
					meta: {
						breadcrumbs() {
							const { archived, id: bidId } = this.bid;
							return generateBreadcrumbs([{
								name: 'Buildings',
								destination: `/bid-form/${bidId}/buildings`,
							},
							{
								name: this.buildingName,
								destination: `/bid-form/${bidId}/building/${this.buildingId}/building-general`,
							}], bidId, archived, this.briefBidName);
						},
					},
				},
				{
					name: 'Levels',
					path: 'levels',
					component: () => import('../views/LevelsForm.vue'),
					props: true,
					meta: {
						breadcrumbs() {
							const { archived, id: bidId } = this.bid;
							return generateBreadcrumbs([{
								name: 'Buildings',
								destination: `/bid-form/${bidId}/buildings`,
							},
							{
								name: this.buildingName,
								destination: `/bid-form/${bidId}/building/${this.buildingId}/building-general`,
							},
							{
								name: 'Levels',
								destination: `/bid-form/${bidId}/building/${this.buildingId}/levels`,
							}], bidId, archived, this.briefBidName);
						},
					},
				},
			],
		},
		{
			name: 'Suppliers',
			path: 'suppliers',
			component: () => import('../views/Suppliers.vue'),
			meta: {
				breadcrumbs() {
					const { archived, id: bidId } = this.bid;
					return generateBreadcrumbs([{
						name: 'Suppliers',
						destination: `/bid-form/${bidId}/suppliers`,
					}], bidId, archived, this.briefBidName);
				},
			},
		},
		{
			name: 'General',
			path: 'general',
			component: () => import('../views/GeneralForm.vue'),
			meta: {
				breadcrumbs() {
					const { archived, id: bidId } = this.bid;
					return generateBreadcrumbs([], bidId, archived, this.briefBidName);
				},
			},
		},
		{
			name: 'Bid Sale CS Sheet',
			path: 'sale-cs-sheet',
			component: () => import('../views/BidSaleCSSheet.vue'),
			meta: {
				breadcrumbs() {
					const { archived, id: bidId } = this.bid;
					return generateBreadcrumbs([{
						name: 'Bid Sale CS Sheet',
						destination: `/bid-form/${bidId}/sale-cs-sheet`,
					}], bidId, archived, this.briefBidName);
				},
			},
		},
		{
			name: 'Bid Scope Checklist',
			path: 'scope-checklist',
			component: () => import('../views/BidScopeChecklist.vue'),
			meta: {
				breadcrumbs() {
					const { archived, id: bidId } = this.bid;
					return generateBreadcrumbs([{
						name: 'Bid Scope Checklist',
						destination: `/bid-form/${bidId}/scope-checklist`,
					}], bidId, archived, this.briefBidName);
				},
			},
		},
		{
			name: 'Estimate Details',
			path: 'estimate-details',
			component: () => import('../views/EstimateDetails.vue'),
			meta: {
				breadcrumbs() {
					const { archived, id: bidId } = this.bid;
					return generateBreadcrumbs([{
						name: 'Estimate Details',
						destination: `/bid-form/${bidId}/estimate-details`,
					}], bidId, archived, this.briefBidName);
				},
			},
			props: true,
		},
	],
};
