<template>
<b-card class="estimator-breakdown mb-3">
	<div style="width: 100%">
		<div style="width: 78.75px; margin: 0 auto">
			<Avatar
				:name="estimator.name"
				size="xl"
			/>
		</div>
		<h3
			class="mt-3"
			style="text-align:center"
		>
			{{ estimator.name }}
		</h3>
	</div>
	<ul class="list-group list-group-flush list-group-no-gutters estimator-breakdown-list">
		<li class="list-group-item">
			<div style="float: left; font-weight: bold">
				Total
			</div>
			<div class="float-right pr-3">
				{{ estimator.count }}
			</div>
		</li>
		<li
			v-for="(bid, index) of estimator.bids"
			:key="index"
			class="list-group-item"
		>
			<div class="float-left ellipsis">
				<router-link
					slot="name"
					:to="`/bid-form/${bid.bid_id}/general`"
				>
					{{ bid.name }}
				</router-link>
			</div>
			<bid-status-badge :status="bid.status" />
		</li>
	</ul>
</b-card>
</template>
<script>

import Avatar from '../Avatar.vue';
import BidStatusBadge from '../BidStatusBadge.vue';

export default {
	components: { Avatar, BidStatusBadge },
	props: {
		estimator: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
		};
	},
};
</script>
