export default class ValidateService {
	constructor({ to, libAjv, _ }) {
		this.to = to;
		this.validators = libAjv;
		this._ = _;
	}

	async validate(payload, type) {
		const validator = this.validators.getSchema(`#/definitions/${type}`);
		if (validator === undefined) {
			throw new Error(`No validators registered for ${type}`);
		}

		const valid = validator(payload);
		if (!valid) {
			throw new Error(`Invalid payload: ${validator.errors[0].message} on (${validator.errors[0].instancePath})`);
		}

		return true;
	}
}
