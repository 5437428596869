import tableService from './table-service.mjs';

export default {
	mutations: {
		...tableService.mutations,
		setProjects(state, projects) {
			const { _ } = this.$cradle;
			state.projects = _.sortBy(projects, ['project_number']);
		},
		setLoadStatus(state, status) {
			state.loaded = status;
		},
	},
	actions: {
		...tableService.actions,
		async initialize({ commit, dispatch, rootState }) {
			commit('initializeColumns');
			// Make sure these are intialized before we try to fetch them...
			await dispatch('region/getRegions', null, { root: true });
			await dispatch('rgm/getRGMS', null, { root: true });
			await dispatch('projectManager/getProjectManagers', null, { root: true });
			await dispatch('siteSupervisor/getSiteSupervisors', null, { root: true });
			await dispatch('generalContractor/getGeneralContractors', null, { root: true });

			commit(
				'setOptions',
				{ items: rootState.region.regions.map(({ name }) => name), target: 'Region' },
			);

			commit(
				'setOptions',
				{ items: rootState.types.projectTypes.map(({ value }) => value), target: 'Project Type' },
			);

			commit(
				'setOptions',
				{ items: rootState.types.states.map(({ abbreviation }) => abbreviation), target: 'State' },
			);

			commit(
				'setOptions',
				{ items: rootState.types.projectStatus.map(({ value }) => value), target: 'Status' },
			);

			commit(
				'setOptions',
				{ items: rootState.projectManager.projectManagers.map(({ name }) => name), target: 'Project Manager' },
			);

			commit(
				'setOptions',
				{ items: rootState.siteSupervisor.siteSupervisors.map(({ name }) => name.toUpperCase()), target: 'Site Supervisor' },
			);

			commit(
				'setOptions',
				{ items: rootState.generalContractor.generalContractors.map(({ name }) => name), target: 'General Contractor' },
			);

			commit(
				'setOptions',
				{ items: rootState.rgm.rgms.map(({ name }) => name), target: 'RGM' },
			);
		},
	},
};
