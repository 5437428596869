<template>
<a
	class="card card-hover-shadow h-100 mt-2"
	href="javascript:void(0);"
>
	<div class="card-body">
		<h6 class="card-subtitle">
			BIDS IN {{ region.name }}
		</h6>
		<div class="row">
			<div class="col-auto">
				<span class="card-title h2">{{ region.count }}</span>
			</div>
		</div>
		<span class="text-body font-size-sm ml-1">active bids</span>
	</div>
</a>
</template>
<script>
export default {
	props: {
		region: {
			type: Object,
			default() {
				return {};
			},
		},
	},
};
</script>
