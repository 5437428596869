export default class BidCSTakeOffRepo {
	constructor({ _, supaTo, supabaseClientFactory, supabaseQueryService, to }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this.supabaseQueryService = supabaseQueryService;
		this._ = _;
		this.to = to;
	}

	async getBidsByParams() {
		const [err, response] = await this.supaTo(this.supabase.from('bidcs_takeoff_view').select('*'));
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getBids() {
		const [err, response] = await this.supaTo(this.supabase.from('bidcs_takeoff_view').select('*'));
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getCSSheetsByParams(params) {
		const base = this.supabase.from('cs_sheet').select('*, cs_file!inner(link, name, job_number)');
		const query = this.supabaseQueryService.createQuery(base, params);
		const [err, response] = await this.supaTo(query);

		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getAllCSSheets() {
		const [err, response] = await this.supaTo(this.supabase.from('bidcs_takeoff_view').select('*'));
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}
}
