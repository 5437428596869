<template>
<div
	id="feedbackDropdown"
	class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right
	navbar-dropdown-menu hs-unfold-content-initialized hs-unfold-css-animation animated slideInUp"
	style="width: 25rem; animation-duration: 300ms;"
	data-hs-target-height="212"
	data-hs-unfold-content=""
	data-hs-unfold-content-animation-in="slideInUp"
	data-hs-unfold-content-animation-out="fadeOut"
>
	<div class="card-body">
		<form @submit.prevent="submitFeedback">
			<textarea
				id="feedback-content"
				v-model="content"
				rows="3"
				class="form-control"
				placeholder="Ideas on how to improve this page."
			/>
			<button
				class="btn btn-secondary my-4 float-left"
				@click="toggle"
			>
				Cancel
			</button>
			<button
				type="submit"
				class="btn btn-primary my-4 float-right"
			>
				Send Feedback
			</button>
		</form>
	</div>
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
	props: {
		toggle: {
			type: Function,
			default() {
				return () => {};
			},
		},
	},
	data() {
		return {
			content: '',
		};
	},
	computed: {
		...mapState('authentication', ['user']),
		environment() {
			return 'CURRENT_ENVIRONMENT';
		},
	},
	methods: {
		...mapActions('feedback', ['createFeedback']),
		...mapActions('alert', ['createAlert']),
		async submitFeedback() {
			const { content, user, environment } = this;
			const { to } = this.$cradle();

			const [err] = await to(this.createFeedback({
				name: user.name,
				email: user.email,
				content,
				url: window.location.href,
				environment,
			}));

			if (err) {
				this.createAlert({
					message: 'Something went wrong with your request. Please try again. If the this error persists, contact support directly.',
					type: 'error',
				});
				return;
			}

			this.createAlert({
				message: 'Thank you for your feedback! We will review your feedback shortly!',
				type: 'success',
			});

			this.toggle();
		},
	},
};
</script>
