export default class RegionRepo {
	constructor({ sbTo, supabaseClientFactory, _ }) {
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getRegionById(id) {
		if (!id) {
			throw new Error('id is required for get region by id');
		}
		const [error, data] = await this.sbTo(
			this.supabase
				.from('region_name')
				.select('*')
				.match({ id }),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data[0]', null);
		}
	}

	async getRegionIdByDate(region, theDate) {
		if (!region) {
			throw new Error('region is required for get region');
		}

		const [error, regionId] = await this.sbTo(this.supabase.rpc('get_region', {
			region_name_var: region,
			date_var: theDate,
		}));
		if (error) {
			throw error;
		}
		return regionId;
	}
}
