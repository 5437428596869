<template>
<div class="d-flex justify-content-center">
	<div
		class="spinner-border"
		role="status"
	>
		<span class="sr-only">Loading...</span>
	</div>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
</style>
