import Vue from 'vue';
import VueRouter from 'vue-router';
import Bootstrap from '../views/Bootstrap.vue';
import Dashboard from '../views/Dashboard.vue';
import ReleaseNotes from '../views/ReleaseNotes.vue';
import Authentication from '../views/Authentication.vue';

import HistoricalDataRoutes from './HistoricalData';
import SettingsRoutes from './Settings';
import ProjectRoutes from './Projects';
import BidsRoutes from './Bids';
import createBreadcrumbs from '../services/breadcrumbs-service.mjs';
import store from '../store';

Vue.use(VueRouter);

function generateBreadcrumbs(route, startPath = []) {
	return createBreadcrumbs(route, startPath);
}

function routeParamToFriendly(routeParam) {
	if (!routeParam || routeParam === '') {
		return null;
	}

	const s = routeParam.split('-');
	const m = s.map((item) => item.charAt(0).toUpperCase() + item.slice(1));
	return m.join(' ');
}

const routes = [
	{
		path: '/bid-log/:filter?',
		name: 'Bid Log',
		component: () => import(/* webpackChunkName: "about" */ '../views/BidLog.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs([{
					name: routeParamToFriendly(this.$route.params.filter),
					destination: this.$route.path,
				}], [{
					name: 'Bid Log',
					destination: '/bid-log',
				}]);
			},
		},
		props: true,
	},
	{
		path: '/archived-bid-log/:filter?',
		name: 'Archived Bid Log',
		component: () => import(/* webpackChunkName: "about" */ '../views/ArchivedBidLog.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs([{
					name: routeParamToFriendly(this.$route.params.filter),
					destination: this.$route.path,
				}], [{
					name: 'Archived Bid Log',
					destination: '/archived-bid-log',
				}]);
			},
		},
		props: true,
	},
	BidsRoutes,
	{
		name: 'Exec Summary',
		path: '/reports/exec-summary',
		component: () => import('../views/reports/ExecSummary.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs({
					name: 'Exec Summary',
					destination: '/reports/exec-summary',
				});
			},
		},
	},
	{
		name: 'Pursuits',
		path: '/reports/pursuits',
		component: () => import('../views/reports/Pursuits.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs({
					name: 'Pursuits',
					destination: '/reports/pursuits',
				});
			},
		},
	},
	{
		name: 'Planned Billings',
		path: '/reports/planned-billings',
		component: () => import('../views/reports/PlannedBillings.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs({
					name: 'Planned Billings',
					destination: '/reports/planned-billings',
				});
			},
		},
	},
	{
		name: 'Regional Report',
		path: '/reports/regional-report/',
		component: () => import('../views/reports/RegionalReport.vue'),
		props: true,
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs({
					name: 'Regional Report',
					destination: '/reports/regional-report',
				});
			},
		},
	},
	{
		name: 'Estimating Summary',
		path: '/reports/estimating-summary-report/',
		component: () => import('../views/reports/EstimatingSummaryReport.vue'),
		props: true,
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs({
					name: 'Estimating Summary',
					destination: '/reports/estimating-summary-report/',
				});
			},
		},
	},
	{
		path: '/authentication',
		name: 'Authentication',
		component: Authentication,
		meta: {
			layout: 'LoginLayout',
		},
	},
	{
		path: '/projects/:filter?',
		name: 'Projects',
		component: () => import('../views/Projects.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs([{
					name: routeParamToFriendly(this.$route.params.filter),
					destination: this.$route.path,
				}], [{
					name: 'Projects',
					destination: '/projects',
				}]);
			},
		},
	},
	{
		path: '/archived-projects/:filter?',
		name: 'Archived Projects',
		component: () => import('../views/ArchivedProjects.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs([{
					name: routeParamToFriendly(this.$route.params.filter),
					destination: this.$route.path,
				}], [{
					name: 'Archived Projects',
					destination: '/archived-projects',
				}]);
			},
		},
	},
	ProjectRoutes,
	SettingsRoutes,
	HistoricalDataRoutes,
	{
		path: '/pricing/:material',
		name: 'Pricing',
		component: () => import('../views/Pricing.vue'),
		props: true,
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs({
					name: 'Pricing',
					destination: this.material,
				});
			},
		},
	},
	{
		path: '/white-board',
		name: 'Pricing White Board',
		component: () => import('../views/WhiteBoard.vue'),
		meta: {
			authenticate: true,
			breadcrumbs() {
				return generateBreadcrumbs({
					name: 'Whiteboard',
					destination: '/white-board',
				});
			},
		},
		children: [
			{
				name: 'Whiteboard Sheet',
				path: ':id',
				props: true,
				component: () => import('../views/WhiteBoardSheet.vue'),
				meta: {
					breadcrumbs() {
						//				const { archived, id: bidId } = this.bid;
						//				return generateBreadcrumbs([{
						//					name: 'Buildings',
						//					destination: `/bid-form/${bidId}/buildings`,
						//				}], bidId, archived, this.briefBidName);
					},
				},
			},
		],
	},
	{
		path: '/release-notes',
		name: 'Release Notes',
		component: ReleaseNotes,
		meta: { authenticate: true },
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			authenticate: true,
			breadcrumbs: [{
				name: 'Dashboard',
				destination: '/',
			}],
		},
	},
	{
		name: '',
		path: '/',
		redirect: '/dashboard',
	},
	{
		path: '*',
		name: 'Bootstrap',
		component: Bootstrap,
		meta: {
			layout: 'WhiteLayout',
		},
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.authenticate)) {
		// Validate Session
		const reathenticate = await store.dispatch('authentication/reauthenticate');
		if (!reathenticate) {
			await store.dispatch('authentication/logout');
			return next({
				path: '/authentication',
			});
		}

		if (store.getters['authentication/authenticated'] || to.path.includes('/access_token')) {
			return next();
		}

		if (to.path !== '/') {
			await store.dispatch('authentication/setInitialRoute', to.path);
		}

		const reAuthenticated = await store.dispatch('authentication/reauthenticate');

		if (reAuthenticated) {
			return next({ path: store.getters['authentication/initialRoute'] });
		}

		return next({
			path: '/authentication',
		});
	}
	return next();
});

export default router;
