export default {
	mutations: {
		setFilters(state, filters) {
			const { _ } = this.$cradle;
			const f = _.omitBy(filters, (value) => value === 'Either');
			state.query.filters = f;
		},
		setColumns(state, columns) {
			if (Array.isArray(columns) === false || columns.length === 0) {
				return;
			}

			let c = columns;

			if (typeof columns[0] !== 'string') {
				c = columns.map((column) => column.name);
			}

			const columnList = [...state.columns];

			const toggledCols = columnList.map((item) => {
				item.display = c.includes(item.name);
				return item;
			});

			// Order to match incoming column order.
			state.columns = toggledCols.sort((a, b) => columns.indexOf(a.name) - columns.indexOf(b.name));
		},
		setFilterValue(state, { filter, value }) {
			state.query.filters[filter] = value;
		},
		setPage(state, page) {
			const cursor = page - 1;
			state.query.page = page;
			state.query.from = cursor * state.query.limit;
			state.query.to = state.query.from + state.query.limit;
		},
		setOptions(state, { items, target }) {
			const index = state.columns.findIndex(({ name }) => name === target);

			if (index === -1) {
				return;
			}

			state.columns[index].options = items;
		},
		setSort(state, { by, desc }) {
			let realby = by;
			if (by.includes('custom')) {
				realby = state.columns.find(({ key }) => key === by)?.filterName || by;
			}

			state.query.sort.by = realby;
			state.query.sort.desc = desc;
		},
		addAvailableFilter(state, filter) {
			state.availableFilters.push(filter);
		},
		setAvailableFilter(state, filters) {
			if (filters.length === 0) {
				return;
			}

			const defaultFilters = state.defaultFilters || [];
			state.availableFilters = [...defaultFilters, ...filters];
		},
		setFilterFromAvailableFilters(state, filter) {
			state.query.filters = { ...filter };
		},
		removeFilter(state, filterId) {
			const index = state.availableFilters.findIndex(({ id }) => id === filterId);

			state.availableFilters.splice(index, 1);
		},
		setInactiveColumns(state) {
			const i = JSON.parse(localStorage.getItem(`${state.table}-settings`));
			if (!i || i.length === 0) {
				return;
			}
			state.columns = state.columns.map((item) => {
				item.display = i.includes(item.key) === false;
				return item;
			});
		},
		setTotalRows(state, count) {
			state.query.totalRows = count;
		},
		initializeColumns(state) {
			const { _ } = this.$cradle;
			state.columns = [];

			_.forEach(state.filterGroups, (item) => {
				_.forEach(item.fields, (subItem) => {
					state.columns.push(subItem);
				});
			});

			state.columns = state.columns.sort((a, b) => {
				if (!a.order) a.order = 50;
				if (!b.order) b.order = 50;
				return a.order - b.order;
			});
		},
	},
	actions: {
		async setSort({ commit, dispatch }, sorting) {
			commit('setSort', sorting);
			await dispatch('getAllBids');
		},
		async resetFilters({ commit }) {
			commit('setFilters', {});
			commit('initializeColumns');
		},
		async createFilter({ rootState, state, commit }, { name, isPublic, filter }) {
			const { to, tableFilterRepo, logDnaFactory: logger } = this.$cradle;
			const newFilter = {
				filter,
				columns: state.columns,
				table: state.table,
				user_id: rootState.authentication.user.id,
				name,
				public: isPublic,
			};

			const [err, result] = await to(tableFilterRepo.createFilter(newFilter));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('addAvailableFilter', result);
		},
		async updateFilter({ rootState, state, dispatch }, { id, name, isPublic, filter }) {
			const { to, tableFilterRepo, logDnaFactory: logger } = this.$cradle;
			const updateFilter = {
				filter,
				columns: state.columns,
				table: state.table,
				user_id: rootState.authentication.user.id,
				name,
				public: isPublic,
			};

			const [err] = await to(tableFilterRepo.updateFilter(id, updateFilter));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			await dispatch('getAvailableFilters');
		},
		async getAvailableFilters({ rootState, commit, state }) {
			const { to, tableFilterRepo, logDnaFactory: logger } = this.$cradle;

			const [err, result] = await to(tableFilterRepo.getAvailableFilters(rootState.authentication.user.id, state.table));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setAvailableFilter', result.data);
		},
		async deleteFilter({ commit }, id) {
			const { to, tableFilterRepo, logDnaFactory: logger } = this.$cradle;

			const [err] = await to(tableFilterRepo.deleteFilter(id));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('removeFilter', id);
		},
		persistColumns({ state }) {
			const inactiveColumns = state.columns.filter(({ display }) => !display).map(({ key }) => key);
			localStorage.setItem(`${state.table}-settings`, JSON.stringify(inactiveColumns));
		},
	},
};
