export default class TableFilterRepo {
	constructor({ supaTo, supabaseClientFactory, _ }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getAvailableFilters(userId, table) {
		const [err, data] = await this.supaTo(this.supabase
			.from('table_filter')
			.select('*')
			.or(`user_id.eq.${userId},public.eq.true`)
			.eq('table', table)
			.eq('deleted', false));

		if (err) {
			throw err;
		}

		return data;
	}

	async getFilterById(id) {
		const [err, response] = await this.supaTo(
			this.supabase.from('table_filter').select('*').match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', []);
		}
	}

	async createFilter(filter) {
		const [err, response] = await this.supaTo(
			this.supabase.from('table_filter').insert(filter),
		);
		if (err) {
			throw err;
		} else {
			const { id } = this._.get(response, 'data[0]', {});
			return this.getFilterById(id);
		}
	}

	async updateFilter(id, filter) {
		const [err] = await this.supaTo(
			this.supabase.from('table_filter').update(filter).match({ id }),
		);
		if (err) {
			throw err;
		}
	}

	async deleteFilter(id) {
		const [err, response] = await this.supaTo(
			this.supabase.from('table_filter').update({ deleted: true }).match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}
}
