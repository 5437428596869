<template>
<div>
	<full-screen-modal :show.sync="show">
		<template #header>
			<span class="badge bg-secondary text-white mr-1">{{ project.project_number }}</span>
			<h4>{{ project.name }} / Inbox</h4>
			<email-copy :prefix="project.project_number" />
		</template>
		<template #body>
			<div
				v-if="!hasEmail"
				class="container"
			>
				<div class="row justify-content-center w-50 mx-auto">
					<div
						class="alert alert-primary col"
						role="alert"
					>
						<div class="d-flex">
							<div class="flex-shrink-0">
								<i class="tio-info" />
							</div>
							<div class="flex-grow-1 ms-2">
								<b>Information:</b> There are no Archived Emails for this project.
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center w-50 mx-auto text-center">
					<div class="col">
						<p>
							Additional emails can be added to the archive by including for following
							address in the TO, CC or BCC fields on any email.
						</p>
						<email-copy
							class="inner-email"
							:prefix="project.project_number"
						/>
						<p>Emails for the archive can be forwarded to this email as well.</p>
					</div>
				</div>
			</div>

			<div v-if="hasEmail">
				<data-table
					title="Emails"
					class="middle-align"
					:headers="headerRows"
					:items="formatedMessages"
				/>
			</div>
		</template>
	</full-screen-modal>
</div>
</template>
<script>
import { mapState } from 'vuex';
import utilitiesMixin from '../../mixins/utilities';

import FullScreenModal from '../FullScreenModal.vue';
import DataTable from '../DataTable.vue';
import EmailCopy from '../EmailCopy.vue';

export default {
	components: { FullScreenModal, DataTable, EmailCopy },
	mixins: [utilitiesMixin],
	data() {
		return {
			loading: false,
			headerRows: [
				{ key: 'FromFull.Name', sortable: true, name: 'From' },
				{ key: 'to', sortable: false, name: 'To' },
				{ key: 'subject', sortable: true, name: 'Subject' },
				{ key: 'sent_date', sortable: true, name: 'Sent' },
				//				{ key: 'cc', sortable: false, name: 'Cc' },
				//				{ key: 'bcc', sortable: false, name: 'Bcc' },
			],
		};
	},
	computed: {
		...mapState('inbox', ['messages']),
		...mapState('activeProject', ['project']),
		formatedMessages() {
			return this.messages.map((item) => {
				item.sent_date = item.sent_date ? this.formatDateTime(item.sent_date, '-') : '';
				item.to = this.toEmailString(item.ToFull);
				item.cc = this.toEmailString(item.CcFull);
				item.bcc = this.toEmailString(item.BccFull);
				return item;
			});
		},
		show: {
			get() {
				return this.$store.state.inbox.show;
			},
			set(value) {
				this.$store.commit('inbox/setShow', value);
			},
		},
		hasEmail() {
			return (this.messages.length > 0);
		},
	},
	methods: {
		toEmailString(emails) {
			const formated = emails.map((item) => {
				const addy = item.Email ? `<${item.Email}>` : '';
				const name = item.Name ? `${item.Name} ` : '';
				return `${name}${addy}`;
			});

			return formated.join(',');
		},

	},
};
</script>
<style scoped>
	.modal-header h4 {
		text-align: left;
		width: 100%;
		margin: 0px;
	}

	.inner-email {
		width: 75%;
		margin-left: 30px;
		margin-bottom: 1rem;
	}
</style>
