export default class ProjectRepo {
	constructor({ supaTo, supabaseClientFactory, _, supabaseQueryService, to, sbTo }) {
		this.supaTo = supaTo;
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this.supabaseQueryService = supabaseQueryService;
		this._ = _;
		this.to = to;
	}

	async updateSheetData(sheetId, sheetType, sheetKind) {
		const [err, payload] = await this.sbTo(
			this.supabase.rpc('get_smartsheet_summary', { sheet_id: sheetId, sheet_type: sheetType, sheet_kind: sheetKind }),
		);

		if (err) {
			throw err;
		}

		return payload;
	}

	async getSheetData(sheetType) {
		const [err, data] = await this.sbTo(
			this.supabase.from('sheet').select('payload').eq('type', sheetType),
		);

		if (err) {
			throw err;
		}

		return this._.get(data, '[0].payload', null);
	}

	async getExecutiveSummaryData() {
		const [err, payload] = await this.sbTo(
			this.supabase.rpc('get_executive_summary'),
		);

		if (err) {
			throw err;
		}

		return payload;
	}
}
