<template>
<header
	id="header"
	class="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered"
>
	<div class="navbar-nav-wrap">
		<div class="navbar-nav-wrap-content-left">
			<!-- Navbar Vertical Toggle -->
			<button
				id="left-nav-trigger-btn"
				type="button"
				class="js-navbar-vertical-aside-toggle-invoker close mr-3"
				@click.prevent="
					navState === 'FULL' ? setToMiniMode() : setNav('FULL')
				"
			>
				<i
					v-if="!isMobileOrTablet"
					:class="menuIconClassObj"
				/>
				<i
					v-if="isMobileOrTablet && navState !== 'FULL'"
					ref="mobile-hamburger-icon"
					class="tio-menu-hamburger"
					@click.prevent="onMobileHamburgerClick($event)"
				/>
			</button>
			<breadcrumbs :crumbs="crumbs" />
		</div>
		<!-- Secondary Content -->
		<div class="navbar-nav-wrap-content-right">
			<!-- Navbar -->
			<ul class="navbar-nav align-items-center flex-row">
				<li class="nav-item">
					<div class="hs-unfold">
						<button
							type="button"
							class="btn btn-secondary btn-sm"
							@click="toggleFeedbackDisplay"
						>
							<i class="tio-help-outlined ml-1" />
							Feedback on this Page?
						</button>
						<feedback-form
							v-if="displayFeedbackForm"
							:toggle="toggleFeedbackDisplay"
						/>
					</div>
				</li>
				<li class="nav-item">
					<!-- Account -->
					<account-dropdown />
					<!-- End Account -->
				</li>
			</ul>
			<!-- End Navbar -->
		</div>
		<!-- End Secondary Content -->
	</div>
</header>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import AccountDropdown from './AccountDropdown.vue';
import FeedbackForm from './FeedbackForm.vue';
import Breadcrumbs from './Breadcrumbs.vue';
import isMobileOrTablet from '../mixins/isMobileOrTablet.js';

export default {
	components: { AccountDropdown, FeedbackForm, Breadcrumbs },
	mixins: [isMobileOrTablet],
	data() {
		return {
			displayFeedbackForm: false,
			crumbs: [],
		};
	},
	computed: {
		...mapState('leftNav', ['navState']),
		menuIconClassObj() {
			return {
				'tio-menu-hamburger': true,
			};
		},
	},
	methods: {
		...mapMutations('leftNav', ['toggleLeftNav', 'setNav']),
		closeAllSubNavs() {
			const allSubNavs = document.getElementsByClassName('nav-sub');
			Array.from(allSubNavs).forEach((s) => {
				s.style.display = 'none';
			});
		},
		toggleFeedbackDisplay() {
			this.displayFeedbackForm = !this.displayFeedbackForm;
		},
		setToMiniMode() {
			this.closeAllSubNavs();
			this.setNav('MINI');
		},
		onMobileHamburgerClick(e) {
			e.stopPropagation();
			if (this.navState === 'FULL') {
				this.setNav('NONE');
			} else {
				this.setNav('FULL');
			}
		},
	},
};
</script>
