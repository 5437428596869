export default {
	namespaced: true,
	state: {
		bid: {},
		formStatus: '',
		bidFormLoading: false,
		bidSaving: false,
		loaded: false,
		archival: {
			show: false,
			generalReason: '',
			detailedReason: '',
		},
		archivalReason: {},
		prediction: {},
		reloadMap: false,
		revisions: [],
		display_revisions: false,
		showEditModal: false,
	},
	getters: {
		editable(state, getters, rootState) {
			if (state.bid.archived === true) {
				return false;
			}

			const bidRevisionId = rootState.route?.params?.bidRevisionId;

			if (bidRevisionId === undefined
			|| state.bid.current_revision_id === bidRevisionId) {
				return true;
			}
			return false;
		},
		getRevsionNo: (state) => (rev) => {
			const r = state.revisions.find((item) => item.id === rev);
			return r?.revision_num;
		},
		getBuildingById: (state) => (id) => state.bid?.bid_revision?.building.find((item) => item.id === id),
		getBuildingTotalNumberOfUnitsById: (state, getters) => (id) => {
			const building = getters.getBuildingById(id);
			if (!building.level || !building.level.length) {
				return 0;
			}
			return building.level.map((item) => item.number_of_units).reduce((a, b) => +a + +b);
		},
		getBuildingTotalWallSquareFootageById: (state, getters) => (id) => {
			const building = getters.getBuildingById(id);

			if (!building.level || !building.level.length) {
				return 0;
			}

			let total = 0;
			const walls = building.level.filter((item) => item.included).map((item) => item.wall_gross_square_footage);

			for (let index = 0; index < walls.length; index++) {
				total += walls[index];
			}

			if (Number.isNaN(total) || total === undefined) {
				total = 0;
			}

			return total;
		},
		getBuildingTotalFloorSquareFootageById: (state, getters) => (id) => {
			const building = getters.getBuildingById(id);

			if (!building.level || !building.level.length) {
				return 0;
			}

			let total = 0;
			const floors = building.level.filter((item) => item.included).map((item) => item.floor_gross_square_footage);

			for (let index = 0; index < floors.length; index++) {
				total += floors[index];
			}

			if (Number.isNaN(total) || total === undefined) {
				total = 0;
			}

			return total;
		},
		getBuildingTotalPorchSquareFootageById: (state, getters) => (id) => {
			const building = getters.getBuildingById(id);

			if (!building.level || !building.level.length) {
				return 0;
			}

			let total = 0;
			const porchs = building.level.filter((item) => item.included).map((item) => item.porch_gross_square_footage);

			for (let index = 0; index < porchs.length; index++) {
				total += porchs[index];
			}

			if (Number.isNaN(total) || total === undefined) {
				total = 0;
			}

			return total;
		},
		getBuildingTotalSquareFootageById: (state, getters) => (id) => {
			const building = getters.getBuildingById(id);

			if (!building.level || !building.level.length) {
				return 0;
			}

			const roof = Number(building.roof_gross_square_footage);
			let total = roof;

			const levels = building.level.filter((item) => item.included);

			for (let index = 0; index < levels.length; index++) {
				total += levels[index].porch_gross_square_footage;
				total += levels[index].floor_gross_square_footage;
			}

			if (Number.isNaN(total) || total === undefined) {
				total = 0;
			}

			return total;
		},
		briefBidName(state) {
			if (state.bid?.name?.length > 26) {
				return `${state.bid.name.slice(0, 25)}...`;
			}

			return state.bid.name;
		},
	},
	mutations: {
		setRevisions(state, revisions) {
			state.revisions = revisions;
		},
		setBidState(state, status) {
			state.bid.status = status;
		},
		setArchivalReason(state, archivalReason) {
			state.archivalReason = archivalReason;
		},
		setSavingStatus(state, saving) {
			state.bidSaving = saving;
		},
		addBuildingToBid(state, building) {
			if (!state.bid.bid_revision.building) {
				state.bid.bid_revision.building = [];
			}

			state.bid.bid_revision.building.push(building);
		},
		updateBidSupplier(state, [value, supplier, updateProperty]) {
			const supplierToUpdate = state.bid.bid_revision.bid_supplier.find((_supplier) => supplier.id === _supplier.supplier.id
			&& supplier.category === _supplier.category);
			supplierToUpdate[updateProperty] = value;
		},
		addLevelToBuilding(state, { buildingId, level }) {
			const index = state.bid.bid_revision.building.findIndex((item) => item.id === buildingId);
			if (!state.bid.bid_revision.building[index].level) {
				state.bid.bid_revision.building[index].level = [];
			}
			state.bid.bid_revision.building[index].level.push(level);
		},
		setBidFormLoading(state, loading) {
			state.bidFormLoading = loading;
		},
		setBidStatus(state, status) {
			state.bid.status = status;
		},
		setBid(state, bid) {
			const deepCopy = JSON.parse(JSON.stringify(bid));
			state.bid = deepCopy;
			state.bid.bid_revision.building.sort((a, b) => a.order - b.order);
			state.archivalReason = {};
			if (state.bid.bid_revision.bid_general_contractor) {
				state.bid.bid_revision.bid_general_contractor = state.bid.bid_revision.bid_general_contractor
					.map((item) => item.general_contractor_id);
			}
		},
		setSquareFootage(state) {
			let total = 0;

			const values = state.bid.bid_revision.building
				.map((building) => building.level.map((level) => +level.floor_gross_square_footage)).flat();

			if (values.length) {
				total = values.reduce((a, b) => a + b);
			}

			state.bid.bid_revision.square_footage = total;
		},
		setPorchSquareFootage(state) {
			let total = 0;

			const values = state.bid.bid_revision.building
				.map((building) => building.level.map((level) => +level.porch_gross_square_footage)).flat();

			if (values.length) {
				total = values.reduce((a, b) => a + b);
			}

			state.bid.bid_revision.porch_square_footage = total;
		},
		setUnits(state) {
			const values = state.bid.bid_revision.building
				.map((building) => building.level.map((level) => +level.number_of_units)).flat();
			state.bid.bid_revision.units = values.reduce((a, b) => a + b);
		},
		setFormStatus(state, status) {
			state.formStatus = status;
		},
		updateBuildingOrder(state) {
			state.bid.bid_revision.building.map((item, index) => {
				item.order = index;
				return item;
			});
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
		},
		toggleArchival(state) {
			state.archival.show = !state.archival.show;
		},
		toggleRevisionsDisplay(state) {
			state.display_revisions = !state.display_revisions;
		},
		addSupplier(state, supplier) {
			if (state.bid.bid_revision.bid_supplier) {
				state.bid.bid_revision.bid_supplier.push(supplier);
			} else {
				state.bid.bid_revision.bid_supplier = [supplier];
			}
		},
		setPrediction(state, prediction) {
			state.prediction = prediction;
		},
		setReloadMap(state, reload) {
			state.reloadMap = reload;
		},
		setShowEditModal(state, show) {
			state.showEditModal = show;
		},
	},
	actions: {
		async fetchBid({ commit, dispatch }, { bidId, bidRevisionId }) {
			commit('setBidFormLoading', true);
			const { bidRepo, to, logDnaFactory: logger } = this.$cradle;
			let err; let
				bid;
			if (bidRevisionId) {
				[err, bid] = await to(bidRepo.getBidByIdAndRev(bidId, bidRevisionId));
			} else {
				[err, bid] = await to(bidRepo.getBidById(bidId));
			}
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setBid', bid);
			commit('setBidFormLoading', false);
			await dispatch('supplier/getSuppliersByBidRevision', bid.bid_revision.id, { root: true });

			if (bid.archived) {
				await dispatch('getArchivalReason', bid.id);
			}
			const [errB] = await to(dispatch('getBidRevisions', bid.id));
			if (errB) {
				logger.error(errB.message);
				throw errB;
			}
		},

		async getBidRevisions({ commit }, bidId) {
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;

			const [err, revisions] = await to(bidRepo.getRevisionsByBidId(bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setRevisions', revisions);
		},

		async updateBidSupplier({ commit, dispatch, state }, [value, supplier, updateProperty]) {
			const { to } = this.$cradle;
			commit('updateBidSupplier', [value, supplier, updateProperty]);
			const supplierToUpdate = state.bid.bid_revision.bid_supplier.find((_supplier) => supplier.id === _supplier.supplier.id
			 && supplier.category === _supplier.category);
			await to(dispatch('supplier/updateBidSupplier', supplierToUpdate, { root: true }));
		},

		async cloneBid({ dispatch, commit }, bidId) {
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;

			await dispatch('updateBid');

			const [err, cloned] = await to(bidRepo.cloneBid(bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			if (cloned.archived) {
				commit('archivedBidLog/insertBid', cloned, { root: true });
			} else {
				commit('bidLog/insertBid', cloned, { root: true });
			}

			return cloned;
		},
		async reviseBid({ dispatch, commit }, bidId) {
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;

			await dispatch('updateBid');

			const [err, revisedBid] = await to(bidRepo.reviseBid(bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}
			if (revisedBid.archived) {
				commit('archivedBidLog/setUpdatedBid', revisedBid, { root: true });
			} else {
				commit('bidLog/setUpdatedBid', revisedBid, { root: true });
			}
			commit('setBid', revisedBid);
			return revisedBid;
		},
		async archiveBid({ commit, state }, bidId) {
			const { to, bidRepo, archivalReasonRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(archivalReasonRepo.createArchivalReason({
				bid_id: bidId,
				general_reason: state.archival.generalReason,
				detailed_reason: state.archival.detailedReason,
			}));
			if (err) {
				logger.error(err.message);
			}
			const [err1] = await to(bidRepo.archiveBidById(bidId));
			if (err1) {
				logger.error(err1.message);
				throw err1;
			} else {
				commit('toggleArchival');
				commit('setArchivalReason', data);
			}
		},
		async toggleArchival({ commit }) {
			commit('toggleArchival');
		},
		async toggleRevisionsDisplay({ commit }) {
			commit('toggleRevisionsDisplay');
		},
		async updateBid({ commit, dispatch, state }) {
			const { to, bidRepo, buildingRepo, levelRepo, generalContractorRepo, logDnaFactory: logger, _ } = this.$cradle;
			commit('setSavingStatus', true);
			commit('setSquareFootage');
			commit('setPorchSquareFootage');

			const bidCopy = _.cloneDeep(state.bid);
			const revision = bidCopy.bid_revision;
			const buildings = [...revision.building];
			const { general_contractor: generalContractor } = revision;
			delete revision.general_contractor;
			delete bidCopy.bid_revision;
			delete revision.building;
			delete revision.bid_supplier;
			delete revision.estimator;
			delete revision.bid_activity_log;
			delete revision.comment;

			let buildingError;
			let levelError;
			const levels = [];

			if (buildings.length) {
				for (const building of buildings) {
					if (building.level && building.level.length) {
						levels.push(...building.level);
					}
					delete building.level;
				}

				[buildingError] = await to(Promise.all(buildings.map((item) => buildingRepo.updateBuilding(item))));
				[levelError] = await to(Promise.all(levels.map((item) => levelRepo.updateLevel(item))));
			}

			revision.estimated_total = Number.parseInt(revision.estimated_labor, 10) + Number.parseInt(revision.estimated_material, 10);

			const [revisionErr] = await to(bidRepo.updateBidRevision(revision));
			const [bidErr, updatedBid] = await to(bidRepo.updateBid({ id: bidCopy.id, name: bidCopy.name, status: bidCopy.status }));

			await to(dispatch('supplier/saveSuppliers', {}, { root: true }));

			let gcError;
			if (generalContractor.length) {
				[gcError] = await to(Promise.all(generalContractor.map((item) => generalContractorRepo.addContractorToBid(revision.id, item))));
				// Ignore duplicate keys. These are items that are already saved.
				if (gcError && gcError.message.includes('duplicate key value violates unique constraint "bid_general_contractor_pkey"')) {
					gcError = null;
				}
			}

			const err = revisionErr || bidErr || buildingError || levelError || gcError;

			if (err) {
				commit('setSavingStatus', false);
				logger.error(err.message);
				throw err;
			} else {
				if (updatedBid.archived) {
					commit('archivedBidLog/setUpdatedBid', updatedBid, { root: true });
				} else {
					commit('bidLog/setUpdatedBid', updatedBid, { root: true });
				}
				commit('setSavingStatus', false);
				commit('setReloadMap', true);
				commit('supplier/clearBidSuppliers', null, { root: true });
				await dispatch('supplier/getSuppliersByBidRevision', state.bid.bid_revision.id, { root: true });
			}
		},
		async getArchivalReason({ commit }, bidId) {
			const { bidRepo, to, logDnaFactory: logger } = this.$cradle;

			const [err, archivalReason] = await to(bidRepo.getArchivalReason(bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setArchivalReason', archivalReason);
		},
		async createBid({ commit }) {
			commit('setBidFormLoading', true);
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;
			const [err, newBid] = await to(bidRepo.createBid());
			if (err) {
				logger.error(err.message);
				commit('setBidFormLoading', false);
				throw err;
			}
			commit('bidLog/insertBid', newBid, { root: true });
			commit('setBidFormLoading', false);
			return newBid;
		},
		async createBuilding({ commit }, { bidRevisionId, name }) {
			const { to, buildingRepo, logDnaFactory: logger } = this.$cradle;
			const [err, building] = await to(buildingRepo.createBuilding(bidRevisionId, name));
			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('addBuildingToBid', building);
			}
		},
		async getLastPrediction({ commit }, bidRevisionId) {
			const { to, predictionRepo, logDnaFactory: logger } = this.$cradle;
			const [err, prediction] = await to(predictionRepo.getLastPredictionByBidRevisionId(bidRevisionId));

			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('setPrediction', prediction);
			}
		},
		toggleEditModal({ state, commit }) {
			commit('setShowEditModal', !state.showEditModal);
		},
	},
};
