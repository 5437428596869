<template>
<div class="w-100">
	<li
		v-if="revision.divider"
		class="step-item"
	>
		<div class="step-content-wrapper">
			<small class="step-divider">{{ revision.divider }}</small>
		</div>
	</li>
	<li class="step-item">
		<div class="step-content-wrapper">
			<span class="step-icon step-icon-soft-dark">
				<div
					v-if="revision.user && revision.user.avatar"
					class="avatar avatar-sm avatar-circle mr-2"
				>
					<img
						class="avatar-img"
						:href="revision.user.avatar"
					>
				</div>
				<span
					v-else-if="userInitials"
					class="avatar avatar-light avatar-sm avatar-circle"
				>
					<span class="avatar-initials">{{ userInitials }}</span>
				</span>
				<span
					v-else
					class="icon icon-primary icon-circle"
				>
					<i class="tio-settings-outlined" />
				</span>
			</span>

			<div class="step-content">
				<h5 class="mb-1">
					<a
						class="text-dark"
						href="javascript:void(0);"
					>{{ username }}</a>
				</h5>

				<p class="font-size-sm">
					{{ types[revision.type] }}
					<span class="badge badge-soft-success">Rebid: {{ getRevsionNo(revision.resource_sub_id) }}</span>
					<span>{{ formatDateTime(revision.date) }}</span>
					<router-link
						:to="{ name: 'Bid Form', params: { bidId: revision.resource_id, bidRevisionId: revision.resource_sub_id }}"
						@click.native="toggleRevisionsDisplay"
					>
						View
					</router-link>
				</p>
			</div>
		</div>
	</li>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import utilitiesMixin from '../../mixins/utilities';

export default {
	mixins: [utilitiesMixin],
	props: {
		revision: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			types: {
				REBID: 'Created a rebid',
				CREATE: 'Created',
				CLONE: 'Cloned',
				ARCHIVED: 'Archived',
				WON: 'Set to "Won"',
				STATUS_CHANGE: 'Changed the status',
				PREDICTION: 'Created a new prediction',
				ADD_BID_SUPPLIER: 'Added a supplier',
			},
		};
	},
	computed: {
		userInitials() {
			return this.revision?.user?.name.split(' ').slice(0, 2).map((item) => item[0]).join('');
		},
		username() {
			return this.revision?.user?.name || 'System';
		},
		...mapGetters('activeBid', ['getRevsionNo']),
	},
	methods: {
		...mapActions('activeBid', ['toggleRevisionsDisplay']),
	},
};
</script>
