export default {
	namespaced: true,
	state: {
		percent: 0.0205,
		professional: {
			type: '',
			cost_code: '001-090',
			component: 'Professional Services',
			percent: 0.0205,
			cost: 0,
			tax_oh: 0,
			cost_tax_oh: '=E1+F1',
			margin_percent: '.12',
			margin_dollar: '=G1/(1-H1)-G1',
			percent_of_job: '',
			total: '=ROUND(SUM(G1,I1), 2)',
			cost_per_gross_sqft: '',
			sale_per_gross_sqft: '',
		},
		columns: [
			{ data: 'type', renderer: 'html' },
			{
				data: 'cost_code',
				type: 'autocomplete',
				source: ['001-140', '001-150'],
				strict: false,
			},
			{
				data: 'component',
			},
			{
				data: 'percent',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
				},
			},
			{
				data: 'cost',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'tax_oh',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'cost_tax_oh',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readonly: true,
			},
			{
				data: 'margin_percent',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'margin_dollar',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'percent_of_job',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'total',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'cost_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'sale_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
		],
		colHeaders: [
			'',
			'Cost Code',
			'',
			'',
			'Cost',
			'Tax + OH',
			'Cost + Tax + OH',
			'Margin%',
			'Margin $$',
			'% Of Job',
			'Total Amt',
			'Cost/Gross SF',
			'Sale/Gross SF',
		],
		comments: [],
	},
	getters: {
		professional: (state, getters, rootState) => {
			const { total, taxRate, overheadCostPerMH, manHourRate } = rootState.bidCs;
			const sqft = rootState.activeBid.bid.bid_revision.square_footage;

			const { material } = rootState.bidCsMaterial;
			const { labor } = rootState.bidCsLabor;
			const { equipment } = rootState.bidCsEquipment;

			const laborHours = labor.reduce((acc, item) => {
				if (!item.hours) return acc;
				return acc + Number(item.hours);
			}, 0);
			const materialCost = material.reduce((acc, item) => {
				if (!item.cost) return acc;
				return acc + Number(item.cost);
			}, 0);
			const laborCost = laborHours * manHourRate;
			const equipmentCost = equipment.reduce((acc, item) => {
				if (!item.cost_sf) return acc;
				return acc + (Number(item.cost_sf) * rootState.activeBid.bid.bid_revision.square_footage);
			}, 0);
			const tax = materialCost * (taxRate / 100);
			const overhead = laborHours * overheadCostPerMH;

			const taxOh = tax + overhead;
			const cost = materialCost + laborCost + equipmentCost;

			const res = { ...state.professional };

			res.cost = `=${cost}*D1`;
			res.tax_oh = `=${taxOh} * D1`;
			res.percent_of_job = `=ROUND(K1/${total} * 100, 2) / 100`;
			res.cost_per_gross_sqft = `=E1/${sqft}`;
			res.sale_per_gross_sqft = `=K1/${sqft}`;
			res.percent = state.percent;

			return [res];
		},
		totalItems(state, getters) {
			return getters.professional.length;
		},
	},
	mutations: {
		updateEquipment(state, payload) {
			state.equipment = payload;
		},
		addRow(state, reload) {
			const currentRow = state.equipment.length + 1;
			state.equipment.push({
				type: '',
				cost_code: '',
				component: '',
				hours: '0',
				cost: '0',
				overhead: '0',
				cost_oh: `=E${currentRow}+F${currentRow}`,
				margin_percent: '.12',
				margin_dollar: `=G${currentRow}/(1-H1)-G${currentRow}`,
				percent_of_job: '.12',
				total: `=G${currentRow}+I${currentRow}`,
			});

			reload();
		},
		removeRow(state, { row, reload }) {
			state.equipment.splice(row, 1);
			if (state.comments) {
				for (let i = 0; i < state.comments.length; i++) {
					if (state.comments[i].row === row) {
						state.comments.splice(i, 1);
					}
					if (state.comments[i].row > row) {
						state.comments[i].row -= 1;
					}
				}
			}
			reload();
		},
		setComments(state, payload) {
			state.comments = payload;
		},
		handleComment(state, data) {
			const existingCommentIndex = state.comments.findIndex(({ row, col }) => row === data.row && col === data.col);

			if (existingCommentIndex > -1) {
				if (data.comment === '') {
					state.comments.splice(existingCommentIndex, 1);
					return;
				}
				const existingComment = state.comments[existingCommentIndex];
				existingComment.comment = data.comment;
			} else {
				if (data.comment === '') return;
				state.comments.push(data);
			}
		},
	},
	actions: {
	},
};
