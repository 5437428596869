export default {
	namespaced: true,
	state: {
		engineers: [],
		loaded: false,
	},
	getters: {
		getEngineers: (state) => state.engineers.map(({ name }) => name),
	},
	mutations: {
		setEngineers(state, engineers) {
			state.engineers = engineers;
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
		},
	},
	actions: {
		async getEngineers({ commit, state }) {
			if (state.loaded) {
				return;
			}
			const { to, engineerRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(engineerRepo.getAllEngineers());
			if (err) {
				logger.error(err.message);
				commit('setEngineers', []);
			} else {
				commit('setEngineers', data);
				commit('setLoadingStatus', true);
			}
		},
	},
};
