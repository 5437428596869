export default class SheetInterface {
	constructor({ enums, to, sbTo, supabaseClientFactory, _ }, sheetType) {
		this.enums = enums;
		this.to = to;
		this.sbTo = sbTo;
		this._ = _;
		this.supabase = supabaseClientFactory;
		this.sheetType = undefined;

		this.isValidSheetType(sheetType);
	}

	_defaultRecord() {
		return {
			id: null,
			payload: {},
			type: this.sheetType,
		};
	}

	isValidSheetType(sheetType) {
		if (!sheetType) {
			throw new Error('SheetType is required');
		}

		if (!this._.has(this.enums.sheet_types, sheetType)) {
			throw new Error(`Invalid SheetType of ${sheetType}`);
		}

		this.sheetType = sheetType;
	}

	async upsertPayload(payload, id = null) {
		// Merge the defaults
		const [errA, merged] = await this.to(this._mergePayload(payload));
		if (errA) {
			throw errA;
		}

		const [err] = await this.to(this.validatePayload(merged));
		if (err) {
			throw err;
		}

		const data = this._defaultRecord();
		data.payload = merged;
		delete data.id;
		if (!this._.isNil(id)) {
			data.id = id;
		}

		const [errB, record] = await this.sbTo(
			this.supabase.from('sheet')
				.upsert(data),
		);
		if (errB) {
			throw errB;
		}

		return this._.get(record, '[0]', null);
	}

	async deleteWhiteboard(id) {
		const [errB] = await this.sbTo(
			this.supabase.from('sheet')
				.update({ deleted: true })
				.match({ id }),
		);
		if (errB) {
			throw errB;
		}

		return true;
	}

	async getSheet(id = null) {
		let record = this._defaultRecord();

		// Grab from the DB if id is set
		if (id) {
			const [errA, r] = await this.sbTo(
				this.supabase.from('sheet')
					.select('*')
					.eq('id', id),
			);
			if (errA) {
				throw errA;
			}

			record = this._.get(r, '[0]', record);
		}

		// Merge in the defaults
		const [errB, merged] = await this.to(this._mergePayload(record.payload));
		if (errB) {
			throw errB;
		}

		record.payload = merged;

		return record;
	}

	async getAllSheetsByType() {
		const [errA, sheets] = await this.sbTo(
			this.supabase.from('sheet')
				.select('*')
				.eq('type', this.sheetType)
				.eq('deleted', false),
		);
		if (errA) {
			throw errA;
		}
		// @TODO - apply defaults?
		return sheets;
	}

	async validatePayload() {
		throw new Error('validatePayload() is not implemented in the parent');
	}

	async payloadDefaults() {
		throw new Error('payloadDefaults() is not implemented in the parent');
	}

	async _mergePayload(payload) {
		// Get the defaults
		const [errB, defaults] = await this.to(this.payloadDefaults());
		if (errB) {
			throw errB;
		}
		return this._.merge({}, defaults, payload);
	}
}
