<template>
<div
	v-show="show"
	id="myModal"
	class="modal"
>
	<div class="modal-content">
		<slot name="body">
			<h2>{{ title }}</h2>
			<p>{{ text }}</p>
		</slot>
		<div class="modal-footer">
			<button
				v-if="onClose"
				id="modal-close-btn"
				class="btn btn-ghost-primary"
				@click.prevent="close()"
			>
				{{ closeText ? closeText : "Close" }}
			</button>
			<button
				v-if="onConfirm"
				id="modal-confirm-btn"
				class="btn btn-primary"
				@click.prevent="confirm()"
			>
				{{ confirmText ? confirmText : "Ok" }}
			</button>
		</div>
	</div>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState('modal', [
			'show',
			'title',
			'text',
			'onConfirm',
			'onClose',
			'closeText',
			'confirmText',
		]),
	},
	methods: {
		closeModal() {
			this.$store.commit('modal/closeModal');
		},
		close() {
			if (this.onClose) {
				this.onClose();
			}
			this.closeModal();
		},
		confirm() {
			if (this.onConfirm) {
				this.onConfirm();
			}
			this.closeModal();
		},
	},
};
</script>

<style scoped>
.modal {
	display: block;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
	background-color: #fefefe;
	margin: 15% auto;
	padding: 20px;
	border: 1px solid #888;
	width: 50%;
}
</style>
