export default class CSSheetRepo {
	constructor({ _, to, supabaseClientFactory, supaTo, boxFactory, libBox, libExcelWB, textProcessingService, sbTo }) {
		this._ = _;
		this.to = to;
		this.box = boxFactory;
		// this.workbook = libExcelWB;
		this.workbook = libExcelWB;
		this.supabase = supabaseClientFactory;
		this.supaTo = supaTo;
		this.sbTo = sbTo;
		this.accountingCodeRegex = /[ABCKGO0-9]{2,3}-[0-9]{3}/;
		this.boxCache = {};
		this.client = libBox.client();
		this.textProcessingService = textProcessingService;
	}

	async getAllCSSheets() {
		const [err, response] = await this.supaTo(this.supabase
			.from('cs_sheet')
			.select('*'));

		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async createCSSheetEntry(entry) {
		if (!entry) {
			throw new Error('must have an entry to create a cs sheet entry');
		}

		const [err, response] = await this.sbTo(
			this.supabase.from('cs_sheet').insert(entry),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async upsertCSSheetEntry(entry) {
		if (!entry) {
			throw new Error('must have an entry to create a cs sheet entry');
		}

		const [err1, existingEntry] = await this.to(
			this.getExistingCSSheetEntries(entry),
		);

		if (err1) {
			throw err1;
		}

		if (existingEntry) {
			entry.id = existingEntry.id;
			const [updateErr, updatedEntry] = await this.to(
				this.updateCSSheetEntry(entry),
			);

			if (updateErr) {
				throw updateErr;
			}

			return updatedEntry;
		}

		return this.createCSSheetEntry(entry);
	}

	async getCSSheetsWithoutBidHash() {
		const [err, response] = await this.supaTo(
			this.supabase.from('cs_sheet').select('project_name, id, job_id, square_footage').is('bid_hash', null),
		);

		if (err) {
			throw err;
		}

		return response?.data;
	}

	async getExistingCSSheetEntries({ cs_file_id: csFileId, tab_name: tabName }) {
		if (!csFileId) {
			throw new Error('A csFileId is required to get an existing CS sheet entry.');
		}

		if (!tabName) {
			throw new Error('A tabName is required to get an existing CS sheet entry.');
		}

		const [err, response] = await this.supaTo(
			this.supabase.from('cs_sheet').select('*').match({ cs_file_id: csFileId, tab_name: tabName }),
		);

		if (err) {
			throw err;
		}

		return this._.get(response, 'data[0]', null);
	}

	// async getExistingCSSheetEntries() {
	// 	const [err, response] = await this.supaTo(
	// 		this.supabase.from('cs_sheet').select('job_id, historical_project(string_id)'),
	// 	);

	// 	if (err) {
	// 		throw err;
	// 	}

	// 	return response?.data?.map((item) => item?.historical_project?.string_id || item.job_id);
	// }

	async getCSSheetEntryByJobId(jobId) {
		if (!jobId) {
			throw new Error('must have a jobId to lookup cs sheet');
		}
		const [err, response] = await this.supaTo(
			this.supabase.from('cs_sheet').select().match({ job_id: jobId }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async associateCSSheet(csSheetId, bidId) {
		const [err, csSheet] = await this.sbTo(this.supabase.from('cs_sheet').select().match({ bid_id: bidId }));

		if (err) {
			throw err;
		}

		if (csSheet.length) {
			const [err1] = await this.to(this.clearBidId(bidId));
			if (err1) {
				throw err1;
			}
		}

		const [err2] = await this.to(this.setBidId(csSheetId, bidId));
		if (err2) {
			throw err2;
		}
	}

	async setBidId(csSheetId, bidId) {
		const csSheet = await this.getCSSheetEntryById(csSheetId);

		csSheet.bid_id = bidId;

		return this.updateCSSheetEntry(csSheet);
	}

	async setBidHash(csSheetId, hash) {
		const csSheet = await this.getCSSheetEntryById(csSheetId);

		csSheet.bid_hash = hash;

		return this.updateCSSheetEntry(csSheet);
	}

	async clearBidId(bidId) {
		if (!bidId) {
			throw new Error('bidId is required to update cs_sheet');
		}
		const [error] = await this.sbTo(
			this.supabase
				.from('cs_sheet')
				.update({ bid_id: null })
				.match({ bid_id: bidId }),
		);

		if (error) {
			throw error;
		}
	}

	async updateCSSheetEntry(csSheet) {
		if (!csSheet) {
			throw new Error('csSheet is required to update a cs_sheet');
		}
		const [error, data] = await this.sbTo(
			this.supabase
				.from('cs_sheet')
				.update(csSheet)
				.match({ id: csSheet.id }),
		);
		if (error) {
			throw error;
		} else if (this._.has(data, 'length')) {
			return this.getCSSheetEntryById(csSheet.id);
		} else {
			throw new Error('Unable to update cs_sheet');
		}
	}

	async getCSSheetEntryById(id) {
		if (!id) {
			throw new Error('must have a id to lookup cs sheet');
		}
		const [err, response] = await this.supaTo(
			this.supabase.from('cs_sheet').select().match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async getCSSheetEntryWithCSFileByFileId(csFileId) {
		if (!csFileId) {
			throw new Error('must have a cs file id to lookup cs sheet');
		}
		const [err, response] = await this.sbTo(
			this.supabase.from('cs_sheet').select('*, cs_file(*)').match({ cs_file_id: csFileId }),
		);

		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async getCSSheetsByLink(link) {
		if (this._.has(this.boxCache, link)) {
			return this.boxCache[link];
		}
		const [err, fileData] = await this.to(this.fetchSheet(link));
		if (err) {
			throw err;
		}
		this.boxCache[link] = { worksheets: [...this.workbook._worksheets].filter((obj) => obj),
			fileVersion: fileData.file_version.id,
			fileName: fileData.name };
		return this.boxCache[link];
	}

	getData(sheet, fileVersion, fileName) {
		const projectSqFt = this.findValueByHorizontalLabel('PROJECT SQFT:', sheet._rows[0]);
		const state = this.validateState(this.findValueByHorizontalLabel('STATE:', sheet._rows[0]));

		const date = this.findValueByHorizontalLabel('DATE:', sheet._rows[0]);
		const { framingStartDate, framingEndDate } = this.findFramingDates(sheet._rows);

		const porchSqFt1 = this.findValueByHorizontalLabel('Porch SF', sheet._rows[3]);
		const porchSqFt2 = this.findValueByHorizontalLabel('Porch SF:', sheet._rows[4]);
		const porchSqFt = porchSqFt1 || porchSqFt2;

		const unitCount = this.findValueByHorizontalLabel('TOTAL UNITS', sheet._rows[7]);
		const wallType = this.findValueByHorizontalLabel('WALL TYPE:', sheet._rows[1]);
		const projectName = this.findValueByHorizontalLabel('JOB NAME:', sheet._rows[0]);
		if ([projectSqFt, date, projectName, wallType, unitCount].every((val) => !val)) {
			return null;
		}
		const mnHrRate = this.findValueByHorizontalLabel('MN HR RATE:', sheet._rows[2]);
		const [sqftRate, sqftRateColor] = this.findValueByHorizontalLabel('SQ FT RATE:', sheet._rows[3], true);
		const [subMhRate, subMhRateColor] = this.findValueByHorizontalLabel('SUB MH RATE:', sheet._rows[3], true);
		const materialCostStart = this.findMaterialStartIndex(sheet);
		const materialData = this.parseMaterial(sheet, materialCostStart);

		const topSqftPerWeek = this.findValueByHorizontalLabel('SF/Week', sheet._rows[0]);
		const bottomSqftPerWeek = this.findValueByHorizontalLabel('SF/Week:', sheet._rows[1]);
		const sqftPerWeek = topSqftPerWeek || bottomSqftPerWeek;

		const framingDuration = this.findValueByHorizontalLabel('FRAMING DURATION:', sheet._rows[2]);
		const jobDuration = this.findValueByHorizontalLabel('TOTAL JOB DURATION:', sheet._rows[3]);

		const resUnits1 = this.findValueByHorizontalLabel('TOTAL RES UNITS', sheet._rows[5]);
		const resUnits2 = this.findValueByHorizontalLabel('TOT RES UNITS', sheet._rows[5]);
		const resUnits = resUnits1 || resUnits2;

		const publicUnits = this.findValueByHorizontalLabel('PUBLIC UNITS', sheet._rows[6]);

		const totalManHours1 = this.findValueByHorizontalLabel('TOTAL MAN HOURS:', sheet._rows[4]);
		const totalManHours2 = this.findValueByHorizontalLabel('TOT MAN HOURS:', sheet._rows[4]);
		const totalManHours = totalManHours1 || totalManHours2;

		const topHoursPerSqft = this.findValueByHorizontalLabel('hours/sf', sheet._rows[7]);
		const bottomHoursPerSqft = this.findValueByHorizontalLabel('hours/sf', sheet._rows[8]);
		const hoursPerSqft = topHoursPerSqft || bottomHoursPerSqft;

		const laborCostStart = this.findLaborStartIndex(sheet);
		const laborData = this.parseLabor(sheet, laborCostStart);

		let grandTotal = null;
		let i = 0;
		while (!this.checkForValidGrandTotal(grandTotal) && i !== sheet._rows.length) {
			grandTotal = this.findValueByHorizontalLabel('Grand Total:', sheet._rows[i]);
			i += 1;
		}

		if (!grandTotal) {
			// Look for grand total in Column B.
			const rowIndex = sheet._rows.findIndex((item, index) => this.isProperGT(sheet, item, index));

			if (rowIndex) {
				// If Grand Total is found in column B, jump to the prior row, and find TOTAL AMT, to tell us where which col.
				const colIndex = sheet?._rows?.[rowIndex - 1]?._cells.findIndex((item) => item._value?.value === 'TOTAL AMT');
				const gt = sheet?._rows?.[rowIndex]?._cells?.[colIndex]?._value?.value;

				grandTotal = gt ? this.findCellsTrueValue(gt) : null;
			}
		}

		return {
			materialData,
			laborData,
			bidData: {
				grandTotal,
				projectName,
				projectSqFt,
				porchSqFt,
				unitCount: Number.isNaN(+unitCount) ? null : unitCount,
				date,
				framingStartDate,
				framingEndDate,
				sqftRate,
				subMhRate,
				mnHrRate,
				sqftRateColor,
				subMhRateColor,
				isPanel: wallType?.toUpperCase?.().includes('PANEL'),
				sqftPerWeek,
				framingDuration,
				jobDuration,
				resUnits,
				publicUnits,
				totalManHours,
				hoursPerSqft,
				state,
			},
			fileVersionId: fileVersion,
			fileName,
			subsheet: sheet.name,
		};
	}

	validateState(state) {
		if (!state || state.length !== 2) {
			return null;
		}

		return state.toUpperCase();
	}

	isProperGT(sheet, item, index) {
		return item?._cells?.[1]?._value?.value?.includes?.('GRAND TOTAL') && sheet._rows[index - 1]?._cells?.[1]?._value?.value === 'COST CODE';
	}

	parseCurrency(currency) {
		return currency.replace(/[$,]+/g, '');
	}

	checkForValidGrandTotal(proposedVal) {
		if (!proposedVal) {
			return false;
		}
		let _proposedVal = proposedVal;
		if (typeof _proposedVal === 'string') {
			_proposedVal = this.parseCurrency(_proposedVal);
		}
		if (typeof proposedVal === 'number') {
			return proposedVal;
		}
		if (this.isNumeric(_proposedVal)) {
			return Number.parseFloat(_proposedVal);
		}
		return false;
	}

	isNumeric(str) {
		if (typeof str === 'number') return true;
		if (typeof str !== 'string') return false; // we only process strings!
		return !Number.isNaN(str) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
			   && !Number.isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
			   && !str.match(/[A-Z]/); // Addition: There are no capital letters in string;
	}

	async fetchSheet(link) {
		const [id] = link.match(/[0-9]{12,}/);
		const [fileDataErr, fileData] = await this.to(
			this.client.files.get(id),
		);

		if (fileDataErr) {
			throw fileDataErr;
		}
		const [err, file] = await this.to(
			this.box.downloadFile(id),
		);

		if (err) {
			throw err;
		}

		const [streamReadErr, buff] = await this.to(this._readFromStream(file, id));
		if (streamReadErr) {
			throw streamReadErr;
		}

		const [loadBuffErr] = await this.to(this.workbook.xlsx.load(buff));
		if (loadBuffErr) {
			throw loadBuffErr;
		}

		return fileData;
	}

	async _readFromStream(file) {
		const buffs = [];
		file.on('data', (buf) => {
			buffs.push(buf);
		});
		return new Promise((resolve, reject) => {
			file.on('end', resolve);
			file.on('close', resolve);
			file.on('error', reject);
		}).then(() => Buffer.concat(buffs));
	}

	findCellsTrueValue(value) {
		if (!value || value instanceof Date || Number.isNaN(+value) === false) {
			return value;
		}

		if (typeof value === 'object') {
			return value.result || 0;
		}

		const checkDate = new Date(value);

		if (checkDate.toString() !== 'Invalid Date') {
			return checkDate;
		}

		return value;
	}

	async getCSFileData(link) {
		const [err, fileData] = await this.to(this.getCSSheetsByLink(link));
		if (err) {
			if (err.statusCode === 404) {
				return null;
			}
			throw new Error(err);
		}

		const csDatas = fileData.worksheets.map((item) => this.getData(item, fileData.fileVersion, fileData.fileName)).filter((val) => val);

		if (!csDatas.length) {
			return null;
		}

		return csDatas;
	}

	findCorrectSheet(sheet, subsheet) {
		if (subsheet && subsheet.length > 0 && sheet?.name) {
			return sheet.name.trim() === subsheet && sheet?.state !== 'hidden';
		}

		return sheet?.state !== 'hidden' && sheet?._rows?.[0]?._cells?.[1]?._value?.value === 'JOB NAME:';
	}

	findLaborStartIndex(sheet) {
		return sheet._rows.findIndex((item, index, array) => {
			if (item?._cells?.[0]?._value?.value === 'LABOR') {
				return true;
			}
			const cellVal = item?._cells?.[1]?._value?.value;
			if (!cellVal || typeof cellVal !== 'string') {
				return false;
			}
			if (cellVal.startsWith('010-')) {
				return true;
			}

			const isTableHeader = cellVal === 'COST CODE' || cellVal === 'COMPONENT';
			if (isTableHeader) {
				// Check next item
				if (index + 1 < array.length) {
					const nextItem = array[index + 1];
					const nextRowTypeVal = nextItem?._cells?.[0]?._value?.value;
					const nextRowCodeVal = nextItem?._cells?.[1];

					let codeVal = nextRowCodeVal?.value || '';
					if (typeof codeVal !== 'string') {
						if (typeof codeVal === 'object' && codeVal.result) {
							codeVal = codeVal.result;
						} else {
							codeVal = String(codeVal);
						}
					}
					const isLaborCode = codeVal.startsWith('010-') || codeVal.startsWith('020-');
					const check = isLaborCode || nextRowTypeVal === 'LABOR';
					return check;
				}
			}
			return false;
		});
	}

	findMaterialStartIndex(sheet) {
		return sheet._rows.findIndex((item, index, array) => {
			const cellVal = item?._cells?.[1]?._value?.value;
			const isTableHeader = cellVal === 'COST CODE' || cellVal === 'COMPONENT';
			if (isTableHeader) {
				if (index + 1 < array.length) {
					const nextItem = array[index + 1];
					const nextRowTypeVal = nextItem?._cells?.[0]?._value?.value;
					const nextRowCodeVal = nextItem?._cells?.[1];

					let codeVal = nextRowCodeVal?.value || '';
					if (typeof codeVal !== 'string') {
						if (typeof codeVal === 'object' && codeVal.result) {
							codeVal = codeVal.result;
						} else {
							codeVal = String(codeVal);
						}
					}
					const isMaterialCode = codeVal.startsWith('006-');
					const check = isMaterialCode || nextRowTypeVal === 'MATERIAL';
					return check;
				}
			}
			return false;
		});
	}

	findFramingDates(rows) {
		const rowIndex = rows.findIndex((item) => item?._cells?.[12]?._value.value === 'FRAMING START');

		if (!rowIndex || rowIndex === -1) {
			return {};
		}
		return {
			framingStartDate: rows[rowIndex]._cells[13]._value.value,
			framingEndDate: this.findCellsTrueValue(rows[rowIndex + 1]?._cells?.[13]?._value.value),
		};
	}

	findValueByHorizontalLabel(label, row, includeColor) {
		const labelIndex = row?._cells.findIndex((item) => item?._value?.value?.includes?.(label));

		if (!labelIndex || labelIndex < 0) {
			return (includeColor) ? [null, null] : null;
		}

		const result = row._cells[labelIndex + 1];
		const valResult = result?._value.value;
		const trueVal = this.findCellsTrueValue(valResult);

		if (!includeColor) {
			return trueVal;
		}
		const color = this._.get(result, 'fill.fgColor.argb');
		return [trueVal, color];
	}

	parseLabor(sheet, laborCostStart) {
		const laborData = [];
		const rowOneCellOneVal = sheet?._rows?.[laborCostStart]?._cells?.[1]?._value?.value;
		const noCostCodeType = rowOneCellOneVal === 'COMPONENT';
		let index = laborCostStart;
		// Move to the next row, if laborCostStart row is just headers
		if (rowOneCellOneVal === 'COST CODE' || noCostCodeType) {
			index += 1;
		}
		let notEndOfLabor = true;

		while (notEndOfLabor) {
			const currentItem = sheet._rows[index];
			index += 1;

			if (currentItem) {
				const cells = currentItem._cells;
				const [type, code, comp, hours, cost] = cells;
				const compValue = comp?.value;
				if (compValue !== 'Labor Cost per MH incl OH') {
					let codeVal = code?.value || '';
					if (typeof codeVal !== 'string') {
						if (typeof codeVal === 'object' && codeVal.result) {
							codeVal = codeVal.result;
						} else {
							codeVal = String(codeVal);
						}
					}
					const isLaborCode = codeVal.startsWith('010-') || codeVal.startsWith('020-');

					if (!(typeof comp?.value === 'string' && comp?.value.toLowerCase().includes('total'))) {
						const isLikelyLaborItem = type?._value?.value === 'LABOR' || isLaborCode;
						if (['OVERHEAD', 'MATERIAL'].includes(type?._value?.value) || !isLikelyLaborItem) {
							notEndOfLabor = false;
							break;
						} else if (this.isValidCostRow(code, comp, 'LABOR TOTALS')) {
							laborData.push({
								cost_code: noCostCodeType || !code._value.value ? 'NO COST CODE' : code._value.value,
								description: this.getDescription(noCostCodeType, code, comp),
								hours: this.round(this.findCellsTrueValue(hours._value.value)),
								margin: this.round(this.findCellsTrueValue(cells[7]._value.value)),
								overhead: this.round(this.findCellsTrueValue(cells[4]._value.value)),
								overhead_margin: this.round(this.findCellsTrueValue(cells[8]._value.value)),
								cost: this.round(this.findCellsTrueValue(cost._value.value)),
								total: this.round(this.findCellsTrueValue(cells[10]._value.value)),
								color: this._.get(hours, 'fill.fgColor.argb') || this._.get(comp, 'fill.fgColor.argb'),
							});
						}
					}
				}
			}
		}
		return laborData;
	}

	getDescription(noCostCodeType, code, comp) {
		const value = noCostCodeType ? code._value.value : comp._value.value;

		if (!value && comp._value.constructor.name === 'NullValue') return null;

		return value;
	}

	parseMaterial(sheet, materialCostStart) {
		const materialData = [];
		const noCostCodeType = sheet?._rows?.[materialCostStart]?._cells?.[1]?._value?.value === 'COMPONENT';
		let index = materialCostStart + 1;
		let notEndOfMaterial = true;

		while (notEndOfMaterial) {
			const currentItem = sheet._rows[index];
			index += 1;
			if (currentItem) {
				const cells = currentItem._cells;
				const [type, code, comp, variable, cost] = cells;

				let codeVal = code?.value || '';
				if (typeof codeVal !== 'string') {
					if (typeof codeVal === 'object' && codeVal.result) {
						codeVal = codeVal.result;
					} else {
						codeVal = String(codeVal);
					}
				}
				const isMaterialCode = codeVal.startsWith('006-');

				if (type?._value?.value !== 'MATERIAL' && !isMaterialCode) {
					notEndOfMaterial = false;
					break;
				} else if (this.isValidCostRow(code, comp, 'MATERIAL TOTALS')) {
					materialData.push({
						cost_code: noCostCodeType || !code._value.value ? 'NO COST CODE' : code._value.value,
						description: noCostCodeType ? code._value.value : comp._value.value,
						variable: variable._value.value,
						tax: this.round(this.findCellsTrueValue(cells?.[4]?._value.value)),
						margin: this.round(this.findCellsTrueValue(cells?.[7]?._value.value)),
						total: this.round(this.findCellsTrueValue(cells?.[10]?._value.value)),
						cost: this.round(this.findCellsTrueValue(cost?._value?.value)),
					});
				}
			}
		}
		return materialData;
	}

	isValidCostRow(code, comp, endDesignator) {
		if (!code || !code._value || !code._value) {
			return false;
		}

		if (this.accountingCodeRegex.test(code._value.value) || code._value.value === 'New Code') {
			return true;
		}

		return !code._value.value && comp?._value?.value !== endDesignator;
	}

	round(float) {
		return Math.round(float * 100) / 100;
	}

	async checkForDuplicateSubCSSheets(link, subsheet) {
		const [err] = await this.to(this.fetchSheet(link));

		if (err) {
			throw err;
		}

		return this.workbook._worksheets.filter((item) => this.findCorrectSheet(item, subsheet));
	}

	async getAllJobFolders() {
		const [err, jobSubfolders] = await this.to(this.client.folders.getItems('120325435545', { limit: 1000 }));

		if (err) {
			throw err;
		}
		// Check Archived Jobs Folder
		const [err0, archivedJobs] = await this.to(this.client.folders.getItems('118179405363', { limit: 1000 }));

		if (err0) {
			throw err0;
		}

		const [err1, quotes] = await this.to(this.client.folders.getItems('120301906360', { limit: 1000 }));

		if (err1) {
			throw err1;
		}
		// Merge Current Jobs with Archived Jobs folders.
		return [
			...jobSubfolders.entries,
			...archivedJobs.entries,
			...quotes.entries,
		];
	}
}
