<template>
<li class="list-comment-item">
	<!-- Media -->
	<div class="media align-items-center mb-3">
		<!-- Avatar -->
		<avatar
			style="margin-right: 10px"
			:name="username"
			:avatar="avatar"
		/>
		<!-- End Avatar -->

		<div class="media-body">
			<div class="float-right">
				<span>{{ formatDateTime(comment.date) }}</span>
				<a
					v-if="displayRebid"
					href="#"
					class="badge badge-soft-success ml-2"
				>Rebid: {{ comment.revision_num }}</a>
			</div>
			<h5 class="mb-0">
				{{ username }}
			</h5>
		</div>
	</div>
	<!-- End Media -->

	<div
		ref="editor"
		class="editor-disabled"
	/>
</li>
</template>
<script>

import { mapState } from 'vuex';
import Avatar from '../Avatar.vue';

import utilitiesMixin from '../../mixins/utilities';

export default {
	components: {
		Avatar,
	},
	mixins: [utilitiesMixin],
	props: {
		comment: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			editor: null,
		};
	},
	computed: {
		...mapState('comment', ['resource']),
		username() {
			return this.comment.username || this.comment?.user?.name;
		},
		avatar() {
			return this.comment.avatar || this.comment?.user?.avatar;
		},
		displayRebid() {
			return this.resource.model === 'BID';
		},
	},
	mounted() {
		this.setCommentDelta();
	},
	updated() {
		this.setCommentDelta();
	},
	methods: {
		setCommentDelta() {
			const { libQuill: Quill } = this.$cradle();
			this.editor = new Quill(this.$refs.editor, {
				formats: ['mention'],
			});
			this.editor.setContents(this.comment.comment_delta);
			this.editor.disable();
		},
	},
};
</script>
<style>
	.ql-container.editor-disabled .ql-editor {
		border: none;
		margin: 0;
		min-height: auto;
	}
	.list-comment-item:not(:first-child) {
		margin-top: 1rem;
		padding-top: 1rem;
	}
</style>
