export default {
	namespaced: true,
	state: {
		suppliers: [],
		bidSuppliers: [],
		bidSuppliersList: [],
		supplierCategories: [
			{ name: 'Hardware', included: false, locked: false },
			{ name: 'Roof', included: false, locked: false },
			{ name: 'Wall', included: false, locked: false },
			{ name: 'EWP', included: false, locked: false },
			{ name: 'Material', included: false, locked: false },
			{ name: 'Truss', included: false, locked: false },
			{ name: 'Component', included: false, locked: false },
			{ name: 'Lumber', included: false, locked: false },
		],
		newSupplier: {
			show: false,
			category: null,
			name: null,
		},
		HARDWARE: [],
		ROOF: [],
		WALL: [],
		MATERIAL: [],
		EWP: [],
		TRUSS: [],
		COMPONENT: [],
		LUMBER: [],
	},
	getters: {
		getSuppliersByCategory: (state) => (category) => state.supplierCategoriesList[category],
	},
	mutations: {
		openNewSupplier(state) {
			state.newSupplier.show = true;
		},
		closeNewSupplier(state) {
			state.newSupplier.show = false;
		},
		addSupplier(state, supplier) {
			state.suppliers.push(supplier);
		},
		addSuppliers(state, suppliers) {
			state.suppliers = [];
			if (suppliers && suppliers.length) {
				state.suppliers.push(...suppliers);
			}
		},
		addBidSupplier(state, supplier) {
			if (supplier) {
				const _supplier = { ...supplier, supplier_id: supplier.supplier.id, category: supplier.category };
				const uniqueComboIds = state.bidSuppliers.map(({ supplier_id: sID, bid_revision_id: bRID, category }) => `
				${sID}:${bRID}:${category}`);
				const currentComboId = `${_supplier.supplier_id}:${_supplier.bid_revision_id}:${_supplier.category}`;
				const indexOfSupplier = uniqueComboIds.indexOf(currentComboId);
				if (indexOfSupplier > -1) {
					state.bidSuppliers.splice(indexOfSupplier, 1);
				}
				state.bidSuppliers.push(_supplier);
			}
		},
		addBidSuppliers(state, suppliers) {
			if (suppliers && suppliers.length) {
				state.bidSuppliers.push(...suppliers.map((item) => {
					const sv = item.bid_supplier[0];
					const nv = { ...item, ...sv };
					delete nv.bid_supplier;
					return nv;
				}));

				const activeSupplierCategories = state.bidSuppliers.map((item) => item.category);

				state.supplierCategories = state.supplierCategories.map((item) => {
					item.included = activeSupplierCategories.includes(item.name.toUpperCase());
					return item;
				});
			}
		},
		clearBidSuppliers(state) {
			state.bidSuppliers = [];
		},
		setBidSuppliersList(state, bidSuppliersList) {
			state.bidSuppliersList = bidSuppliersList;
			state.HARDWARE = bidSuppliersList.filter(({ category }) => category === 'HARDWARE');
			state.ROOF = bidSuppliersList.filter(({ category }) => category === 'ROOF');
			state.WALL = bidSuppliersList.filter(({ category }) => category === 'WALL');
			state.EWP = bidSuppliersList.filter(({ category }) => category === 'EWP');
			state.MATERIAL = bidSuppliersList.filter(({ category }) => category === 'MATERIAL');
			state.TRUSS = bidSuppliersList.filter(({ category }) => category === 'TRUSS');
			state.COMPONENT = bidSuppliersList.filter(({ category }) => category === 'COMPONENT');
			state.LUMBER = bidSuppliersList.filter(({ category }) => category === 'LUMBER');
		},
	},
	actions: {
		async openNewSupplier({ commit }, category) {
			if (category) {
				commit('openNewSupplier', category);
			}
		},
		async closeNewSupplier({ commit }) {
			commit('closeNewSupplier');
		},
		async updateBidSupplier({ commit }, supplier) {
			commit('addBidSupplier', supplier);
		},
		async createSupplier({ commit }, name) {
			const { to, supplierRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(supplierRepo.createSupplier(name));

			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('addSupplier', data);
			}
		},
		async addSupplierToBid({ dispatch, commit, rootState, state }) {
			const { to, supplierRepo, logDnaFactory: logger } = this.$cradle;
			const { suppliers, newSupplier } = state;

			let supplier = suppliers.find((item) => item.name === newSupplier.name);
			let err;

			if (!supplier) {
				[err, supplier] = await to(supplierRepo.createSupplier(newSupplier.name));

				if (err) {
					logger.error(err.message);
					throw err;
				}
			}

			const [err2, data] = await to(
				supplierRepo.addSupplierToBid(
					rootState.activeBid.bid.bid_revision.id,
					supplier.id,
					newSupplier.category.toUpperCase(),
				),
			);

			if (err2) {
				logger.error(err2.message);
				throw err;
			} else {
				commit('activeBid/addSupplier', { ...data, supplier }, { root: true });
				await dispatch('getSuppliers');
				commit('closeNewSupplier');
				await dispatch('getSuppliersByBidRevision', rootState.activeBid.bid.bid_revision.id);
			}
		},
		async removeSupplierFromBid({ dispatch }, { bidRevisionId, supplierId, category }) {
			const { to, supplierRepo, logDnaFactory: logger } = this.$cradle;
			const [err] = await to(supplierRepo.removeSupplierFromBid(bidRevisionId, supplierId, category));
			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				await dispatch('getSuppliersByBidRevision', bidRevisionId);
			}
		},
		async getSuppliers({ commit }) {
			const { to, supplierRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(supplierRepo.getAllSuppliers());
			if (err) {
				logger.error(err.message);
				commit('addSuppliers', []);
				throw err;
			} else {
				commit('addSuppliers', data);
			}
		},
		async saveSuppliers({ state, dispatch }) {
			const { to, supplierRepo, logDnaFactory: logger } = this.$cradle;
			const { bidSuppliers } = state;

			if (bidSuppliers.length === 0) {
				return;
			}
			const promises = bidSuppliers.map((item) => {
				const bidSupplier = { ...item };
				delete bidSupplier.name;
				delete bidSupplier.files;
				delete bidSupplier.supplier_name;
				delete bidSupplier.supplier;
				return supplierRepo.updateBidSupplier(bidSupplier);
			});

			const [err] = await to(Promise.all(promises));

			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				await dispatch('getSuppliers');
			}
		},
		async getSuppliersByBidRevision({ commit }, bidRevisionId) {
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;
			const [err, suppliers] = await to(bidRepo.getSuppliersByBidRevision(bidRevisionId));

			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('setBidSuppliersList', suppliers);
			}
		},
	},
};
