export default {
	namespaced: true,
	state: {
		materialPrediction: {},
		materialLoaded: false,
		materialError: '',
	},
	getters: {},
	mutations: {
		setPrediction(state, materialPrediction) {
			state.materialPrediction = materialPrediction;
		},
		setLoadingStatus(state, status) {
			state.materialLoaded = status;
		},
		setError(state, materialError) {
			state.materialError = materialError.message;
		},
		resetStates(state) {
			state.materialPrediction = {};
			state.materialLoaded = false;
			state.materialError = '';
		},
	},
	actions: {
		async getMaterialPrediction({ commit, state }, { bidRevisionId, isPanelType }) {
			if (state.materialLoaded) {
				return;
			}
			const { to, materialPredictionRepo } = this.$cradle;
			const [err, data] = await to(materialPredictionRepo.getMaterialPrediction(bidRevisionId, isPanelType));
			if (err) {
				commit('setPrediction', {});
				commit('setError', err);
				commit('setLoadingStatus', true);
			} else {
				commit('setPrediction', data);
				commit('setLoadingStatus', true);
			}
		},
		async resetMaterialPredictionStates({ commit }) {
			commit('resetStates');
		},
	},
};
