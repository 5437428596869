export default class ArchivalReasonRepo {
	constructor({ supaTo, supabaseClientFactory, _ }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async createArchivalReason(archivalReason) {
		if (!archivalReason) {
			throw new Error('An archivalReason is required in order to create it.');
		}
		archivalReason.created_at = new Date();
		const [err, response] = await this.supaTo(
			this.supabase.from('bid_archival_reason').insert(archivalReason),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', {});
		}
	}
}
