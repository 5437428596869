<template>
<div
	v-if="authenticated"
	v-click-outside="handleClickOutside"
	class="hs-unfold"
>
	<a
		id="navbarAvatar"
		class="js-hs-unfold-invoker navbar-dropdown-account-wrapper"
		href="#"
		@click.prevent="toggleUserDetails"
	>
		<Avatar
			:name="user.name"
			:avatar="user.avatar"
		/>
	</a>
	<div
		id="accountNavbarDropdown"
		class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right
      navbar-dropdown-menu navbar-dropdown-account hs-unfold-content-initialized
      hs-unfold-css-animation animated"
		:class="{ 'hs-unfold-hidden': !showUserDetails }"
		style="width: 16rem; animation-duration: 300ms"
		data-hs-target-height="396.281"
		data-hs-unfold-content=""
		data-hs-unfold-content-animation-in="slideInUp"
		data-hs-unfold-content-animation-out="fadeOut"
	>
		<div class="dropdown-item-text">
			<div class="media align-items-center">
				<Avatar
					class="mr-2"
					:name="user.name"
					:avatar="user.avatar"
				/>
				<div class="media-body">
					<span class="card-title h5">{{ user.name }}</span>
					<span class="card-text">{{ user.email }}</span>
				</div>
			</div>
		</div>
		<div class="dropdown-divider" />
		<a
			class="dropdown-item"
			href="#/release-notes"
		>
			<span
				class="text-truncate pr-2"
				title="Release Notes"
			>Release Notes</span>
		</a>
		<div class="dropdown-divider" />

		<a
			class="dropdown-item"
			href="#"
			@click.prevent="logout"
		>
			<span
				class="text-truncate pr-2"
				title="Sign out"
			>Sign out</span>
		</a>
	</div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from './Avatar.vue';

export default {
	name: 'AccountDropdown',
	components: { Avatar },
	data() {
		return {
			showUserDetails: false,
		};
	},
	computed: {
		...mapState('authentication', ['user']),
	},
	methods: {
		...mapGetters('authentication', ['authenticated']),
		async logout() {
			const { to } = this.$cradle();
			const [err] = await to(this.$store.dispatch('authentication/logout'));
			if (err) {
				throw err;
			}
			this.showUserDetails = false;
			this.$router.push({ path: '/authentication' });
		},
		toggleUserDetails() {
			this.showUserDetails = !this.showUserDetails;
		},
		handleClickOutside() {
			if (this.showUserDetails) {
				this.showUserDetails = false;
			}
		},
	},
};
</script>
