<template>
<main
	id="content"
	role="main"
	class="main"
>
	<div
		class="position-fixed top-0 right-0 left-0 bg-img-hero"
		style="height: 32rem; background-image: url(./svg/components/abstract-bg-4.svg);"
	>
		<!-- SVG Bottom Shape -->
		<figure class="position-absolute right-0 bottom-0 left-0">
			<svg
				preserveAspectRatio="none"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 1921 273"
			>
				<polygon
					fill="#fff"
					points="0,273 1921,273 1921,0 "
				/>
			</svg>
		</figure>
		<!-- End SVG Bottom Shape -->
	</div>

	<slot />
</main>
</template>
<script>

export default {
	name: 'LoginLayout',
};
</script>
