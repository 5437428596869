export default class ActivityRepo {
	constructor({ supaTo, supabaseClientFactory, _ }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getActivitiesByBidId(bidId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('activity').select(`
				type,
				user(
					name,
					avatar
				),
				resource_id,
				resource_sub_id,
				date
			`)
				.match({ resource_id: bidId, resource_type: 'BID' })
				.order('date', { ascending: true }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getActivitiesByBidIdInList(bidIds) {
		const [err, response] = await this.supaTo(
			this.supabase.from('activity').select(`
				type,
				user(
					name,
					avatar
				),
				resource_id,
				resource_sub_id,
				date
			`)
				.in('resource_id', bidIds)
				.match({ resource_type: 'BID' })
				.order('date', { ascending: true }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getActivitiesByProjectId(projectId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('activity').select(`
				type,
				user(
					name,
					avatar
				),
				resource_id,
				resource_sub_id,
				date
			`)
				.match({ resource_id: projectId, resource_type: 'PROJECT' })
				.order('date', { ascending: true }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async createActivity(activity) {
		const [err, response] = await this.supaTo(
			this.supabase.from('activity').insert(activity),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}
}
