export default class PricingPointRepo {
	constructor({ to, supaTo, supabaseClientFactory, _ }) {
		this.to = to;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getPricingPointsByCategory(category) {
		const [err, pricingPoints] = await this.supaTo(
			this.supabase
				.from('pricing_point')
				.select('*')
				.match({ category }),
		);

		if (err) {
			throw err;
		} else {
			return pricingPoints.data;
		}
	}

	async getPricingPointsBySubcategory(subcategory) {
		const [err, pricingPoints] = await this.supaTo(
			this.supabase.from('pricing_point')
				.select('*')
				.match({ subcategory }),
		);

		if (err) {
			throw err;
		} else {
			return pricingPoints.data;
		}
	}
}
