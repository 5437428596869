<template>
<div
	class="float-right pr-3"
	:title="status"
>
	<span
		class="badge rounded-pill text-white"
		:class="colors[status]"
	>
		{{ abbreviatedStatus(status) }}
	</span>
</div>
</template>
<script>
export default {
	props: {
		status: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			colors: {
				'Request For Quote': 'bg-primary',
				'Regional Manager Review': 'bg-info',
				'Sent To Customer': 'bg-warning',
				'Sent to Customer': 'bg-warning',
				'Bid Won': 'bg-success',
				RFQ: 'bg-primary',
				RMR: 'bg-info',
				STC: 'bg-warning',
				Won: 'bg-success',
			},
		};
	},
	methods: {
		abbreviatedStatus(currentStatus) {
			if (currentStatus === 'Request For Quote') {
				return 'RFQ';
			}

			if (currentStatus === 'Bid Won') {
				return 'Won';
			}

			if (currentStatus === 'Sent to Customer') {
				return 'STC';
			}

			if (currentStatus === 'Sent To Customer') {
				return 'STC';
			}

			if (currentStatus === 'Regional Manager Review') {
				return 'RMR';
			}

			return currentStatus;
		},
	},
};
</script>
