export default class MaterialPricingRepo {
	constructor({ to, supaTo, supabaseClientFactory, _ }) {
		this.to = to;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async createPricing(pricing) {
		if (!pricing) {
			throw new Error('pricing is required to create new pricing');
		}

		const [err] = await this.supaTo(
			this.supabase.from('material_pricing').insert(pricing),
		);

		if (err) {
			throw err;
		}
	}

	async getMaterialPricingByCategory(subcategory) {
		const [err, response] = await this.supaTo(this.supabase.rpc('get_latest_material_pricing_by_subcategory', { sc: subcategory }));

		if (err) {
			throw err;
		} else {
			return response.data;
		}
	}
}
