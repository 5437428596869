export default class ArchitectRepo {
	constructor({ sbTo, supabaseClientFactory, _ }) {
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getAllArchitects() {
		const [err, response] = await this.sbTo(
			this.supabase.from('architect').select(),
		);

		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async upsertArchitect(eng) {
		if (!eng) {
			throw new Error('Architect required to upsert.');
		}
		const [err, response] = await this.sbTo(this.supabase
			.from('architect')
			.upsert(eng, { onConflict: 'name' }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}
}
