<template>
<div class="page-header">
	<div class="row align-items-start">
		<div class="col col-sm-7 mb-2 mb-sm-0 justify-content-start">
			<h1 class="page-header-title">
				{{ title }}
			</h1>
			<slot name="left" />
		</div>
		<div class="col col-sm-5 justify-content-end">
			<div class="d-flex justify-content-end">
				<slot name="right" />
			</div>
		</div>
	</div>
</div>
</template>
<script>

export default {
	props: {
		title: {
			type: String,
			default: '',
		},
	},
};
</script>
