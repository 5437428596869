export default {
	namespaced: true,
	state: {
		ohCostPerMH: 0,
		ohCostPerSF: 0,
		ohMarinPerMH: 0,
		totalOHPerMH: 0,
		totalOHPerSF: 0,
		overhead: [
			{
			   cost_code: '010-100',
			   variable: 80,
			   cost: '=D1*$I$4*12',
			   tax: '',
			   cost_tax: '=SUM(F1,E1)',
			   margin_percent: '.2',
			   margin_dollar: '=(E1/(1-H1))-E1',
			   percent_of_job: '',
			   total: '=I1+G1',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '<div class="cs-type">Overhead</div>',
			   component: 'Supervision - RGM',
			},
			{
			   cost_code: '010-100',
			   variable: 80,
			   cost: '=D2*$I$4*40',
			   tax: '',
			   cost_tax: '=SUM(F2,E2)',
			   margin_percent: '.2',
			   margin_dollar: '=(E2/(1-H2))-E2',
			   percent_of_job: '',
			   total: '=I2+G2',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Supervision - PM',
			},
			{
			   cost_code: '010-110',
			   variable: 50,
			   cost: '=D3*$I$4*40',
			   tax: '',
			   cost_tax: '=SUM(F3,E3)',
			   margin_percent: '.2',
			   margin_dollar: '=(E3/(1-H3))-E3',
			   percent_of_job: '',
			   total: '=I3+G3',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Supervision - Assitant',
			},
			{
			   cost_code: '001-200',
			   variable: 70,
			   cost: '=D4*$I$4*8',
			   tax: '',
			   cost_tax: '=SUM(F4,E4)',
			   margin_percent: '.2',
			   margin_dollar: '=(E4/(1-H4))-E4',
			   percent_of_job: '',
			   total: '=I4+G4',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Pre-Construction',
			},
			{
			   cost_code: '010-130',
			   variable: 40,
			   cost: '=D5*$I$4*40',
			   tax: '',
			   cost_tax: '=SUM(F5,E5)',
			   margin_percent: '.2',
			   margin_dollar: '=(E5/(1-H5))-E5',
			   percent_of_job: '',
			   total: '=I5+G5',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'QC Supervisor',
			},
			{
			   cost_code: '010-040',
			   variable: 36,
			   cost: '=D6*$I$4*40*2',
			   tax: '',
			   cost_tax: '=SUM(F6,E6)',
			   margin_percent: '.2',
			   margin_dollar: '=(E6/(1-H6))-E6',
			   percent_of_job: '',
			   total: '=I6+G6',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Rigging / Forklift Driver x 2',
			},
			{
			   cost_code: '010-050',
			   variable: '',
			   cost: '10000',
			   tax: '',
			   cost_tax: '=SUM(F7,E7)',
			   margin_percent: '.2',
			   margin_dollar: '=(E7/(1-H7))-E7',
			   percent_of_job: '',
			   total: '=I7+G7',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Setup',
			},
			{
			   cost_code: '010-140',
			   variable: 20,
			   cost: '=$I$4*D8*40*2',
			   tax: '',
			   cost_tax: '=SUM(F8,E8)',
			   margin_percent: '.2',
			   margin_dollar: '=(E8/(1-H8))-E8',
			   percent_of_job: '',
			   total: '=I8+G8',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Clean up x 2',
			},
			{
			   cost_code: '001-010',
			   variable: '',
			   cost: '?',
				//    cost: '=SUM({{laborCost}},E1:E8)*$F$3+$E$51*$F$4',
			   tax: '',
			   cost_tax: '=SUM(F9,E9)',
			   margin_percent: '.2',
			   margin_dollar: '=(E9/(1-H9))-E9',
			   percent_of_job: '',
			   total: '=I9+G9',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'GL & Umbrella Insurance',
			},
			{
			   cost_code: '001-030',
			   variable: '',
			   cost: '5000',
			   tax: '',
			   cost_tax: '=SUM(F10,E10)',
			   margin_percent: '.2',
			   margin_dollar: '=(E10/(1-H10))-E10',
			   percent_of_job: '',
			   total: '=I10+G10',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Home Office Travel',
			},
			{
			   cost_code: '010-050',
			   variable: '',
			   cost: '2000',
			   tax: '',
			   cost_tax: '=SUM(F11,E11)',
			   margin_percent: '.2',
			   margin_dollar: '=(E11/(1-H11))-E11',
			   percent_of_job: '',
			   total: '=I11+G11',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Move In and Move Out',
			},
			{
			   cost_code: '001-080',
			   variable: '',
			   cost: '=I3/4*1000',
			   tax: '',
			   cost_tax: '=SUM(F12,E12)',
			   margin_percent: '.2',
			   margin_dollar: '=(E12/(1-H12))-E12',
			   percent_of_job: '',
			   total: '=I12+G12',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Site Office',
			},
			{
			   cost_code: '010-160',
			   variable: 80,
			   cost: '=D13*$I$3*5',
			   tax: '',
			   cost_tax: '=SUM(F13,E13)',
			   margin_percent: '.2',
			   margin_dollar: '=(E13/(1-H13))-E13',
			   percent_of_job: '',
			   total: '=I13+G13',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Safety (Labor)',
			},
			{
			   cost_code: '001-120',
			   variable: '',
			   cost: '2500',
			   tax: '',
			   cost_tax: '=SUM(F14,E14)',
			   margin_percent: '.2',
			   margin_dollar: '=(E14/(1-H14))-E14',
			   percent_of_job: '',
			   total: '=I14+G14',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Safety (Expenses)',
			},
			{
			   cost_code: '001-020',
			   variable: '',
			   cost: '6500',
			   tax: '',
			   cost_tax: '=SUM(F15,E15)',
			   margin_percent: '.2',
			   margin_dollar: '=(E15/(1-H15))-E15',
			   percent_of_job: '',
			   total: '=I15+G15',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Site Phone',
			},
			{
			   cost_code: '001-170',
			   variable: '',
			   cost: '10000',
			   tax: '',
			   cost_tax: '=SUM(F16,E16)',
			   margin_percent: '.2',
			   margin_dollar: '=(E16/(1-H16))-E16',
			   percent_of_job: '',
			   total: '=I16+G16',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Vehicle Allowance',
			},
			{
			   cost_code: '001-180',
			   variable: 1,
			   cost: '=D17*I5',
			   tax: '',
			   cost_tax: '=SUM(F17,E17)',
			   margin_percent: '.2',
			   margin_dollar: '=(E17/(1-H17))-E17',
			   percent_of_job: '',
			   total: '=I17+G17',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Tools',
			},
			{
			   cost_code: '001-900',
			   variable: 0.03,
			   cost: '=D18*(E13+E11+E8+E7+E6+E5+E3+E2+E1+E55-E54)',
			   tax: '',
			   cost_tax: '=SUM(F18,E18)',
			   margin_percent: '.2',
			   margin_dollar: '=(E18/(1-H18))-E18',
			   percent_of_job: '',
			   total: '=I18+G18',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Indirect Cost Allocation (Labor)',
			},
			{
			   cost_code: '001-900',
			   variable: 0.03,
			   cost: '=D19*E54',
			   tax: '',
			   cost_tax: '=SUM(F19,E19)',
			   margin_percent: '.2',
			   margin_dollar: '=(E19/(1-H19))-E19',
			   percent_of_job: '',
			   total: '=I19+G19',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Indirect Cost Allocation (Sub)',
			},
			{
			   cost_code: '001-901',
			   variable: 0.008,
			   cost: '=D20*(E13+E11+E8+E7+E6+E5+E3+E2+E1+E55-E54)',
			   tax: '',
			   cost_tax: '=SUM(F20,E20)',
			   margin_percent: '.2',
			   margin_dollar: '=(E20/(1-H20))-E20',
			   percent_of_job: '',
			   total: '=I20+G20',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Preconstruction Allocation (Labor)',
			},
			{
			   cost_code: '001-901',
			   variable: 0.008,
			   cost: '=D21*E54',
			   tax: '',
			   cost_tax: '=SUM(F21,E21)',
			   margin_percent: '.2',
			   margin_dollar: '=(E21/(1-H21))-E21',
			   percent_of_job: '',
			   total: '=I21+G21',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Preconstruction Allocation (Sub)',
			},
			{
			   cost_code: '001-190',
			   variable: 0.1,
			   cost: '=D22*SQFT',
			   tax: '',
			   cost_tax: '=SUM(F22,E22)',
			   margin_percent: '.2',
			   margin_dollar: '=(E22/(1-H22))-E22',
			   percent_of_job: '',
			   total: '=I22+G22',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Oil and Gas - Equipment',
			},
			{
			   cost_code: '006-090',
			   variable: 2.5,
			   cost: '=D23*I5',
			   tax: '',
			   cost_tax: '=SUM(F23,E23)',
			   margin_percent: '.2',
			   margin_dollar: '=(E23/(1-H23))-E23',
			   percent_of_job: '',
			   total: '=I23+G23',
			   cost_per_gross_sqft: '',
			   sale_per_gross_sqft: '',
			   type: '',
			   component: 'Nails ($2.00 panel / $2.50 stick)',
			},
			{
				type: '',
				cost_code: '001-100',
				component: 'Accomodations',
				variable: 0.3,
				hours: '',
				cost: '=D24*SQFT',
				tax: '',
				cost_tax: '=E24+F24',
				margin_percent: '.2',
				margin_dollar: '=(E24/(1-H24))-E24',
				percent_of_job: '',
				total: '=I24+G24',
			},
		],
		columns: [
			{ data: 'type', renderer: 'html' },
			{
				data: 'cost_code',
				type: 'autocomplete',
				source: ['010-100', '010-110', '001-200', '010-130', '010-040'],
				strict: false,
			},
			{
				data: 'component',
				type: 'autocomplete',
				source: ['Supervision - RGM', 'Pre-Construction (25%)', 'QC Supervisor X 1', 'Setup', 'Vehicle Allowance'],
				strict: false,
			},
			{
				data: 'variable',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'cost',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'tax',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'cost_tax',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readonly: true,
			},
			{
				data: 'margin_percent',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				// readOnly: true,
			},
			{
				data: 'margin_dollar',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				// readOnly: true,
			},
			{
				data: 'percent_of_job',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'total',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readonly: true,
			},
			{
				data: 'cost_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'sale_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
		],
		colHeaders: [
			'',
			'Cost Code',
			'Component',
			'Variable',
			'Cost',
			'Tax',
			'Cost + Tax',
			'Margin%',
			'Margin $$',
			'% Of Job',
			'Total Amt',
			'Cost/Sqft',
			'Sale/Sqft',
		],
		comments: [],
	},
	getters: {
		overhead: (state, getters, rootState) => {
			const { totalManHours, totalJobDuration, framingDuration, total, subUmbrella, coltGLUmbr } = rootState.bidCs;
			const { totalSubCost, totalLaborCost } = rootState.bidCsLabor;
			const sqft = rootState.activeBid.bid.bid_revision.square_footage;
			const data = state.overhead.map((row, index) => {
				const currentRow = index + 1;

				if (typeof row.cost === 'string') {
					row.cost = row.cost
						.replace('I5', totalManHours)
						.replace('$I$4', totalJobDuration)
						.replace('$I$3', framingDuration)
						.replace('I3', framingDuration)
						.replace('E54', totalSubCost)
						.replace('E55', totalLaborCost);
				}

				row.overhead = `=ROUND(K${currentRow}/${total}, 2)`;
				row.cost_per_gross_sqft = `=E${currentRow}/${sqft}`;
				row.sale_per_gross_sqft = `=K${currentRow}/${sqft}`;

				if (index === 8) {
					row.cost = `=SUM(${totalLaborCost}, E1:E8) * ${coltGLUmbr} + ${totalSubCost} * ${subUmbrella}`;
				}

				return row;
			});

			const rows = data.length;
			const totalRow = rows + 1;

			data.push({
				type: '',
				cost_code: '',
				component: 'Total Overhead',
				variable: '',
				cost: `=SUM(E1:E${rows})`,
				tax: `=SUM(F1:F${rows})`,
				cost_tax: `=E${totalRow}+F${totalRow}`,
				margin_percent: `=AVERAGE(H1:H${rows})`,
				margin_dollar: `=SUM(I1:I${rows})`,
				percent_of_job: `=SUM(J1:J${rows})`,
				total: `=SUM(K1:K${rows})`,
			});

			return data;
		},
		totalItems(state, getters) {
			return getters.overhead.length;
		},
	},
	mutations: {
		updateOverhead(state, payload) {
			state.overhead = payload;
		},
		addRow(state, reload) {
			const currentRow = state.overhead.length + 1;
			state.overhead.push({
				type: '',
				cost_code: '',
				component: '',
				variable: '',
				cost: '0',
				tax: '0',
				cost_tax: `=E${currentRow}+F${currentRow}`,
				margin_percent: '.12',
				margin_dollar: `=G${currentRow}/(1-H1)-G${currentRow}`,
				percent_of_job: '.12',
				total: `=G${currentRow}+I${currentRow}`,
				cost_per_gross_sqft: `=SUM(L1:L${currentRow})`,
				sale_per_gross_sqft: `=SUM(M1:M${currentRow})`,
			});

			reload();
		},
		setComments(state, payload) {
			state.comments = payload;
		},
		removeRow(state, { row, reload }) {
			state.overhead.splice(row, 1);

			if (state.comments) {
				for (let i = 0; i < state.comments.length; i++) {
					if (state.comments[i].row === row) {
						state.comments.splice(i, 1);
					}
					if (state.comments[i].row > row) {
						state.comments[i].row -= 1;
					}
				}
			}
			reload();
		},
		setOhData(state, { cost, margin, total, totalManHours, sqft }) {
			state.ohCostPerMH = cost / totalManHours;
			state.ohMarginPerMH = margin / totalManHours;
			state.ohCostPerSF = cost / sqft;
			state.totalOHPerMH = total / totalManHours;
			state.totalOHPerSF = total / sqft;
		},
		handleComment(state, data) {
			const existingCommentIndex = state.comments.findIndex(({ row, col }) => row === data.row && col === data.col);

			if (existingCommentIndex > -1) {
				if (data.comment === '') {
					state.comments.splice(existingCommentIndex, 1);
					return;
				}
				const existingComment = state.comments[existingCommentIndex];
				existingComment.comment = data.comment;
			} else {
				if (data.comment === '') return;
				state.comments.push(data);
			}
		},
	},
};
