export default class TrainingDataAssociationLogRepo {
	constructor({ sbTo, supabaseClientFactory }) {
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
	}

	async listByBidId(bidId) {
		const [error, data] = await this.sbTo(
			this.supabase
				.from('training_data_association_log')
				.select('*, user(*)')
				.match({ bid_id: bidId }),
		);
		if (error) {
			throw error;
		}

		return data;
	}

	async create(data) {
		const [error, result] = await this.sbTo(
			this.supabase
				.from('training_data_association_log')
				.insert(data),
		);
		if (error) {
			throw error;
		}

		return result;
	}
}
