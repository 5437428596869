export default {
	namespaced: true,
	state: {
		siteSupervisors: [],
	},
	getters: {
		getSiteSupervisors: (state) => state.siteSupervisors.map((item) => item.name),
	},
	mutations: {
		setSiteSupervisors(state, siteSupervisors) {
			state.siteSupervisors = siteSupervisors;
		},
	},
	actions: {
		async getSiteSupervisors({ commit }) {
			const { to, siteSupervisorRepo, logDnaFactory: logger } = this.$cradle;
			const [err, siteSupervisors] = await to(siteSupervisorRepo.getSiteSupervisors());
			if (err) {
				logger.error(err.message);
				throw err;
			}
			commit('setSiteSupervisors', siteSupervisors);
		},
	},
};
