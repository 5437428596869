export default {
	namespaced: true,
	state: {
		display: false,
		displaySavedFilters: false,
		tab: 'Filters',
	},
	mutations: {
		setDisplay(state, display) {
			state.display = display;
		},
		setDisplaySavedFilters(state, display) {
			state.displaySavedFilters = display;
		},
		setTab(state, tab) {
			state.tab = tab;
		},
	},
	actions: {
		toggleDisplay({ commit, state }) {
			commit('setDisplay', !state.display);
		},
		toggleDisplaySavedFilters({ commit, state }) {
			commit('setDisplaySavedFilters', !state.displaySavedFilters);
		},
	},
};
