export default {
	namespaced: true,
	state: {
		buildings: [],
	},
	mutations: {
		setBuildings(state, buildings) {
			state.buildings = buildings;
		},
	},
	actions: {
		async getBuildings({ commit }, bidRevisionId) {
			const { to, buildingRepo } = this.$cradle;
			const [err, buildings] = await to(buildingRepo.getBuildingsByBidRevision(bidRevisionId));

			if (err) {
				throw err;
			}

			commit('setBuildings', buildings);
		},
	},
};
