import Settings from '../views/Settings.vue';
import createBreadcrumbs from '../services/breadcrumbs-service.mjs';

function generateBreadcrumbs(route) {
	return createBreadcrumbs(route);
}

export default {
	path: '/historical-data',
	name: 'Historical Data',
	component: Settings,
	meta: { authenticate: true },
	children: [
		{
			name: 'Purchase Takeoffs',
			path: 'purchase-takeoffs',
			component: () => import('../views/PurchaseTakeOffs.vue'),
			meta: {
				authenticate: true,
				breadcrumbs() {
					return generateBreadcrumbs({
						name: 'Purchase Takeoffs',
						destination: '/historical-data/purchase-takeoffs',
					});
				},
			},
		},
		{
			name: 'Historical Projects',
			path: 'historical-projects',
			component: () => import('../views/HistoricalProjects.vue'),
			meta: {
				authenticate: true,
				breadcrumbs() {
					return generateBreadcrumbs({
						name: 'Historical Projects',
						destination: '/historical-data/historical-projects',
					});
				},
			},
		},
		{
			name: 'Historical Archived Projects 2',
			path: 'archived-projects',
			component: () => import('../views/ArchivedProjects.vue'),
			meta: {
				authenticate: true,
				breadcrumbs() {
					return generateBreadcrumbs({
						name: 'Archived Projects',
						destination: '/historical-data/archived-projects',
					});
				},
			},
		},
		{
			name: 'Training Data',
			path: 'training-data',
			component: () => import('../views/TrainingData.vue'),
			meta: {
				authenticate: true,
				breadcrumbs() {
					return generateBreadcrumbs({
						name: 'Training Data',
						destination: '/historical-data/training-data',
					});
				},
			},
		},
	],
};
