export default class SupplierAttachmentRepo {
	constructor({ to, supaTo, supabaseClientFactory, _ }) {
		this.to = to;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getSupplierAttachments(bidRevisionId, supplierId) {
		const [error, data] = await this.supaTo(
			this.supabase
				.from('supplier_attachment_view')
				.select('*')
				.match({
					bid_revision_id: bidRevisionId,
					supplier_id: supplierId,
				}),
		);

		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data', null);
		}
	}
}
