<template>
<div>
	<button
		v-if="isProcessing"
		class="btn btn-primary"
		type="button"
		disabled
	>
		<span
			class="spinner-border spinner-border-sm"
			role="status"
			aria-hidden="true"
		/>
		{{ processingText }}
	</button>
	<button
		v-else
		id="create-building"
		type="submit"
		class="btn btn-primary"
		@click="action"
	>
		{{ initialText }}
	</button>
</div>
</template>
<script>
export default {
	props: {
		processingText: {
			type: String,
			default: 'Saving...',
		},
		initialText: {
			type: String,
			default: 'Save Changes',
		},
		action: {
			type: Function,
			default: () => {},
		},
		isProcessing: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
