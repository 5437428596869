export default {
	namespaced: true,
	state: {
		estimators: [],
		loaded: false,
		estimatorToUpdate: {},
		estimatorForm: {
			display: false,
		},
		loading: true,
	},
	getters: {
		estimatorList(state) {
			return state.estimators.map(({ id, name, email, archived }) => ({ id, name, email, status: archived ? 'Archived' : 'Active' }));
		},
		getEstimators: (state) => state.estimators.map(({ name }) => name),
		activeEstimatorList(state) {
			return state.estimators.filter(({ archived }) => !archived).map(({ name }) => name);
		},
	},
	mutations: {
		addEstimator(state, estimator) {
			state.estimators.push(estimator);
		},
		setEstimators(state, estimators) {
			estimators.sort((a, b) => {
				if (a.name < b.name) { return -1; }
				if (a.name > b.name) { return 1; }
				return 0;
			});
			state.estimators = estimators;
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
			state.loading = false;
		},
		setEstimatorToUpdate(state, estimator) {
			state.estimatorToUpdate = { ...estimator };
		},
		toggleEstimatorForm(state) {
			state.estimatorForm.display = !state.estimatorForm.display;

			if (!state.estimatorForm.display) {
				state.estimatorToUpdate = {};
			}
		},
		updateExistingEstimator(state, estimator) {
			state.loading = true;
			const index = state.estimators.findIndex(({ id }) => id === estimator.id);
			const { estimators } = state;
			state.estimators = [];
			estimators[index] = estimator;
			state.estimators = estimators;
			state.loading = false;
		},
		setLoading(state) {
			state.loading = true;
		},
	},
	actions: {
		async getEstimators({ commit, state }) {
			if (state.loaded) {
				return;
			}
			commit('setLoading');
			const { to, estimatorRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(estimatorRepo.getAllEstimators());
			if (err) {
				logger.error(err.message);
				commit('setEstimators', []);
				throw err;
			} else {
				commit('setEstimators', data);
				commit('setLoadingStatus', true);
			}
		},
		async setEstimatorToUpdate({ commit, state }, estimatorId) {
			const estimatorToUpdate = state.estimators.find((item) => item.id === estimatorId);
			commit('setEstimatorToUpdate', estimatorToUpdate);
		},
		async toggleEstimatorForm({ commit }) {
			commit('toggleEstimatorForm');
		},
		async createOrUpdateEstimators({ commit, state }) {
			const { estimatorRepo, to, logDnaFactory: logger } = this.$cradle;

			if (state.estimatorToUpdate.id) {
				const [err] = await to(estimatorRepo.updateEstimator(state.estimatorToUpdate));

				if (err) {
					logger.error(err.message);
					throw err;
				}
				commit('updateExistingEstimator', state.estimatorToUpdate);
			} else {
				const [err, estimator] = await to(estimatorRepo.createEstimator(state.estimatorToUpdate));

				if (err) {
					logger.error(err.message);
					throw err;
				}

				commit('addEstimator', estimator);
			}
		},
	},
};
