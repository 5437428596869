export default class FileRepo {
	constructor({ supaTo, supabaseClientFactory, _, to }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
		this.to = to;
	}

	cleanFileName(fileName) {
		return fileName.replace(/[^a-z0-9]/gi, '-').toLowerCase();
	}

	getExtentionFile(fileName) {
		return (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName) : undefined;
	}

	async saveFileToSupabaseStore(file, bucket) {
		const extensionFile = this.getExtentionFile(file.name);
		const fileName = `${this.cleanFileName(file.name)}-${new Date().valueOf()}.${extensionFile}`;

		const [err, response] = await this.supaTo(this.supabase
			.storage
			.from(bucket)
			.upload(`public/${fileName}`, file, {
				cacheControl: '3600',
				upsert: false,
			}));

		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data.Key', null);
		}
	}

	async getFilePublicUrl(filePath, bucket) {
		// Clear bucket name on file path.
		const path = filePath.replace(`${bucket}/`, '');

		const { publicURL, error } = this.supabase
			.storage
			.from(bucket)
			.getPublicUrl(path);

		if (error) {
			throw error;
		} else {
			return publicURL;
		}
	}

	async createFile(name, url, resourceId, resourceType, userId) {
		const [err, response] = await this.supaTo(
			this.supabase.from('file').insert({
				name,
				url,
				resource_id: resourceId,
				resource_type: resourceType,
				user_id: userId,
			 }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async deleteFile(fileId) {
		const [err] = await this.supaTo(
			this.supabase.from('file').update({
				deleted_at: new Date(),
			}).match({
				id: fileId,
			}),
		);
		if (err) {
			throw err;
		}
	}
}
