export default class RegionRevisionRepo {
	constructor({ to, supaTo, supabaseClientFactory }) {
		this.to = to;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
	}

	async getCurrentRevision() {
		const [err, res] = await this.supaTo(this.supabase
			.from('region_revision')
			.select(`
				*,
				regions:region (
					id,
					region_name:region_name_id (
						name,
						full_name
					)
				)
			`)
			.eq('archived', false));
		if (err) {
			throw err;
		}
		const [revision] = res.data;
		return revision;
	}

	async getRegions() {
		const [err, res] = await this.supaTo(this.supabase
			.from('region_name')
			.select('*'));
		if (err) {
			throw err;
		}
		return res.data;
	}

	async getRevisionByDate(date) {
		const [err, res] = await this.supaTo(this.supabase
			.from('region_revision')
			.select(`
				*,
				regions:region (
					id,
					region_name:region_name_id (
						name,
						full_name
					)
				)
			`));
		if (err) {
			throw err;
		}
		const matchingRevision = res.data.filter(({ start_date: startDate, end_date: endDate }) => {
			const _startDate = new Date(startDate);
			let _endDate;
			if (!endDate) {
				_endDate = new Date('2099-01-01');
			} else {
				_endDate = new Date(endDate);
			}
			return _startDate <= date && _endDate > date;
		});
		const [revision] = matchingRevision;
		return revision;
	}

	async getRevisionByDateAndName(date, name) {
		const [err, res] = await this.to(this.getRevisionByDate(date));
		if (err) {
			throw err;
		}
		const lowerName = name.toLowerCase();
		return res.regions.find(({ region_name: regionName }) => Object.values(regionName).map((val) => val.toLowerCase()).includes(lowerName));
	}
}
