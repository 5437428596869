export default class MaterialPredictionRepo {
	constructor({ to, _, modelData, predictionVersions, costConverterService, predictionRepo }) {
		this.to = to;
		this.costConverter = costConverterService;
		this._ = _;
		this.predictionRepo = predictionRepo;
		this.stickModelName = 'total_material_cost_stick_jobs';
		this.stickModelVersion = predictionVersions[this.stickModelName].version;
		this.stickModelData = modelData.models.total_material_cost_stick_jobs.versions[this.stickModelVersion];
		this.panelModelName = 'total_material_cost_panel_jobs';
		this.panelModelVersion = predictionVersions[this.panelModelName].version;
		this.panelModelData = modelData.models.total_material_cost_panel_jobs.versions[this.panelModelVersion];
	}

	async getMaterialPrediction(bidRevisionId, isPanelType) {
		let res = {};
		if ([null, undefined].includes(isPanelType)) {
			throw new Error('Panel type checkbox is a required feature for material cost prediction.');
		}

		if (isPanelType) {
			const [err, _res] = await this.to(this.getMaterialPanelPrediction(bidRevisionId));
			if (err) {
				throw err;
			}
			res = _res;
		} else {
			const [err, _res] = await this.to(this.getMaterialStickPrediction(bidRevisionId));
			if (err) {
				throw err;
			}
			res = _res;
		}
		return res;
	}

	async getMaterialPanelPrediction(bidRevisionId) {
		const [err, res] = await this.to(
			this._getPredictionByModelData(bidRevisionId, this.panelModelData, this.panelModelName, this.panelModelVersion),
		);
		if (err) {
			throw err;
		}
		return res;
	}

	async getMaterialStickPrediction(bidRevisionId) {
		const [err, res] = await this.to(
			this._getPredictionByModelData(bidRevisionId, this.stickModelData, this.stickModelName, this.stickModelVersion),
		);
		if (err) {
			throw err;
		}
		return res;
	}

	async _getPredictionByModelData(bidRevisionId, modelData, modelName, modelVersion) {
		const { endpoint_id: endpointId, fred_adjustment_series: fredAdjustmentSeries, adjustment_date: adjustmentDate,
			prediction_interval: predictionInterval, mean, median,
			deployed_model_id: configDeployedModelId, standard_score_95: standardScore95 } = modelData;
		const [predictionErr, predictionRes] = await this.to(this._submitPrediction(endpointId, bidRevisionId));
		if (predictionErr) {
			throw predictionErr;
		}
		const r = Math.round;
		const { predictions, deployedModelId } = predictionRes;
		if (!predictions) {
			throw new Error('Prediction not available. Please check that gross/porch square footage has been added to buildings.');
		}
		if (deployedModelId !== configDeployedModelId) {
			throw new Error('Prediction model ID does not match model ID in config.');
		}
		const [prediction] = predictions;
		const [costErr, costRes] = await this.to(this.costConverter.convertByDateAndCode(prediction, adjustmentDate, fredAdjustmentSeries));
		if (costErr) {
			throw costErr;
		}
		const [scaledCost, scaledDate] = costRes;
		const adjustedStdv = predictionInterval / 1.96;

		return {
			modelMetadata: {
				modelName,
				modelVersion,
			},
			adjustedStdv,
			meanRange: [r(scaledCost - scaledCost * mean), r(scaledCost + scaledCost * mean)],
			medianRange: [r(scaledCost - scaledCost * median), r(scaledCost + scaledCost * median)],
			scaleDate: new Date(scaledDate),
			scaledPrediction: r(scaledCost),
			scaledRange: [r(scaledCost - scaledCost * predictionInterval), r(scaledCost + scaledCost * predictionInterval)],
			standardScore95,
		};
	}

	async _submitPrediction(endpointId, bidRevisionId) {
		const [error, data] = await this.to(this.predictionRepo.getPrediction(endpointId, bidRevisionId, 'MATERIAL'));
		if (error) {
			throw error;
		} else {
			return data.data;
		}
	}
}
