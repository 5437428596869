<template>
<div
	class="card"
>
	<div class="card-header">
		<div class="row justify-content-between align-items-center flex-grow-1">
			<div class="col-12 col-md">
				<div class="d-flex align-items-center">
					<h5 class="card-header-title mr-1">
						{{ title }}
					</h5>
					<div
						v-show="showDropdownFilterNames"
						class="dropdown ml-2"
					>
						<button
							id="filter_names"
							class="btn btn-outline-primary dropdown-toggle"
							type="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							@click="toggleFilterNames"
						>
							{{ activeFilterTitle }}
						</button>
						<div
							class="dropdown-menu dropdown-menu-right"
							:class="{ show: showFilterNames }"
							aria-labelledby="dropdownMenuButton"
						>
							<a
								class="dropdown-item"
								href="javascript:void(0)"
								@click="resetFilterNames"
							>All {{ title }}</a>
							<a
								v-for="availableFilter of availableFilters"
								:key="availableFilter.name"
								class="dropdown-item"
								href="javascript:void(0)"
								@click="setActiveFilter(availableFilter.filter, availableFilter.name, availableFilter.columns)"
							>{{ availableFilter.name }}</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-auto">
				<input
					id="datatableSearch"
					v-model="searchTerm"
					type="search"
					class="form-control form-control-sm"
					:placeholder="searchPlaceholder"
					aria-label="Search"
				>
			</div>
			<slot name="table-actions" />
			<div
				v-if="query.filters"
				class="hs-unfold mr-3"
			>
				<a
					v-if="showFilterButton"
					class="js-hs-unfold-invoker btn btn-sm btn-white"
					href="#"
					@click.prevent="toggleFilters"
				>
					<i class="tio-filter-list" />
					Filters

					<span
						v-if="countFilters > 0"
						class="badge bg-secondary text-light ms-1"
					>{{ countFilters }}</span>
				</a>
			</div>
			<button
				v-if="downloadable"
				class="btn btn-sm btn-white mr-2"
				:disabled="disableDownload"
				@click="createXLSX"
			>
				<i class="tio-download-to" />
				{{ downloadButtonText }}
			</button>
		</div>
	</div>
	<div class="table-responsive datatable-custom">
		<div class="table-wrapper">
			<b-table
				id="data-table"
				ref="dataTable"
				class="datatable-custom"
				:sticky-header="true"
				:no-border-collapse="false"
				:items="searchedItems"
				:fields="headers"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:per-page="itemsPerPage"
				:busy="loading"
				:show-empty="isEmpty"
				:small="small"
			>
				<template #table-busy>
					<div class="text-danger py-6">
						<b-spinner class="align-middle ma-3" />
						<strong>Loading...</strong>
					</div>
				</template>
				<template #empty>
					<h4 class="py-6">
						No results were found.
					</h4>
				</template>
				<div
					v-for="header of headers"
					:slot="`head(${header.key})`"
					:key="header.key"
					:class="determineSortClass(header, header.filterName || header.key)"
				>
					<span :class="header.customClasses">
						{{ header.name }}
					</span>
				</div>
				<template #cell(name)="data">
					<slot
						:data="data"
						name="name"
					>
						{{ data.item.name }}
					</slot>
				</template>
				<template #cell(custom1)="data">
					<slot
						:data="data"
						name="custom1"
					>
						Custom 1
					</slot>
				</template>
				<template #cell(custom2)="data">
					<slot
						:data="data"
						name="custom2"
					/>
				</template>
				<template #cell(custom3)="data">
					<slot
						:data="data"
						name="custom3"
					/>
				</template>
				<template #cell(custom4)="data">
					<slot
						:data="data"
						name="custom4"
					/>
				</template>
				<template #cell(custom5)="data">
					<slot
						:data="data"
						name="custom5"
					/>
				</template>
				<template #cell(custom6)="data">
					<slot
						:data="data"
						name="custom6"
					/>
				</template>
				<template #cell(custom7)="data">
					<slot
						:data="data"
						name="custom7"
					/>
				</template>
				<template #cell(custom8)="data">
					<slot
						:data="data"
						name="custom8"
					/>
				</template>
				<template #cell(custom9)="data">
					<slot
						:data="data"
						name="custom9"
					/>
				</template>
				<template #cell(custom10)="data">
					<slot
						:data="data"
						name="custom10"
					/>
				</template>
			</b-table>
		</div>
	</div>
	<div class="card-footer">
		<div class="row justify-content-between align-items-center flex-grow-1">
			<div
				v-if="showFooter"
				class="col-sm mb-2 mb-sm-0"
			>
				<h5 v-if="query && query.totalRows">
					Total: {{ query.totalRows }}
				</h5>
			</div>
			<div
				v-if="showFooter"
				class="col-sm-auto"
			>
				<div class="d-flex justify-content-center justify-content-sm-end">
					<b-pagination
						v-model="page"
						class="float-right"
						:total-rows="query.totalRows"
						:per-page="itemsPerPage"
						aria-controls="data-table"
						first-text="First"
						next-text="Next"
						prev-text="Prev"
						last-text="Last"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
	props: {
		headers: {
			type: Array,
			default() {
				return [];
			},
		},
		items: {
			type: Array,
			default() {
				return [];
			},
		},
		title: {
			type: String,
			default: 'Data Table',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		fetchData: {
			type: Function,
			default: () => {},
		},
		query: {
			type: Object,
			default() {
				return { sort: {} };
			},
		},
		setFilters: {
			type: Function,
			default: () => {},
		},
		setColumns: {
			type: Function,
			default: () => {},
		},
		setFilterValue: {
			type: Function,
			default: () => {},
		},
		searchFilterName: {
			type: String,
			default: null,
		},
		filterSchema: {
			type: Array,
			default() {
				return [];
			},
		},
		setPage: {
			type: Function,
			default: () => {},
		},
		setItemsPerPage: {
			type: Function,
			default: () => {},
		},
		resetFilters: {
			type: Function,
			default: () => {},
		},
		setSort: {
			type: Function,
			default: () => {},
		},
		createFilter: {
			type: Function,
			default: () => {},
		},
		availableFilters: {
			type: Array,
			default: () => [],
		},
		downloadable: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		download: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			searchTerm: '',
			showFilters: false,
			showFilterNames: false,
			search: null,
			activeFilter: '',
			disableDownload: false,
			downloadButtonText: 'Download',
		};
	},
	computed: {
		sortBy: {
			get() {
				return this.query.sort.by;
			},
			set(newSortBy) {
				this.setSort({ by: newSortBy, desc: this.sortDesc });
			},
		},
		sortDesc: {
			get() {
				return this.query.sort.desc;
			},
			set(newSortDesc) {
				this.setSort({ by: this.sortBy, desc: newSortDesc });
			},
		},
		itemsPerPage: {
			get() {
				return this.query?.limit || 0;
			},
			set(newLimit) {
				this.setItemsPerPage(newLimit);
			},
		},
		page: {
			get() {
				return this.query?.page || 1;
			},
			set(newPage) {
				this.setPage(newPage);
				this.fetchData();
			},
		},
		isEmpty() {
			return this.items.length === 0;
		},
		searchPlaceholder() {
			if (this.searchFilterName) {
				return `Search by ${this.searchFilterName.replace(/_/g, ' ')}`;
			}

			return 'Search';
		},
		searchedItems() {
			if (this.searchFilterName) {
				return this.items;
			}

			return this.items.filter((item) => JSON.stringify(item).toLowerCase().includes(this.searchTerm.toLowerCase()));
		},
		showDropdownFilterNames() {
			return this.availableFilters.length > 0;
		},
		activeColumnCount() {
			return this.headers.length;
		},
		activeFilterTitle() {
			if (!this.activeFilter) {
				return `All ${this.title}`;
			}

			return this.activeFilter;
		},
		showFilterButton() {
			return this.filterSchema.length > 0;
		},
		countFilters() {
			return Object.keys(this.query.filters).length;
		},
	},
	watch: {
		searchTerm() {
			this.setTermAndExecuteSearch();
		},
	},
	mounted() {
		const { _ } = this.$cradle();
		if (this.fetchData && this.setFilterValue) {
			this.search = _.debounce(this.fetchData, 500, { trailing: true });
		}
	},
	methods: {
		...mapActions('alert', ['createErrorAlert', 'createSuccessAlert']),
		...mapActions('filtersNav', ['toggleDisplay']),
		determineSortClass(header, col) {
			return {
				sorting: header.sortable,
				sorting_asc: this.sortBy === col && !this.sortDesc,
				sorting_desc: this.sortBy === col && this.sortDesc,
			};
		},
		toggleFilters() {
			this.toggleDisplay();
		},
		toggleFilterNames() {
			this.showFilterNames = !this.showFilterNames;
		},
		async apply() {
			this.showFilterNames = false;
			await this.fetchData();
		},
		setActiveFilter(filter, filterName, columns) {
			this.setFilters(filter);
			this.setColumns(columns);
			this.apply();
			this.activeFilter = filterName;
		},
		resetFilterNames() {
			this.resetFilters();
			this.apply();
			this.activeFilter = '';
		},
		async setTermAndExecuteSearch() {
			if (this.search) {
				const value = {
					dataType: 'string',
					display: true,
					filterName: this.searchFilterName,
					filterType: 'fuzzy match',
					key: this.searchFilterName,
					sortable: true,
					value: this.searchTerm,
				};
				const filter = this.searchFilterName;
				this.setFilterValue({ value, filter });
				await this.search();
			}
		},
		async createXLSX() {
			const { title } = this;
			this.disableDownload = true;
			this.downloadButtonText = 'Processing';
			const { to } = this.$cradle();
			const [err] = await to(this.download(title));
			if (err) {
				this.createErrorAlert(`Error generating: ${err.message}`);
			} else {
				this.createSuccessAlert('Generating download... We will email it to you, when its ready!');
			}

			this.disableDownload = false;
			this.downloadButtonText = 'Download';
		},
	},

};

</script>
