const ok = (code) => {
	if (code >= 200 && code <= 299) {
		return true;
	}
	return false;
};

function supabaseToCorrect(supabaseFn) {
	return supabaseFn
		.then((resp) => {
			// Error handler for Invoke
			if (resp?.data?.status === 'error') {
				return [Error(`[${resp.data.code}] ${resp.data.err}`), undefined];
			}

			// Regular Supabase calls
			// error could be string or Error() or ([] + status != 200 && statusText) or null
			if (resp.error && resp.error.code && resp.error.message) {
				return [Error(`[${resp.error.code}] ${resp.error.message}`), undefined];
			} if (resp.error && resp.error.message) {
				return [Error(resp.error.message), undefined];
			} if (typeof (resp.error) === 'string') {
				return [Error(resp.error), undefined];
			} if (resp.status && !ok(resp.status)) {
				// This is kind of weird... supabase/pg-rest sometimes returns
				// a 4xx code for a not found item vs a real error
				//
				// https://github.com/PostgREST/postgrest/issues/1257
				// https://postgrest.org/en/latest/errors.html
				return [Error(`[${resp.status}] ${resp.statusText}`), undefined];
			}

			if (resp.data && resp.data.payload) {
				return [null, resp.data.payload];
			} if (resp.data) {
				return [null, resp.data];
			}
			return [null, {}];
		})
		.catch((err) => [err, undefined]);
}

export default supabaseToCorrect;
