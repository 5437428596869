export default {
	methods: {
		canParseISO(d) {
			const { libDateFns: ld } = this.$cradle();
			let dd;
			// eslint-disable-next-line no-restricted-syntax
			try {
				dd = ld.parseISO(d);
			} catch (e) {
				return false;
			}

			if (String(dd) === 'Invalid Date') {
				return false;
			}
			return true;
		},
		formatDate(d, noDateMessage) {
			const { libDateFns: ld } = this.$cradle();
			if (!this.canParseISO(d)) {
				return noDateMessage;
			}
			return ld.format(ld.parseISO(d), 'MM/dd/yy');
		},
		formatDateAs(d, format) {
			const { libDateFns: ld } = this.$cradle();
			if (!this.canParseISO(d)) {
				return '';
			}
			return ld.format(ld.parseISO(d), format);
		},
		formatDateTime(d) {
			const { libDateFns: ld } = this.$cradle();
			if (!this.canParseISO(d)) {
				return '';
			}
			return ld.format(ld.parseISO(d), 'MM/dd/yy h:mmaaa');
		},
		formatISO(d) {
			const { libDateFns: ld } = this.$cradle();
			if (!this.canParseISO(d)) {
				return '';
			}
			return ld.formatISO(ld.parseISO(d));
		},
		formatDateTimeZone(d) {
			const { libDateFns: ld } = this.$cradle();
			if (!this.canParseISO(d)) {
				return '';
			}
			const date = ld.parseISO(d);
			const tzName = date.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
			const text = `${ld.format(date, 'MM/dd/yy h:mmaaa')} ${tzName}`;
			return text;
		},
		getWeekColorClass(w) {
			if (!w || Number.isNaN(+w)) {
				return '';
			}
			return `week-color-${w % 4}`;
		},
		dateDiffForHumans(d) {
			const { libDateFns: ld } = this.$cradle();
			if (!this.canParseISO(d)) {
				return '';
			}

			const text = ld.formatDistanceToNow(ld.parseISO(d), { addSuffix: true });
			return text.replace(/about /i, '');
		},
		formatNumber(number, withDecimals) {
			const { libNumeral } = this.$cradle();
			if (withDecimals) {
				return `${libNumeral(number).format('0,0.00')}`;
			}
			return `${libNumeral(number).format('0,0')}`;
		},
		formatCurrency(i) {
			const { libNumeral } = this.$cradle();
			return libNumeral(i).format('$0,0.00');
		},
		formatPercentage(number) {
			const { libNumeral } = this.$cradle();
			const percent = number * 100;

			return `${libNumeral(percent).format('0,0.0')}%`;
		},
		routeParamToFriendly(routeParam) {
			if (!routeParam || routeParam === '') {
				return null;
			}

			const s = routeParam.split('-');
			const m = s.map((item) => item.charAt(0).toUpperCase() + item.slice(1));
			return m.join(' ');
		},
	},
};
