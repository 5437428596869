export default {
	namespaced: true,
	state: {
		invalidBidPages: {},
	},
	getters: {
		isErrors: (state) => () => !!Object.keys(state.invalidBidPages).length,
		invalidPages: (state) => state.invalidBidPages,
	},
	mutations: {
		addInvalidPage(state, invalidPage) {
			state.invalidBidPages[invalidPage.name] = invalidPage;
		},
		clearInvalidPageByName(state, name) {
			delete state.invalidBidPages[name];
		},
		resetInvalidPages(state) {
			state.invalidBidPages = {};
		},
	},
	actions: {
		async addInvalidPage({ commit }, invalidPage) {
			commit('addInvalidPage', invalidPage);
		},
		async clearInvalidPageByName({ commit }, page) {
			commit('clearInvalidPageByName', page);
		},
	},
};
