export default {
	namespaced: true,
	state: {
		regions: [],
		loaded: false,
	},
	getters: {
		getRegions: (state) => state.regions.map(({ name }) => name),
	},
	mutations: {
		setRegions(state, regions) {
			state.regions = regions;
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
		},
	},
	actions: {
		async getRegions({ commit, state }) {
			if (state.loaded) {
				return;
			}
			const { to, regionRevisionRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(regionRevisionRepo.getRegions());
			if (err) {
				logger.error(err.message);
				commit('setRegions', []);
				throw err;
			} else {
				commit('setRegions', data);
				commit('setLoadingStatus', true);
			}
		},
	},
};
