<template>
<div>
	<AppLayout>
		<AppModal v-if="modalShow" />

		<router-view />
	</AppLayout>
</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import AppLayout from './layouts/AppLayout.vue';
import AppModal from './components/AppModal.vue';

export default {
	name: 'App',
	components: { AppLayout, AppModal },
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState('modal', ['show']),
		modalShow() {
			return this.show;
		},
	},
	async mounted() {
		const { to } = this.$cradle();
		await this.$store.dispatch('types/initialize');
		await this.$store.dispatch('user/getAllUsers');
		const [regionErr] = await to(this.getRegions());
		if (regionErr) {
			this.createErrorAlert('An error ocurred while trying to fetch regions!');
		}
		const [estimatorErr] = await to(this.getEstimators());
		if (estimatorErr) {
			this.createErrorAlert('There was an error getting estimators.');
		}
		const [architectErr] = await to(this.getArchitects());
		if (architectErr) {
			this.createErrorAlert(`getArchitects ${architectErr.message}`);
		}
		const [engineerErr] = await to(this.getEngineers());
		if (engineerErr) {
			this.createErrorAlert(`getEngineers ${engineerErr.message}`);
		}
	},
	methods: {
		...mapActions('estimator', ['getEstimators']),
		...mapActions('engineer', ['getEngineers']),
		...mapActions('architect', ['getArchitects']),
		...mapActions('region', ['getRegions']),
		...mapActions('alert', ['createErrorAlert']),
	},
};
</script>
