<template>
<div>
	<page-header title="Release Notes" />
	<!-- eslint-disable vue/no-v-html -->
	<div v-html="notes" />
</div>
</template>
<script>
import ChangeLog from '../../../CHANGELOG.md';
import PageHeader from '../components/PageHeader.vue';

export default {
	components: { PageHeader },
	data() {
		return {
			notes: ChangeLog,
		};
	},
};
</script>
