<template>
<b-card class="mb-4">
	<div class="row justify-content-start mb-2">
		<div
			v-for="(data, index) of datas"
			:key="index"
			class="col-auto"
		>
			<span
				class="legend-indicator"
				:class="colors[index]"
			/>{{ data.name }} ({{ data.count }})
		</div>
	</div>
	<div class="progress">
		<div
			v-for="(data, index) of datas"
			:key="index"
			class="progress-bar"
			:class="colors[index]"
			role="progressbar"
			:style="`width: ${data.percentage}%`"
			:aria-valuenow="data.pecentage"
			aria-valuemin="0"
			aria-valuemax="100"
		/>
	</div>
</b-card>
</template>
<script>
import { mapState } from 'vuex';

export default {
	props: {
		bids: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			colors: {
				'Request For Quote': 'bg-primary',
				'Regional Manager Review': 'bg-info',
				'Sent to Customer': 'bg-warning',
				'Bid Won': 'bg-success',
			},
		};
	},
	computed: {
		...mapState('types', ['bidStatus']),
		datas() {
			const { _ } = this.$cradle();
			const bids = _.cloneDeep(this.bids);
			const bidStatus = {};
			for (const bid of bids) {
				const { name } = this.bidStatus.find((item) => item.value === bid.status || item.name === bid.status);
				bid.status = name;
				if (!bidStatus[bid.status]) {
					bidStatus[bid.status] = { name, count: 1, percentage: this.getPercentage(1) };
				} else {
					bidStatus[bid.status].count += 1;
					bidStatus[bid.status].percentage = this.getPercentage(bidStatus[bid.status].count);
				}
			}
			return bidStatus;
		},
	},
	methods: {
		getPercentage(count) {
			return Math.round((count / this.bids.length) * 100);
		},
	},
};
</script>
