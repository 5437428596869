export default {
	namespaced: true,
	state: {
		baseLineSquareFootage: 200000,
		squareFootage: 200000,
		hourlyRate: 60,
		scope: [
			{ name: 'Takeoff GSF', baseLineHours: 6, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff NSF', baseLineHours: 3, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff balcony SF', baseLineHours: 2, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff floor framing SF', baseLineHours: 3, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff roof framing SF', baseLineHours: 2, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff residential & public unit counts', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff balcony count', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff Windows/Doors', baseLineHours: 2, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff Tyvek/WRB', baseLineHours: 2, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff Firewalls', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff stairs', baseLineHours: 2, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff shearwall sheathing', baseLineHours: 4, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff hold-downs', baseLineHours: 4, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff trellises/exterior features', baseLineHours: 2, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Takeoff siding/trim', baseLineHours: 16, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Fill out budget cs sheet with GSF & unit counts', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{
				name: 'Fill out budget cs sheet with SF, count and takeoff info',
				baseLineHours: 1,
				hours: 0,
				included: false,
				section: 'sqft_estimate',
			},
			{ name: 'Fill out project info sheet', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{
				name: 'Create budgetary quote letter with typical/general info',
				baseLineHours: 1,
				hours: 0,
				included: false,
				section: 'sqft_estimate',
			},
			{ name: 'Create quote letter with job specific info', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Upload plans to fieldwire', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Complete customer scope sheet', baseLineHours: 1, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'sqft_estimate' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'sqft_estimate' },
			// { name: 'Takeoff', baseLineHours: 0, hours: 0, included: false },
			{ name: 'Takeoff GSF', baseLineHours: 6, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff NSF', baseLineHours: 3, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff balcony SF', baseLineHours: 2, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff floor framing SF', baseLineHours: 3, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff roof framing SF', baseLineHours: 2, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff residential & public unit counts', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff balcony count', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff Windows/Doors', baseLineHours: 2, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff Tyvek/WRB', baseLineHours: 2, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff Firewalls', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff stairs', baseLineHours: 2, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Takeoff trellises/exterior features', baseLineHours: 2, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Partial lumber takeoff (stick framed walls)', baseLineHours: 24, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Partial lumber takeoff (wall panels)', baseLineHours: 20, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Full lumber takeoff (stick framed walls)', baseLineHours: 40, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Full lumber takeoff (wall panels)', baseLineHours: 32, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Partial hardware takeoff', baseLineHours: 4, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Full hardware takeoff', baseLineHours: 8, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Partial hold-down takeoff', baseLineHours: 4, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Full hold-down takeoff', baseLineHours: 8, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Full siding/trim takeoff', baseLineHours: 16, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Create material list & send to vendors', baseLineHours: 2, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Fill out cs sheet with SF, unit counts & pricing', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Create quote letter with job specific info', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Fill out project info sheet', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Upload plans to fieldwire', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Complete customer scope sheet', baseLineHours: 1, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'takeoff' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'takeoff' },
			// { name: 'Pricing Updates / ASI / Plan Updates', baseLineHours: 0, hours: 0, included: false },
			{ name: 'Full plan review', baseLineHours: 16, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Update takeoff for changes', baseLineHours: 16, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Fill out estimating change log', baseLineHours: 2, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Fill out cs sheet with updated info', baseLineHours: 1, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Revise quote letter', baseLineHours: 1, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Send plans to vendors', baseLineHours: 1, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Request updated vendor pricing', baseLineHours: 0.5, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Upload plans to fieldwire', baseLineHours: 1, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'pricing_updates' },
			{ name: 'Blank', baseLineHours: 0, hours: 0, included: false, section: 'pricing_updates' },
		],
	},
	getters: {
		sqftEstimate: (state) => state.scope
			.filter(({ section }) => section === 'sqft_estimate')
			.map((item, index) => {
				const rowNumber = index + 1;
				if (index < 17) { // 49
					item.type = 'Scales With Floor';
					/* eslint-disable max-len */
					item.value = `=IF((D${rowNumber}/${state.baseLineSquareFootage}*${state.squareFootage})<1, 1, D${rowNumber}/${state.baseLineSquareFootage}*${state.squareFootage})`;
				} else {
					item.type = 'Constant';
					item.value = `=D${rowNumber}`;
				}

				item.hourlyRate = state.hourlyRate;
				item.hours = `=IF(B${rowNumber},E${rowNumber},0)`;
				item.cost = `=IF(B${rowNumber}, E${rowNumber}*${state.hourlyRate},0)`;

				return item;
			}),
		takeoff: (state) => state.scope
			.filter(({ section }) => section === 'takeoff')
			.map((item, index) => {
				const rowNumber = index + 1;
				if (index < 24) {
					item.type = 'Scales With Floor';
					/* eslint-disable max-len */
					item.value = `=IF((D${rowNumber}/${state.baseLineSquareFootage}*${state.squareFootage})<1, 1, D${rowNumber}/${state.baseLineSquareFootage}*${state.squareFootage})`;
				} else {
					item.type = 'Constant';
					item.value = `=D${rowNumber}`;
				}

				item.hourlyRate = state.hourlyRate;
				item.hours = `=IF(B${rowNumber},E${rowNumber},0)`;
				item.cost = `=IF(B${rowNumber}, E${rowNumber}*${state.hourlyRate},0)`;

				return item;
			}),
		pricingUpdates: (state) => state.scope
			.filter(({ section }) => section === 'pricing_updates')
			.map((item, index) => {
				const rowNumber = index + 1;
				item.type = 'Scales';
				item.value = `=${state.squareFootage}*D${rowNumber}/${state.baseLineSquareFootage}`;

				item.hourlyRate = state.hourlyRate;
				item.hours = `=IF(B${rowNumber},E${rowNumber},0)`;
				item.cost = `=IF(B${rowNumber}, E${rowNumber}*${state.hourlyRate},0)`;

				return item;
			}),
	},
	mutations: {
		updateScope(state, payload) {
			state.scope = payload;
		},
		setSquareFootage(state, payload) {
			state.squareFootage = payload;
		},
	},
	actions: {
		setSquareFootageFromBid({ commit, rootState }) {
			commit('setSquareFootage', rootState.activeBid.bid.bid_revision.square_footage);
		},
		async save({ rootState }, payload) {
			if (!payload) {
				return;
			}
			const { bidScopeRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err] = await to(bidScopeRepo.upsertBidScope(rootState.activeBid.bid.id, rootState.activeBid.bid.bid_revision.id, payload));

			if (err) {
				logger.error(err.message);
				throw err;
			}
		},
		async load({ commit, rootState }, updateTables) {
			const { bidScopeRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, res] = await to(bidScopeRepo.getBidScope(rootState.activeBid.bid.id, rootState.activeBid.bid.bid_revision.id));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			if (res) {
				commit('updateScope', res.data);
				updateTables();
			}
		},
	},
};
