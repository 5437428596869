import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		whiteboard: [],
		activeWhiteboard: null,
		dirtyActiveWhiteboard: false,
		validActiveWhiteboard: false,
	},
	mutations: {
		setWhiteboard(state, sheets) {
			state.whiteboard = sheets;
		},
		setActiveWhiteboard(state, sheet) {
			const { _ } = this.$cradle;
			Vue.set(state, 'activeWhiteboard', _.cloneDeep(sheet));
			Vue.set(state, 'dirtyActiveWhiteboard', false);
		},
		setActiveWhiteboardData(state, data) {
			Vue.set(state.activeWhiteboard.payload, 'data', data);
		},
		setDirtyActiveWhiteboard(state, change) {
			state.dirtyActiveWhiteboard = change;
		},
		setValidActiveWhiteboard(state, change) {
			state.validActiveWhiteboard = change;
		},
		setEffectiveDateActiveWhiteboard(state, change) {
			state.activeWhiteboard.payload.effective_date = change;
		},
	},
	actions: {
		async getAllWhiteboardSheets({ commit }) {
			const { to, whiteboardSheetRepo } = this.$cradle;
			const [err, data] = await to(whiteboardSheetRepo.getAllSheetsByType());
			if (err) {
				throw err;
			}
			commit('setWhiteboard', data);
		},
		async deleteWhiteboard({ commit, dispatch }, id) {
			const { to, whiteboardSheetRepo } = this.$cradle;
			const [err] = await to(whiteboardSheetRepo.deleteWhiteboard(id));
			if (err) {
				throw err;
			}

			const [errB] = await to(dispatch('clearActiveWhiteboard'));
			if (errB) {
				throw errB;
			}

			commit('setWhiteboard', []);

			const [errC] = await to(dispatch('getAllWhiteboardSheets'));
			if (errC) {
				throw errC;
			}
		},
		async changeActiveWhiteboardSheet({ commit, state }, id) {
			const { _ } = this.$cradle;
			const sheet = _.find(state.whiteboard, { id: Number.parseInt(id, 10) });
			commit('setActiveWhiteboard', sheet);
		},
		async dirtyActiveWhiteboardSheet({ commit }, change) {
			commit('setDirtyActiveWhiteboard', change);
		},
		async updateActiveWhiteboardSheetData({ commit }, data) {
			const { _ } = this.$cradle;
			commit('setActiveWhiteboardData', _.cloneDeep(data));
		},
		async newWhiteboard({ commit }) {
			const { to, whiteboardSheetRepo } = this.$cradle;
			const [err, data] = await to(whiteboardSheetRepo.getSheet());
			if (err) {
				throw err;
			}
			commit('setActiveWhiteboard', data);
		},
		async saveWhiteboard({ commit }, sheet) {
			const { to, whiteboardSheetRepo } = this.$cradle;
			const [err, record] = await to(whiteboardSheetRepo.upsertPayload(sheet.payload, sheet.id));
			if (err) {
				throw err;
			}
			commit('setActiveWhiteboard', record);
		},
		async clearActiveWhiteboard({ commit }) {
			commit('setActiveWhiteboard', null);
		},
		async changeEffectiveDate({ commit }, date) {
			commit('setEffectiveDateActiveWhiteboard', date);
		},
		async changeValidActiveWhiteboard({ commit }, valid) {
			commit('setValidActiveWhiteboard', valid);
		},
	},
};
