export default {
	namespaced: true,
	state: {
		comments: [],
		display: false,
		saving: false,
		success: true,
		failure: false,
		currentCommentText: '',
		resource: {
			id: '',
			sub_id: '',
			model: 'BID',
		},
	},
	mutations: {
		addComment(state, comment) {
			state.comments.push(comment);
		},
		setComments(state, comments) {
			state.comments = comments;
		},
		toggleDisplay(state) {
			state.display = !state.display;
		},
		setSaving(state, status) {
			state.saving = status;
		},
		setSuccess(state, status) {
			state.success = status;
		},
		setFailure(state, status) {
			state.failure = status;
		},
		setCurrentCommentText(state, comment) {
			state.currentCommentText = comment;
		},
		setResourceId(state, id) {
			state.resource.id = id;
		},
		setResourceSubId(state, subId) {
			state.resource.sub_id = subId;
		},
		setResourceModel(state, model) {
			state.resource.model = model;
		},
	},
	actions: {
		async createComment({ commit, dispatch }, [newComment, resourceType]) {
			commit('setSaving', true);
			commit('setSuccess', false);

			const { commentRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err] = await to(commentRepo.createComment(newComment, resourceType));

			commit('setSaving', false);

			if (err) {
				commit('setFailure', true);
				commit('toggleDisplay');
				logger.error(err.message);
				throw err;
			} else {
				if (resourceType === 'BID') {
					dispatch('getBidCommentsList', newComment.resource_id);
				} else if (resourceType === 'PROJECT') {
					dispatch('getProjectCommentsList', newComment.resource_id);
				}
				commit('setSuccess', true);
				commit('setCurrentCommentText', '');
			}
		},
		async getBidCommentsList({ commit }, bidId) {
			const { commentRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, comments] = await to(commentRepo.getBidCommentsList(bidId));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setComments', comments);
		},
		async getProjectCommentsList({ commit }, projectId) {
			const { commentRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, comments] = await to(commentRepo.getProjectCommentsList(projectId));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setComments', comments);
		},
		async toggleDisplay({ commit }) {
			commit('toggleDisplay');
		},
	},
};
