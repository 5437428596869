"use strict";

exports.__esModule = true;
exports.KEY = void 0;
exports.default = separatorItem;
var KEY = '---------';
/**
 * @returns {object}
 */

exports.KEY = KEY;

function separatorItem() {
  return {
    name: KEY
  };
}