export default class {
	constructor({ supabaseClientFactory, cookies, to, supaTo }) {
		this.supabase = supabaseClientFactory;
		this.cookies = cookies;
		this.to = to;
		this.supaTo = supaTo;
	}

	setAuthCookies(accessToken, refreshToken) {
		if (!accessToken || !refreshToken) {
			throw new Error('accessToken and refreshToken are required.');
		}
		document.cookie = `access_token=${accessToken}; SameSite=Strict; Secure;`;
		document.cookie = `refresh_token=${refreshToken}; SameSite=Strict; Secure;`;
	}

	clearAuthCookies() {
		this.cookies.remove('access_token');
		this.cookies.remove('refresh_token');
	}

	parseHashLocationForAuthTokens() {
		const hash = window.location.hash.replace('#/', '');
		const vars = hash.split('&');
		const map = vars.map((item) => item.split('='));
		return map.reduce((acc, [key, val]) => {
			acc[key] = val;
			return acc;
		}, {});
	}

	async signInWithAzure() {
		const { error } = await this.supabase.auth.signIn({
			provider: 'azure',
		});

		if (error) {
			throw error;
		}
	}

	async getUser(accessToken) {
		if (!accessToken) {
			throw new Error('accessToken is required');
		}
		const [error, response] = await this.supaTo(this.supabase.auth.api.getUser(accessToken));
		if (error) {
			this.clearAuthCookies();
			const errMsg = error && error.message ? error.message : 'Unable to log user in now.';
			throw new Error(errMsg);
		}

		this.supabase.auth.setAuth(accessToken);
		return response.user;
	}

	async refreshAccessToken(refreshToken) {
		if (!refreshToken) {
			throw new Error('refreshToken is required');
		}
		const [error, response] = await this.supaTo(this.supabase.auth.api.refreshAccessToken(refreshToken));
		if (error) {
			const errMsg = error && error.message ? error.message : 'Unable to log user in now.';
			this.clearAuthCookies();
			throw new Error(errMsg);
		}
		if (response && response.data) {
			this.setAuthCookies(response.data.access_token, response.data.refresh_token);
			return response.data;
		}
		return undefined;
	}
}
