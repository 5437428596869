<template>
<nav
	aria-label="breadcrumb"
	class="navbar-nav align-items-center flex-row"
>
	<ul
		v-if="breadcrumbs"
		class="breadcrumb breadcrumb-no-gutter m-0"
	>
		<li
			v-for="(crumb, index) in breadcrumbs"
			:key="index"
			class="breadcrumb-item"
			:class="{ active: breadcrumbs.length - 1 === index }"
		>
			<template v-if="crumb.name === 'Dashboard'">
				<router-link
					class="breadcrumb-link"
					:to="crumb.destination"
				>
					<i class="tio-home" />
				</router-link>
			</template>
			<template v-else-if="breadcrumbs.length - 1 === index">
				{{ crumb.name }}
			</template>
			<template v-else>
				<router-link
					class="breadcrumb-link"
					:to="crumb.destination"
				>
					{{ crumb.name }}
				</router-link>
			</template>
		</li>
	</ul>
</nav>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';

export default {
	computed: {
		...mapState('activeBid', ['bid']),
		...mapGetters('activeBid', [
			'getBuildingById',
			'briefBidName',
		]),
		...mapState('activeProject', ['project']),
		buildingName() {
			if (this.buildingId) {
				return this.getBuildingById(this.buildingId)?.name;
			}

			return '';
		},
		buildingId() {
			return this.$route.params.buildingId;
		},
		breadcrumbs() {
			let { breadcrumbs } = this.$route.meta;

			if (typeof breadcrumbs === 'function') {
				breadcrumbs = breadcrumbs.call(this);
			}

			return breadcrumbs;
		},
	},
	methods: {
		...mapMutations('breadcrumbs', ['setBreadcrumbs', 'clearAllBreadcrumbs']),
	},
};
</script>
