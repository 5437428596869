export default {
	namespaced: true,
	state: {
		pricingPoints: [],
		newPriceSheet: {
			sheet: {},
			effectiveDate: null,
		},
		pricing: [],
	},
	getters: {
		getCsv(state) {
			const data = state.pricingPoints.map((item) => `${item.name},${item.category},${item.price}\r\n`);
			const csv = `"Name","Category","Price Per MSF"\r\n${data.join('')}`;
			return `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
		},
	},
	mutations: {
		newSheetSubmission(state, sheet) {
			state.sheet = sheet;
		},
		setPricingPoints(state, pricingPoints) {
			state.pricingPoints = pricingPoints.map((item) => ({
				id: item.id,
				name: item.name,
				category: item.subcategory.replace(/_/g, ' '),
				price: 0,
			}));
		},
		setPricing(state, pricing) {
			state.pricing = pricing;
		},
	},
	actions: {
		async validatePricingSheet({ commit }, { sheet }) {
			for (const { name, price } of sheet) {
				if (Number.isNaN(Number(price))) {
					throw new Error(`${name}'s price is not a number'`);
				}
			}

			commit('newSheetSubmission', sheet);
		},
		async createPricing({ state }) {
			const { to, randomLengthPricingRepo, logDnaFactory: logger } = this.$cradle;
			const effectiveDate = new Date(state.newPriceSheet.effectiveDate);
			const data = state.sheet.map((item) => {
				item.price_point_id = state.pricingPoints.find((price) => price.name === item.name).id;
				delete item.name;
				item.effective_date = new Date(effectiveDate);
				return item;
			});

			const [err] = await to(randomLengthPricingRepo.createPricing(data));

			if (err) {
				logger.error(err.message);
				throw err;
			}
		},
		async getRandomLengthsPricingPoints({ commit }) {
			const { to, pricingPointRepo, logDnaFactory: logger } = this.$cradle;
			const [err, pricingPoints] = await to(pricingPointRepo.getPricingPointsByCategory('RANDOM_LENGTHS'));
			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('setPricingPoints', pricingPoints);
			}
		},
		async getRandomLengthsPricing({ commit }) {
			const { to, randomLengthPricingRepo, logDnaFactory: logger } = this.$cradle;
			const [err, pricing] = await to(randomLengthPricingRepo.getAllRandomLengthsPricing());
			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('setPricing', pricing);
			}
		},
	},
};
