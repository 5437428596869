import tableService from '../../services/table-service.mjs';

export default {
	namespaced: true,
	state: {
		...tableService.state,
		bids: [],
		CSSheets: [],
		availableFilters: [],
		columns: [],
		loaded: false,
		query: {
			totalRows: 0,
		},
		defaultColumns: [
			{ name: 'BID No.', key: 'bid_number' },
			{ name: 'Bid Name', key: 'bid_name' },
			{ name: 'CS Sheet', key: 'valid_cs_sheet' },
			{ name: 'Sub Vs MH', key: 'valid_sub_vs_mh' },
			{ name: 'Takeoff', key: 'valid_takeoff' },
		],
		table: 'BIDCSTAKEOFFVIEW',
	},
	mutations: {
		...tableService.mutations,
		setBids(state, bids) {
			state.bids = bids;
		},
		setCSSheets(state, CSSheets) {
			state.CSSheets = CSSheets;
		},
		setIds(state, ids) {
			const index = state.bids.findIndex((bid) => bid.bid_id === ids.bidId);

			if (index > -1) {
				state.bids[index].cs_sheet_id = ids.sheetId;
				state.bids[index].sub_vs_mh_id = ids.subVsMHID;
			}
		},
	},
	actions: {
		...tableService.actions,
		async getBids({ commit }) {
			const { to, bidCSTakeOffRepo, logDnaFactory: logger } = this.$cradle;

			const [err, data] = await to(bidCSTakeOffRepo.getBidsByParams());
			if (err) {
				logger.error(err.message);
				commit('setBids', []);
			} else {
				commit('setBids', data);
			}

			const [countErr, bids] = await to(bidCSTakeOffRepo.getBids());
			if (countErr) {
				logger.error(err.message);
			} else {
				commit('setTotalRows', bids.length);
			}
		},
	},
};
