export default {
	namespaced: true,
	state: {
		job: [
			[
				'JOB NAME:',
				'',
				'',
				'STATE:',
				'',
				'',
				'PROJECT SQFT:',
				'',
				'DATE:',
				'',
				'<div class="cs-framing">CS Framing</div>',
			],
			[
				'JOBSITE ADDRESS:',
				'',
				'',
				'SF/Week:',
				'',
				'',
				'',
				'',
				'WALL TYPE:',
				'',
				'',
			],
			[
				'CUSTOMER:',
				'',
				'',
				'Colt GL + Umbr:',
				'',
				'',
				'FRAMING DURATION:',
				'=H1/E2',
				'MN HR RATE:',
				'',
				'',
			],
			[
				'CONTACT:',
				'',
				'',
				'Sub Umbrella:',
				'',
				'',
				'TOTAL JOB DURATION:',
				'=H3+4',
				'SUB MH RATE:',
				'',
				'',
			],
			[
				'CONTACT EMAIL:',
				'',
				'',
				'Porch SF:',
				'',
				'',
				'TOT MAN HOURS:',
				'',
				'TAX RATE:',
				'',
				'',
			],
			[
				'CONTACT PHONE:',
				'',
				'',
				'Gross w/o porches:',
				0,
				'',
				'TOT RES UNITS',
				'',
				'UNIT PUNCH HRS',
				0,
				'',
			],
			[
				'Estimator:',
				'',
				'',
				'Gross SF From Plans',
				0,
				'',
				'PUBLIC UNITS',
				'',
				'PUBLIC PUNCH HRS',
				0,
				'',
			],
			[
				'NOTE:',
				'',
				'',
				'',
				'',
				'',
				'TOTAL UNITS',
				'',
				'',
				'',
				'',
			],
			[
				'',
				'',
				'',
				'',
				'',
				'',
				'hours/sf',
				'=IF(H1=0,0,H5/H1)',
				'',
				'',
				'',
			],
		],
		columns: [
			{ type: 'text', readOnly: true },
			{ type: 'text' },
			{ type: 'text', readOnly: true },
			{ type: 'text' },
			{ type: 'text' },
			{ type: 'text', readOnly: true },
			{ type: 'text', readOnly: true },
			{ type: 'text', readOnly: false },
			{ type: 'text', readOnly: true },
			{ type: 'text' },
			{ type: 'text', renderer: 'html', readOnly: true },
		],
		comments: [],
	},
	getters: {
		job(state, getters, rootState) {
			const { bid } = rootState.activeBid;
			const {
				manHourRate,
				sfPerWeek,
				totalManHours,
				taxRate,
				totalResUnits,
				totalPubUnits,
				coltGLUmbr,
				subUmbrella,
				subManHourRate,
			} = rootState.bidCs;

			const job = [...state.job];

			job[0][1] = bid.name;
			job[0][4] = bid.bid_revision.state;
			job[0][7] = bid.bid_revision.square_footage;
			job[0][9] = bid.bid_revision.date;

			job[1][1] = bid.bid_revision.address || '';
			job[1][4] = sfPerWeek;
			job[1][9] = bid.bid_revision.is_panel ? 'Panel' : 'Stick';

			job[2][4] = coltGLUmbr;
			job[2][9] = manHourRate;

			job[3][4] = subUmbrella;
			job[3][9] = subManHourRate;

			job[4][4] = bid.bid_revision.porch_square_footage;
			job[4][7] = totalManHours;
			job[4][9] = taxRate;

			job[5][7] = totalResUnits;

			job[6][7] = totalPubUnits;

			job[7][7] = bid.bid_revision.units || 0;

			return job;
		},
		totalItems(state, getters) {
			return getters.job.length;
		},
	},
	mutations: {
		updateJob(state, payload) {
			state.job = payload;
		},
		setComments(state, payload) {
			state.comments = payload;
		},
		handleComment(state, data) {
			const existingCommentIndex = state.comments.findIndex(({ row, col }) => row === data.row && col === data.col);

			if (existingCommentIndex > -1) {
				if (data.comment === '') {
					state.comments.splice(existingCommentIndex, 1);
					return;
				}
				const existingComment = state.comments[existingCommentIndex];
				existingComment.comment = data.comment;
			} else {
				if (data.comment === '') return;
				state.comments.push(data);
			}
		},
	},
	actions: {
	},
};
