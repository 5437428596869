export default class FREDRepo {
	constructor({ _, supaTo, supabaseClientFactory }) {
		this._ = _;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this.cache = {};
	}

	async getObservationsBySeriesAndDate(series, date) {
		const cacheVal = this._.get(this.cache, `${series}.${date}`);
		if (cacheVal) {
			return cacheVal;
		}

		const [error, data] = await this.supaTo(
			this.supabase.rpc('get_fred_series', {
				series_id: series,
				observation_date: date,
			}),
		);
		if (error) {
			throw error;
		}
		this._.set(this.cache, `${series}.${date}`, data);
		return data;
	}
}
