export default {

	patterns: {
		manufacturer_models: [
			/R-[0-9]{1,2}/,
			/(Top Notch|Flameblock) ([0-9]{3}|\([0-9] sides?\))/,
			/Densglass( Gold)?/,
			/(PLUS|WOLM)/,
			/(GLULAM|Select RS|SEL|Select|Type X|DryGuard|Sel|Dense Select|DUROCK|Strongbolt 2)/,
			/Marine Grade/,
		],

		material_subtypes: [
			/[0-9]\.[0-9]{1,2}E( RIM)?/,
			/[0-9]{4}f-[0-9]\.[0-9]E/,
			/#\s?[0-9]/,
			/#( )?[0-9]/,
			/(CDX|AC|BC)/,
		],
	},

	parserExclusions: [
		'Interior Buildouts',
		'Residential Unit Count',
		'Public Unit Count',
		'Misc Column Hardware',
		'Stair Framing Count',
		'Hardware Allowance',
		'Porch Sleeper Materials',
		'Balcony Count',
		'Misc Connection Hardware',
		/* eslint-disable max-len */
		'S-805/559 - (2) 1/4" steel plates welded to 1/4" steel bent "U" plate with (4) 3/4" thru bolts at 12x12 column to column connection, brace to beam connection and brace to column connection',
		'Welded Base (1/2"x7"x7") and Knife (3/8"x6"x9") Plate',
		'S-901/611 - 1/4" bent "U" plate with (3) 3/4" thru bolts and 1/4" bent "U" plate with (2) 3/4" thru bolts',
		'!!!May be included in truss package - S-702/430 - DSC2R/L at truss collector',
		'2 x 4 x 10 ft',
		'S-705/488 - 3.5"x3.5"x12GAx4" wide L angle with (4) Simpson 3/16"x1" Titen screw to CMU wall and (4)#12 to truss',
		'S-602/337 - 1/4" steel plate with (2) 3/4" thru bolts',
		'Temp Door Materials',
		'S-602/337 - 2"x2"x3/16" angle with 3/4" bolts @ 48" oc',
		'Miscellaneous Framing',
		'S-805/559 - (2) 1/4" verticle steel welded to 3/8" steel plate with (4) 3/4" thru bolts at 12x12 column to column attachment',
		'Tie Into Existing',
		'S-805/564 - H6 clip, CS16x24" strap and 3/8" steel plate, 1/4" steel plate with (2) 3/4" thru bolts and 1/4"x5" steel plate with (6) 1/4"x3-1/2" SDS screws',
		'7/16"x4\'x8\'',
		'5/8" x 8" - J-Bolt Galv w/ 2" x 2" - 1/4" Washer',
		'BP 1/2- 2 1/2" SQ x1/4" Plate Washer Simpson Strong Tie',
		'L8 x 4 x 7/16 x 6 Steel Bracket w/ Bolts',
		'Added Porch Material',
		'3"- 2\' x 10\' AAC - U210', // This looks like its a firewall.
	],
	enums: {
		bid_types: {
			PURCHASE_ESTIMATE: {
				name: 'Purchase Estimate',
				index: 0,
			},
			PARTIAL_TAKEOFF: {
				name: 'Partial Takeoff',
				index: 1,
			},
			SF_ESTIMATE: {
				name: 'SF Estimate',
				index: 2,
			},
			PRICE_UPDATE: {
				name: 'Price Update',
				index: 3,
			},
			PLAN_REVIEW: {
				name: 'Plan Review',
				index: 4,
			},
			RFQ: {
				name: 'RFQ',
				index: 5,
			},
			PLAN_UPDATE: {
				name: 'Plan Update',
				index: 6,
			},
			DECLINED: {
				name: 'Declined',
				index: 7,
			},
			TAKEOFF: {
				name: 'Takeoff',
				index: 8,
			},
		},
		states: [
			{ name: 'ALABAMA', abbreviation: 'AL' },
			{ name: 'ALASKA', abbreviation: 'AK' },
			{ name: 'ALBERTA', abbreviation: 'AB' },
			{ name: 'AMERICAN SAMOA', abbreviation: 'AS' },
			{ name: 'ARIZONA', abbreviation: 'AZ' },
			{ name: 'ARKANSAS', abbreviation: 'AR' },
			{ name: 'CALIFORNIA', abbreviation: 'CA' },
			{ name: 'COLORADO', abbreviation: 'CO' },
			{ name: 'CONNECTICUT', abbreviation: 'CT' },
			{ name: 'DELAWARE', abbreviation: 'DE' },
			{ name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
			{ name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
			{ name: 'FLORIDA', abbreviation: 'FL' },
			{ name: 'GEORGIA', abbreviation: 'GA' },
			{ name: 'GUAM', abbreviation: 'GU' },
			{ name: 'HAWAII', abbreviation: 'HI' },
			{ name: 'IDAHO', abbreviation: 'ID' },
			{ name: 'ILLINOIS', abbreviation: 'IL' },
			{ name: 'INDIANA', abbreviation: 'IN' },
			{ name: 'IOWA', abbreviation: 'IA' },
			{ name: 'KANSAS', abbreviation: 'KS' },
			{ name: 'KENTUCKY', abbreviation: 'KY' },
			{ name: 'LOUISIANA', abbreviation: 'LA' },
			{ name: 'MAINE', abbreviation: 'ME' },
			{ name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
			{ name: 'MARYLAND', abbreviation: 'MD' },
			{ name: 'MASSACHUSETTS', abbreviation: 'MA' },
			{ name: 'MICHIGAN', abbreviation: 'MI' },
			{ name: 'MINNESOTA', abbreviation: 'MN' },
			{ name: 'MISSISSIPPI', abbreviation: 'MS' },
			{ name: 'MISSOURI', abbreviation: 'MO' },
			{ name: 'MONTANA', abbreviation: 'MT' },
			{ name: 'NEBRASKA', abbreviation: 'NE' },
			{ name: 'NEVADA', abbreviation: 'NV' },
			{ name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
			{ name: 'NEW JERSEY', abbreviation: 'NJ' },
			{ name: 'NEW MEXICO', abbreviation: 'NM' },
			{ name: 'NEW YORK', abbreviation: 'NY' },
			{ name: 'NORTH CAROLINA', abbreviation: 'NC' },
			{ name: 'NORTH DAKOTA', abbreviation: 'ND' },
			{ name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
			{ name: 'OHIO', abbreviation: 'OH' },
			{ name: 'OKLAHOMA', abbreviation: 'OK' },
			{ name: 'OREGON', abbreviation: 'OR' },
			{ name: 'PALAU', abbreviation: 'PW' },
			{ name: 'PENNSYLVANIA', abbreviation: 'PA' },
			{ name: 'PUERTO RICO', abbreviation: 'PR' },
			{ name: 'RHODE ISLAND', abbreviation: 'RI' },
			{ name: 'SOUTH CAROLINA', abbreviation: 'SC' },
			{ name: 'SOUTH DAKOTA', abbreviation: 'SD' },
			{ name: 'TENNESSEE', abbreviation: 'TN' },
			{ name: 'TEXAS', abbreviation: 'TX' },
			{ name: 'UTAH', abbreviation: 'UT' },
			{ name: 'VERMONT', abbreviation: 'VT' },
			{ name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
			{ name: 'VIRGINIA', abbreviation: 'VA' },
			{ name: 'WASHINGTON', abbreviation: 'WA' },
			{ name: 'WEST VIRGINIA', abbreviation: 'WV' },
			{ name: 'WISCONSIN', abbreviation: 'WI' },
			{ name: 'WYOMING', abbreviation: 'WY' },
			{ name: 'BRITISH COLUMBIA', abbreviation: 'BC' },
			{ name: 'ONTARIO', abbreviation: 'ON' },
		],
		bid_grades: {
			GRADE_A: {
				name: 'A',
				index: 0,
			},
			GRADE_B: {
				name: 'B',
				index: 0,
			},
			GRADE_C: {
				name: 'C',
				index: 0,
			},
			GRADE_D: {
				name: 'D',
				index: 0,
			},
		},
		plan_types: {
			SD: {
				name: 'SD Plans',
				index: 0,
			},
			GMP: {
				name: 'GMP Plans',
				index: 1,
			},
			CD: {
				name: 'CD Plans',
				index: 2,
			},
			DD: {
				name: 'DD Plans',
				index: 3,
			},
			DESIGN_REVIEW: {
				name: 'Design Review Plans',
				index: 4,
			},
			PERMIT: {
				name: 'Permit Plans',
				index: 5,
			},
			CITY_SUBMITTAL: {
				name: 'City Submittal Plans',
				index: 6,
			},
			BID_SET: {
				name: 'Bid Set Plans',
				index: 7,
			},
			PRICING_PCT90: {
				name: '90% Pricing Plans',
				index: 8,
			},
			CONCEPT: {
				name: 'Concept Plans',
				index: 9,
			},
			PRELIMINARY: {
				name: 'Preliminary Plans',
				index: 10,
			},
			CITY_COMMENT: {
				name: 'City Comment Plans',
				index: 11,
			},
			IFC: {
				name: 'IFC Plans',
				index: 12,
			},
			CONSTRUCTION: {
				name: 'Construction Plans',
				index: 13,
			},
			PLAN_CHECK: {
				name: 'Plan Check Plans',
				index: 14,
			},
		},
		pricing_point_categories: {
			RANDOM_LENGTHS: {
				name: 'Random lengths',
				index: 0,
			},
			MATERIALS: {
				name: 'Materials',
				index: 1,
			},
		},
		pricing_point_subcategories: {
			LUMBER_MARKET_INDICATOR: {
				name: 'Lumber Market Indicator',
				index: 0,
			},
			PANEL_MARKET_INDICATOR: {
				name: 'Panel Market Indicator',
				index: 1,
			},
			LUMBER_GROUP_COMPOSITES: {
				name: 'Lumber Group Composites',
				index: 2,
			},
			PANEL_GROUP_COMPOSITES: {
				name: 'Panel Group Composites',
				index: 3,
			},
		},
		pricing_point_pricing_units: {
			DOLLAR_PER_MSF: {
				name: 'Dollar per MSF',
				index: 0,
			},
		},
		supplier_categories: {
			HARDWARE: {
				name: 'Hardware',
				index: 0,
			},
			ROOF: {
				name: 'Roof',
				index: 1,
			},
			WALL: {
				name: 'Wall',
				index: 2,
			},
			EWP: {
				name: 'EWP',
				index: 3,
			},
			MATERIAL: {
				name: 'Material',
				index: 4,
			},
			TRUSS: {
				name: 'Truss',
				index: 5,
			},
			COMPONENT: {
				name: 'Component',
				index: 6,
			},
			LUMBER: {
				name: 'Lumber',
				index: 7,
			},
		},
		bid_activity_types: {
			REBID: {
				name: 'Rebid / Revision',
				index: 0,
			},
			NEW: {
				name: 'Created New Bid',
				index: 1,
			},
			CLONE: {
				name: 'Cloned Existing Bid',
				index: 2,
			},
			ARCHIVED: {
				name: 'Archived Bid',
				index: 3,
			},
			WON: {
				name: 'Set Bid To Won',
				index: 4,
			},
		},
		bid_archival_general_reason_types: {
			DECLINED: {
				name: 'Bid Declined (Will Not Quote)',
				index: 0,
			},
			DECLINED_CUSTOMER: {
				name: 'Bid Declined (Customer)',
				index: 1,
			},
			BID_WON: {
				name: 'Bid Won',
				index: 2,
			},
			BID_LOST: {
				name: 'Bid Lost',
				index: 3,
			},
			BID_FINISHED: {
				name: 'Bid Finished',
				index: 4,
			},
			STATUS_CHANGE: {
				name: 'Status was changed',
				index: 5,
			},
		},
		bid_status_types: {
			REQUEST_FOR_QUOTE: {
				name: 'Request For Quote',
				index: 0,
			},
			REGIONAL_MANAGER_REVIEW: {
				name: 'Regional Manager Review',
				index: 1,
			},
			SENT_TO_CUSTOMER: {
				name: 'Sent to Customer',
				index: 2,
			},
			WON: {
				name: 'Bid Won',
				index: 3,
			},
		},
		bid_award_status_types: {
			OPEN: {
				name: 'Bid Open',
				index: 0,
			},
			DECLINED: {
				name: 'Bid Declined (Will Not Quote)',
				index: 1,
			},
			DECLINED_CUSTOMER: {
				name: 'Bid Declined (Customer)',
				index: 2,
			},
			BID_WON: {
				name: 'Bid Won',
				index: 3,
			},
			BID_LOST: {
				name: 'Bid Lost',
				index: 4,
			},
			BID_FINISHED: {
				name: 'Bid Finished',
				index: 5,
			},
		},
		scope_types: {
			TURNKEY: {
				name: 'Turnkey',
				index: 0,
			},
			LABOR_ONLY: {
				name: 'Labor Only',
				index: 1,
			},
			SIDING_ONLY: {
				name: 'Siding Only',
				index: 2,
			},
			ROOF_ONLY: {
				name: 'Roof Only',
				index: 3,
			},
			FRAMING_SIDING: {
				name: 'Framing & Siding',
				index: 4,
			},
			SIDING_TRIM_ONLY: {
				name: 'Siding & Trim Only',
				index: 5,
			},
		},
		project_types: {
			MULTI_FAMILY: {
				name: 'Multiple Family',
				index: 0,
			},
			APARTMENTS: {
				name: 'Apartments',
				index: 1,
			},
			TOWNHOMES: {
				name: 'Townhomes',
				index: 2,
			},
			OFFICE: {
				name: 'Office',
				index: 3,
			},
			SENIOR_LIVING: {
				name: 'Senior Living',
				index: 4,
			},
			STUDENT_HOUSING: {
				name: 'Student Housing',
				index: 5,
			},
			HOTEL: {
				name: 'Hotel',
				index: 6,
			},
			ASSISTED_LIVING: {
				name: 'Assisted Living',
				index: 7,
			},
			OTHER: {
				name: 'Other',
				index: 8,
			},
		},
		project_status_types: {
			PRECON: {
				name: 'Pre-Con',
				index: 0,
			},
			COMPLETED: {
				name: 'Completed',
				index: 1,
			},
			IN_PROGRESS: {
				name: 'In Progress',
				index: 2,
			},
		},
		wall_height_types: {
			'7-08-10': {
				name: '7-08-10',
				index: 0,
			},
			'8-00-00': {
				name: '8-00-00',
				index: 1,
			},
			'8-08-10': {
				name: '8-08-10',
				index: 2,
			},
			'10-00-00': {
				name: '10-00-00',
				index: 3,
			},
			'12-00-00': {
				name: '12-00-00',
				index: 4,
			},
			'14-00-00': {
				name: '14-00-00',
				index: 5,
			},
			'16-00-00': {
				name: '16-00-00',
				index: 6,
			},
		},
		level_types: {
			BASEMENT_1: {
				name: 'Basement 1',
				index: 0,
			},
			BASEMENT_2: {
				name: 'Basement 2',
				index: 1,
			},
			BASEMENT_3: {
				name: 'Basement 3',
				index: 2,
			},
			LEVEL_1: {
				name: 'Level 1',
				index: 3,
			},
			LEVEL_2: {
				name: 'Level 2',
				index: 4,
			},
			LEVEL_3: {
				name: 'Level 3',
				index: 5,
			},
			LEVEL_4: {
				name: 'Level 4',
				index: 6,
			},
			LEVEL_5: {
				name: 'Level 5',
				index: 7,
			},
			LEVEL_6: {
				name: 'Level 6',
				index: 8,
			},
			LEVEL_7: {
				name: 'Level 7',
				index: 9,
			},
			LEVEL_8: {
				name: 'Level 8',
				index: 10,
			},
			ROOF: {
				name: 'Roof',
				index: 11,
			},
			LEVEL_0: {
				name: 'Level 0',
				index: 12,
			},
		},
		window_and_doors_types: {
			SINGLE_WINDOW: {
				name: 'Single Window',
				index: 0,
			},
			DOUBLE_WINDOW: {
				name: 'Double Window',
				index: 1,
			},
			TRIPLE_WINDOW: {
				name: 'Triple Window',
				index: 2,
			},
			LARGE_WINDOW: {
				name: 'Large Window',
				index: 3,
			},
			SINGLE_DOOR: {
				name: 'Single Door',
				index: 4,
			},
			DOUBLE_DOOR: {
				name: 'Double Door',
				index: 5,
			},
			SLIDER: {
				name: 'Slider',
				index: 6,
			},
			TRIPLE_SLIDER: {
				name: 'Triple Slider',
				index: 7,
			},
			SITE_MULLING: {
				name: 'Site Mulling',
				index: 8,
			},
		},
		levels: {
			ALL: {
				name: 'ALL',
				index: 0,
			},
			ATTIC: {
				name: 'ATTIC',
				index: 1,
			},
			FLOOR0: {
				name: 'FLOOR0 (Basement)',
				index: 2,
			},
			FLOOR1: {
				name: 'FLOOR1',
				index: 3,
			},
			FLOOR2: {
				name: 'FLOOR2',
				index: 4,
			},
			FLOOR3: {
				name: 'FLOOR3',
				index: 5,
			},
			FLOOR4: {
				name: 'FLOOR4',
				index: 6,
			},
			FLOOR5: {
				name: 'FLOOR5',
				index: 7,
			},
			FLOOR6: {
				name: 'FLOOR6',
				index: 8,
			},
			FLOOR7: {
				name: 'FLOOR7',
				index: 9,
			},
			FLOOR8: {
				name: 'FLOOR8',
				index: 10,
			},
			UNKNOWN: {
				name: 'UNKNOWN',
				index: 11,
			},
		},

		divisions: {
			ALL: {
				name: 'ALL',
				index: 0,
			},
			ARBOR: {
				name: 'ARBOR',
				index: 1,
			},
			BEARINGWALLS: {
				name: 'BEARINGWALLS',
				index: 2,
			},
			BRIDGE: {
				name: 'BRIDGE',
				index: 3,
			},
			BUILDINGWRAP: {
				name: 'BUILDINGWRAP',
				index: 4,
			},
			CABANA: {
				name: 'CABANA',
				index: 5,
			},
			CANOPY: {
				name: 'CANOPY',
				index: 6,
			},
			CEILING: {
				name: 'CEILING',
				index: 7,
			},
			CHIMNEY: {
				name: 'CHIMNEY',
				index: 8,
			},
			CLUBHOUSE: {
				name: 'CLUBHOUSE',
				index: 9,
			},
			CORRIDOR: {
				name: 'CORRIDOR',
				index: 10,
			},
			CUPOLA: {
				name: 'CUPOLA',
				index: 11,
			},
			DECKING: {
				name: 'DECKING',
				index: 12,
			},
			DEMISINGWALLS: {
				name: 'DEMISINGWALLS',
				index: 13,
			},
			DRAFTSTOP: {
				name: 'DRAFTSTOP',
				index: 14,
			},
			EXTTRIM: {
				name: 'EXTTRIM',
				index: 15,
			},
			EXTWALLS: {
				name: 'EXTWALLS',
				index: 16,
			},
			FIREWALL: {
				name: 'FIREWALL',
				index: 17,
			},
			FLASHING: {
				name: 'FLASHING',
				index: 18,
			},
			FLOOR: {
				name: 'FLOOR',
				index: 19,
			},
			GARAGE: {
				name: 'GARAGE',
				index: 20,
			},
			HARDWARE: {
				name: 'HARDWARE',
				index: 21,
			},
			HIGHROOF: {
				name: 'HIGHROOF',
				index: 22,
			},
			IPS: {
				name: 'IPS',
				index: 23,
			},
			LOWROOF: {
				name: 'LOWROOF',
				index: 24,
			},
			PARAPET: {
				name: 'PARAPET',
				index: 25,
			},
			PAVILION: {
				name: 'PAVILION',
				index: 26,
			},
			PLATFORM: {
				name: 'PLATFORM',
				index: 27,
			},
			POOL: {
				name: 'POOL',
				index: 28,
			},
			PORCHESANDBALCONIES: {
				name: 'PORCHESANDBALCONIES',
				index: 29,
			},
			PORTECOCHERE: {
				name: 'PORTECOCHERE',
				index: 30,
			},
			PUNCHOUT: {
				name: 'PUNCHOUT',
				index: 31,
			},
			ROOF: {
				name: 'ROOF',
				index: 32,
			},
			SAFETYRAILS: {
				name: 'SAFETYRAILS',
				index: 33,
			},
			SHEARWALL: {
				name: 'SHEARWALL',
				index: 34,
			},
			SHED: {
				name: 'SHED',
				index: 35,
			},
			STAIRS: {
				name: 'STAIRS',
				index: 36,
			},
			TRASHCHUTE: {
				name: 'TRASHCHUTE',
				index: 37,
			},
			TRELLIS: {
				name: 'TRELLIS',
				index: 38,
			},
			UNITS: {
				name: 'UNITS',
				index: 39,
			},
			UNKNOWN: {
				name: 'UNKNOWN',
				index: 40,
			},
			WALLS: {
				name: 'WALLS',
				index: 41,
			},
			WINDOWSANDDOORS: {
				name: 'WINDOWSANDDOORS',
				index: 42,
			},
		},

		type_units: {
			in: {
				name: 'inch',
				short: '"',
				index: 0,
			},
			ft: {
				name: 'feet',
				short: "'",
				index: 1,
			},
			oz: {
				name: 'ounce',
				index: 2,
			},
			mm: {
				name: 'millimeter',
				index: 3,
				short: 'mil',
			},
		},

		unit_dividers: {
			'/': {
				name: 'fraction',
				index: 0,
				regex: /\//,
			},
			'.': {
				name: 'decimal',
				index: 1,
				regex: /\./,
			},
			x: {
				name: 'by',
				index: 2,
			},
			'-': {
				name: 'dash',
				index: 3,
			},
		},

		material_categories: {
			SHEATHING: {
				name: 'Sheathing',
				index: 25,
			},
			DIMLUMBER: {
				name: 'Dimensional Lumber',
				index: 26,
			},
			ENGLUMBER: {
				name: 'Engineered Lumber',
				index: 27,
			},
			IJOISTS: {
				name: 'I Joists',
				index: 28,
			},
			DECKING: {
				name: 'Decking',
				index: 29,
			},
			TIMBERS: {
				name: 'Timbers',
				index: 30,
			},
			HARDWARE: {
				name: 'Hardware',
				index: 31,
			},
			ADHESIVESCAULKING: {
				name: 'Adhesives & Caulking',
				index: 32,
			},
			HANGERS: {
				name: 'Hangers',
				index: 33,
			},
			HOLDOWNSSTRAPS: {
				name: 'Holdowns & Straps',
				index: 34,
			},
			SIMPSONCLIPSANGLES: {
				name: 'Simpson Clips & Angles',
				index: 35,
			},
			SIMPSONSHEATHINGCLIPS: {
				name: 'Simpson Sheathing Clips',
				index: 36,
			},
			POSTCAPSBASES: {
				name: 'Post Caps & Bases',
				index: 37,
			},
			THREADEDROD: {
				name: 'Threaded Rod',
				index: 38,
			},
			SIMPSONPAB: {
				name: 'Simpson PAB',
				index: 39,
			},
			HEXBOLTS: {
				name: 'Hex Bolts',
				index: 40,
			},
			CARRIAGEBOLTS: {
				name: 'Carriage Bolts',
				index: 41,
			},
			NUTSWASHERS: {
				name: 'Nuts & Washers',
				index: 42,
			},
			LAGS: {
				name: 'Lags',
				index: 43,
			},
			SCREWS: {
				name: 'Screws',
				index: 44,
			},
			EXPTITENBOLTS: {
				name: 'Exp & Titen Bolts',
				index: 45,
			},
			BEARINGPLATES: {
				name: 'Bearing Plates',
				index: 46,
			},
			PREFABSTAIRS: {
				name: 'PreFab Stairs',
				index: 47,
			},
			BLDGWRAPFLASHING: {
				name: 'Bldg Wrap & Flashing',
				index: 48,
			},
			FIREWALLMATERIAL: {
				name: 'Firewall Material',
				index: 49,
			},
			RIGIDINSULATION: {
				name: 'Rigid Insulation',
				index: 50,
			},
			EXTTRIM: {
				name: 'Exterior Trim',
				index: 51,
			},
			FASTENERS: {
				name: 'Fasteners',
				index: 52,
			},
		},

		spartan: {
			rows: {
				EMPTY: {
					name: 'empty',
					index: 0,
				},
				HEADER: {
					name: 'header',
					index: 1,
				},
				ITEM: {
					name: 'item',
					index: 2,
				},
			},
		},

		takeoff_types: {
			FULL: {
				name: 'Full',
				index: 0,
			},
			LABOR_ONLY: {
				name: 'Labor Only',
				index: 1,
			},
			PARTIAL: {
				name: 'Partial',
				index: 2,
			},
			FULL_SPARTAN: {
				name: 'Full - Spartan',
				index: 3,
			},
			PURCHASE_TAKEOFF: {
				name: 'Purchase Takeoff',
				index: 4,
			},
			OTHER: {
				name: 'Other',
				index: 5,
			},
			/// ///// I dont think the below enums are needed anymore but previous tests are now failing without them...///
			STANDARD: {
				name: 'Standard',
				index: 6,
			},
			SPARTAN: {
				name: 'Spartan',
				index: 7,
			},
			/// //////////////////////////////////////////////////////////////////////////////////////////////////////////
		},

		material_units: {
			LF: {
				name: 'Linear Feet',
				index: 0,
			},
			SF: {
				short: 'SQ FT',
				name: 'Square Feet',
				index: 1,
			},
			BF: {
				name: 'Board Feet',
				index: 2,
			},
			EA: {
				name: 'Each',
				index: 3,
			},
			FT: {
				name: 'Feet',
				index: 4,
			},
			ROLL: {
				name: 'Roll',
				index: 5,
			},
			BOX: {
				name: 'Box',
				index: 6,
			},
			RS: {
				name: 'Roll sheets?',
				index: 7,
			},
			CT: {
				name: 'Count',
				index: 8,
			},
			'sf/mh': {
				name: 'Square Feet per Man Hour',
				index: 9,
			},
			'lf/mh': {
				name: 'Linear Feet per Man Hour',
				index: 10,
			},
			'mh/ea': {
				name: 'Man Hour per Each',
				index: 11,
			},
			'rs/mh': {
				name: 'rs per Man Hour',
				index: 12,
			},
			GA: {
				name: 'Gauge',
				index: 13,
			},
		},

		labor_units: {
			'sf/mh': {
				name: 'Square Feet per Man Hour',
				index: 0,
			},
			'lf/mh': {
				name: 'Linear Feet per Man Hour',
				index: 1,
			},
			'mh/ea': {
				name: 'Man Hour per Each',
				index: 2,
			},
			'rs/mh': {
				name: 'rs per Man Hour',
				index: 3,
			},
		},

		sheet_types: {
			WHITEBOARD: {
				index: 0,
				name: 'Whiteboard',
				sheet_id: null,
				sheet_kind: 'reports',
			},
			EXECUTIVE_SUMMARY_PLANNED_BILLING: {
				index: 1,
				name: 'Smart Sheets Executive Summary Planned Billing',
				sheet_id: '754434780227460',
				sheet_kind: 'reports',
			},
			PURSUIT_SUMMARY_2021: {
				index: 2,
				name: 'Pursuit Summary 2021',
				sheet_id: '4963189466130308',
				sheet_kind: 'reports',
			},
			PURSUIT_SUMMARY_2022: {
				index: 3,
				name: 'Pursuit Summary 2022',
				sheet_id: '7913113665202052',
				sheet_kind: 'reports',
			},
			PURSUIT_SUMMARY_2023: {
				index: 4,
				name: 'Pursuit Summary 2023',
				sheet_id: '7874003516843908',
				sheet_kind: 'reports',
			},
			OTHER: {
				index: 5,
				name: 'Pursuit 2025',
				sheet_id: '444385058',
				sheet_kind: 'reports',
			},
		},

		regions: {
			SOUTHEAST: {
				index: 0,
				name: 'South East',
			},
			NORTHEAST: {
				index: 1,
				name: 'North East',
			},
			ARIZONA: {
				index: 2,
				name: 'Arizona',
			},
			NORTHCALIFORNIA: {
				index: 3,
				name: 'North California',
			},
			SOUTHCALIFORNIA: {
				index: 4,
				name: 'South California',
			},
			MIDATLANTIC: {
				index: 5,
				name: 'Mid-Atlantic',
			},
			TEXAS: {
				index: 6,
				name: 'Texas',
			},
			SOUTHWEST: {
				index: 7,
				name: 'South West',
			},
			PACIFIC: {
				index: 8,
				name: 'Pacific',
			},
			MOUNTAINWEST: {
				index: 9,
				name: 'Mountain West',
			},
			CENTRAL: {
				index: 10,
				name: 'Central',
			},
			// CALIFORNIA: {
			// 	index: 11,
			// 	name: 'California',
			// },
		},
		building_types: {
			TYPE1: {
				index: 0,
				short: 'Type I',
				full: 'Type 1 Building',
				name: 'Type I',
			},
			TYPE2: {
				index: 1,
				short: 'Type II',
				full: 'Type 2 Building',
				name: 'Type II',
			},
			TYPE3: {
				index: 2,
				short: 'Type III',
				full: 'Type 3 Building',
				name: 'Type III',
			},
			TYPE4: {
				index: 3,
				short: 'Type IV',
				full: 'Type 4 Building',
				name: 'Type IV',
			},
			TYPE5: {
				index: 4,
				short: 'Type V',
				full: 'Type 5 Building',
				name: 'Type V',
			},
			MULTIPLEBUILDINGTYPES: {
				index: 5,
				name: 'Multiple Building Types',
			},
		},
		building_subtypes: {
			SENIOR: {
				index: 0,
				name: 'Senior Living',
			},
			THDE: {
				index: 1,
				name: 'TH/DE',
			},
			HOTEL: {
				index: 2,
				name: 'Hotel',
			},
			APARTMENTS: {
				index: 3,
				name: 'Apartments',
			},
			STUDENT: {
				index: 4,
				name: 'Student Housing',
			},
			MIXED: {
				index: 5,
				name: 'Mixed',
			},
			CLUBHOUSE: {
				index: 6,
				name: 'Clubhouse',
			},
			GARAGES: {
				index: 7,
				name: 'Garages',
			},
		},

		quantity_units: {
			EA: {
				index: 0,
				name: 'Each',
			},
			SF: {
				index: 1,
				name: 'Square Feet',
			},
			LF: {
				index: 2,
				name: 'Linear Feet',
			},
			BF: {
				index: 3,
				name: 'Board Feet',
			},
			FT: {
				name: 'Feet',
				index: 4,
			},
			ROLL: {
				name: 'Roll',
				index: 5,
			},
			BOX: {
				name: 'Box',
				index: 6,
			},
		},

		formats: {
			STANDARD: {
				index: 0,
				name: 'Standard',
			},
			SPARTAN: {
				index: 1,
				name: 'Spartan',
			},
			OTHER: {
				index: 2,
				name: 'Other',
			},
		},

		cost_type: {
			LABOR: {
				index: 0,
				name: 'Labor',
			},
			MATERIAL: {
				index: 1,
				short: 'Material',
				name: 'Materials',
			},
			OTHER: {
				index: 2,
				name: 'Other',
			},
		},

		filter_types: {
			BETWEEN_DATES: {
				index: 0,
				name: 'between dates',
			},
			BETWEEN_DOLLARS: {
				index: 1,
				name: 'between dollars',
			},
			BETWEEN_NUMBERS: {
				index: 2,
				name: 'between numbers',
			},
			BETWEEN_PERCENT: {
				index: 3,
				name: 'between percent',
			},
			FUZZY_MATCH: {
				index: 4,
				name: 'fuzzy match',
			},
			MULTI: {
				index: 5,
				name: 'multi (or)',
			},
			YES_NO_EITHER: {
				index: 6,
				name: 'YES/NO/EITHER',
			},
		},

		data_source_types: {
			BIDLOG: {
				index: 0,
				name: 'Bid Log Smartsheet',
			},
			SAGE: {
				index: 0,
				name: 'Sage 300',
			},
		},

		data_types: {
			SET: {
				index: 0,
				name: 'set',
			},
			STRING: {
				index: 1,
				name: 'string',
			},
			BOOL: {
				index: 2,
				name: 'bool',
			},
			NUMBER: {
				index: 3,
				name: 'number',
			},
			PERCENT: {
				index: 4,
				name: 'percent',
			},
			TEXT: {
				index: 5,
				name: 'text',
			},
			DOLLAR: {
				index: 6,
				name: 'dollar',
			},
			DATE: {
				index: 7,
				name: 'date',
			},
		},
		bid_unit_occupancy: {
			FOR_RENT: {
				index: 0,
				name: 'For Rent',
			},
			FOR_SALE: {
				index: 0,
				name: 'For Sale',
			},
			BOTH: {
				index: 0,
				name: 'Both',
			},
		},
	},

	mappings: {
		general_contractor: {
			'AECOM Hunt': ['AECOM Hunt'],
			'AG Spanos': ['AG Spanos'],
			'AP Construction': ['AP Construction'],
			'AR Mays': ['AR Mays'],
			Alpine: ['Alpine'],
			'Alpine 12 → 24': ['Alpine'],
			Annex: ['Annex'],
			Anthem: ['Anthem'],
			'Arch Con': ['Arch Con'],
			'Archer Western': ['Archer Western'],
			'Archer Western (Walsh)': ['Archer Western'],
			Archstone: ['Archstone'],
			Arco: ['Arco'],
			'Arco Murray': ['Arco Murray'],
			'Arco Murray - 2/25\nCBG - 3/5': ['Arco Murray', 'CBG'],
			Associated: ['Associated'],
			'Atlantic Pacific': ['Atlantic Pacific'],
			Avalon: ['Avalon'],
			'Avalon 2nd ← 29th': ['Avalon'],
			'Balfour Beatty': ['Balfour Beatty'],
			'Bartlett Cocke': ['Bartlett Cocke'],
			'Batson Cook': ['Batson Cook'],
			Benchmark: ['Benchmark'],
			Berman: ['Berman'],
			'Big D 21 → 1/5': ['Big-D'],
			'Big-D': ['Big-D'],
			Bowdoin: ['Bowdoin'],
			'Bowdoin\nStateside\nWindover': ['Bowdoin', 'Stateside', 'Windover'],
			Bozzuto: ['Bozzuto'],
			Brinkman: ['Brinkman'],
			Brinkmann: ['Brinkmann'],
			'Broadpoint  Rabren\nHarkins': ['Broadpoint  Rabren', 'Harkins'],
			Brown: ['Brown'],
			'Brown 19th → 1st': ['Brown'],
			'CA Ventures': ['CA Ventures'],
			CBG: ['CBG'],
			'CBG\nHoar\nWhiting Turner': ['CBG', 'Hoar', 'Whiting Turner'],
			'CBG 1.6\nVCC 1.7': ['CBG', 'VCC'],
			'CBG 8.16\nProvident 8.13': ['CBG', 'Provident'],
			'CE Floyd': ['CE Floyd'],
			'CE Floyd\nConnoly Brothers': ['CE Floyd', 'Connoly Brothers'],
			'CE Floyd 3rd → 12th': ['CE Floyd'],
			'CE Floyd 4th → 14th → 16th': ['CE Floyd'],
			'CE Floyd 9.1.21\nDellbrook': ['CE Floyd', 'Dellbrook'],
			CSI: ['CSI'],
			Callahan: ['Callahan'],
			'Callahan - 14th\nBald Hill - 14th\nDellbrook - 14th\nNEI - 15th': ['Callahan', 'Bald Hill', 'Dellbrook', 'NEI'],
			'Callahan 12th → 17th': ['Callahan'],
			'Callahan 12th → 17th → 21st': ['Callahan'],
			'Callahan 14th\nStateside 18th\nDF Pray 20th': ['Callahan', 'Stateside', 'DF Pray'],
			'Callahan 1st → 7th': ['Callahan'],
			'Callahan 24th\nErland 19th': ['Callahan', 'Erland'],
			Campanelli: ['Campanelli'],
			Carmel: ['Carmel'],
			'Carmel Partners': ['Carmel Partners'],
			Catalyst: ['Catalyst'],
			Cathcart: ['Cathcart'],
			Chandos: ['Chandos'],
			Chasse: ['Chasse'],
			Chesapeake: ['Chesapeake'],
			'Chesapeake 8/4\nMCN 7/26': ['Chesapeake', 'MCN'],
			Choate: ['Choate'],
			'Choate 14th → 19th': ['Choate'],
			'Choate 15th\nSamet 21st': ['Choate', 'Samet'],
			'Clancy & T 21st → 27th\nKaufman Lynn - 26th → 1st': ['Clancy & Theys', 'Kaufman Lynn'],
			'Clancy & Theys': ['Clancy & Theys'],
			'Clancy & Theys 12th → 26th': ['Clancy & Theys'],
			'Clancy and Theys 19th →  31st': ['Clancy & Theys'],
			Colarelli: ['Colarelli'],
			'Colson/Hawthorn': ['Colson/Hawthorn'],
			'Colt GC': ['Colt GC'],
			Core: ['Core'],
			'Core West 6th → 12th': ['Core West'],
			Cumby: ['Cumby'],
			DCI: ['DCI'],
			Davis: ['Davis'],
			'Davis\nMorgan Keller': ['Davis', 'Morgan Keller'],
			'Davis 22nd\nMorgan Keller 27th': ['Davis', 'Morgan Keller'],
			'DeAngelis Diamond': ['DeAngelis Diamond'],
			'DeAngelis Diamond - 3.25\nJuneau - 4.7': ['DeAngelis Diamond', 'Juneau'],
			Deacon: ['Deacon'],
			Delbrook: ['Dellbrook'],
			Dellbrook: ['Dellbrook'],
			'Dellbrook - 10th\nDF Pray - 15th': ['Dellbrook', 'DF Pray'],
			'Dellbrook 1st → 10th\nDelphi 2nd\nWindover 5th → 8th': ['Dellbrook', 'Delphi', 'Windover'],
			Discovery: ['Discovery'],
			Donohoe: ['Donohoe'],
			'Donohoe 11th\nDavis 13th\nParadigm 12th': ['Donohoe', 'Davis', 'Paradigm'],
			EBCO: ['EBCO'],
			EMJ: ['EMJ'],
			'EMJ and Congress': ['EMJ', 'Congress'],
			ESI: ['ESI'],
			EYA: ['EYA'],
			'Elford RDC': ['Elford RDC'],
			Elmington: ['Elmington'],
			Embrey: ['Embrey'],
			Emmerson: ['Emmerson'],
			Enterprise: ['Enterprise'],
			Erland: ['Erland'],
			Essex: ['Essex'],
			Essey: ['Essey'],
			Fairfield: ['Fairfield'],
			'Flintco 4th → 14th\nTurner': ['Flintco', 'Turner'],
			'Florida First': ['Florida First'],
			Flournoy: ['Flournoy'],
			'Flournoy 15th → 20th': ['Flournoy'],
			'Fortune Johnson': ['Fortune Johnson'],
			'Foulger Pratt': ['Foulger Pratt'],
			Franklin: ['Franklin'],
			Fulcrum: ['Fulcrum'],
			GCON: ['GCon'],
			GCon: ['GCon'],
			Garret: ['Garret'],
			'Gilban (Clancy & Theys)': ['Clancy & Theys'],
			'Gilbane (Clancy & Theys)': ['Clancy & Theys'],
			Greystar: ['Greystar'],
			'Group West': ['Group West'],
			Hanover: ['Hanover'],
			'Hardison Downey': ['Hardison Downey'],
			'Harkins 25th\nCEI 25th': ['Harkins', 'CEI'],
			'Harpco 19th→ 26th': ['Harpco'],
			Harvey: ['Harvey'],
			Hawthorn: ['Hawthorn'],
			'Hawthorne / Colson': ['Hawthorne / Colson'],
			Hinton: ['Hinton'],
			'Hinton - 12\nKaufman Lynn - 23rd': ['Hinton - 12\nKaufman Lynn - 23rd'],
			Hoar: ['Hoar'],
			'Hudson Meridian': ['Hudson Meridian'],
			Huff: ['Huff'],
			InnoCon: ['InnoCon'],
			JMA: ['JMA'],
			'JMA (Jim Murphy & Associates)': ['JMA (Jim Murphy & Associates)'],
			JPI: ['JPI'],
			Jacobsen: ['Jacobsen'],
			'Johnson Carlier': ['Johnson Carlier'],
			'Johnston Moyer': ['Johnston Moyer'],
			Juneau: ['Juneau'],
			KAST: ['KAST'],
			KBE: ['KBE'],
			'KL Masters': ['KL Masters'],
			KPRS: ['KPRS'],
			Kast: ['Kast'],
			'Kast - 23rd\nKaufman Lynn - 20th': ['Kast', 'Kaufman Lynn'],
			'Kast 8th\nDeAngelis Diamond 5th': ['Kast 8th', 'DeAngelis Diamond 5th'],
			Katerra: ['Katerra'],
			'Katerra 1.12\nClancy & Theys 1.14': ['Katerra', 'Clancy & Theys'],
			'Katerra 10th\nClancy & Theys 3.5.21': ['Katerra', 'Clancy & Theys'],
			'Kaufman Lynn': ['Kaufman Lynn'],
			Keith: ['Keith'],
			'Keith \nDF Pray \nKaplan\nBald Hill': ['Keith', 'DF Pray', 'Kaplan', 'Bald Hill'],
			'Keith Construction': ['Keith Construction'],
			'Kier - 23rd → 30th\nLayton - 31st': ['Kier', 'Layton'],
			'Kier 11th  → 16th': ['Kier'],
			Kinney: ['Kinney'],
			Landsouth: ['Landsouth'],
			Layton: ['Layton'],
			'Layton 18th → 8th`\nSuffick': ['Layton', 'Suffick'],
			'Layton 22nd  → 24th': ['Layton'],
			LeChase: ['LeChase'],
			Lemoine: ['Lemoine'],
			'Lemoine Company': ['Lemoine Company'],
			Lennar: ['Lennar'],
			'Live Oak': ['Live Oak'],
			'Live Oak\nKBE': ['Live Oak', 'KBE'],
			MPC: ['MPC'],
			'MPC - 30th\nOden Hughes - 22nd': ['MPC', 'Oden Hughes'],
			'MPC 9th →  12th': ['MPC'],
			'MW Builders': ['MW Builders'],
			'Makers Line': ['Makers Line'],
			'Makers Line 23rd → 2nd': ['Makers Line'],
			'Makers Line 3.12 → 4.30': ['Makers Line'],
			Map: ['Map Construction'],
			'Map Construction': ['Map Construction'],
			McCullough: ['McCullough'],
			McShane: ['McShane'],
			Merit: ['Merit'],
			Michaels: ['Michaels'],
			'Mill Creek': ['Mill Creek'],
			Miller: ['Miller'],
			Mint: ['Mint'],
			Modern: ['Modern'],
			Morgan: ['Morgan'],
			'Morgan Group': ['Morgan Group'],
			Moriarty: ['Moriarty'],
			Morley: ['Morley'],
			'Muckleroy & Falls': ['Muckleroy & Falls'],
			NEI: ['NEI'],
			'NEI - 7/23\nWindover - 7/9 Dellbrook 7/9': ['NEI', 'Windover', 'Dellbrook'],
			NRP: ['NRP'],
			'NRP\nBrinkman': ['NRP', 'Brinkman'],
			'Network Design 15th\nMontagno 16th': ['Network Design', 'Montagno'],
			'New England': ['New England'],
			'New South': ['New South'],
			'Nordic Structures': ['Nordic Structures'],
			'Northwood Ravin': ['Northwood Ravin'],
			'Oden Hughes': ['Oden Hughes'],
			'Oden Hughes\nArch Con': ['Oden Hughes', 'Arch Con'],
			'Oden Hughes 11th → 13th': ['Oden Hughes'],
			PCL: ['PCL'],
			PWI: ['PWI'],
			Palisade: ['Palisade'],
			Pallisades: ['Pallisades'],
			'Pallisades\nCSI': ['Pallisades', 'CSI'],
			Paradigm: ['Paradigm'],
			'Partners in Construction': ['Partners in Construction'],
			Pentalon: ['Pentalon'],
			Pinnacle: ['Pinnacle'],
			Platinum: ['Platinum'],
			Plenium: ['Plenium'],
			'Plumb House': ['Plumb House'],
			'Plumb House\nDF Pray': ['Plumb House', 'DF Pray'],
			'Plumb House  5th → 13th': ['Plumb House'],
			'Plumb House 12 → 24': ['Plumb House'],
			'Plumb House 5th → 9th\nCallahan 19th': ['Plumb House, Callahan'],
			Procon: ['Procon'],
			Procopio: ['Procopio'],
			'Promontory Developments': ['Promontory Developments'],
			Provident: ['Provident'],
			'Provident 15th\nCadence McShane 11th': ['Provident 15th', 'Cadence McShane'],
			'R.G. Anderson': ['R.G. Anderson'],
			RMS: ['RMS'],
			Rabren: ['Rabren'],
			Rampart: ['Rampart'],
			'Rampart \nMcShane': ['Rampart', 'McShane'],
			Republic: ['Republic'],
			Rockwood: ['Rockwood Construction'],
			'Rockwood Construction': ['Rockwood Construction'],
			Rubicon: ['Rubicon'],
			Ruscilli: ['Ruscilli'],
			Ryan: ['Ryan'],
			'Ryan 30th → 6th': ['Ryan'],
			SBI: ['SBI'],
			'SBI / Brown': ['SBI', 'Brown'],
			Samet: ['Samet'],
			'Samet 13th → 27th': ['Samet'],
			Satterfield: ['Satterfield'],
			Skycore: ['Skycore'],
			SoHo: ['SoHo'],
			Spinnaker: ['Spinnaker'],
			Streetlights: ['Streetlights'],
			'Summit Brooke': ['Summit Brooke'],
			'Sun Eagle': ['Sun Eagle'],
			'Sundt 16th → 14th → 19th': ['Sundt'],
			Swenson: ['Swenson'],
			'TB Penick': ['TB Penick'],
			'TB Penick 15th →  24th → 30th': ['TB Penick'],
			TRW: ['TRW'],
			Timberline: ['Timberline'],
			'Tofel Dent': ['Tofel Dent'],
			Trinity: ['Trinity'],
			'Trinity - 7th\nLeChase - 26th': ['Trinity', 'LeChase'],
			VCC: ['VCC'],
			'VCC\nMAP': ['VCC', 'Map Construction'],
			'VCC 4th → 19th': ['VCC'],
			'Van Daele': ['Van Daele'],
			'Van Meren Group': ['Van Meren Group'],
			'WCF Construction': ['WCF Construction'],
			'WCS Construction': ['WCS Construction'],
			"WE O'Neil": ["W.E. O'Neil"],
			"WE O'Neil\nZwick": ["W.E. O'Neil", 'Zwick'],
			'WE Oneil': ["W.E. O'Neil"],
			'WL Butler': ['WL Butler'],
			Walsh: ['Walsh'],
			'Walsh (Archer Western)': ['Archer Western'],
			'Walsh 1st\nWe Oneil 2nd': ['Walsh', "W.E. O'Neil"],
			'Walsh 21st\nHarkins 17th': ['Walsh', 'Harkins'],
			'Walsh Group': ['Walsh Group'],
			Weis: ['Weis'],
			'Weis \nMcShane\nTofel Dent': ['Weis', 'McShane', 'Tofel Dent'],
			Weitz: ['Weitz'],
			'Weitz 10th → 12th': ['Weitz'],
			'Weitz 7th → 10th': ['Weitz'],
			'Weitz 9th → 25th → 3.25': ['Weitz'],
			Wespac: ['Wespac'],
			'West Builders': ['West Builders'],
			'Western Builders': ['Western Builders'],
			'White Construction': ['White Construction'],
			'Whiting Turner 17th → 21st': ['Whiting Turner'],
			Windover: ['Windover'],
			Wohlsen: ['Wohlsen'],
			'Wood Partners': ['Wood Partners'],
			ZCON: ['ZCON'],
			Zwick: ['Zwick'],
			'Zwick  - 3rd → 8th\nPentalon - 3rd → 8th\nKier - 3rd': ['Zwick', 'Pentalon', 'Kier'],
		},

		level: {
			'': { level: 'UNKNOWN' },
			null: { level: 'UNKNOWN' },
			'0 BASEMENT': { level: 'FLOOR0' },
			'0 BASEMENT - 1': { level: 'FLOOR0' },
			'0 BASEMENT - 2': { level: 'FLOOR0' },
			'0 TERRACE': { level: 'FLOOR0' },
			Terrace: { level: 'FLOOR0' },
			'00 SUB BASEMENT': { level: 'FLOOR0' },
			'1 1ST FL FRAME': { level: 'FLOOR1', levelNum: 1, isFrame: true },
			'1 1ST FLOOR': { level: 'FLOOR1', levelNum: 1 },
			'1 1ST FLOOR - BP': { level: 'FLOOR1', levelNum: 1, belowPodium: true },
			'1 2ND FL FRAME': { level: 'FLOOR2', levelNum: 2, isFrame: true },
			'1 2ND FLOOR': { level: 'FLOOR2', levelNum: 2 },
			'1 2ND FL FRAME - BP': { level: 'FLOOR2', levelNum: 2, belowPodium: true },
			'1 3RD FL FRAME': { level: 'FLOOR3', levelNum: 3, isFrame: true },
			'1 3RD FLOOR': { level: 'FLOOR3', levelNum: 3 },
			'1 4TH FL FRAME': { level: 'FLOOR4', levelNum: 4, isFrame: true },
			'1 4TH FLOOR': { level: 'FLOOR4', levelNum: 4 },
			'1 5TH FL FRAME': { level: 'FLOOR5', levelNum: 5, isFrame: true },
			'1 5TH FLOOR': { level: 'FLOOR5', levelNum: 5 },
			'1 6TH FL FRAME': { level: 'FLOOR6', levelNum: 5, isFrame: true },
			'1 6TH FLOOR': { level: 'FLOOR6', levelNum: 6 },
			'1 7TH FL FRAME': { level: 'FLOOR7', levelNum: 7, isFrame: true },
			'1 7TH FLOOR': { level: 'FLOOR7', levelNum: 7 },
			'1 8TH FLOOR': { level: 'FLOOR8', levelNum: 8 },
			'1 8TH FL FRAME': { level: 'FLOOR8', levelNum: 8, isFrame: true },
			'1 ALL LEVELS': { level: 'ALL' },
			'1 LOFT FL FRAME': { level: 'ATTIC', isFloor: true, isFrame: true },
			Loft: { level: 'ATTIC' },
			'1 LOFT FLOOR': { level: 'ATTIC', isFloor: true },
			'2 3RD FLOOR': { level: 'FLOOR3', levelNum: 3 },
			'2 ATTIC': { level: 'ATTIC' },
			'3 3RD FLOOR': { level: 'FLOOR3', levelNum: 3 },
			'3 ROOF': { level: 'ALL', division: 'ROOF', isRoof: true },
			'3 ROOF- BP': { level: 'ALL', division: 'ROOF', isRoof: true, belowPodium: true },
			'4 PORCHES / BALCONIES': { level: 'ALL', division: 'PORCHESANDBALCONIES' },
			'5 2HR FIRE WALLS': { level: 'ALL', division: 'FIREWALL' },
			'5 ALL LEVELS': { level: 'ALL' },
			'5 BELOW PODIUM': { level: 'ALL', belowPodium: true },
			'5 WINDOWS': { level: 'ALL', division: 'WINDOWSANDDOORS' },
			'5 WINDOWS / EXT DOORS': { level: 'ALL', division: 'WINDOWSANDDOORS' },
			'6 BUILDING WRAP': { level: 'ALL', isBuildingWrap: true },
			Roof: { level: 'ALL', division: 'ROOF', isRoof: true },
			'3rd Level': { level: 'FLOOR3', levelNum: 3 },
			'2nd Level': { level: 'FLOOR2', levelNum: 2 },
			'3rd Level Floor': { level: 'FLOOR3', levelNum: 3, isFloor: true },
			'4th Level': { level: 'FLOOR4', levelNum: 4 },
			'4th Level Floor': { level: 'FLOOR2', levelNum: 2, isFloor: true },
			'1st Level': { level: 'FLOOR1', levelNum: 1 },
			'2nd Level Floor': { level: 'FLOOR2', levelNum: 2, isFloor: true },
			'5th Level': { level: 'FLOOR5', levelNum: 5 },
			'5th Level Floor': { level: 'FLOOR5', levelNum: 5, isFloor: true },
			'6th Level Floor': { level: 'FLOOR6', levelNum: 6, isFloor: true },
			'6th Level': { level: 'FLOOR6', levelNum: 6 },
			'Penthouse Roof': { level: 'ALL', division: 'PENTHOUSE', isRoof: true },
			Penthouse: { level: 'ALL', division: 'PENTHOUSE' },
			'8th Level': { level: 'FLOOR8', levelNum: 8 },
			'Tower Roof': { level: 'ALL', division: 'TOWER', isRoof: true },
			Ceiling: { level: 'ALL', division: 'CEILING' },
			'7th Level': { level: 'FLOOR7', levelNum: 7 },
			'Upper Roof': { level: 'ALL', division: 'HIGHROOF', isRoof: true },
			'New Plans': { level: 'ALL' },
			'Low Roof': { level: 'ALL', division: 'LOWROOF', isRoof: true },
			'High Roof': { level: 'ALL', division: 'HIGHROOF', isRoof: true },
			All: { level: 'ALL' },
			'8th': { level: 'FLOOR8', levelNum: 8 },
			'7th': { level: 'FLOOR7', levelNum: 7 },
			'6th': { level: 'FLOOR6', levelNum: 6 },
			'6TH': { level: 'FLOOR6', levelNum: 6 },
			'5th': { level: 'FLOOR5', levelNum: 5 },
			'4th': { level: 'FLOOR4', levelNum: 4 },
			'3rd': { level: 'FLOOR3', levelNum: 3 },
			'2nd': { level: 'FLOOR2', levelNum: 2 },
			'1st ': { level: 'FLOOR1', levelNum: 1 },
			'1st': { level: 'FLOOR1', levelNum: 1 },
			'1&2': { level: 'ALL' },
		},

		division: {
			'': { division: 'UNKNOWN' },
			null: { division: 'UNKNOWN' },
			'0 BUILDING WRAP': { division: 'BUILDINGWRAP', isBuildingWrap: true },
			'1 BUILDING WRAP': { division: 'BUILDINGWRAP', isBuildingWrap: true },
			'1 EXT WALL BUILDOUTS': { division: 'EXTWALLS', isWall: true },
			'zz EXT BUILDOUTS SF': { division: 'EXTWALLS' },
			'1 EXT WALLS': { division: 'EXTWALLS', isWall: true },
			'1 EXT WALLS - RADIUS': { division: 'EXTWALLS', isWall: true },
			'1 WINDOWS': { division: 'WINDOWSANDDOORS' },
			'2 BEARING WALLS': { division: 'BEARINGWALLS', isLoadBearing: true, isWall: true },
			'2 CORR BEARING WALLS': { division: 'CORRIDOR', isWall: true },
			'2 CORR WALLS': { division: 'CORRIDOR', isWall: true },
			'2 DEMISING WALLS': { division: 'DEMISINGWALLS', isWall: true },
			'2 EXT DOORS': { division: 'WINDOWSANDDOORS' },
			'2 MISC BEARING WALLS': { division: 'BEARINGWALLS', isLoadBearing: true, isWall: true },
			'2 UNIT BEARING WALLS': { division: 'BEARINGWALLS', isLoadBearing: true, isWall: true },
			"3 IP'S": { division: 'IPS' },
			'3 SAFETY RAILS': { division: 'SAFETYRAILS' },
			'3 SHEAR': { division: 'SHEARWALL' },
			'3 SHEAR - 2ND SIDE': { division: 'SHEARWALL' },
			'4 CEILINGS': { division: 'CEILING' },
			'4 MISC FRM/BLCK': { division: 'ALL', isFrame: true },
			'4 MISC HARDWARE': { division: 'HARDWARE' },
			'4 PUNCHOUT': { division: 'PUNCHOUT' },
			'5 FLOOR FRAMING': { division: 'FLOOR', isFloor: true, isFrame: true },
			'6 BALCONY ROOF TRELLIS': { division: 'TRELLIS' },
			'6 DECKS/BALCONIES': { division: 'PORCHESANDBALCONIES' },
			'6 ENTRY CANOPY ROOF': { division: 'CANOPY', isRoof: true },
			'6 LOW ROOF LVL 1': { level: 'FLOOR1', division: 'LOWROOF', isRoof: true },
			'6 LOW ROOF LVL 2': { level: 'FLOOR2', division: 'LOWROOF', isRoof: true },
			'6 LOW ROOF LVL 3': { level: 'FLOOR3', division: 'LOWROOF', isRoof: true },
			'6 LOW ROOF LVL 4': { level: 'FLOOR4', division: 'LOWROOF', isRoof: true },
			'6 LOW ROOF LVL 5': { level: 'FLOOR5', division: 'LOWROOF', isRoof: true },
			'6 LOW ROOF LVL 6': { level: 'FLOOR6', division: 'LOWROOF', isRoof: true },
			'6 LOW ROOF LVL 7': { level: 'FLOOR7', division: 'LOWROOF', isRoof: true },
			'6 ROOF EYEBROWS': { division: 'ROOF', isRoof: true },
			'6 ROOF FRAMING': { division: 'ROOF', isRoof: true, isFrame: true },
			'6 STICK ROOF': { division: 'ROOF', isRoof: true },
			'6 UPPER CANOPY ROOF': { division: 'CANOPY', isRoof: true },
			'6 UPPER ROOF': { division: 'HIGHROOF', isRoof: true },
			'7 FLOOR FRAMING': { division: 'FLOOR', isFloor: true, isFrame: true },
			'7 PARAPETS': { division: 'PARAPET', isRoof: true },
			'7 ROOF OVERHANGS 1': { division: 'ROOF', isRoof: true },
			'7 SHAFT & FIRE WALLS': { division: 'FIREWALL' },
			'7 STAIRS': { division: 'STAIRS' },
			'8 FLOOR FRAMING': { division: 'FLOOR', isFloor: true, isFrame: true },
			'8 ROOF TRIM': { division: 'ROOF', isRoof: true },
			'8 TRELLIS': { division: 'TRELLIS' },
			'9 DRAFTSTOP': { division: 'DRAFTSTOP' },
			Added: { division: 'ALL', isAddition: true },
			Add: { division: 'ALL', isAddition: true },
			'Eave-Co': { division: 'ALL' },
			Awning: { division: 'UNKNOWN' },
			'w BALCONIES': { division: 'PORCHESANDBALCONIES' },
			'w BUILDING STAIRS': { division: 'STAIRS' },
			'w PUBLIC UNITS': { division: 'UNITS' },
			'w RES UNITS': { division: 'UNITS' },
			'w SLEEPER/DECKING SF': { division: 'DECKING' },
			'x BALCONY SF': { division: 'PORCHESANDBALCONIES' },
			'w TH RES UNITS': { division: 'UNITS' },
			'y CORRIDOR AREA': { division: 'CORRIDOR' },
			'y FLOOR TRUSS AREA': { division: 'FLOOR', isFloor: true, isTruss: true },
			'y STICK FLOOR FRAMING AREA': { division: 'FLOOR', isFloor: true, isFrame: true },
			'y STICK ROOF FRAMING': { division: 'ROOF', isRoof: true, isFrame: true },
			'z FLOOR TRUSS SF 24"oc': { division: 'FLOOR', isFloor: true, isTruss: true },
			'z Public Units': { division: 'UNITS' },
			'z ROOF TRUSS SF': { division: 'ROOF', isTruss: true, isRoof: true },
			'z ROOF TRUSS SF 24"oc': { division: 'ROOF', isTruss: true, isRoof: true },
			'z Res Units': { division: 'UNITS' },
			Walls: { division: 'WALLS', isWall: true },
			Wall: { division: 'WALLS', isWall: true },
			wall: { division: 'WALLS', isWall: true },
			'Hardware and Metal Connector': { division: 'HARDWARE' },
			'Walls  Hardware and Metal Connector': { division: 'HARDWARE' },
			'Walls Hardware and Metal Connector': { division: 'HARDWARE' },
			'Low    Hardware and Metal Connector': { division: 'HARDWARE' },
			Low: { division: 'LOWROOF', isRoof: true },
			'Low Roof': { division: 'LOWROOF', isRoof: true },
			'z ROOF TRUSS SF 24oc"': { division: 'ROOF', isRoof: true, isTruss: true },
			'z FLOOR TRUSS SF 24oc"': { division: 'FLOOR', isFloor: true, isTruss: true },
			'New Plan Add': { division: 'ALL', isAddition: true },
			Floors: { division: 'FLOOR', isFloor: true },
			Floor: { division: 'FLOOR', isFloor: true },
			floor: { division: 'FLOOR', isFloor: true },
			High: { division: 'HIGHROOF', isRoof: true },
			'Joist Pack': { division: 'HARDWARE' },
			'Top Wall': { division: 'WALLS', isWall: true },
			'(Amentaties)': { division: 'ALL' },
			'Amenity Wall': { division: 'WALLS', isWall: true },
			'Amenity Floor': { division: 'FLOOR', isFloor: true },
			'Amenity Roof': { division: 'ROOF', isFloor: true },
			'New Plan Add  Hardware and Metal Connector': { division: 'HARDWARE' },
			Upper: { division: 'ALL' },
			'Walls-Under Podium': { division: 'WALLS', belowPodium: true },
			'Walls- Amenity Under Podium': { division: 'WALLS', belowPodium: true },
			'Joist   Hardware and Metal Connector': { division: 'HARDWARE' },
			Joist: { division: 'ALL' },
			'Stair   Hardware and Metal Connector': { division: 'HARDWARE' },
			'(Amentaties)  Walls  Hardware and Metal Connector': { division: 'HARDWARE' },
			'High    Hardware and Metal Connector': { division: 'HARDWARE' },
			'Upper    Hardware and Metal Connector': { division: 'HARDWARE' },
			'(Amentaties)  Walls': { division: 'WALLS' },
			'Walls- Amenity Under Podium Hardware and Metal Connector': { division: 'HARDWARE', belowPodium: true },
			Platforms: { division: 'PLATFORM' },
			'(Amentaties)    Hardware and Metal Connector': { division: 'HARDWARE' },
			'Walls/Platforms Hardware and Metal Connector': { division: 'HARDWARE' },
			Stair: { division: 'STAIRS' },
			Stairs: { division: 'STAIRS' },
			'Walls-Under Podium Hardware and Metal Connector': { division: 'HARDWARE', belowPodium: true },
			'Pickup Framing': { division: 'ALL', isFrame: true },
			Roof: { division: 'ROOF', isRoof: true },
			'8 ROOF PIPING WALLS': { division: 'ROOF', isWall: true, isRoof: true },
			roof: { division: 'ROOF', isRoof: true },
			'Walls/Platforms': { division: 'WALLS', isWall: true },
		},

		building: {
			null: {},
			AL: {},
			CMN: {},
			IL: {},
			MC: {},
			PC: {},
			GAR: {},
			'1A': {},
			'1B': {},
			'2A': {},
			'2B': {},
			'3A': {},
			'3B': {},
			'3HR FIREWALL': { division: 'FIREWALL' },
			'3rd FL ROOF DECKS': { division: 'PORCHESANDBALCONIES' },
			'5-Story Building': {},
			'4-Story Building': {},
			A: {},
			'A (BP)': { belowPodium: true },
			'A FRAME': { isFrame: true },
			'A P3': {},
			'A wFirewalls': { division: 'FIREWALL' },
			'A,B FRAME': { isFrame: true },
			'A-D': {},
			'AAA CAR CARE CENTER': {},
			'ADD/ALT': { isAddition: true },
			'ADDED WIN/DOOR': { division: 'WINDOWSANDDOORS', isAddition: true },
			ALL: {},
			Amenity: {},
			ALTERNATE: {},
			ARBOR: { division: 'ARBOR' },
			ARBORS: { division: 'ARBOR' },
			'AREAWAY TRELLIS': { division: 'TRELLIS' },
			'Above Podium': {},
			Add: { isAddition: true },
			All: {},
			'All-2': {},
			B: {},
			'B (BP)': { belowPodium: true },
			'B FRAME': { isFrame: true },
			'B P2': {},
			'B P3': {},
			'B P4': {},
			'B P5': {},
			'B P6': {},
			'B wFIREWALLS': { division: 'FIREWALL' },
			B2: {},
			'B2/B4b/B5 (Type 5)': {},
			'B3/B4a/B6 (Type 3)': {},
			BALCONIES: { division: 'PORCHESANDBALCONIES' },
			'Bldg - A': {},
			'BEL PODIUM': { belowPodium: true },
			'BELOW TYVEK': {},
			'Bldg 1C 1st': {},
			'Bldg 1B 1st': {},
			'Bldg 1A 1st': {},
			'Bldg - Clubhouse': { division: 'CLUBHOUSE' },
			'Bldg - Garage': { division: 'GARAGE' },
			'Bldg A': {},
			'Bldg - B': {},
			'Bldg - D': {},
			'Building E': {},
			'Building D': {},
			'BLDG E': {},
			'BLDG F': {},
			Bldg1A: {},
			'Bldg 1D 1st': {},
			'Bldg 6': {},
			'Bldg 7': {},
			'Bldg 8': {},
			'Bldg 5': {},
			'Bldg 9': {},
			'Bldg 4': {},
			'BLDG D': {},
			'BLDG 1': {},
			'Bldg 1C': {},
			'BLDG 11': {},
			'BLDG 12': {},
			'BLDG 13': {},
			'BLDG 14': {},
			'BLDG 2': {},
			'BLDG 21': {},
			'BLDG 29': {},
			'BLDG 3': {},
			'BLDG 35': {},
			'BLDG 36': {},
			'BLDG 4': {},
			'BLDG 40': {},
			'BLDG 5': {},
			'BLDG A': {},
			'BLDG B': {},
			'BLDG B1': {},
			'BLDG B2': {},
			'BLDG B3': {},
			'BLDG C': {},
			'BLDG C1': {},
			'BLDG C2': {},
			'BLDG D1': {},
			'BLDG D2': {},
			'BLDG E1': {},
			'BLDG E2': {},
			'BLDG E3': {},
			'BLDG E4': {},
			'BLDG E5': {},
			'BLDG E6': {},
			'BLDG E7': {},
			BLDG5: {},
			BP: { belowPodium: true },
			'Below Podium - New': { belowPodium: true },
			'Main - BP': { belowPodium: true },
			'BP ': { belowPodium: true },
			Balcony: { division: 'PORCHESANDBALCONIES' },
			'BALCONY TYPE I': { division: 'PORCHESANDBALCONIES' },
			'Below Podium': { belowPodium: true },
			'Bldg 1C basement': { level: 'FLOOR0' },
			'Bld A': {},
			'Bld B': {},
			'Bld 1': {},
			'Bld 2': {},
			'Bldg 1': {},
			'Bldg 2': {},
			'Bldg 3': {},
			'Bldg - C': {},
			'Bldg - E': {},
			Bridge: { division: 'BRIDGE' },
			'Building 1': {},
			'Building 2': {},
			'Building 3': {},
			'Building 4': {},
			'Building 4a': {},
			'Building 4b': {},
			'Building 5': {},
			'Building 6': {},
			'Building A': {},
			'Building A & C': {},
			'Building B': {},
			'Building C': {},
			C: {},
			'C FRAME': { isFrame: true },
			CABANA: { division: 'CABANA' },
			CARPORT: { division: 'GARAGE' },
			'CARPORT W/D': { division: 'GARAGE' },
			'CEILING - ALT': { division: 'CEILING' },
			CLUB: { division: 'CLUBHOUSE' },
			CLUBHOUSE: { division: 'CLUBHOUSE' },
			Clubhouse: { division: 'CLUBHOUSE' },
			ClubH: { division: 'CLUBHOUSE' },
			'CLUBHOUSE TYVEK': { division: 'BUILDINGWRAP' },
			'CLUBHOUSE WIN/DOOR': { division: 'WINDOWSANDDOORS' },
			COMBUSTIBLE: {},
			'CORNER PORCH': { division: 'PORCHESANDBALCONIES' },
			'CRTYRD TRELLIS': { division: 'TRELLIS' },
			CUPOLA: { division: 'CUPOLA' },
			Changes: {},
			D: {},
			'D FRAME': { isFrame: true },
			DE: {},
			'DECKING OPT 1': {},
			DOORS: { division: 'WINDOWSANDDOORS' },
			DRAFTSTOP: { division: 'DRAFTSTOP' },
			'E FRAME': { isFrame: true },
			'E-F': {},
			EAST: {},
			'EAST BLDG': {},
			'EAST TRELLIS': { division: 'TRELLIS' },
			'EAST V': {},
			'ENTRY CANOPY': { division: 'CANOPY' },
			'ENTRY CANOPY ZIP': { division: 'CANOPY' },
			'EXT SHEATH ALT': {},
			'EXT TRELLIS': { division: 'TRELLIS' },
			East: {},
			'East-2': {},
			'Elevator room': {},
			F3: {},
			'FALSE CHIMNEY': { division: 'CHIMNEY' },
			FIREWALLS: { division: 'FIREWALL', isFirewall: true },
			FLASHING: { division: 'FLASHING' },
			'FLITCH BEAM': {},
			FRAME: { isFrame: true },
			'FRAME - ALT': { isFrame: true },
			'FRAME 5 CAR HC': { division: 'GARAGE' },
			'5 car garage': { division: 'GARAGE' },
			'7-Car Garage': { division: 'GARAGE' },
			'5-Car Garage w Accessible': { division: 'GARAGE' },
			'10-Car Garage': { division: 'GARAGE' },
			'8-Car Garage': { division: 'GARAGE' },
			'7 car garage': { division: 'GARAGE' },
			'FRAME 5 CAR HC/BIKE': { division: 'GARAGE' },
			'FRAME 6 CAR': { division: 'GARAGE' },
			'FRAME 6 CAR BIKE': { division: 'GARAGE' },
			'FRAME A': { isFrame: true },
			'FRAME AREA 1': { isFrame: true },
			'FRAME AREA 2': { isFrame: true },
			'FRAME B': { isFrame: true },
			'FRAME B WING': { isFrame: true },
			'FRAME BLDG 1': { isFrame: true },
			'FRAME BLDG 4': { isFrame: true },
			'FRAME BLDG 9': { isFrame: true },
			'FRAME C': { isFrame: true },
			'FRAME C WING': { isFrame: true },
			'FRAME CH': { isFrame: true },
			'FRAME CORE': { isFrame: true },
			'FRAME D': { isFrame: true },
			'FRAME E': { isFrame: true },
			'FRAME F': { isFrame: true },
			FULL: {},
			Full: {},
			'Full-2': {},
			G1: {},
			G2: {},
			G3: {},
			G4: {},
			G5: {},
			G6: {},
			G7: {},
			G8: {},
			'Gar 5A': { division: 'GARAGE' },
			'Gar 5B': { division: 'GARAGE' },
			GARAGE: { division: 'GARAGE' },
			Garage: { division: 'GARAGE' },
			'5-Car Garages': { division: 'GARAGE' },
			'GARAGE 1': { division: 'GARAGE' },
			'GARAGE 1 WIN/DOOR': { division: 'GARAGE' },
			'GARAGE 2': { division: 'GARAGE' },
			'GARAGE 2 WIN/DOOR': { division: 'GARAGE' },
			'GARAGE 3': { division: 'GARAGE' },
			'GARAGE 4': { division: 'GARAGE' },
			'GARAGE 5': { division: 'GARAGE' },
			'GARAGE 6': { division: 'GARAGE' },
			'GARGAE G1': { division: 'GARAGE' },
			'GARGAE G2': { division: 'GARAGE' },
			'GARGAE G3': { division: 'GARAGE' },
			'GARGAE G4': { division: 'GARAGE' },
			'GARGAE G5': { division: 'GARAGE' },
			'GARGAE G6': { division: 'GARAGE' },
			'GARAGE DOORS': { division: 'GARAGE' },
			'GARAGE ZIP': { division: 'GARAGE' },
			GARAGES: { division: 'GARAGE' },
			Garages: { division: 'GARAGE' },
			GREEN: {},
			Green: {},
			ORANGE: {},
			'HAMPTON INN': {},
			'HAMPTON INN - CLP': {},
			'HAMPTON INN - HD': {},
			'HAMPTON INN - RFI OUT': {},
			HD: {},
			'HTT - A': {},
			'HTT - B': {},
			'HTT - C': {},
			'HTT - D': {},
			'HTT - E': {},
			'HTT - PC': {},
			'I Joist': {},
			'IL EAST': {},
			'IL WEST': {},
			'Live Work': {},
			'Building Separation': {},
			MAIN: {},
			Mail: { division: 'UNKNOWN' },
			'MAIL KIOSK 1': { division: 'UNKNOWN' },
			'MAIL KIOSK 2': { division: 'UNKNOWN' },
			'MAIL KIOSK 3': { division: 'UNKNOWN' },
			'Main BLDG': {},
			'Main BLDG ': {},
			'MAIN ADD/ALT': { isAddition: true },
			'MAIN ALT': { isAddition: true },
			MAINTENANCE: {},
			Maint: {},
			'METAL ROOFS': { division: 'ROOF', isRoof: true },
			'MISC DECKS/SEATS': {},
			MITSUI: {},
			'MP Room': {},
			Main: {},
			'Main below podium': { belowPodium: true },
			'Main corridor popout': { division: 'CORRIDOR' },
			'Main ext wall band otp.4b': {},
			'Main exterior band': {},
			'Main exterior band roof': { division: 'ROOF', isRoof: true },
			'Main-2': {},
			Maintenance: {},
			NORTH: {},
			North: {},
			'NORTH TRELLIS': { division: 'TRELLIS' },
			'New - Below Podium': { belowPodium: true },
			'New - Main': {},
			'PAD A': { division: 'PORCHESANDBALCONIES' },
			PANELTRUSS: { isTruss: true },
			'PARTIAL B3': {},
			'PARTIAL E1': {},
			PAVILION: { division: 'PAVILION' },
			'PHASE 1': {},
			'PHASE 2': {},
			'PHASE 3': {},
			'PHASE 4': {},
			'PINEVIEW CANOPY': { division: 'CANOPY' },
			'POOL DECK': { division: 'POOL' },
			'POOL SHED': { division: 'POOL' },
			'Pool H': { division: 'POOL' },
			POOLHOUSE: { division: 'POOL' },
			PORCH: { division: 'PORCHESANDBALCONIES' },
			'PORCHES ON SLAB': { division: 'PORCHESANDBALCONIES' },
			'PORTE COCHERE': { division: 'PORTECOCHERE' },
			'Phase A': {},
			'Phase B': {},
			'Phase C': {},
			'Phase 1': {},
			'Phase 1 below podium': { belowPodium: true },
			'Phase 1 - BP': { belowPodium: true },
			'Phase 2': {},
			'Phase 2 below podium': { belowPodium: true },
			'Phase 2 - BP': { belowPodium: true },
			'Phase 3': {},
			'Phase 3 below podium': { belowPodium: true },
			'Phase 3 - BP': { belowPodium: true },
			'Phase II': {},
			'Phase II BP': { belowPodium: true },
			'Phase III': {},
			'Phase III BP': { belowPodium: true },
			'Phase 4': {},
			'Phase 4 - BP': { belowPodium: true },
			'Phase 5': {},
			'Phase 5 - BP': { belowPodium: true },
			'Phase 6': {},
			'Phase 6 - BP': { belowPodium: true },
			Purple: {},
			PINK: {},
			PURPLE: {},
			YELLOW: {},
			'YELLOW CO': {},
			'GREEN CO': {},
			'PINK CO': {},
			'PURPLE CO': {},
			'ORANGE CO': {},
			'RES @ BURLINGTON': {},
			'RETAIL/TRASH CHUTES': { division: 'TRASHCHUTE' },
			'RFI 039': {},
			'ROOF SCREEN BLDG A': { division: 'ROOF', isRoof: true },
			'MAIN ROOF ADD': { division: 'ROOF', isAddition: true },
			Red: {},
			'SEC 1': {},
			'SEC 2': {},
			'SEC 3': {},
			'SEC 4': {},
			'SEC 5': {},
			'SECTION A': {},
			'SECTION A 1': {},
			'SECTION B': {},
			'SECTION B 1': {},
			'SECTION C': {},
			'SECTION C 1': {},
			'STAIR 1': { division: 'STAIRS' },
			'STAIR 2': { division: 'STAIRS' },
			'STAIR 3': { division: 'STAIRS' },
			'STAIR 4': { division: 'STAIRS' },
			'STAIR 5': { division: 'STAIRS' },
			'STAIR 6': { division: 'STAIRS' },
			'STAIR 7': { division: 'STAIRS' },
			'STAIR A': { division: 'STAIRS' },
			'STAIR B': { division: 'STAIRS' },
			'Stair 2 HR': { division: 'STAIRS' },
			'STEEL COLUMNS': {},
			'Sec A': {},
			'Sec B': {},
			'Sec C': {},
			'Sec D': {},
			'Section A': {},
			'Section B': {},
			'Section 4-7': {},
			'Section 8-10': {},
			South: {},
			'Store Fronts': {},
			'TEMP STAIRS': { division: 'STAIRS' },
			'TEMP WIN/DOOR': { division: 'WINDOWSANDDOORS' },
			TH: {},
			'TOOL SHED': { division: 'SHED' },
			'TOOL SHED ZIP': { division: 'SHED' },
			TRELLIS: { division: 'TRELLIS' },
			'TURN 1': {},
			'TURN 2': {},
			'TURN 3': {},
			'TURN 4': {},
			TYVEK: { division: 'BUILDINGWRAP' },
			'TYVEK A ': { division: 'BUILDINGWRAP' },
			'TYVEK A BEL POD': { division: 'BUILDINGWRAP', belowPodium: true },
			'TYVEK B': { division: 'BUILDINGWRAP' },
			'TYVEK BLDG 1': { division: 'BUILDINGWRAP' },
			'TYVEK BLDG 9': { division: 'BUILDINGWRAP' },
			Townhouse: {},
			Townhouses: {},
			'Type 3': {},
			'Type 3 & 5': {},
			'Type 5': {},
			Tyvek: { division: 'BUILDINGWRAP' },
			'UNIT PORCH': { division: 'PORCHESANDBALCONIES' },
			'VAN GARAGE': { division: 'GARAGE' },
			'VAN GARAGE DOORS': { division: 'GARAGE' },
			'VAN GARAGE WIN/DOOR': { division: 'GARAGE' },
			'VAN GARAGE ZIP': { division: 'GARAGE' },
			WEST: {},
			'WEST BLDG': {},
			'WINDOW D': { division: 'WINDOWSANDDOORS' },
			'WINDOW E': { division: 'WINDOWSANDDOORS' },
			'WINDOW F': { division: 'WINDOWSANDDOORS' },
			'WINDOW G': { division: 'WINDOWSANDDOORS' },
			'WINDOW H': { division: 'WINDOWSANDDOORS' },
			'WINDOW J': { division: 'WINDOWSANDDOORS' },
			'WINDOW K': { division: 'WINDOWSANDDOORS' },
			'WINDOW L': { division: 'WINDOWSANDDOORS' },
			'WINDOW M': { division: 'WINDOWSANDDOORS' },
			'WINDOW N': { division: 'WINDOWSANDDOORS' },
			'WINDOW A': { division: 'WINDOWSANDDOORS' },
			'WINDOW B': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR - CORE': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR 5 CAR HC/BIKE': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR A': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR A,B': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR AREA 1': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR AREA 2': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR B': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR B WING': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR BLDG 1': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR BLDG 2': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR BLDG 3': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR BLDG 4': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR BLDG 9': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR C': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR C WING': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR CH': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR D': { division: 'WINDOWSANDDOORS' },
			'WIN/DOOR E': { division: 'WINDOWSANDDOORS' },
			'WIND/DOOR A ABV POD': { division: 'WINDOWSANDDOORS' },
			'WIND/DOOR A BEL POD': { division: 'WINDOWSANDDOORS', belowPodium: true },
			'WIND/DOOR B ': { division: 'WINDOWSANDDOORS' },
			'WIND/DOORS': { division: 'WINDOWSANDDOORS' },
			WINDOW: { division: 'WINDOWSANDDOORS' },
			'WINDOW C': { division: 'WINDOWSANDDOORS' },
			WINDOWS: { division: 'WINDOWSANDDOORS' },
			'WING A FRAME': { isFrame: true },
			'WING B FRAME': { isFrame: true },
			'WING C FRAME': { isFrame: true },
			West: {},
			'West-2': {},
			White: {},
			WWTF: {},
			Yellow: {},
			'ZIP ROOF 5 CAR HC GAR': { division: 'GARAGE', isRoof: true },
			'ZIP ROOF 5 CAR HC/BIKE GAR': { division: 'GARAGE', isRoof: true },
			'ZIP ROOF 6 CAR BIKE GAR': { division: 'GARAGE', isRoof: true },
			'ZIP ROOF 6 CAR GAR': { division: 'GARAGE', isRoof: true },
			'ZIP ROOF BLDG 4': { division: 'ROOF', isRoof: true },
			'ZIP ROOF CLUBHOUSE': { division: 'CLUBHOUSE', isRoof: true },
			'ZIP WALL 5 CAR HC GAR': { division: 'GARAGE', isWall: true },
			'ZIP WALL 5 CAR HC/BIKE GAR': { division: 'GARAGE', isWall: true },
			'ZIP WALL 6 CAR BIKE GAR': { division: 'GARAGE', isWall: true },
			'ZIP WALL 6 CAR GAR': { division: 'GARAGE', isWall: true },
			'ZIP WALL BLDG 4': { isWall: true },
			'ZIP WALL CLUBHOUSE': { division: 'CLUBHOUSE', isWall: true },
			'ZIP WING A': {},
			'ZIP WING B': {},
			'ZIP WING C': {},
			main: {},
			'x FIRE WALLS': { division: 'FIREWALL' },
			'xFIRE WALLS': { division: 'FIREWALL' },
			'xTYVEK 5 CAR HC': { division: 'BUILDINGWRAP' },
			'xTYVEK 5 CAR HC/BIKE': { division: 'BUILDINGWRAP' },
			'xTYVEK 6 CAR': { division: 'BUILDINGWRAP' },
			'xTYVEK 6 CAR BIKE': { division: 'BUILDINGWRAP' },
			'xTYVEK ABV PODIUM': { division: 'BUILDINGWRAP' },
			'xTYVEK BLDG 1': { division: 'BUILDINGWRAP' },
			'xTYVEK BLDG 2': { division: 'BUILDINGWRAP' },
			'xTYVEK BLDG 3': { division: 'BUILDINGWRAP' },
			'xTYVEK BLDG 4': { division: 'BUILDINGWRAP' },
			'xTYVEK CH': { division: 'BUILDINGWRAP' },
			'yEAVES C WING': {},
			'yEAVES B WING': {},
			'yEAVES CORE': {},
			yTYVEK: { division: 'BUILDINGWRAP' },
			'yTYVEK BEL PODIUM': { division: 'BUILDINGWRAP', belowPodium: true },
			'z FIRE WALLS': { division: 'FIREWALL' },
			'z2HR FW BLDG 4': { division: 'FIREWALL' },
			'zFIRE WALLS': { division: 'FIREWALL' },
			'zzEXT TRIM': { division: 'EXTTRIM' },
			'TYVEK A  ': { division: 'BUILDINGWRAP' },
			'VAN GARAGE  ': { division: 'GARAGE' },
			'xASI #2,4 ADDS': { isAddition: true },
			'yEAVES  C WING': {},
		},

		enums: {

			material_list: {
				category: 'material_categories',
				hardware_qty_unit: 'material_units',
			},

			project: {
				region: 'regions',
				building_type: 'building_types',
				building_subtype: 'building_subtypes',
			},

			takeoffs: {
				cost_type: 'cost_type',
				material_qty_1_unit: 'material_units',
				material_qty_2_unit: 'material_units',
				spartan_material_qty_unit: 'material_units',
				labor_productivity_unit: 'labor_units',
			},
		},

		material_booleans: {
			FRT: 'is_fire_treated',
			PT: 'is_pressure_treated',
			RESAWN: 'is_resawn',
			'T&G': 'is_tongue_and_groove',
		},

		foreign_keys: {
			project: [
				{
					key: 'structural_engineer_id',
					identifier: 'name',
				},
				{
					key: 'architect_id',
					identifier: 'name',
				},
				{
					key: 'estimator_id',
					identifier: 'email',
				},
			],
		},

		takeoff: {
			'Job No': 'project_id',
			Building: 'building',
			Level: 'level',
			Division: 'division',
			Description: 'description',
			'Product Description': 'description',
			'Cost Association': 'cost_association',
			'Cost Each': 'cost_each',
			Name: 'name',
			Notes: 'notes',
			'Cost Type': 'cost_type',
			'Waste %': 'waste_percentage',
			'stick prod': 'labor_productivity',
			'panel produc': 'labor_productivity',
			productivity: 'labor_productivity',
			'Productivity Units': 'labor_productivity_unit',
			'prod units': 'labor_productivity_unit',
			'prod units 2': 'labor_productivity_unit',
			'Stick Labor Qty': 'labor_qty',
			'Panel Labor Qty': 'labor_qty',
			UOP: 'spartan_material_qty_unit',
			Amount: 'spartan_material_qty',
			'Measurement Total': 'spartan_material_qty',
			'Material Location': 'division',
			'Unit of Measurement': 'spartan_material_qty_unit',
			'Unit of Measure': 'spartan_material_qty_unit',
			Quantity: 'spartan_quantity',
			'Qty Each': 'spartan_quantity',
			'Quantity Each': 'spartan_quantity',
			'Product Application': 'name',
			'Loose Qty 1 (Stick Job)': 'stick_material_qty_1',
			'Loose Qty 2 (Stick Job)': 'stick_material_qty_2',
			'Loose Qty 1 (Panel Job)': 'panel_material_qty_1',
			'Loose Qty 2 (Panel Job)': 'panel_material_qty_2',
			'Panel Material Qty 1': 'panel_material_qty_1',
			'Panel Material Qty 2': 'panel_material_qty_2',
			'Stick Mat Qty 1': 'stick_material_qty_1',
			'Loose Qty 2 (Total)': 'stick_material_qty_1',
			'Stick Mat Qty 2': 'stick_material_qty_2',
			'St Mat Units 1': 'stick_material_qty_1_unit',
			'St Mat Units 2': 'stick_material_qty_2_unit',
			'panel shop mat units 2': 'panel_material_qty_2_unit',
			'Pa Mat Units 1': 'panel_material_qty_1_unit',
			'Pa Mat Units 2': 'panel_material_qty_2_unit',
			'Panel Mat Qty 1': 'panel_material_qty_1',
			'Panel Mat Qty 2': 'panel_material_qty_2',
			'Material Group': 'material_group',
			'Overall Panel Material Qtys': 'panel_material_qty_1',
		},

		takeoff_fields_with_units: {
			spartan_material_qty: 'spartan_material_qty_unit',
			stick_material_qty_1: 'stick_material_qty_1_unit',
			stick_material_qty_2: 'stick_material_qty_2_unit',
			panel_material_qty_1: 'panel_material_qty_1_unit',
			panel_material_qty_2: 'panel_material_qty_2_unit',
			labor_productivity: 'labor_productivity_unit',
		},

		takeoff_coocur: [
			['spartan_material_qty', 'spartan_material_qty_unit'],
			['stick_material_qty_1', 'stick_material_qty_1_unit'],
			['stick_material_qty_2', 'stick_material_qty_2_unit'],
			['panel_material_qty_1', 'panel_material_qty_1_unit'],
			['panel_material_qty_2', 'panel_material_qty_2_unit'],
			['labor_productivity', 'labor_productivity_unit', 'labor_qty'],
		],

		project: {
			'Job No': 'string_id',
			Name: 'name',
			Estimator: 'estimator_id',
			'Take Off Excel': 'takeoff_url',
			'Entry Complete': 'complete',
			Region: 'region',
			levels: 'levels',
			'Specific Bldg Type': 'building_type',
			'Structural Eng': 'structural_engineer_id',
			'Building type': 'building_type',
			'Building subtype': 'building_subtype',
			Architect: 'architect_id',
			'Unit Count': 'unit_count',
			'Gross SQFT': 'gross_sqft',
			'Porch SQFT': 'porch_sqft',
			'L1 BC Wall Hgt': 'l1_wall_height',
			'GSF L1 Walls': 'l1_wall_gsf',
			'GSF L1 Floors': 'l1_floor_gsf',
			'L2 BC Wall Hgt': 'l2_wall_height',
			'GSF L2 Walls': 'l2_wall_gsf',
			'GSF L2 Floors': 'l2_floor_gsf',
			'L3 BC Wall Hgt': 'l3_wall_height',
			'GSF L3 Walls': 'l3_wall_gsf',
			'GSF L3 Floors': 'l3_floor_gsf',
			'L4 BC Wall Hgt': 'l4_wall_height',
			'GSF L4 Walls': 'l4_wall_gsf',
			'GSF L4 Floors': 'l4_floor_gsf',
			'L5 BC Wall Hgt': 'l5_wall_height',
			'GSF L5 Walls': 'l5_wall_gsf',
			'GSF L5 Floors': 'l5_floor_gsf',
			'Roof GSF': 'roof_gsf',
			'Tall @ Parallel': 'tall_at_parallel',
			'Labor Incld?': 'labor_included',
			'Walls Incld?': 'walls_included',
			'Panels?': 'is_panel_type',
			'Floors Incld?': 'floors_included',
			'Roof Incld?': 'roof_included',
			'Hardware Incld?': 'hardware_included',
			'Win & Doors Incld?': 'windows_doors_included',
			'Building Wrap Incld?': 'building_wrap_included',
			'Below Podium Wall Height': 'bp_wall_height',
			'GSF Walls below Podium': 'bp_wall_gsf',
			'Aggregate Project': 'is_aggregate',
		},

		material_list_cols: {
			Decking: 'name',
			'Decking depth txt': 'thickness',
			'Decking length': 'length',
			'Decking material': 'material_type_id',
			'Decking width': 'width',
			'Eng Lumber': 'name',
			'Eng Lumber depth': 'thickness',
			'Eng Lumber length': 'length',
			'Eng Lumber material': 'material_type_id',
			'Eng Lumber width': 'width',
			Hardware: 'name',
			'Hardware Length txt': 'width',
			'Hardware Name': 'material_type_id',
			'Hardware qty': 'hardware_qty',
			'Hardware units': 'hardware_qty_unit',
			'Hardware Width Txt': 'width',
			'I Joist depth': 'thickness',
			'I Joist Series': 'material_type_id',
			'I Joists': 'name',
			Labor: 'name',
			'labor productivity': 'stick_productivity',
			Lumber: 'name',
			'Lumber depth': 'thickness',
			'Lumber length': 'length',
			'Lumber material': 'material_type_id',
			'Lumber width': 'width',
			'panel prod': 'panel_productivity',
			'prod units': 'productivity_unit',
			Sheathing: 'name',
			'Sheathing depth txt': 'thickness',
			'Sheathing length': 'length',
			'Sheathing material': 'material_type_id',
			'Sheathing width': 'width',
			spacing: 'spacing',
			'stick prod': 'stick_productivity',
			'stud pack #': 'stud_pack_num',
			Trim: 'name',
			'Trim Length': 'length',
			'Trim Material': 'material_type_id',
			'Trim Thickness': 'thickness',
			'Trim width txt': 'width',
			units: 'productivity_unit',
			'wall height': 'wall_height',
		},

		labor_values: {
			Framing: {
				stud_pack_num: {
					undefined: 1,
					null: 1,
					'dble stud': 2,
					'trpl stud': 3,
					'quad stud': 4,
				},
			},
		},

		material_values: {
			gauge: {
				1: 9 / 32,
				2: 17 / 64,
				3: 1 / 4,
				4: 15 / 64,
				5: 7 / 32,
				6: 13 / 64,
				7: 3 / 16,
				8: 11 / 64,
				9: 5 / 32,
				10: 9 / 64,
				20: 3 / 80,
				25: 7 / 320,
			},
		},

	},

	takeoff_columns: {
		standard: ['# of Bldgs', 'Building', 'Cost Type', 'Cost Association', 'Cost Each', 'Description', 'Detail', 'Division', 'Level',
			'Loose Qty 1 (Stick Job)', 'Loose Qty 2 (Stick Job)', 'Loose Qty 1 (Panel Job)', 'Loose Qty 2 (Panel Job)', 'Material Group',
			'Material Sub Group', 'Name', 'Notes', 'Overall Panel Material Qtys', 'Overall Panel Cost Total', 'Pa Mat Units 1',
			'Pa Mat Units 2', 'panel produc', 'panel shop prod', 'panel shop mat units 2', 'Panel Labor Qty', 'Panel Cost Total',
			'Panel Mat Qty 1', 'Panel Material Qty 1', 'Panel Material Qty 2', 'Panel Labor Totals', 'Panel Mat Qty 2', 'Panel Shop Labor Qty',
			'Panel Shop Mat Cost', 'Panel Shop Mat Qty 1', 'Panel Shop Mat Qty 2', 'Panel Shop Cost Total', 'Panel Shot Mat Cost',
			'prod units', 'prod units 2', 'productivity', 'Productivity Units', 'St Mat Units 1', 'St Mat Units 2', 'stick prod',
			'Stick Labor Qty', 'Stick Cost Total', 'Stick Mat Qty 1', 'Stick Mat Qty 2', 'Takeoff', 'Takeoff Units', 'Type', 'Units',
			'Units EA', 'Units BF/LF', 'Waste %'],
		spartan: ['Building', 'Level', 'Material Location', 'Material', 'Grade', 'Qty Each', 'Product Description', 'Product Application', 'Unit of Measure', 'Measurement Total',
			'Price', 'Per', 'Cost Total', 'Notes'],
	},
};
