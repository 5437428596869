function supabaseTo(supabaseFn) {
	return new Promise((resolve) => supabaseFn
		.then((supabaseResponse) => {
			if (supabaseResponse.error && (!Array.isArray(supabaseResponse.error) || supabaseResponse.error.length)) {
				return resolve([supabaseResponse.error]);
			}
			if (supabaseResponse.status === 404) {
				return resolve([new Error('Item not found')]);
			}
			return resolve([null, supabaseResponse]);
		}).catch((err) => resolve([err])));
}

export default supabaseTo;
