export default {
	namespaced: true,
	state: {
		project: {},
		projectFormLoading: false,
		metadata: {
			address: '',
			type: '',
			geo: {},
			coords: {},
		},
	},
	mutations: {
		setProjectFormLoading(state, loading) {
			state.projectFormLoading = loading;
		},
		setProject(state, project) {
			state.project = project;
		},
		setProjectMetadata(state, project) {
			const address = `${project.address}, ${project.city}, ${project.state}.`;
			state.metadata.address = address;
			state.metadata.geo = {
				address: project.address,
				city: project.city,
				state: project.state,
			};
			state.metadata.coords = {
				lat: project.latitude,
				lng: project.longitude,
			};
		},
		setProjectType(state, type) {
			const { _, types } = this.$cradle;
			state.metadata.type = _.get(types, ['projectTypes', type, 'name']);
		},
	},
	actions: {
		async setProject({ commit }, projectId) {
			commit('setProjectFormLoading', true);
			const { projectRepo, to, logDnaFactory: logger } = this.$cradle;

			const [err, project] = await to(projectRepo.getProjectById(projectId));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setProject', project);
			commit('setProjectMetadata', project);
			commit('setProjectType', project.type);

			commit('setProjectFormLoading', false);
		},
		async setArchivedProject({ commit }, projectId) {
			commit('setProjectFormLoading', true);
			const { projectRepo, to, logDnaFactory: logger } = this.$cradle;

			const [err, project] = await to(projectRepo.getArchivedProjectById(projectId));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setProject', project);
			commit('setProjectMetadata', project);
			commit('setProjectType', project.type);

			commit('setProjectFormLoading', false);
		},
	},
};
