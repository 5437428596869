export default {
	namespaced: true,
	state: {
		display: false,
		display_inbox: false,
		display_revisions: false,
	},
	mutations: {
		setDisplay(state, display) {
			state.display = display;
		},
		setInboxDisplay(state, display) {
			state.display_inbox = display;
		},
		setRevisionsDisplay(state, display) {
			state.display_revisions = display;
		},
	},
};
