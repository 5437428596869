export default class RGMRepo {
	constructor({ supaTo, supabaseClientFactory, _ }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getRGMById(id) {
		if (!id) {
			throw new Error('id is required for get RGM by id');
		}
		const [error, data] = await this.supaTo(
			this.supabase
				.from('rgm')
				.select('*')
				.match({ id }),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data[0]', null);
		}
	}

	async getRGMS() {
		const [error, data] = await this.supaTo(
			this.supabase
				.from('rgm')
				.select('*'),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data', []);
		}
	}
}
