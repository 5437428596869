export default {
	namespaced: true,
	state: {
		trainingData: [],
		issues: {},
		loaded: false,
		query: {
			totalRows: 0,
		},
	},
	mutations: {
		setTrainingData(state, trainingData) {
			state.trainingData = trainingData;
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
		},
		setIssues(state, { csFileId, issues }) {
			state.issues[csFileId] = issues;
		},
	},
	actions: {
		async getTrainingData({ commit, state }, { bidId, historicalProjectId, csSheetId }) {
			if (state.loaded) {
				return;
			}
			const { to, trainingDataRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(trainingDataRepo.getTrainingData(bidId, historicalProjectId, csSheetId));
			if (err) {
				logger.error(err.message);
				commit('setTrainingData', []);
				throw err;
			} else {
				commit('setTrainingData', data);
				commit('setLoadingStatus', true);
			}
		},
		async createAssociationLogEntry({ commit, rootState }, { bidId, historicalProjectId, csSheetId, csSubVsMHID, type }) {
			const { to, trainingDataAssociationLogRepo, logDnaFactory: logger } = this.$cradle;

			const [err, data] = await to(trainingDataAssociationLogRepo.create({
				bid_id: bidId,
				historical_project_id: historicalProjectId,
				cs_sheet_id: csSheetId,
				cs_sub_vs_mh_id: csSubVsMHID,
				user_id: rootState.authentication.user.id,
				type,
			}));

			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('setTrainingData', data);
				commit('setLoadingStatus', true);
			}
		},
		async getCSSheetIssues({ commit, state }, csFileId) {
			if (!csFileId || state.issues[csFileId]) {
				return;
			}
			const { to, csStatusRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(csStatusRepo.getIssuesByCSFileId(csFileId));

			const issues = data.map(({ issues: i }) => i).reduce((acc, curr) => [...acc, ...curr], []);

			if (err) {
				logger.error(err.message);
				throw err;
			} else {
				commit('setIssues', { csFileId, issues });
			}
		},
		async associateData(_, { bidId, sheetId, subVsMHID }) {
			const { to, csSheetRepo, csSubVsMHRepo, logDnaFactory: logger } = this.$cradle;

			if (sheetId) {
				const [err] = await to(csSheetRepo.associateCSSheet(sheetId, bidId));
				if (err) {
					logger.error(err.message);
					throw err;
				}
			}
			if (subVsMHID) {
				const [err2] = await to(csSubVsMHRepo.associateSubVsMH(subVsMHID, bidId));
				if (err2) {
					logger.error(err2.message);
					throw err2;
				}
			}
		},
	},
};
