export default {
	namespaced: true,
	state: {
		tables: {
			bidLog: {
				showSettings: false,
				showFilters: false,
				columns: [
					// Proper order
					{ name: 'Target Win', key: 'target_win', sortable: true, display: true },
					{ name: 'Name', key: 'name', sortable: true, display: true },
					{ name: 'Week', key: 'custom1', sortable: true, display: true },
					{ name: 'City', key: 'city', sortable: true, display: true },
					{ name: 'State', key: 'state', sortable: true, display: true },
					{ name: 'Description', key: 'custom6', sortable: true, display: true },
					{ name: 'Bid Due', key: 'custom2', sortable: true, display: true },
					{ name: 'Estimator', key: 'custom3', sortable: true, display: true },
					{ name: 'Frame Start', key: 'frame_start', sortable: true, display: true },
					{ name: 'Frame End', key: 'frame_end', sortable: true, display: true },
					{ name: 'Region', key: 'region', sortable: true, display: true },
					// Unordered
					{ name: 'Rev No.', key: 'revision_num', sortable: true, display: true },
					{ name: 'Standard', key: 'non_standard', sortable: true, display: true },
					{ name: 'SE', key: 'structural_engineer', sortable: true, display: true },
					{ name: 'Architect', key: 'architect', sortable: true, display: true },
					{ name: 'Address', key: 'address', sortable: true, display: true },
					{ name: 'GSF', key: 'square_footage', sortable: true, display: true },
					{ name: 'Porch GSF', key: 'porch_square_footage', sortable: true, display: true },
					{ name: 'Buildings', key: 'building_count', sortable: true, display: true },

					{ name: 'Activities', key: 'custom4', sortable: true, display: true },
					{ name: 'Comments', key: 'custom5', sortable: true, display: true },
				],
				initialized: false,
			},
		},
	},
	mutations: {
		toggleBidLogTableSettings(state) {
			state.tables.bidLog.showSettings = !state.tables.bidLog.showSettings;

			if (!state.tables.bidLog.showSettings) {
				localStorage?.setItem?.('bidLogColumns', JSON.stringify(state.tables.bidLog.columns));
			}
		},

		toggleBidLogTableFilters(state) {
			state.tables.bidLog.showFilters = !state.tables.bidLog.showFilters;
		},

		initializeBidLogSettings(state) {
			if (!state.tables.bidLog.initialied && localStorage?.getItem?.('bidLogColumns')) {
				state.tables.bidLog.initialized = true;
				const columns = localStorage.getItem('bidLogColumns');

				state.tables.bidLog.columns = JSON.parse(columns);
			}
		},
	},
	actions: {},
};
