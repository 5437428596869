export default class EmailRepo {
	constructor({ sbTo, supabaseClientFactory, _, libAsync, to, hashService }) {
		this.sbTo = sbTo;
		this.to = to;
		this.supabase = supabaseClientFactory;
		this._ = _;
		this.eachSeries = libAsync.eachSeries;
		this.hash = hashService;
	}

	async getInboxMessageList(id, type) {
		if (type !== 'PROJECT') {
			throw new Error('Invalid inbox message type');
		}

		// @TODO - this will need to be re-written when additional inbound types are added
		const [err, data] = await this.sbTo(
			this.supabase.from('email').select(`id,subject,sent_date,
				payload->BccFull,
				payload->CcFull,
				payload->ToFull,
				payload->FromFull,
				email_project!inner(project_id)
			`)
				.eq('email_project.project_id', id),
		);
		if (err) {
			throw err;
		}
		return data;
	}

	async getAllEmails() {
		const [err, data] = await this.sbTo(
			this.supabase.from('email').select('*'),
		);
		if (err) {
			throw err;
		}
		return data;
	}

	async getEmailById(id) {
		const [err, data] = await this.sbTo(
			this.supabase.from('email').select().match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return data?.[0];
		}
	}

	async getEmailByProjectId(id) {
		const [err, data] = await this.sbTo(
			this.supabase.from('email').select().match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return data?.[0];
		}
	}

	async createEmailForProjects(postMarkEmail, projectIds) {
		const [errH, hash] = await this.to(this.hash.createHash(JSON.stringify(postMarkEmail)));
		if (errH) {
			throw new Error(`Could not create signature: ${errH.message}`);
		}

		const [err, data] = await this.sbTo(
			this.supabase.from('email').insert({
				subject: postMarkEmail.Subject,
				payload: postMarkEmail,
				sent_date: postMarkEmail.Date,
				message_id: postMarkEmail.MessageID,
				signature: hash,
			}),
		);

		if (err) {
			throw err;
		}

		const email = this._.get(data, '[0]', null);
		if (email === null) {
			throw new Error('Could not insert into email');
		}

		// Attach to the projects
		const [err1] = await this.to(this.eachSeries(projectIds, async (o) => {
			const [errP] = await this.sbTo(this.supabase.from('email_project').insert({
				email_id: email.id,
				project_id: o,
			}));
			if (errP) {
				throw errP;
			}
		}));

		if (err1) {
			throw err1;
		}

		return email.id;
	}
}
