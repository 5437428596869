const rawConfig = {
	paths: {
		root: './',
		src: 'src/',
		commands: 'src/commands/',
		factory: 'src/factory/',
		service: 'src/service/',
		ui: 'src/ui/',
		testSrc: 'test/src/',
		testCommands: 'test/src/commands/',
		testServices: 'test/src/services/',
		testUI: 'test/ui/',
		bin: 'test/',
		db: 'db/',
		preSyncMigrations: 'db/migrations/presync',
		postSyncMigrations: 'db/migrations/postsync',
		models: 'db/models/',
		seeders: 'db/seeders/',
		backups: 'db/backups/',
		migration_data: 'db/migrations/data/',
		nockFixtures: 'test/setup/nock-fixtures/',
		model: 'node_modules/@colt-builders-corp/estimating-tool-ml/model/',
	},

	prediction: {
		total_material_cost: {
			version: '0.1.0-alpha.3',
		},
		total_material_cost_stick_jobs: {
			version: '1.0',
		},
		total_material_cost_panel_jobs: {
			version: '1.0',
		},
		total_labor_cost_cheap_regions: {
			version: '1.1',
		},
		total_labor_cost_medium_regions: {
			version: '1.1',
		},
		total_labor_cost_expensive_regions: {
			version: '1.1',
		},
	},
	logdna: 'befb407c599de92eaa142e40679793c5',
	postmark: '150a1fcb-0444-4b4c-8ba4-3e5f1595fb7c',
	prefect: {
		api_key: 'pcs_pBlL6nQguN1729toXRzmKWd9IcSEzK2Vszc3',
		flows: {
			'project-import': {
				production: {
					version_group_id: '84b9035c-b9b9-4c14-bef2-17b49f3de397',
					name: 'project-import_production',
				},
				staging: {
					version_group_id: 'dd03cd87-f061-4bde-b338-9ba41924a09e',
					name: 'project-import_staging',
				},
				branch1: {
					version_group_id: '986b7be5-b984-41bc-9d58-6180310eeea8',
					name: 'project-import_branch1',
				},
				branch2: {
					version_group_id: '2d00af9d-9b54-4ce7-8491-0dddb21f9a42',
					name: 'project-import_branch2',
				},
				branch3: {
					version_group_id: 'e802eaae-b357-48fe-98c6-245b2800caa0',
					name: 'project-import_branch3',
				},
				branch4: {
					version_group_id: '8176e93a-f099-454b-9c01-fe40d00b7f54',
					name: 'project-import_branch4',
				},
				branch5: {
					version_group_id: 'b1d5d1e7-e0c1-424e-b64e-cee6c626b96a',
					name: 'project-import_branch5',
				},
			},
		},
	},

	smartsheet: {
		accessToken: '2RjvcRNCeAnFXFFdsez0iYfPBQUNdkpWFop6a',
		jobsSheetId: '1716510608648068',
		bidsSheetId: '478910195492740',
		archivedBids2022SheetId: '7814153013356420',
		archivedBids2021SheetId: '5365772655585156',
		archivedBids2020SheetId: '7130622733903748',
		archivedBids2019SheetId: '2807398780954500',
		archivedBids2018SheetId: '6185235940435844',
		archivedBids2014to2017SheetId: '7102632650139524',
	},

	fred: {
		apiKey: '453d5fb1075659977421c1f548e56c6c',
	},
};
export default rawConfig;
export const { prediction, supaConfig: supabase } = rawConfig;
