import tableService from './table-service.mjs';

export default {
	mutations: {
		...tableService.mutations,
		setBids(state, bids) {
			state.bids = bids;
		},
		addMoreBids(state, bids) {
			state.bids = state.bids.concat(bids);
		},
		insertBid(state, newBid) {
			state.bids = [...state.bids, newBid];
		},
		setStatus(state, status) {
			state.status = status;
		},
		setUpdatedBid(state, revisedBid) {
			const index = state.bids.findIndex((b) => b.id === revisedBid.id);
			if (index > -1) {
				state.bids.splice(index, 1, revisedBid);
			}
		},
		setActivities(state, activities) {
			state.activities = activities;
		},
		setComments(state, comments) {
			state.comments = comments;
		},
	},
	actions: {
		...tableService.actions,
		async initialize({ commit, dispatch, rootState }) {
			commit('initializeColumns');
			// Make sure these are intialized before we try to fetch them...
			await dispatch('estimator/getEstimators', null, { root: true });
			await dispatch('architect/getArchitects', null, { root: true });
			await dispatch('engineer/getEngineers', null, { root: true });
			await dispatch('region/getRegions', null, { root: true });
			await dispatch('generalContractor/getGeneralContractors', null, { root: true });

			commit(
				'setOptions',
				{ items: rootState.region.regions.map(({ name }) => name), target: 'Region' },
			);
			commit(
				'setOptions',
				{ items: rootState.types.states.map(({ abbreviation }) => abbreviation), target: 'State' },
			);
			commit(
				'setOptions',
				{ items: rootState.estimator.estimators.map(({ name }) => name), target: 'Estimator' },
			);
			commit(
				'setOptions',
				{ items: rootState.architect.architects.map(({ name }) => name), target: 'Architect' },
			);
			commit(
				'setOptions',
				{ items: rootState.types.bidStatus.map(({ name }) => name), target: 'Bid Status' },
			);
			commit(
				'setOptions',
				{ items: rootState.engineer.engineers.map(({ name }) => name), target: 'Structural Engineer' },
			);
			commit(
				'setOptions',
				{ items: rootState.types.bidTypes.map(({ name }) => name), target: 'Bid Type' },
			);
			commit('setInactiveColumns');
		},
		async setSort({ commit, dispatch }, sorting) {
			commit('setSort', sorting);
			await dispatch('getAllBids');
		},
	},
};
