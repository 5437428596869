<template>
<div
	v-show="show"
	id="full-screen-modal"
	class="modal"
>
	<div class="modal-content">
		<div class="modal-header">
			<slot name="header" />
			<button
				type="button"
				class="btn btn-xs btn-icon btn-ghost-secondary"
				data-dismiss="modal"
				aria-label="Close"
				@click="closeModal"
			>
				<i class="tio-clear tio-lg" />
			</button>
		</div>
		<div class="modal-body">
			<slot name="body" />
		</div>
		<div class="modal-footer">
			<slot name="footer" />
		</div>
	</div>
</div>
</template>

<script>

export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		closeModal() {
			this.$emit('update:show', false);
		},
	},
};
</script>

<style scoped>
.modal {
	display: block;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 0px;
}

.modal-content {
	background-color: #fefefe;
	margin: 0;
	padding: 0px;
	border: 0px;
	width: 100%;
	min-height: 100%;
	border-radius: 0px;
}

.modal-body {
	max-height: calc(100vh - 130px);
    overflow: scroll;
}
.modal-header {
	border-bottom: 1px solid #ccc;
	border-radius: 0px;
}

</style>
