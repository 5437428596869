<template>
<b-input-group
	size="sm"
	class="email-widget"
>
	<b-input-group-prepend class="icon icon-primary icon-sm">
		<i class="tio-new-email" />
	</b-input-group-prepend>
	<b-form-input
		ref="clone"
		:value="email"
		readonly
		@focus="$event.target.select()"
	/>
	<b-input-group-append>
		<b-button
			variant="info"
			@click="copyEmail"
		>
			Copy
		</b-button>
	</b-input-group-append>
</b-input-group>
</template>
<script>

export default {
	props: {
		prefix: {
			type: String,
			default: '',
		},
	},
	computed: {
		email() {
			return `${this.prefix}${process.env.POSTMARK_INBOUND}`;
		},
	},
	methods: {
		copyEmail() {
			this.$refs.clone.focus();
			document.execCommand('copy');
		},
	},
};
</script>
<style scoped>
        .email-widget {
                width: 500px;
                margin-right: 30px;

        }

        .email-widget .icon {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                height: 35.5px;
        }

        .email-widget .form-control {
                border-radius: 0;
        }
</style>
