export default class TakeOffRowRepo {
	constructor({ _, supaTo, supabaseClientFactory }) {
		this._ = _;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
	}

	async getAllTakeOffRows() {
		const [err, response] = await this.supaTo(this.supabase.from('takeoff_row').select());
		if (err) {
			throw err;
		} else {
			return response.data;
		}
	}

	async getTakeoffRowsByProjectId(projectId) {
		const [error, data] = await this.supaTo(this.supabase
			.from('historical_project')
			.select(`
				takeoff_row (
					id,
					index,
					format,
					building,
					level,
					division,
					spartan_material_qty,
					spartan_material_qty_unit,
					spartan_quantity,
					stick_material_qty_1,
					stick_material_qty_1_unit,
					stick_material_qty_2,
					stick_material_qty_2_unit,
					panel_material_qty_1,
					panel_material_qty_1_unit,
					panel_material_qty_2,
					panel_material_qty_2_unit,
					labor_productivity,
					labor_productivity_unit,
					labor_qty,
					description,
					notes,
					cost_association,
					cost_each,
					name,
					cost_type,
					material_group,
					waste_percentage
				)
			`)
			.eq('id', projectId));
		if (error) {
			throw error;
		}
		return this._.get(data, 'data[0].takeoff_row');
	}
}
