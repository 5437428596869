import tableService from '../../services/table-service.mjs';

export default {
	namespaced: true,
	state: {
		...tableService.state,
		CSSheets: [],
		query: {
			filters: {},
			page: 1,
			limit: 50,
			totalRows: 0,
			from: 0,
			to: 50,
			sort: {
				by: '',
				desc: false,
			},
		},
		availableFilters: [],
		columns: [],
		defaultColumns: [
			{
				key: 'name',
				name: 'File Name',
				filterName: 'name',
				sortable: true,
				display: true,
				selectionType: String,
			},
			{
				key: 'tab_name',
				name: 'Tab Name',
				filterName: 'tab_name',
				sortable: true,
				display: true,
				selectionType: String,
			},
			{
				key: 'custom2',
				name: 'Selected',
				filterName: 'bid_id',
				sortable: true,
				display: true,
				selectionType: String,
			},
			{
				key: 'custom3',
				name: 'Selected Sub Vs MH',
				filterName: '',
				sortable: true,
				display: true,
				selectionType: String,
			},
			{
				key: 'file_version',
				name: 'File Version',
				filterName: 'file_version',
				sortable: true,
				display: true,
				selectionType: String,
			},
			{
				key: 'status',
				name: 'Status',
				filterName: 'status',
				sortable: true,
				display: true,
				selectionType: String,
			},
			{
				key: 'grand_total_cost',
				name: 'Grand Total Cost',
				filterName: 'grand_total_cost',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'total_labor_cost',
				name: 'Total Labor Cost',
				filterName: 'total_labor_cost',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'total_material_cost',
				name: 'Total Material Cost',
				filterName: 'total_material_cost',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'square_footage',
				name: 'Square Footage',
				filterName: 'square_footage',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'unit_count',
				name: 'Unit Count',
				filterName: 'unit_count',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'porch_square_footage',
				name: 'Porch Square Footage',
				filterName: 'porch_square_footage',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'panel_job',
				name: 'Panel Job',
				filterName: 'panel_job',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'framing_start_date',
				name: 'Framing Start Date',
				filterName: 'framing_start_date',
				sortable: true,
				display: true,
				selectionType: Date,
			},
			{
				key: 'framing_end_date',
				name: 'Framing End Date',
				filterName: 'framing_end_date',
				sortable: true,
				display: true,
				selectionType: Date,
			},
			{
				key: 'date',
				name: 'Date',
				filterName: 'date',
				sortable: true,
				display: true,
				selectionType: Date,
			},
			{
				key: 'chosen_by_estimator',
				name: 'Chosen by Estimator',
				filterName: 'chosen_by_estimator',
				sortable: true,
				display: true,
				selectionType: Boolean,
			},
			{
				key: 'total_labor_hours',
				name: 'Total Labor Hours',
				filterName: 'total_labor_hours',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'total_sqft_hours',
				name: 'Total SQFT Hours',
				filterName: 'total_sqft_hours',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'total_mn_hr_hours',
				name: 'Total MN HR Hours',
				filterName: 'total_mn_hr_hours',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'total_sub_mn_hr_hours',
				name: 'Total Sub MN HR Hours',
				filterName: 'total_sub_mn_hr_hours',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'total_sqft_cost',
				name: 'Total SQFT Cost',
				filterName: 'total_sqft_cost',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'sqft_rate',
				name: 'SQFT Rate',
				filterName: 'sqft_rate',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'mn_hr_rate',
				name: 'MN HR Rate',
				filterName: 'mn_hr_rate',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'sub_mn_hr_rate',
				name: 'Sub MN HR Rate',
				filterName: 'sub_mn_hr_rate',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'sqft_per_week',
				name: 'SQFT Per Week',
				filterName: 'sqft_per_week',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'framing_duration',
				name: 'Framing Duration',
				filterName: 'framing_duration',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'job_duration',
				name: 'Job Duration',
				filterName: 'job_duration',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'res_units',
				name: 'Res Units',
				filterName: 'res_units',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'public_units',
				name: 'Public Units',
				filterName: 'public_units',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'total_man_hours',
				name: 'Total Man Hours',
				filterName: 'total_man_hours',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'hours_per_sqft',
				name: 'Hours Per SQFT',
				filterName: 'hours_per_sqft',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'labor_hours_man_hours_match',
				name: 'Labor Hours Man Hours Match',
				filterName: 'labor_hours_man_hours_match',
				sortable: true,
				display: true,
				selectionType: Boolean,
			},
			{
				key: 'sqft_rate_accuracy_check',
				name: 'SQFT Rate Accuracy Check',
				filterName: 'sqft_rate_accuracy_check',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'mn_hr_rate_accuracy_check',
				name: 'MN HR Rate Accuracy Check',
				filterName: 'mn_hr_rate_accuracy_check',
				sortable: true,
				display: true,
				selectionType: Number,
			},
			{
				key: 'sub_mn_hr_rate_accuracy_check',
				name: 'Sub MN HR Rate Accuracy Check',
				filterName: 'sub_mn_hr_rate_accuracy_check',
				sortable: true,
				display: true,
				selectionType: Number,
			},
		],
	},
	mutations: {
		...tableService.mutations,
		setCSSheets(state, CSSheets) {
			state.CSSheets = CSSheets;
		},
	},
	actions: {
		async getCSSheets({ commit, state }) {
			const { _, to, bidCSTakeOffRepo, csSheetRepo, logDnaFactory: logger } = this.$cradle;
			const query = _.cloneDeep(state.query);
			const [err, data] = await to(bidCSTakeOffRepo.getCSSheetsByParams(query));

			if (err) {
				logger.error(err.message);
				commit('setCSSheets', []);
			} else {
				commit('setCSSheets', data);
			}

			const [countErr, sheets] = await to(csSheetRepo.getAllCSSheets());
			if (countErr) {
				logger.error(countErr.message);
			} else {
				commit('setTotalRows', sheets.length);
			}
		},
		async setBidId({ dispatch }, { CSSheetId, bidId }) {
			const { to, csSheetRepo, logDnaFactory: logger } = this.$cradle;

			const [errClear] = await to(csSheetRepo.clearBidId(bidId));
			if (errClear && errClear.message !== 'Item not found') {
				logger.error(errClear.message);
				throw errClear;
			}

			const [err] = await to(csSheetRepo.setBidId(CSSheetId, bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			const [associationLogErr] = await to(dispatch('trainingData/createAssociationLogEntry', {
				bidId,
				type: 'cs_sheet',
				csSheetId: CSSheetId,
			}, { root: true }));

			if (associationLogErr) {
				logger.error(associationLogErr.message);
				throw associationLogErr;
			}

			dispatch('getCSSheets');
		},
	},
};
