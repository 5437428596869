/* eslint-disable import/no-extraneous-dependencies */
import Ajv from 'ajv';
import addFormats from 'ajv-formats';

const emailSchema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	type: 'object',
	properties: {
		Email: { type: 'string', format: 'email' },
		Name: { type: 'string' },
		MailboxHash: { type: 'string' },
	},
	required: ['Email', 'Name', 'MailboxHash'],
	additionalProperties: false,
};

const schemaInbound = {
	$id: '#/definitions/Inbound',
	$schema: 'http://json-schema.org/draft-07/schema#',
	type: 'object',
	properties: {
		FromName: { type: 'string' },
		From: { type: 'string' },
		To: { type: 'string' },
		Bcc: { type: 'string' },
		CcFull: { type: 'array', items: emailSchema },
		BccFull: { type: 'array', items: emailSchema },
		ToFull: { type: 'array', items: emailSchema },
	},
	required: ['FromName', 'From', 'To', 'CcFull', 'BccFull', 'ToFull'],
	additionalProperties: true,
};

const schemaCreateXlsx = {
	$id: '#/definitions/CreateXlsx',
	$schema: 'http://json-schema.org/draft-07/schema#',
	type: 'object',
	properties: {
		to: { type: 'string', format: 'email' },
		name: { type: 'string' },
		params: { type: 'object' },
		columns: { type: 'array' },
		table: { type: 'string' },
	},
	required: ['to', 'name', 'params', 'columns', 'table'],
	additionalProperties: false,
};

const whiteboardSheet = {
	$id: '#/definitions/WhiteboardSheet',
	$schema: 'http://json-schema.org/draft-07/schema#',
	type: 'object',
	properties: {
		effective_date: { type: 'string', format: 'date-time' },
		cols: { type: 'array' },
		data: { type: 'array' },
	},
	required: ['effective_date', 'data', 'cols'],
	additionalProperties: false,
};

const smartsheetRowToBid = {
	$id: '#/definitions/SmartsheetRowToBid',
	$schema: 'http://json-schema.org/draft-07/schema#',
	type: 'object',
	properties: {
		/*
		framing_start_date: { type: 'string', format: 'date-time' },
		framing_end_date: { type: 'string', format: 'date-time' },
		bid_due: { type: 'string', format: 'date-time' },
		bid_done: { type: 'string', format: 'date-time' },
		est_start: { type: 'string', format: 'date-time' },
		award_date: { type: 'string', format: 'date-time' },
		bid_id: { type: 'string', format: 'uuid' },
// ehhh?
		sheetId: { type: 'string' },
		sheetName: { type: 'string' },
		rowId: { type: 'string' },
//
		estimator: { type: 'string' },
		// @TODO - validate this with an enum
		region: { type: 'enum', enum: [] },
		status: { type: 'enum', enum: [] },
		archived: { type: 'boolean' },
		name: { type: 'string' },
		bid_no: { type: 'string' },
		total: { type: 'string' },
		sq_ft: { type: 'string' },
		state: { type: 'enum', enum: [] },
		unit: { type: 'string' },
		city: { type: 'string' },
		project_description: { type: 'string' },
		target_win: { type: 'boolean' },
		bid_type: { type: 'enum', enum: [] },
		// @TODO - this should be json. How to validate?
		diff: { type: 'string' },
		project_notes: { type: 'string' },

		building_type: { type: 'enum', enum: [] },
		suppliers: { type: 'string' },
			// name
			// rfq_sent_at
			// rfq_response_at
			// category

		// Additional Fields
		// new or rebid
		// AoR
		// EoR
		// scope
		// construction type
		// est hours
		// actual hours
		// est. finish
		// est. start
		// RFQ recived
		// ITB recived
		// owner
		// grade
		// Current Bid - same as estimated_total?
*/
	},
	required: ['effective_date', 'data', 'cols'],
	additionalProperties: false,
};

const ajv = new Ajv({ schemas: [schemaInbound, schemaCreateXlsx, whiteboardSheet, smartsheetRowToBid], code: { source: true, esm: true } });
addFormats(ajv);

export default ajv;
