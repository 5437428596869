export default class FeedbackRepo {
	constructor({ to, supabaseClientFactory, _ }) {
		this.to = to;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async createFeedback(feedback) {
		const [err, res] = await this.to(this.supabase.from('feedback').insert(feedback));
		if (err) {
			throw err;
		}
		return res;
	}
}
