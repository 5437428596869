<template>
<div>
	<div
		v-if="display"
		id="splittedContentMini"
		class="hs-unfold-content
		splitted-content-mini
		splitted-content-mini-right
		splitted-content-bordered
		h-100 py-2 hs-unfold-hidden
		hs-unfold-content-initialized
		hs-unfold-css-animation animated"
		data-hs-target-height="872"
		data-hs-unfold-content=""
		data-hs-unfold-content-animation-in="fadeInRight"
		data-hs-unfold-content-animation-out="fadeOutRight"
		style="animation-duration: 300ms;"
	>
		<ul class="nav nav-compact-icon nav-compact-icon-circle justify-content-center">
			<li
				v-if="display_inbox"
				id="inbox-toggle"
				class="nav-item"
				tabindex="0"
				@click="toggleInboxDisplay"
			>
				<a
					class="nav-icon right-nav-icon"
					href="javascript:void(0);"
				>
					<span class="icon icon-primary">
						<i class="tio-new-email" />
					</span>
					<span
						v-if="messages.length"
						class="badge badge-light badge-pill ml-1"
					>{{ messages.length }}</span>
				</a>
			</li>
			<li
				id="comments-toggle"
				class="nav-item"
				tabindex="0"
				@click="toggleCommentDisplay"
			>
				<a
					class="nav-icon right-nav-icon"
					href="javascript:void(0);"
				>
					<span class="icon icon-primary">
						<i class="tio-comment-vs-outlined" />
					</span>
					<span
						v-if="comments.length"
						class="badge badge-light badge-pill ml-1"
					>{{ comments.length }}</span>
				</a>
			</li>
			<li
				id="activities-toggle"
				class="nav-item"
				@click="toggleActivitiesDisplay"
			>
				<a
					class="nav-icon right-nav-icon"
					href="javascript:void(0);"
				>
					<span class="icon icon-primary">
						<i class="tio-notifications-on" />
					</span>
					<span
						v-if="activities.length"
						class="badge badge-light badge-pill ml-1"
					>{{ activities.length }}</span>
				</a>
			</li>
			<li
				v-if="display_revisions"
				id="revisions-toggle"
				class="nav-item"
				@click="toggleRevisionsDisplay"
			>
				<a
					class="nav-icon right-nav-icon"
					href="javascript:void(0);"
				>
					<span class="icon icon-primary">
						<i class="tio-history" />
					</span>
					<span
						v-if="bidRevisions.length"
						class="badge badge-light badge-pill ml-1"
					>{{ bidRevisions.length }}</span>
				</a>
			</li>
		</ul>
		<b-tooltip
			id="activities-tooltip"
			target="activities-toggle"
			title="Activities"
			triggers="hover"
			placement="left"
		/>
		<b-tooltip
			id="comments-tooltip"
			target="comments-toggle"
			title="Comments"
			triggers="hover"
			placement="left"
		/>
		<b-tooltip
			v-if="display_inbox"
			id="inbox-tooltip"
			target="inbox-toggle"
			title="Inbox"
			triggers="hover"
			placement="left"
		/>
		<b-tooltip
			v-if="display_revisions"
			id="revisions-tooltip"
			target="revisions-toggle"
			title="Rebid Log"
			triggers="hover"
			placement="left"
		/>
	</div>
	<comments />
	<activity-log />
	<inbox />
	<bid-revisions />
</div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Comments from './comments/comments.vue';
import ActivityLog from './activities/activity-log.vue';
import BidRevisions from './bid-revisions/bid-revisions.vue';
import Inbox from './inbox/inbox.vue';

export default {
	components: {
		Comments,
		ActivityLog,
		Inbox,
		BidRevisions,
	},
	computed: {
		...mapState('rightNavbarStore', ['display', 'display_inbox', 'display_revisions']),
		...mapState('comment', ['comments']),
		...mapState('inbox', ['messages']),
		...mapState('activityLog', ['activities']),
		...mapGetters('activityLog', ['bidRevisions']),
	},
	methods: {
		...mapActions('comment', {
			toggleCommentDisplay: 'toggleDisplay',
		}),
		...mapActions('inbox', {
			toggleInboxDisplay: 'toggleDisplay',
		}),
		...mapActions('activityLog', {
			toggleActivitiesDisplay: 'toggleDisplay',
		}),
		...mapActions('activeBid', {
			toggleRevisionsDisplay: 'toggleRevisionsDisplay',
		}),
	},
};
</script>
