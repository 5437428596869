export default class HistoricalProjectRepo {
	constructor({ supaTo, supabaseClientFactory, supabaseQueryService }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this.supabaseQueryService = supabaseQueryService;
	}

	async getAllHistoricalProjects(params) {
		const base = this.supabase
			.from('historical_project')
			.select('*', { count: 'exact' });
		const query = this.supabaseQueryService.createQuery(base, params);
		const [err, response] = await this.supaTo(query);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async getProjectByJobId(jobId) {
		const [err, response] = await this.supaTo(this.supabase.from('historical_project').select().match({ string_id: jobId }));
		if (err) {
			throw err;
		} else {
			return response.data;
		}
	}
}
