function calculateMargin(cost, taxRate = 0, margin) {
	const costWithTax = cost * (1 + taxRate);
	return costWithTax / (1 - margin) - costWithTax;
}

export default {
	namespaced: true,
	state: {
		columns: [
			{
				data: 'type',
				readOnly: true,
			},
			{
				data: 'cost_code',
				readOnly: true,
			},
			{
				data: 'component',
				readOnly: true,
			},
			{
				data: 'hours',
				readOnly: true,
			},
			{
				data: 'cost',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'tax_oh',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'cost_tax_oh',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'margin_percent',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'margin_dollar',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'oh_margin_dollar',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'percent_of_job',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'total',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'cost_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
		],
		colHeaders: [
			'',
			'Cost Code',
			'',
			'',
			'Cost',
			'TAX + OH',
			'Cost + TAX + OH',
			'Margin%',
			'Margin $',
			'OH Margin $',
			'% Of Job',
			'Total Amt',
			'Cost/Sqft',
			'Sale/Sqft',
		],
		comments: [],
	},
	getters: {
		totals(state, getters, rootState) {
			const laborHours = rootState.bidCsLabor.labor.reduce((acc, item) => {
				if (!item.hours) return acc;
				return acc + Number(item.hours);
			}, 0);
			const materialCost = rootState.bidCsMaterial.material.reduce((acc, item) => {
				if (!item.cost) return acc;
				return acc + Number(item.cost);
			}, 0);
			const taxRate = (rootState.bidCs.taxRate / 100);
			const tax = materialCost * taxRate;
			const overhead = laborHours * rootState.bidCs.overheadCostPerMH;
			const laborCost = laborHours * rootState.bidCs.manHourRate;
			const equipmentCost = rootState.bidCsEquipment.equipment
				.reduce((acc, item) => {
					if (!item.cost_sf) return acc;
					return acc + (Number(item.cost_sf) * rootState.activeBid.bid.bid_revision.square_footage);
				}, 0);
			const professionalCost = (laborCost + materialCost + equipmentCost) * rootState.bidCsProfessional.professional.percent;
			const professionalOh = (tax + overhead) * rootState.bidCsProfessional.percent;
			const professionaMarginRate = Number(rootState.bidCsProfessional.professional.margin_percent);
			const professionalMargin = calculateMargin(professionalCost + professionalOh, null, professionaMarginRate);

			const laborMargin = rootState.bidCsLabor.labor
				.reduce((acc, item) => {
					if (!item.hours) return acc;
					return acc + calculateMargin(item.hours * rootState.bidCs.manHourRate, null, item.margin_percent);
				}, 0);
			const materialMargin = rootState.bidCsMaterial.material
				.reduce((acc, item) => {
					if (!item.cost) return acc;
					return acc + calculateMargin(item.cost, taxRate, item.margin_percent);
				}, 0);
			const equipmentMargin = rootState.bidCsEquipment.equipment
				.reduce((acc, item) => {
					if (!item.cost_sf) return acc;
					const margin = calculateMargin((item.cost_sf * rootState.activeBid.bid.bid_revision.square_footage), null, item.margin_percent);
					return acc + margin;
				}, 0);
			const cost = laborCost + materialCost + equipmentCost + professionalCost;

			const ohMargin = laborHours * rootState.bidCs.overheadMarginPerMH;

			const { bid } = rootState.activeBid;
			const taxOh = tax + overhead + professionalOh;
			return [
				{
					type: '',
					cost_code: 'GRAND TOTALS',
					component: '',
					hours: '',
					cost,
					tax_oh: taxOh,
					cost_tax_oh: '=SUM(E1+F1)',
					margin_percent: '=(I1+J1)/L1',
					margin_dollar: laborMargin + materialMargin + equipmentMargin + professionalMargin,
					oh_margin_dollar: ohMargin,
					percent_of_job: '1',
					total: '=ROUND(SUM(G1,I1), 2)',
					cost_per_gross_sqft: `=E1/${bid.bid_revision.square_footage}`,
				},
				{
					type: '',
					cost_code: '',
					component: '',
					hours: '',
					cost: '',
					tax_oh: '',
					cost_tax_oh: '',
					margin_percent: '',
					margin_dollar: '',
					oh_margin_dollar: 'TOTAL BASE FRAME per SF',
					percent_of_job: '',
					total: `=L1/${bid.bid_revision.square_footage}`,
					cost_per_gross_sqft: '',
				},
			];
		},
		totalItems(state, getters) {
			return getters.job.length;
		},
	},
	mutations: {
		updateJob(state, payload) {
			state.overhead = payload;
		},
		handleComment(state, data) {
			const existingCommentIndex = state.comments.findIndex(({ row, col }) => row === data.row && col === data.col);

			if (existingCommentIndex > -1) {
				if (data.comment === '') {
					state.comments.splice(existingCommentIndex, 1);
					return;
				}
				const existingComment = state.comments[existingCommentIndex];
				existingComment.comment = data.comment;
			} else {
				if (data.comment === '') return;
				state.comments.push(data);
			}
		},
		setComments(state, payload) {
			state.comments = payload;
		},
	},
	actions: {
	},
};
