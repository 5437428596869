export default {
	namespaced: true,
	state: {
		users: [],
		loaded: false,
		error: '',
	},
	getters: {
		getUserByEmail: (state) => (userEmail) => state.users.find(({ email }) => userEmail === email),
		getUserByName: (state) => (username) => state.users.find(({ name }) => username === name),
	},
	mutations: {
		setUsers(state, users) {
			state.users = users;
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
		},
		setError(state, error) {
			state.error = error.message;
		},
	},
	actions: {
		async getAllUsers({ commit, state }) {
			if (state.loaded) {
				return;
			}
			const { to, userRepo } = this.$cradle;
			const [err, data] = await to(userRepo.getAllUsers());
			if (err) {
				commit('setUsers', []);
				commit('setError', err);
				commit('setLoadingStatus', true);
			} else {
				commit('setUsers', data);
				commit('setLoadingStatus', true);
			}
		},
	},
};
