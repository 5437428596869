<template>
<div>
	<span
		v-if="currentBranch"
		id="cb"
	>
		<a
			class="branch-sha-text"
			target="_blank"
			:href="commitLink"
		>{{ currentBranch }}
			<span v-if="currentSha">{{ `- ${currentSha.slice(0, 7)}` }}</span>
		</a>
	</span>
	<b-tooltip
		id="cb-tooltip"
		target="cb"
		:title="branchTitle"
		triggers="hover"
		variant="light"
	/>
</div>
</template>

<script>
export default {
	computed: {
		currentBranch() {
			return process.env.CURRENT_BRANCH;
		},
		currentSha() {
			return process.env.CURRENT_SHA;
		},
		currentEnv() {
			return `${this.currentBranch}@${process.env.NODE_ENV}.${process.env.DOPPLER_CONFIG}`;
		},
		branchTitle() {
			return `Current Branch: ${this.currentEnv}`;
		},
		commitLink() {
			return `https://github.com/Colt-Builders-Corp/estimating-tool/commit/${this.currentSha}`;
		},
	},
};
</script>

<style>
.branch-sha-text {
	font-size: 10px;
	color: whitesmoke;
}

#cb-tooltip > .tooltip-inner {
	font-size: 10px;
	background-color: #71869d !important;
}

#cb-tooltip > .arrow::before {
	border-top-color: #71869d !important;
}
</style>
