"use strict";

exports.__esModule = true;

var _view = _interopRequireDefault(require("./view"));

exports.OrderView = _view.default;

var _sharedView = _interopRequireDefault(require("./sharedView"));

exports.SharedOrderView = _sharedView.default;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }