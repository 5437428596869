export default {
	namespaced: true,
	state: {
		projectTypes: [],
		projectStatus: [],
		materialCategories: [],
		bidStatus: [],
		bidTypes: [],
		bidAwardStatus: [],
		bidGrades: [],
		bidScopeTypes: [],
		bidUnitOccupancies: [],
		wallHeights: [],
		buildingTypes: [],
		planTypes: [],
		takeoffTypes: [],
		filterTypes: [],
		dataTypes: [],
		states: [
			{ name: 'ALABAMA', abbreviation: 'AL' },
			{ name: 'ALASKA', abbreviation: 'AK' },
			{ name: 'AMERICAN SAMOA', abbreviation: 'AS' },
			{ name: 'ARIZONA', abbreviation: 'AZ' },
			{ name: 'ARKANSAS', abbreviation: 'AR' },
			{ name: 'BRITISH COLUMBIA', abbreviation: 'BC' },
			{ name: 'CALIFORNIA', abbreviation: 'CA' },
			{ name: 'COLORADO', abbreviation: 'CO' },
			{ name: 'CONNECTICUT', abbreviation: 'CT' },
			{ name: 'DELAWARE', abbreviation: 'DE' },
			{ name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
			{ name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
			{ name: 'FLORIDA', abbreviation: 'FL' },
			{ name: 'GEORGIA', abbreviation: 'GA' },
			{ name: 'GUAM', abbreviation: 'GU' },
			{ name: 'HAWAII', abbreviation: 'HI' },
			{ name: 'IDAHO', abbreviation: 'ID' },
			{ name: 'ILLINOIS', abbreviation: 'IL' },
			{ name: 'INDIANA', abbreviation: 'IN' },
			{ name: 'IOWA', abbreviation: 'IA' },
			{ name: 'KANSAS', abbreviation: 'KS' },
			{ name: 'KENTUCKY', abbreviation: 'KY' },
			{ name: 'LOUISIANA', abbreviation: 'LA' },
			{ name: 'MAINE', abbreviation: 'ME' },
			{ name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
			{ name: 'MARYLAND', abbreviation: 'MD' },
			{ name: 'MASSACHUSETTS', abbreviation: 'MA' },
			{ name: 'MICHIGAN', abbreviation: 'MI' },
			{ name: 'MINNESOTA', abbreviation: 'MN' },
			{ name: 'MISSISSIPPI', abbreviation: 'MS' },
			{ name: 'MISSOURI', abbreviation: 'MO' },
			{ name: 'MONTANA', abbreviation: 'MT' },
			{ name: 'NEBRASKA', abbreviation: 'NE' },
			{ name: 'NEVADA', abbreviation: 'NV' },
			{ name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
			{ name: 'NEW JERSEY', abbreviation: 'NJ' },
			{ name: 'NEW MEXICO', abbreviation: 'NM' },
			{ name: 'NEW YORK', abbreviation: 'NY' },
			{ name: 'NORTH CAROLINA', abbreviation: 'NC' },
			{ name: 'NORTH DAKOTA', abbreviation: 'ND' },
			{ name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
			{ name: 'OHIO', abbreviation: 'OH' },
			{ name: 'OKLAHOMA', abbreviation: 'OK' },
			{ name: 'OREGON', abbreviation: 'OR' },
			{ name: 'PALAU', abbreviation: 'PW' },
			{ name: 'PENNSYLVANIA', abbreviation: 'PA' },
			{ name: 'PUERTO RICO', abbreviation: 'PR' },
			{ name: 'RHODE ISLAND', abbreviation: 'RI' },
			{ name: 'SOUTH CAROLINA', abbreviation: 'SC' },
			{ name: 'SOUTH DAKOTA', abbreviation: 'SD' },
			{ name: 'TENNESSEE', abbreviation: 'TN' },
			{ name: 'TEXAS', abbreviation: 'TX' },
			{ name: 'UTAH', abbreviation: 'UT' },
			{ name: 'VERMONT', abbreviation: 'VT' },
			{ name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
			{ name: 'VIRGINIA', abbreviation: 'VA' },
			{ name: 'WASHINGTON', abbreviation: 'WA' },
			{ name: 'WEST VIRGINIA', abbreviation: 'WV' },
			{ name: 'WISCONSIN', abbreviation: 'WI' },
			{ name: 'WYOMING', abbreviation: 'WY' },
		],
	},
	getters: {
		getBidStatuses: (state) => state.bidStatus.map(({ name }) => name),
		getBidTypes: (state) => state.bidTypes.map(({ name }) => name),
		getProjectTypes: (state) => state.projectTypes.map(({ name }) => name),
		getProjectStatuses: (state) => state.projectStatus.map(({ name }) => name),
		getPlanTypes: (state) => state.planTypes.map(({ name }) => name),
		getStates: (state) => state.states.map(({ name }) => name),
		getAbbreviationStates: (state) => state.states.map(({ abbreviation }) => abbreviation),
		getTakeoffTypes: (state) => state.takeoffTypes.map(({ name }) => name),
		getFilterTypes: (state) => state.filterTypes.map(({ name }) => name),
		getDataTypes: (state) => state.dataTypes.map(({ name }) => name),
		getBidAwardStatuses: (state) => state.bidAwardStatus.map(({ name }) => name),
		getBidGrades: (state) => state.bidGrades.map(({ name }) => name),
		getBidScopeTypes: (state) => state.bidScopeTypes.map(({ name }) => name),
		getBidUnitOccupancies: (state) => state.bidUnitOccupancies.map(({ name }) => name),
	},
	mutations: {
		setProjectTypes(state, projectTypes) {
			state.projectTypes = projectTypes;
		},
		setProjectStatus(state, projectStatus) {
			state.projectStatus = projectStatus;
		},
		setMaterialCategories(state, materialCategories) {
			state.materialCategories = materialCategories;
		},
		setBidStatus(state, bidStatus) {
			state.bidStatus = bidStatus;
		},
		setWallHeights(state, wallHeights) {
			state.wallHeights = wallHeights;
		},
		setBuildingTypes(state, buildingTypes) {
			state.buildingTypes = buildingTypes;
		},
		setBidArchivalReasonTypes(state, bidArchivalReasonTypes) {
			state.bidArchivalReasonTypes = bidArchivalReasonTypes;
		},
		setPlanTypes(state, planTypes) {
			const { _ } = this.$cradle;
			state.planTypes = _.sortBy(planTypes, ['name']);
		},
		setBidTypes(state, bidTypes) {
			const { _ } = this.$cradle;
			state.bidTypes = _.sortBy(bidTypes, ['name']);
		},
		setTakeoffTypes(state, takeoffTypes) {
			const { _ } = this.$cradle;
			state.takeoffTypes = _.sortBy(takeoffTypes, ['name']);
		},
		setFilterTypes(state, filterTypes) {
			const { _ } = this.$cradle;
			state.filterTypes = _.sortBy(filterTypes, ['name']);
		},
		setDataTypes(state, dataTypes) {
			const { _ } = this.$cradle;
			state.dataTypes = _.sortBy(dataTypes, ['name']);
		},
		setBidAwardStatus(state, bidAwardStatus) {
			const { _ } = this.$cradle;
			state.bidAwardStatus = _.sortBy(bidAwardStatus, ['name']);
		},
		setBidGrades(state, bidGrades) {
			const { _ } = this.$cradle;
			state.bidGrades = _.sortBy(bidGrades, ['name']);
		},
		setBidScopeTypes(state, bidScopeTypes) {
			const { _ } = this.$cradle;
			state.bidScopeTypes = _.sortBy(bidScopeTypes, ['name']);
		},
		setBidUnitOccupancies(state, bidUnitOccupancies) {
			const { _ } = this.$cradle;
			state.bidUnitOccupancies = _.sortBy(bidUnitOccupancies, ['name']);
		},
	},
	actions: {
		async initialize({ commit }) {
			const { types, _ } = this.$cradle;

			const projectTypes = [];
			const projectStatus = [];
			const materialCategories = [];
			const bidStatus = [];
			const wallHeights = [];
			const buildingTypes = [];
			const bidArchivalReasonTypes = [];
			const planTypes = [];
			const bidTypes = [];
			const takeoffTypes = [];
			const filterTypes = [];
			const dataTypes = [];
			const bidAwardStatus = [];
			const bidGrades = [];
			const bidScopeTypes = [];
			const bidUnitOccupancies = [];

			_.forOwn(types.projectTypes, ({ name }, key) => projectTypes.push({ name, value: key }));
			_.forOwn(types.projectStatus, ({ name }, key) => projectStatus.push({ name, value: key }));
			_.forOwn(types.materialCategories, ({ name }, key) => materialCategories.push({ name, value: key }));
			_.forOwn(types.bidStatus, ({ name }, key) => bidStatus.push({ name, value: key }));
			_.forOwn(types.wallHeights, ({ name }, key) => wallHeights.push({ name, value: key }));
			_.forOwn(types.buildingTypes, ({ name }, key) => buildingTypes.push({ name, value: key }));
			_.forOwn(types.bidArchivalReasonTypes, ({ name }, key) => bidArchivalReasonTypes.push({ name, value: key }));
			_.forOwn(types.planTypes, ({ name }, key) => planTypes.push({ name, value: key }));
			_.forOwn(types.bidTypes, ({ name }, key) => bidTypes.push({ name, value: key }));
			_.forOwn(types.takeoffTypes, ({ name }, key) => takeoffTypes.push({ name, value: key }));
			_.forOwn(types.filterTypes, ({ name }, key) => filterTypes.push({ name, value: key }));
			_.forOwn(types.dataTypes, ({ name }, key) => dataTypes.push({ name, value: key }));
			_.forOwn(types.bidAwardStatus, ({ name }, key) => bidAwardStatus.push({ name, value: key }));
			_.forOwn(types.bidGrades, ({ name }, key) => bidGrades.push({ name, value: key }));
			_.forOwn(types.bidScopeTypes, ({ name }, key) => bidScopeTypes.push({ name, value: key }));
			_.forOwn(types.bidUnitOccupancies, ({ name }, key) => bidUnitOccupancies.push({ name, value: key }));

			commit('setProjectTypes', projectTypes);
			commit('setProjectStatus', projectStatus);
			commit('setMaterialCategories', materialCategories);
			commit('setBidStatus', bidStatus);
			commit('setWallHeights', wallHeights);
			commit('setBuildingTypes', buildingTypes);
			commit('setBidArchivalReasonTypes', bidArchivalReasonTypes);
			commit('setPlanTypes', planTypes);
			commit('setBidTypes', bidTypes);
			commit('setTakeoffTypes', takeoffTypes);
			commit('setFilterTypes', filterTypes);
			commit('setDataTypes', dataTypes);
			commit('setBidAwardStatus', bidAwardStatus);
			commit('setBidGrades', bidGrades);
			commit('setBidScopeTypes', bidScopeTypes);
			commit('setBidUnitOccupancies', bidUnitOccupancies);
		},
	},
};
