export default {
	namespaced: true,
	state: {
		architects: [],
		loaded: false,
		query: {
			sort: {
				by: 'name',
				desc: false,
			},
		},
	},
	getters: {
		getArchitects: (state) => state.architects.map(({ name }) => name),
	},
	mutations: {
		setArchitects(state, architects) {
			const { _ } = this.$cradle;
			state.architects = _.sortBy(architects, ['name']);
		},
		setLoadingStatus(state, status) {
			state.loaded = status;
		},
	},
	actions: {
		async getArchitects({ commit, state }) {
			if (state.loaded) {
				return;
			}
			const { to, architectRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(architectRepo.getAllArchitects());
			if (err) {
				logger.error(err.message);
				commit('setArchitects', []);
				throw err;
			} else {
				commit('setArchitects', data);
				commit('setLoadingStatus', true);
			}
		},
	},
};
