function calculateMargin(cost, taxRate = 0, margin) {
	const costWithTax = cost * (1 + taxRate);
	return costWithTax / (1 - margin) - costWithTax;
}

export default {
	namespaced: true,
	state: {
		totalLaborCost: 0,
		totalColtLaborCost: 0,
		totalSubCost: 0,
		labor: [
			{
				type: '<div class="cs-type">Labor</div>',
				cost_code: '010-020',
				component: 'COLT HOURLY LABOR',
				hours: 500,
				overhead: 0,
				cost_oh: '=E1+F1',
				margin_percent: '.12',
				margin_dollar: '=E1/(1-H1)-E1',
				percent_of_job: '0',
				total: '=ROUND(SUM(G1,I1), 2)',
			},
			{
				type: '',
				cost_code: '',
				component: '',
				hours: 0,
				cost: 0,
				overhead: 0,
				cost_oh: '=E2+F2',
				margin_percent: '.12',
				margin_dollar: '=E2/(1-H2)-E2',
				percent_of_job: '0',
				total: '=ROUND(SUM(G2,I2), 2)',
			},
			{
				type: '',
				cost_code: '020-020',
				component: 'Sub - Sq Ft Labor',
				hours: 0,
				cost: 0,
				overhead: 0,
				cost_oh: '=E3+F3',
				margin_percent: '.12',
				margin_dollar: '=E3/(1-H3)-E3',
				percent_of_job: '0',
				total: '=ROUND(SUM(G3,I3), 2)',
			},
		],
		columns: [
			{ data: 'type', renderer: 'html', readOnly: true },
			{
				data: 'cost_code',
				type: 'autocomplete',
				source: ['010-020', '010-150', '010-060', '010-020', '010-070'],
				strict: false,
			},
			{
				data: 'component',
				type: 'autocomplete',
				source: ['COLT HOURLY LABOR', 'HOLDOWN LABOR', 'MISC HARDWARE LABOR', 'PORCH FRAMING LABOR', 'STAIR FRAMING LABOR'],
				strict: false,
			},
			{ data: 'hours' },
			{
				data: 'cost',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'overhead',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'cost_oh',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readonly: true,
			},
			{
				data: 'margin_percent',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'margin_dollar',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'oh_margin_dollar',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'percent_of_job',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'total',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'cost_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
			{
				data: 'sale_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readOnly: true,
			},
		],
		colHeaders: [
			'',
			'Cost Code',
			'Component',
			'Hours',
			'Cost',
			'Overhead',
			'Cost + OH',
			'Lab Margin%',
			'Lab Margin $',
			'OH Margin $',
			'% Of Job',
			'Total Amt',
			'Cost/Sqft',
			'Sale/Sqft',
		],
		comments: [],
	},
	getters: {
		labor: (state, getters, rootState) => {
			const sqft = rootState.activeBid.bid.bid_revision.square_footage;
			const { manHourRate, overheadMarginPerMH, overheadCostPerMH, total, subManHourRate } = rootState.bidCs;
			const taxRate = rootState.bidCs.taxRate / 100;
			const mainLabor = state.labor.map((row, index) => {
				if (row.component === 'Sub - Sq Ft Labor') {
					row.cost = `=D${index + 1}*${subManHourRate}`;
				} else {
					row.cost = `=D${index + 1}*${manHourRate}`;
				}
				row.cost_oh = `=E${index + 1}+F${index + 1}`;
				row.cost_per_gross_sqft = `=E${index + 1}/${sqft}`;
				row.overhead = `=D${index + 1}*${overheadCostPerMH}`;
				row.sale_per_gross_sqft = `=L${index + 1}/${sqft}`;
				row.margin_dollar = `=E${index + 1}/(1-H${index + 1})-E${index + 1}`;
				row.oh_margin_dollar = `=D${index + 1} * ${overheadMarginPerMH}`;
				row.percent_of_job = `=ROUND(L${index + 1}/${total}*100, 2)/100`;
				row.total = `=ROUND(SUM(G${index + 1},I${index + 1}), 2)`;
				return row;
			});

			const currentRow = mainLabor.length;
			const totalRow = currentRow + 1;

			mainLabor.push({
				type: '',
				cost_code: '',
				component: 'LABOR TOTALS',
				hours: `=SUM(D1:D${currentRow})`,
				cost: `=SUM(E1:E${currentRow})`,
				overhead: `=SUM(F1:F${currentRow})`,
				cost_oh: `=E${totalRow}+F${totalRow}`,
				margin_percent: '.12',
				margin_dollar: `=SUM(I1:I${currentRow})`,
				oh_margin_dollar: `=SUM(J1:J${currentRow})`,
				percent_of_job: `=SUM(K1:K${currentRow})`,
				total: `=SUM(L1:L${currentRow})`,
				cost_per_gross_sqft: `=SUM(M1:M${currentRow})`,
				sale_per_gross_sqft: `=SUM(N1:N${currentRow})`,
			});

			const materialTotals = rootState.bidCsMaterial.material.reduce((acc, row) => {
				acc.cost += Number(row.cost);
				acc.margin_dollar += calculateMargin(row.cost, taxRate, Number(row.margin_percent));
				return acc;
			}, { cost: 0, margin_dollar: 0 });

			const cost = materialTotals.cost * (1 + taxRate);

			const subRow = totalRow + 1;

			// Please note that cost_oh is actually cost + oh + tax in this row, since the material equivalent of overhead is tax
			// I think ideally this row is its own table, so that this isn't confusing.

			mainLabor.push({
				type: '',
				cost_code: 'SUBTOTAL - LABOR & MATERIALS',
				component: '',
				hours: `=SUM(D${totalRow})`,
				cost: `=SUM(E${totalRow}+${materialTotals.cost})`,
				overhead: `=SUM(F${totalRow})`,
				cost_oh: `=E${totalRow}+${cost}+F${subRow}`,
				margin_percent: `=I${subRow}/E${subRow}`,
				margin_dollar: `=I${totalRow}+${materialTotals.margin_dollar}`,
				percent_of_job: `=ROUND(L${subRow}/${total}*100, 2)/100`,
				oh_margin_dollar: `=D${subRow} * ${overheadMarginPerMH}`,
				total: `=ROUND(SUM(G${subRow},I${subRow}), 2)`,
				cost_per_gross_sqft: `=E${subRow}/${sqft}`,
				sale_per_gross_sqft: `=L${subRow}/${sqft}`,
			});

			return mainLabor;
		},
		totalItems(state, getters) {
			return getters.labor.length;
		},
	},
	mutations: {
		updateLabor(state, payload) {
			state.labor = payload;
		},
		addRow(state, reload) {
			const currentRow = state.labor.length + 1;
			state.labor.push({
				type: '',
				cost_code: '',
				component: '',
				hours: '0',
				cost: '0',
				overhead: '0',
				cost_oh: `=E${currentRow}+F${currentRow}`,
				margin_percent: '.12',
				margin_dollar: `=G${currentRow}/(1-H1)-G${currentRow}`,
				percent_of_job: '.12',
			});

			reload();
		},
		removeRow(state, { row, reload }) {
			state.labor.splice(row, 1);

			if (state.comments) {
				for (let i = 0; i < state.comments.length; i++) {
					if (state.comments[i].row === row) {
						state.comments.splice(i, 1);
					}
					if (state.comments[i].row > row) {
						state.comments[i].row -= 1;
					}
				}
			}
			reload();
		},
		setComments(state, payload) {
			state.comments = payload;
		},
		setTotalColtLaborCost(state, payload) {
			state.totalColtLaborCost = payload;
		},
		setTotalSubCost(state, payload) {
			state.totalSubCost = payload;
		},
		setTotalLaborCost(state, payload) {
			state.totalLaborCost = payload;
		},
		handleComment(state, data) {
			const existingCommentIndex = state.comments.findIndex(({ row, col }) => row === data.row && col === data.col);

			if (existingCommentIndex > -1) {
				if (data.comment === '') {
					state.comments.splice(existingCommentIndex, 1);
					return;
				}
				const existingComment = state.comments[existingCommentIndex];
				existingComment.comment = data.comment;
			} else {
				if (data.comment === '') return;
				state.comments.push(data);
			}
		},
	},
	actions: {
	},
};
