<template>
<component :is="layout">
	<slot />
</component>
</template>

<script>

import DefaultLayout from './templates/DefaultLayout.vue';
import LoginLayout from './templates/LoginLayout.vue';
import WhiteLayout from './templates/WhiteLayout.vue';

const defaultLayout = 'DefaultLayout';

const layouts = {
	DefaultLayout,
	LoginLayout,
	WhiteLayout,
};

export default {
	name: 'AppLayout',
	computed: {
		layout() {
			const layout = this.$route.meta.layout || defaultLayout;
			return layouts[layout];
		},
	},
};
</script>
