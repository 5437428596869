<template>
<div>
	<page-header title="Dashboard" />
	<div v-if="loading">
		<Loader />
	</div>
	<template v-else>
		<div class="row mb-4">
			<div
				v-for="region of bidCountsPerRegion"
				:key="region.name"
				class="col-3 my-3"
				@click="goToRegionList(region)"
			>
				<region-bid-breakdown :region="region" />
			</div>
		</div>
		<bid-status-distribution-bar :bids="bids" />
		<div class="row">
			<div
				v-for="estimator of estimatorWorkload"
				:key="estimator.name"
				class="col-4 my-3"
			>
				<estimator-bid-breakdown :estimator="estimator" />
			</div>
		</div>
	</template>
</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import Loader from '../components/Loader.vue';
import BidStatusDistributionBar from '../components/dashboard/bids-status-distribution-bar.vue';
import EstimatorBidBreakdown from '../components/dashboard/estimator-bid-breakdown.vue';
import RegionBidBreakdown from '../components/dashboard/region-bid-breakdown.vue';
import PageHeader from '../components/PageHeader.vue';

export default {
	name: 'Dashboard',
	components: { BidStatusDistributionBar, EstimatorBidBreakdown, Loader, RegionBidBreakdown, PageHeader },
	data() {
		return {
			user: 'NO USER',
			loading: true,
		};
	},
	computed: {
		...mapState('dashboardReport', ['bids']),
		...mapState('bidLog', ['query']),
		...mapState('types', ['bidStatus']),
		...mapState('dashboardReport', ['bidCountsPerRegion', 'bidCountsPerEstimator']),
		estimatorWorkload() {
			const { _ } = this.$cradle();
			const bidsByEstimator = {};

			const bidCountsPerEstimator = _.cloneDeep(this.bidCountsPerEstimator);

			for (const bid of bidCountsPerEstimator) {
				if (bid.estimator !== 'None Assigned') {
					bid.status = this.bidStatus.find((item) => item.value === bid.status).name;
					if (!bidsByEstimator[bid.estimator]) {
						bidsByEstimator[bid.estimator] = { name: bid.estimator, count: bid.count, bids: [bid] };
					} else {
						bidsByEstimator[bid.estimator].bids.push(bid);
					}
				}
			}

			return bidsByEstimator;
		},
	},
	async mounted() {
		const { to } = this.$cradle();
		if (!this.bids || this.bids.length < 1) {
			const [err] = await to(this.getAllBids());
			if (err) {
				this.createErrorAlert('There was an error getting bid log. Please try again.');
			}
		}
		if (!this.bidCountsPerRegion || this.bidCountsPerRegion < 1) {
			await this.getBidCountsPerRegion();
		}
		if (!this.bidCountsPerEstimator || this.bidCountsPerEstimator < 1) {
			await this.getBidCountsPerEstimator();
		}
		this.user = this.$store.getters['authentication/user'];

		this.loading = false;
	},
	methods: {
		...mapActions('alert', ['createErrorAlert']),
		...mapMutations('bidLog', ['setRegionFilter']),
		...mapActions('dashboardReport', ['getAllBids']),
		...mapActions('dashboardReport', ['getBidCountsPerRegion', 'getBidCountsPerEstimator']),
		goToRegionList(region) {
			this.setRegionFilter(region.name);
			this.$router.push('/bid-log');
		},
	},
};
</script>
