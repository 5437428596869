import SheetInterface from '../../interface/SheetInterface.mjs';

export default class WhiteboardSheetRepo extends SheetInterface {
	constructor(cradle) {
		super(cradle, 'WHITEBOARD');
		const { regionRevisionRepo, to, sbTo, supabaseClientFactory, _, libAsync, validateService } = cradle;

		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
		this.to = to;
		this.libAsync = libAsync;
		this.regions = regionRevisionRepo;
		this.validateService = validateService;
	}

	async validatePayload(payload) {
		const [err] = await this.to(this.validateService.validate(payload, 'WhiteboardSheet'));
		if (err) {
			throw err;
		}
		return true;
	}

	async payloadDefaults() {
		// Find the regions
		const [err, regions] = await this.to(this.regions.getRegions());
		if (err) {
			throw err;
		}
		// Find the materials
		const [errA, materials] = await this.to(this.materials());
		if (errA) {
			throw errA;
		}

		const defs = {
			cols: this.cols(regions),
			data: this.defaultData(materials, regions),
			effective_date: '',
		};

		return defs;
	}

	defaultData(materials, regions) {
		const cols = [];

		const regs = Object.fromEntries(new Map(this._.map(regions, (region) => [`region_${region.id}`, ''])));

		this._.each(materials, (material) => {
			cols.push({
				material,
				...regs,
			});
		});

		return cols;
	}

	cols(regions) {
		const cols = [
			{
				data: 'material',
				title: 'Material',
				readOnly: true,
			},
		];

		this._.each(regions, (region) => {
			cols.push({
				data: `region_${region.id}`,
				title: region.name,
				type: 'numeric',
				numericFormat: {
					pattern: {
						output: 'currency',
						mantissa: 2,
					},
				},
			});
		});

		return cols;
	}

	async materials() {
		// @TODO - do this better
		return [
			'2x4x104-5/8" SPF #2',
			'2x6x104-5/8" SPF #2',
			'2x4x12 SPF #2',
			'2x6x12 SPF #2',
			'2x8x12 SPF #2',
			'2x10x12 SPF #2',
			'2x4x12 SYP #2 PT',
			'2x6x12 SYP #2 PT',
			'2x8x12 SYP #2 PT',
			'2x10x12 SYP #2 PT',
			'4x8x7/16" OSB SE',
			'4x8x15/32" OSB SE',
			'4x8x19/32" OSB SE',
			'4x8x23/32" OSB T&G',
			'23/32" Advantech',
			'7/16" ZIP',
			'15/32" Plywood',
			'23/32" Plywood',
			'FRT add',
			'2x4x12 SYP #2',
			'2x6x12 SYP #2',
			'2x4x104-5/8" SYP #2',
			'2x6x104-5/8" SYP #2',
			'2x8x12 SYP #2',
			'2x10x12 SYP #2',
			'2x4x104-5/8" DF #2',
			'2x6x104-5/8" DF #2',
			'2x4x12 DF #2',
			'2x6x12 DF #2',
			'2x12x12 SYP #2',
			'2x8x12 DF #2',
			'2x10x12 DF #2',
			'2x12x12 DF #2',
			'4x10x12 DF #2',
			'4x12x12 DF #2',
			'6x10x12 DF #1',
			'6x12x12 DF #1',
			'2x4x12 DF #2 PT',
			'2x6x12 DF #2 PT',
			'2x8x12 DF #2 PT',
			'2x10x12 DF #2 PT',
			'4x8x15/32" CDX SE',
			'4x8x23/32" CDX T&G',
		];
	}
}
