<template>
<aside
	v-show="navState !== 'NONE'"
	id="left-nav"
	v-click-outside="handleClickOutsideLeftNav"
	class="
      js-navbar-vertical-aside
      navbar
      navbar-vertical-aside
      navbar-vertical
      navbar-vertical-fixed
      navbar-expand-xl
      navbar-bordered
      navbar-dark
      navbar-vertical-aside-initialized
    "
>
	<div class="navbar-vertical-container">
		<div class="navbar-vertical-footer-offset">
			<div class="navbar-brand-wrapper justify-content-between">
				<a
					class="navbar-brand"
					href="/dashboard"
					aria-label="Front"
					style="margin: 0 auto"
				>
					<img
						class="navbar-brand-logo"
						src="/img/coltLogo.png"
						alt="Logo"
					>
					<img
						class="navbar-brand-logo-mini"
						src="/img/coltLogo.png"
						alt="Logo"
					>
				</a>
			</div>

			<!-- Content -->
			<div class="navbar-vertical-content">
				<div class="nav-tab-container">
					<ul class="navbar-nav navbar-nav-lg nav-tabs show">
						<li
							v-for="(category, index) of menu.items"
							:id="`left-nav-menu-item-${category.name}`"
							:key="index"
							class="nav-item"
							:class="{
								show: subMenuIsOpen(category),
								active: shouldShowActiveClassOnMenuItem(category),
								'navbar-vertical-aside-has-menu':
									category.children && category.children.length,
							}"
						>
							<router-link
								:to="category.destination"
								class="nav-link"
								:title="category.name"
								:class="{
									active: isActiveRoute(category),
									'nav-link-toggle':
										category.children && category.children.length,
								}"
								@click.native="onRouterLinkClick($event, category)"
							>
								<i
									class="nav-icon"
									:class="category.icon"
								/>
								<span class="text-truncate">{{ category.name }}</span>
							</router-link>

							<ul
								v-if="category.children && category.children.length"
								:id="`left-nav-sub-menu-${category.name}`"
								class="js-navbar-vertical-aside-submenu nav nav-sub"
								style="display: none"
							>
								<li
									v-for="(subItem, i) of category.children"
									:key="i"
									class="nav-item"
								>
									<!-- menu sub item -->
									<router-link
										:class="{ active: routeMatches(subItem) }"
										class="nav-link"
										:to="subItem.destination"
										:title="subItem.name"
										@click.native="onSubMenuItemClick($event, category)"
									>
										<span class="text-truncate">{{ subItem.name }}</span>
									</router-link>
									<!--  ----  -->
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div
				id="left-nav-footer"
				class="navbar-vertical-footer navbar-custom"
			>
				<git-branch-and-sha />
				<ul class="navbar-vertical-footer-list">
					<li class="navbar-vertical-footer-list-item" />
					<button
						v-if="isMobileOrTablet"
						class="js-navbar-vertical-aside-toggle-invoker close mr-3"
						@click.prevent="setNav('NONE')"
					>
						<i class="tio-clear" />
					</button>
				</ul>
			</div>
		</div>
	</div>
</aside>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import isMobileOrTablet from '../mixins/isMobileOrTablet';
import GitBranchAndSha from './GitBranchAndSha.vue';

export default {
	components: { GitBranchAndSha },
	mixins: [isMobileOrTablet],
	data() {
		return {
			menu: {
				items: [
					{
						name: 'Dashboard',
						icon: 'tio-home-vs-1-outlined',
						destination: '/dashboard',
					},
					{
						name: 'Bids',
						icon: 'tio-pages-outlined',
						destination: '',
						children: [
							{
								name: 'Bid Log',
								destination: '/bid-log',
							},
							{
								name: 'Archived Bids',
								destination: '/archived-bid-log',
							},
						],
					},
					{
						name: 'Projects',
						icon: 'tio-folder-labeled-outlined',
						destination: '',
						children: [
							{
								name: 'Projects',
								destination: '/projects',
							},
							{
								name: 'Archived Projects',
								destination: '/archived-projects',
							},
						],
					},
					{
						name: 'Settings',
						icon: 'tio-info',
						destination: '',
						children: [
							{
								name: 'Architects',
								destination: '/settings/architects',
							},
							{
								name: 'Structural Engineers',
								destination: '/settings/structural-engineers',
							},
							{
								name: 'Estimators',
								destination: '/settings/estimators',
							},
						],
					},
					{
						name: 'Reports',
						icon: 'tio-chart-bar-1',
						destination: '',
						children: [
							{
								name: 'Exec Summary',
								destination: '/reports/exec-summary',
							},
							{
								name: 'Estimating Summary',
								destination: '/reports/estimating-summary-report',
							},
							{
								name: 'Regional Report',
								destination: '/reports/regional-report',
							},
							{
								name: 'Pursuits',
								destination: '/reports/pursuits',
							},
							{
								name: 'Planned Billings',
								destination: '/reports/planned-billings',
							},
						],
					},
					{
						name: 'Pricing',
						icon: 'tio-dollar-outlined',
						destination: '',
						children: [
							{
								name: 'Whiteboard',
								destination: '/white-board',
							},
							{
								name: 'Random Lengths',
								destination: '/pricing/random-lengths',
							},
						],
					},
					{
						name: 'Historical Data',
						icon: 'tio-history',
						destination: '',
						children: [
							{
								name: 'Purchase Takeoffs',
								destination: '/historical-data/purchase-takeoffs',
							},
							{
								name: 'Historical Projects',
								destination: '/historical-data/historical-projects',
							},
							{
								name: 'Historical Archived Projects',
								destination: '/historical-data/archived-projects',
							},
							{
								name: 'Training Data',
								destination: '/historical-data/training-data',
							},
						],
					},
				],
			},
		};
	},
	computed: {
		...mapState('leftNav', ['navState']),
		...mapState('types', ['materialCategories']),
	},
	watch: {
		isMobileOrTablet(newValue, oldValue) {
			if (oldValue && !newValue) {
				this.setNav('FULL');
			} else {
				this.setNav('NONE');
			}
		},
		$route() {
			this.closeAllSubNavsInMiniMode();
		},
		materialCategories: {
			handler() {
				if (!this.materialCategories) {
					return;
				}

				const pricingList = this.materialCategories.map(({ name, value }) => ({
					name,
					destination: `/pricing/${value.replace(/\s/g, '-').toLowerCase()}`,
				}));

				const pricingMenuItemIndex = this.menu.items.findIndex(
					({ name }) => name === 'Pricing',
				);

				this.menu.items[pricingMenuItemIndex].children.push(...pricingList);
			},
			immediate: true,
		},
	},
	created() {
		this.$root.$on('nav-toggle', this.toggleLeftNav);
	},
	mounted() {
		this.setNav('FULL');
	},
	methods: {
		...mapMutations('leftNav', ['setNav']),
		shouldShowActiveClassOnMenuItem(category) {
			if (!category.destination) {
				return false;
			}
			if (category && category.children) {
				return this.childIsActive(category);
			}
			return this.isActiveRoute(category);
		},
		childIsActive(category) {
			const subMenuId = `left-nav-sub-menu-${category.name}`;
			const subMenuEl = document.getElementById(subMenuId);
			if (subMenuEl && subMenuEl.style && subMenuEl.style.display === 'block') {
				return true;
			}
			return false;
		},
		subMenuIsOpen(category) {
			const subMenuEl = document.getElementById(
				`left-nav-sub-menu-${category.name}`,
			);
			if (subMenuEl && subMenuEl.style && subMenuEl.style.display === 'block') {
				return true;
			}
			return false;
		},
		routeMatches(subItem) {
			return this.$route.fullPath === subItem.destination;
		},
		toggleParentMenuItem(menuItemName, el, top = 0) {
			const subMenuId = `left-nav-sub-menu-${menuItemName}`;
			if (el.classList.contains('show')) {
				el.classList.remove('show');
				document.getElementById(subMenuId).style.display = 'none';
			} else {
				el.classList.add('show');
				const subMenuList = document.getElementById(subMenuId);
				subMenuList.style.display = 'block';
				subMenuList.style.top = `${top}px`;
			}
		},
		onRouterLinkClick(event, category) {
			if (this.navState === 'MINI') {
				this.closeAllSubNavsInMiniMode();
			}
			const isParentMenuItem = category && category.children && category.children.length > 0;
			if (this.isMobileOrTablet && !isParentMenuItem) {
				this.toggleLeftNav();
			} else if (isParentMenuItem) {
				const parentEl = document.getElementById(
					`left-nav-menu-item-${category.name}`,
				);
				if (parentEl) {
					this.toggleParentMenuItem(category.name, parentEl, event.clientY);
				}
			}
		},
		onSubMenuItemClick(event, parentMenuItem) {
			event.stopPropagation();
			if (this.isMobileOrTablet) {
				this.toggleLeftNav();
			}
			if (this.navState === 'MINI') {
				this.closeAllSubNavsInMiniMode();
				const subMenuEl = document.getElementById(
					`left-nav-sub-menu-${parentMenuItem.name}`,
				);
				subMenuEl.style.display = 'none';
			}
		},

		isActiveRoute(category) {
			if (category && category.children && category.children.length) {
				// Check if it's a child route and make sure parent has active class.
				const childRoute = category.children.find(
					(c) => c.destination === this.$route.fullPath,
				);
				return !!childRoute;
			}
			if (this.$route.name === category.name) {
				//	Most common, just a normal route w/o children routes.
				return true;
			}

			return false;
		},
		toggleLeftNav() {
			if (this.navState === 'NONE') {
				this.setNav('FULL');
				const el = document.getElementById('app');
				if (el) {
					el.className = 'has-navbar-vertical-aside navbar-vertical-aside-show-xl';
				}
			} else {
				this.setNav('NONE');
				const el = document.getElementById('app');
				if (el) {
					el.className = '';
				}
			}
		},
		handleClickOutsideLeftNav() {
			if (this.navState === 'FULL' && this.isMobileOrTablet) {
				this.setNav('NONE');
			}
		},
		closeAllSubNavsInMiniMode() {
			if (this.navState !== 'MINI') {
				return;
			}
			const allSubNavs = document.getElementsByClassName('nav-sub');
			Array.from(allSubNavs).forEach((s) => {
				s.style.display = 'none';
			});
		},
	},
};
</script>
<style scoped>
.navbar-custom {
  background-color: #132144;
  height: 47px;
}
.nav-tab-container {
	max-height: calc(100% - 65px);
	overflow-y: auto;
}
</style>
