<template>
<div class="border-bottom mb-3">
	<form @submit.prevent="createNewComment()">
		<div class="d-flex align-items-center avatar-comments">
			<Avatar
				class="mr-2"
				:name="user.name"
				:avatar="user.avatar"
			/>
			<label><b>{{ user.name }}</b></label>
		</div>
		<div
			ref="editor"
			class="pl-4"
		/>
		<processing-button
			class="mt-2 float-right"
			initial-text="Save"
			:is-processing="saving"
		/>
		<div class="clearfix mb-2" />
	</form>
</div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ProcessingButton from '../ProcessingButton.vue';
import Avatar from '../Avatar.vue';

export default {
	components: {
		ProcessingButton,
		Avatar,
	},
	data() {
		return {
			editor: null,
		};
	},
	computed: {
		...mapState('user', ['users']),
		...mapState('authentication', ['user']),
		...mapState('comment', ['saving', 'success', 'failure', 'currentCommentText', 'resource']),
		processedUsers(el) {
			return el.users.map(({ email, name }) => ({ id: email, value: name, valLower: name.toLowerCase() }));
		},
	},
	async mounted() {
		const { libQuill: Quill } = this.$cradle();
		this.editor = new Quill(this.$refs.editor, {
			formats: ['mention'],
			modules: {
				mention: {
					allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
					mentionDenotationChars: ['@'],
					source: this._handleMention,
				},
				toolbar: false,
			},
			placeholder: 'Add a comment...',
		});

		if (this.failure === true) {
			this.editor.setText(this.currentCommentText);
		}

		this.setLoadingStatus(false);
		await this.getAllUsers();
	},
	methods: {
		...mapActions('comment', ['createComment']),
		...mapMutations('comment', ['setCurrentCommentText']),
		...mapActions('alert', ['createErrorAlert']),
		...mapActions('user', ['getAllUsers']),
		...mapMutations('user', ['setLoadingStatus']),
		async _handleMention(searchTerm, renderList) {
			return renderList(this.processedUsers.filter(({ valLower }) => valLower.includes(searchTerm)));
		},
		async createNewComment() {
			this.editor.enable(false);
			this.setCurrentCommentText(this.getPlainText(this.editor.getContents()));

			const { to } = this.$cradle();
			const [err] = await to(this.createComment([{
				comment_text: this.getPlainText(this.editor.getContents()),
				comment_delta: this.editor.getContents(),
				base_url: window.location.origin || 'https://estimating.coltbuilders.com',
				user_id: this.user.id,
				resource_id: this.resource.id,
				resource_sub_id: this.resource.sub_id,
				date: new Date(),
			}, this.resource.model]));
			if (err) {
				this.createErrorAlert('There was an error creating this comment. Please try again.');
				this.displayModal();
			}

			if (this.success === true) {
				this.editor.setText('');
			}

			this.editor.enable();
		},
		getPlainText(delta) {
			return delta.ops.reduce((p, c) => {
				if (typeof c.insert !== 'string') {
					if (c.insert.mention) {
						return `${p}@${c.insert.mention.value}`;
					}
					return p;
				}
				return p + c.insert;
			}, '').trim().replace(/(\r\n|\n|\r)/gm, ' ');
		},
		async displayModal() {
			const { to } = this.$cradle();

			const [err] = await to(this.$store.dispatch('modal/showModal', {
				title: 'Error',
				text: 'There was an error creating this comment. Please try again.',
				onConfirm: () => {
					this.$store.dispatch('comment/toggleDisplay');
				},
			}));

			if (err) {
				throw err;
			}
		},
	},
};
</script>
<style>
	.avatar-comments {
		margin-left: -1rem;
	}
	.ql-container .ql-editor {
		border: 1px solid #a3a3a3;
		border-radius: 6px;
		font-size: 14px;
		color: rgb(30, 32, 34);
		font-family: "Open Sans", sans-serif;
		min-height: 100px;
		line-height: 1.6;
		position: relative;
	}
	.ql-container .ql-editor:focus {
		border: 1px solid #025fae;
	}
	.ql-container .ql-mention-list-container {
		color: rgb(30, 32, 34);
		font-family: "Open Sans", sans-serif;
	}
	.ql-container .ql-mention-list-container .ql-mention-list-item {
		font-size: 14px;
	}
</style>
