export default {
	namespaced: true,
	state: {
		totalLaborCost: 0,
		totalSubCost: 0,
		equipment: [
			{
				type: '',
				cost_code: '001-140',
				component: 'Equipment Rental - Forklift',
				cost_sf: 0.2,
				cost: 0,
				cost_oh: '=E1+F1',
				overhead: 0,
				margin_percent: '.12',
				margin_dollar: '=G1/(1-H1)-G1',
				percent_of_job: '0',
				total: '=ROUND(SUM(G1,I1), 2)',
			},
		],
		columns: [
			{ data: 'type', renderer: 'html' },
			{
				data: 'cost_code',
				type: 'autocomplete',
				source: ['001-140', '001-150'],
				strict: false,
			},
			{
				data: 'component',
				type: 'autocomplete',
				source: [
					'Equipment Rental - Forklift',
					'Equipment Rental - Boomlift',
					'Equipment Rental - Scaffolding',
					'Equipment Rental - Crane',
				],
				strict: false,
			},
			{
				data: 'cost_sf',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'cost',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'overhead',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
				readonly: true,
			},
			{
				data: 'cost_oh',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'margin_percent',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
			},
			{
				data: 'margin_dollar',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'percent_of_job',
				type: 'numeric',
				numericFormat: {
					pattern: '0.00%',
					culture: 'en-US',
				},
			},
			{
				data: 'total',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'cost_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
			{
				data: 'sale_per_gross_sqft',
				type: 'numeric',
				numericFormat: {
					pattern: '$0,0.00',
					culture: 'en-US',
				},
			},
		],
		colHeaders: [
			'',
			'Cost Code',
			'Component',
			'Cost/SF',
			'Cost',
			'Overhead',
			'Cost + OH',
			'Margin%',
			'Margin $$',
			'% Of Job',
			'Total Amt',
			'Cost/Sqft',
			'Sale/Sqft',
		],
		comments: [],
	},
	getters: {
		equipment: (state, getters, rootState) => {
			const { total } = rootState.bidCs;
			const sqft = rootState.activeBid.bid.bid_revision.square_footage;
			const data = state.equipment.map((row, index) => {
				row.cost = `=D${index + 1}*${sqft}`;
				row.cost_per_gross_sqft = `=E${index + 1}/${sqft}`;
				row.sale_per_gross_sqft = `=K${index + 1}/${sqft}`;
				row.percent_of_job = `=ROUND(K${index + 1}/${total} * 100, 2)/100`;
				return row;
			});

			const currentRow = data.length;
			const totalRow = currentRow + 1;

			data.push({
				type: '',
				cost_code: '',
				component: 'SUBTOTAL -  EQUIP',
				cost_sf: `=SUM(D1:D${currentRow})`,
				cost: `=SUM(E1:E${currentRow})`,
				overhead: '0',
				cost_oh: `=E${totalRow}+F${totalRow}`,
				margin_percent: '.12',
				margin_dollar: `=SUM(I1:I${currentRow})`,
				percent_of_job: `=SUM(J1:J${currentRow})`,
				total: `=SUM(K1:L${currentRow})`,
				cost_per_gross_sqft: `=SUM(L1:L${currentRow})`,
				sale_per_gross_sqft: `=SUM(M1:M${currentRow})`,
			});

			return data;
		},
		totalItems(state, getters) {
			return getters.equipment.length;
		},
	},
	mutations: {
		updateEquipment(state, payload) {
			state.equipment = payload;
		},
		addRow(state, reload) {
			const currentRow = state.equipment.length + 1;
			state.equipment.push({
				type: '',
				cost_code: '',
				component: '',
				hours: '0',
				cost: '0',
				overhead: '0',
				cost_oh: `=E${currentRow}+F${currentRow}`,
				total: `=SUM(K1:L${currentRow})`,
				margin_percent: '.12',
				margin_dollar: `=G${currentRow}/(1-H1)-G${currentRow}`,
				percent_of_job: '.12',
			});

			reload();
		},
		setComments(state, payload) {
			state.comments = payload;
		},
		removeRow(state, { row, reload }) {
			state.equipment.splice(row, 1);

			if (state.comments) {
				for (let i = 0; i < state.comments.length; i++) {
					if (state.comments[i].row === row) {
						state.comments.splice(i, 1);
					}
					if (state.comments[i].row > row) {
						state.comments[i].row -= 1;
					}
				}
			}

			reload();
		},
		handleComment(state, data) {
			const existingCommentIndex = state.comments.findIndex(({ row, col }) => row === data.row && col === data.col);

			if (existingCommentIndex > -1) {
				if (data.comment === '') {
					state.comments.splice(existingCommentIndex, 1);
					return;
				}
				const existingComment = state.comments[existingCommentIndex];
				existingComment.comment = data.comment;
			} else {
				if (data.comment === '') return;
				state.comments.push(data);
			}
		},
	},
	actions: {
	},
};
