import createBreadcrumbs from '../services/breadcrumbs-service.mjs';

function generateBreadcrumbs(route, archived) {
	const startPath = [{
		name: archived === true ? 'Archived Projects' : 'Projects',
		destination: archived === true ? '/archived-projects' : '/projects',
	}];

	return createBreadcrumbs(route, startPath);
}

export default {
	path: '/project/:projectId',
	component: () => import('../views/projects/Container.vue'),
	props: true,
	meta: { authenticate: true },
	children: [
		{
			name: 'Project General',
			path: 'general',
			component: () => import('../views/projects/General.vue'),
			props: true,
			meta: {
				title: 'General',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name}`,
							destination: `/project/${this.projectId}/general`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Schedule',
			path: 'schedule',
			component: () => import('../views/projects/Schedule.vue'),
			props: true,
			meta: {
				title: 'Schedule',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Schedule`,
							destination: `/project/${this.projectId}/schedule`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Budget',
			path: 'budget',
			component: () => import('../views/projects/Budget.vue'),
			props: true,
			meta: {
				title: 'Budget',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Budget`,
							destination: `/project/${this.projectId}/budget`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Change Orders',
			path: 'change-orders',
			component: () => import('../views/projects/ChangeOrders.vue'),
			props: true,
			meta: {
				title: 'Change Orders',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Change Orders`,
							destination: `/project/${this.projectId}/change-orders`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Tool Tracking',
			path: 'tool-tracking',
			component: () => import('../views/projects/ToolTracking.vue'),
			props: true,
			meta: {
				title: 'Tool Tracking',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Tool Tracking`,
							destination: `/project/${this.projectId}/tool-tracking`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Inventory',
			path: 'inventory',
			component: () => import('../views/projects/Inventory.vue'),
			props: true,
			meta: {
				title: 'Inventory',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Inventory`,
							destination: `/project/${this.projectId}/inventory`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project PO\'s',
			path: 'pos',
			component: () => import('../views/projects/Pos.vue'),
			props: true,
			meta: {
				title: 'PO\'s',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / PO's`,
							destination: `/project/${this.projectId}/pos`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Teams',
			path: 'teams',
			component: () => import('../views/projects/Teams.vue'),
			props: true,
			meta: {
				title: 'Teams',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Teams`,
							destination: `/project/${this.projectId}/teams`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Time Sheets',
			path: 'time-sheets',
			component: () => import('../views/projects/Timesheets.vue'),
			props: true,
			meta: {
				title: 'Time Sheets',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Time Sheets`,
							destination: `/project/${this.projectId}/time-sheets`,
						},
					], archived);
				},
			},
		},
		{
			name: 'Project Safety Forms',
			path: 'safety-forms',
			component: () => import('../views/projects/SafetyForms.vue'),
			props: true,
			meta: {
				title: 'Safety Forms',
				breadcrumbs() {
					const { name, archived } = this.project;
					return generateBreadcrumbs([
						{
							name: `${name} / Safety Forms`,
							destination: `/project/${this.projectId}/safety-forms`,
						},
					], archived);
				},
			},
		},
	],
};
