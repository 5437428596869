const defaultUser = {
	name: '',
	avatar: '',
	email: '',
	id: '',
};

export default {
	namespaced: true,
	state: {
		user: defaultUser,
		initialRoute: '/dashboard',
		tokenTimeout: null,
	},
	getters: {
		authenticated(state) {
			return !!state.user && !!state.user.email;
		},
		initialRoute({ initialRoute }) {
			return initialRoute;
		},
	},
	mutations: {
		setUser(state, user) {
			state.user = user == null ? defaultUser : user;
		},
		setInitialRoute(state, route) {
			if (route === '/authentication') {
				return;
			}

			if (route === '/') {
				state.initialRoute = '/Dashboard';
				return;
			}

			state.initialRoute = route;
		},
		setAccessTokenRefresh(state, { func, accessToken }) {
			if (state.tokenTimeout) {
				clearTimeout(state.tokenTimeout);
			}
			const body = accessToken.split('.')[1];
			const { exp } = JSON.parse(window.atob(body));

			const expiration = exp * 1000;
			const current = new Date().getTime();
			const padding = 10 * 60 * 1000;

			const pretimeout = expiration - current - padding;
			if (pretimeout < 0) {
				func();

				return;
			}
			state.tokenTimeout = setTimeout(func, pretimeout);
		},
	},
	actions: {
		async logout({ commit }) {
			const { authService } = this.$cradle;
			authService.clearAuthCookies();
			commit('setUser', null);
		},
		async setInitialRoute({ commit }, route) {
			commit('setInitialRoute', route);
		},
		async signInWithAzure() {
			const { authService } = this.$cradle;
			return authService.signInWithAzure();
		},

		async loginUserWithAccessToken({ commit, dispatch, state }, payload) {
			const { authService, cookies, logDnaFactory: logger } = this.$cradle;
			const user = await authService.getUser(payload.token);
			const refreshToken = cookies.get('refresh_token');
			commit('setAccessTokenRefresh', {
				func: () => dispatch('loginUserWithRefreshToken', { refreshToken }),
				accessToken: payload.token,
			});

			const metaUser = {
				id: user.id,
				name: user.user_metadata.full_name,
				email: user.email,
				avatar: user.user_metadata.avatar_url,
			};

			const now = new Date();
			logger.addContext({
				user: metaUser,
			});
			logger.log(`User ${metaUser.name} logged at ${now.toTimeString()} to ${state.initialRoute}.`);
			commit('setUser', metaUser);
		},
		async saveUser({ state }) {
			const { userRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, eUser] = await to(userRepo.getUserByEmail(state.user.email));
			if (err) {
				logger.error(err.message);
				return;
			}

			if (!eUser) {
				await userRepo.createUser({
					id: state.user.id,
					name: state.user.name,
					email: state.user.email,
					avatar: state.user.avatar_url,
				});
			}
		},

		async loginUserWithRefreshToken({ dispatch }, payload) {
			const { authService } = this.$cradle;
			const data = await authService.refreshAccessToken(payload.refreshToken);
			await dispatch('loginUserWithAccessToken', {
				token: data.access_token,
			});
		},

		async reauthenticate({ dispatch }) {
			const { cookies, to, logDnaFactory: logger } = this.$cradle;
			const refreshToken = cookies.get('refresh_token');
			const accessToken = cookies.get('access_token');

			if (accessToken) {
				//	User is visiting the site with an access token cookie.
				const [err] = await to(
					dispatch('loginUserWithAccessToken', {
						token: accessToken,
					}),
				);

				if (err) {
					if (refreshToken && err.message.includes('token is expired')) {
						//	If the token is expired and a refresh token is present, try to login with that.
						await dispatch('loginUserWithRefreshToken', {
							refreshToken,
						});
						return true;
					}

					logger.error(err.message);
				} else {
					return true;
				}
			}

			return false;
		},

		async login({ dispatch }) {
			const { to, authService, cookies, logDnaFactory: logger } = this.$cradle;
			const refreshToken = cookies.get('refresh_token');
			const accessToken = cookies.get('access_token');
			if (window.location.hash.includes('access_token')) {
				//	User is being redirected by Supabase Auth and URL contains the access token.
				const tokenData = authService.parseHashLocationForAuthTokens();
				authService.setAuthCookies(tokenData.access_token, tokenData.refresh_token);
				await dispatch('loginUserWithAccessToken', {
					token: tokenData.access_token,
				});
			} else if (accessToken) {
				//	User is visiting the site with an access token cookie.
				const [err] = await to(
					await dispatch('loginUserWithAccessToken', {
						token: accessToken,
					}),
				);
				if (err) {
					if (refreshToken && err.message.includes('token is expired')) {
						//	If the token is expired and a refresh token is present, try to login with that.
						await dispatch('loginUserWithRefreshToken', {
							refreshToken,
						});
					} else {
						logger.error(err.message);
					}
				}
			}
		},
	},
};
