export default class EstimatorRepo {
	constructor({ sbTo, supabaseClientFactory, _ }) {
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getAllEstimators() {
		const [err, response] = await this.sbTo(
			this.supabase.from('estimator').select(),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async getEstimatorByName(name) {
		const [err, response] = await this.sbTo(
			this.supabase.from('estimator').select().match({ name }),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async getEstimatorByEmail(email) {
		const [err, response] = await this.sbTo(
			this.supabase.from('estimator').select().match({ email }),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async createEstimator(estimator) {
		if (!estimator) {
			throw new Error('Estimator is required to create estimator.');
		}
		const [err, response] = await this.sbTo(
			this.supabase.from('estimator').insert(estimator),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async updateEstimator(estimator) {
		if (!estimator) {
			throw new Error('Estimator is required to update estimator.');
		}
		const [err, response] = await this.sbTo(
			this.supabase
				.from('estimator')
				.update(estimator)
				.match({ id: estimator.id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async upsertEstimator(estimator) {
		if (!estimator) {
			throw new Error('Estimator is required to upsert estimator.');
		}
		const [err, response] = await this.sbTo(this.supabase
			.from('estimator')
			.upsert(estimator, { onConflict: 'name' }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}
}
