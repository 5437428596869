export default {
	namespaced: true,
	state: {
		show: false,
		title: '',
		text: '',
		onConfirm: null,
		onClose: null,
		closeText: '',
		confirmText: '',
	},
	getters: {},
	mutations: {
		showModal(state, payload) {
			state.title = payload.title;
			state.text = payload.text;
			state.onClose = payload.onClose;
			state.onConfirm = payload.onConfirm;
			state.closeText = payload.closeText;
			state.confirmText = payload.confirmText;
			state.show = true;
		},
		closeModal(state) {
			state.title = '';
			state.text = '';
			state.onClose = null;
			state.onConfirm = null;
			state.show = false;
		},

	},
	actions: {
		async showModal({ commit }, payload) {
			const { onClose, onConfirm } = payload;

			payload.onClose = () => {
				if (onClose) onClose();
				commit('closeModal');
			};
			payload.onConfirm = () => {
				if (onConfirm) onConfirm();
				commit('closeModal');
			};
			commit('showModal', payload);
		},
	},
};
