class CostConverterService {
	constructor({ _, to, fredRepo }) {
		this._ = _;
		this.to = to;
		this.fredRepo = fredRepo;
	}

	async convertByDateAndCode(val, date, code) {
		const [seriesErr, seriesRes] = await this.to(this.fredRepo.getObservationsBySeriesAndDate(code, date));
		if (seriesErr) {
			throw seriesErr;
		}
		const [latestObs] = seriesRes.data.observations;
		const latestDate = latestObs.date;
		let obsMapping = this._createObservationMapping(seriesRes.data);
		obsMapping = this._scaleToDate(obsMapping, date);
		const updatedCost = (val / 100) * obsMapping[latestDate];
		return [updatedCost, latestDate];
	}

	_scaleToDate(obsMapping, date) {
		const scaleObservation = obsMapping[date];
		if (!scaleObservation) {
			throw new Error(`No observation found for scale date: ${date}`);
		}
		return this._.mapValues(obsMapping, (val) => (val / scaleObservation) * 100);
	}

	_createObservationMapping({ observations }) {
		return this._.chain(observations)
			.keyBy('date')
			.mapValues(({ value }) => Number.parseFloat(value))
			.value();
	}
}

export default CostConverterService;
