<template>
<div
	v-if="userAvatar"
	:class="avatarClass"
>
	<img
		class="avatar-img"
		:src="userAvatar"
		:alt="`${name}'s avatar`"
	>
</div>
<div
	v-else-if="name"
	class="media align-items-center"
>
	<div :class="initialsClass">
		<span class="avatar-initials">{{ userInitials }}</span>
	</div>
	<span
		v-if="full"
		class="avatar-name"
	>{{ name }}</span>
</div>
</template>
<script>

export default {
	props: {
		name: {
			type: String,
			default: '',
		},
		avatar: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: 'sm',
		},
		full: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {};
	},
	computed: {
		userInitials() {
			if (!this.name) {
				return '';
			}

			return this.name.split(' ').slice(0, 2).map((item) => item[0]).join('');
		},
		initialsClass() {
			return `avatar avatar-${this.size} avatar-primary avatar-circle`;
		},
		avatarClass() {
			return `avatar avatar-soft-dark avatar-${this.size} avatar-circle`;
		},
		userAvatar() {
			if (this.avatar) {
				return this.avatar;
			}

			if (this.name) {
				return this.$store.getters['user/getUserByName'](this.name)?.avatar;
			}

			return null;
		},
	},
};
</script>
<style scoped>
.avatar-name {
	padding-left: 5px;
}
.avatar.avatar-xs > .avatar-initials {
	font-size: .75rem;
}

.avatar {
	z-index: 0;
}

</style>
