export default {
	namespaced: true,
	state: {
		bids: [],
		query: {
			filters: {
				archived: false,
			},
			page: 1,
			limit: 10000,
			totalRows: 0,
			from: 0,
			to: 10000,
		},
		bidCountsPerRegion: [],
		bidCountsPerEstimator: [],
		status: 'loading',
	},
	getters: {},
	mutations: {
		setBidCountsPerRegion(state, bidCountsPerRegion) {
			state.bidCountsPerRegion = bidCountsPerRegion;
		},
		setBidCountsPerEstimator(state, bidCountsPerEstimator) {
			state.bidCountsPerEstimator = bidCountsPerEstimator;
		},
		setBids(state, bids) {
			state.bids = bids;
		},
		setStatus(state, status) {
			state.status = status;
		},
	},
	actions: {
		async getBidCountsPerRegion({ commit }) {
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(bidRepo.getBidCountsPerRegion());
			if (err) {
				logger.error(err.message);
				commit('setBidCountsPerRegion', []);
			} else {
				commit('setBidCountsPerRegion', data);
			}
		},
		async getBidCountsPerEstimator({ commit }) {
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(bidRepo.getBidCountsPerEstimator());
			if (err) {
				logger.error(err.message);
				commit('setBidCountsPerEstimator', []);
			} else {
				commit('setBidCountsPerEstimator', data);
			}
		},
		async getAllBids({ commit, state }) {
			commit('setStatus', 'loading');
			const { to, bidRepo, logDnaFactory: logger } = this.$cradle;

			const [err, data] = await to(bidRepo.getAllBids(state.query));
			if (err) {
				logger.error(err.message);
				commit('setBids', []);
				throw err;
			} else {
				state.query.totalRows = data.count;
				commit('setBids', data.data);
				commit('setStatus', 'display');
			}
		},
	},
};
