export default class ProjectRepo {
	constructor({ supaTo, supabaseClientFactory, _, supabaseQueryService, to, sbTo }) {
		this.supaTo = supaTo;
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this.supabaseQueryService = supabaseQueryService;
		this._ = _;
		this.to = to;
	}

	async findByProjectNumber(projectNumber) {
		const [err, project] = await this.sbTo(this.supabase
			.from('project')
			.select()
			.ilike('project_number', projectNumber)
			.limit(1));

		if (err) {
			throw err;
		}

		return this._.get(project, '[0]', null);
	}

	async getProjectById(id) {
		if (!id) {
			throw new Error('id is required for get project by id');
		}
		const [err, project] = await this.supaTo(
			this.supabase
				.from('project_view')
				.select('*')
				.match({ id }),
		);

		if (err) {
			throw err;
		} else {
			return this._.get(project, 'data[0]', []);
		}
	}

	async getArchivedProjectById(id) {
		if (!id) {
			throw new Error('id is required for get project by id');
		}
		const [err, project] = await this.supaTo(
			this.supabase
				.from('archived_project_view')
				.select('*')
				.match({ id }),
		);

		if (err) {
			throw err;
		} else {
			return this._.get(project, 'data[0]', []);
		}
	}

	async getAllProjects() {
		const [err, response] = await this.supaTo(this.supabase
			.from('project')
			.select('*'));

		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getProjects(params) {
		const base = this.supabase.from('project_view').select('*');
		const query = this.supabaseQueryService.createQuery(base, params);
		const [err, response] = await this.supaTo(query);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async countProjects() {
		const [err, response] = await this.supaTo(this.supabase.from('project_view').select('*', { count: 'exact' }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async getArchivedProjects(params) {
		const base = this.supabase.from('archived_project_view').select('*');
		const query = this.supabaseQueryService.createQuery(base, params);
		const [err, response] = await this.supaTo(query);
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async countArchivedProjects() {
		const [err, response] = await this.supaTo(this.supabase.from('archived_project_view').select('*', { count: 'exact' }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}

	async createProject() {
		const [error, data] = await this.supaTo(this.supabase.rpc('create_project_fn', json()));
		if (error) {
			throw error;
		} else if (this._.has(data, 'data.length')) {
			return this.getProjectById(data.data);
		} else {
			throw new Error('Unable to create project.');
		}
	}

	async downloadProjects(to, params, columns, name) {
		const [error, data] = await this.sbTo(this.supabase.functions.invoke('create-xlsx', {
			body: JSON.stringify({
				to,
				name,
				params,
				table: 'project_view',
				columns,
			}),
		}));

		if (error) {
			throw error;
		}
		return data;
	}

	async downloadArchivedProjects(to, params, columns, name) {
		const [error, data] = await this.sbTo(this.supabase.functions.invoke('create-xlsx', {
			body: JSON.stringify({
				to,
				name,
				params,
				table: 'archived_project_view',
				columns,
			}),
		}));

		if (error) {
			throw error;
		}
		return data;
	}
}
