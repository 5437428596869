export default class BidScopeRepo {
	constructor({ _, supaTo, supabaseClientFactory, supabaseQueryService, to }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this.supabaseQueryService = supabaseQueryService;
		this._ = _;
		this.to = to;
	}

	async getBidScope(bidId) {
		const [err, response] = await this.supaTo(this.supabase.from('bid_scope').select('*').match({ bid_id: bidId }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async upsertBidScope(bidId, bidRevisionId, data) {
		const [err, existingBidScope] = await this.to(this.getBidScope(bidId));

		if (err) {
			throw err;
		}

		if (!existingBidScope) {
			const [err0, response] = await this.to(this.createBidScope(bidId, bidRevisionId, data));
			if (err0) {
				throw err0;
			} else {
				return response;
			}
		}

		const [err1, response1] = await this.supaTo(this.supabase.from('bid_scope').update({ data }).match({ bid_id: bidId }));

		if (err1) {
			throw err1;
		}

		return response1;
	}

	async createBidScope(bidId, bidRevisionId, data) {
		const [err, response] = await this.supaTo(
			this.supabase.from('bid_scope')
				.insert({ bid_id: bidId, bid_revision_id: bidRevisionId, data }),
		);

		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}
}
