export default class SupplierRepo {
	constructor({ sbTo, supabaseClientFactory, _ }) {
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async getAllSuppliers() {
		const [err, response] = await this.sbTo(
			this.supabase.from('supplier').select('id, name'),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async getSuppliersByNames(names) {
		const [err, response] = await this.sbTo(
			this.supabase.from('supplier').select('id, name').in('name', names),
		);
		if (err) {
			throw err;
		} else {
			return response;
		}
	}

	async addSupplierToBid(bidRevisionId, supplierId, category, rfqSentAt, rfqResponseAt) {
		if (!supplierId) {
			throw new Error('supplierId is required to add a supplierId to bid.');
		}

		if (!bidRevisionId) {
			throw new Error('bidRevisionId is required to add a supplier to bid.');
		}

		const [err, response] = await this.sbTo(
			this.supabase.from('bid_supplier').insert({
				bid_revision_id: bidRevisionId,
				supplier_id: supplierId,
				category,
				rfq_sent_at: rfqSentAt,
				rfq_response_at: rfqResponseAt,
			}),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async removeSupplierFromBid(bidRevisionId, supplierId, category) {
		if (!supplierId) {
			throw new Error('supplierId is required to add a supplierId to bid.');
		}

		if (!bidRevisionId) {
			throw new Error('bidRevisionId is required to add a supplier to bid.');
		}

		if (!category) {
			throw new Error('category is required to add a supplier to bid.');
		}

		const [err] = await this.sbTo(
			this.supabase.from('bid_supplier').delete().match({
				bid_revision_id: bidRevisionId,
				supplier_id: supplierId,
				category,
			}),
		);
		if (err) {
			throw err;
		}
	}

	async createSupplier(name) {
		if (!name) {
			throw new Error('name is required to create name.');
		}
		const [err, response] = await this.sbTo(
			this.supabase.from('supplier').insert({ name }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async updateSupplier(supplier) {
		if (!supplier) {
			throw new Error('supplier is required.');
		}
		const { id } = supplier;
		const [err, response] = await this.sbTo(
			this.supabase
				.from('supplier')
				.update(supplier)
				.match({ id }),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async updateBidSupplier(supplier) {
		if (!supplier) {
			throw new Error('supplier is required to update bid_supplier.');
		}
		const bidRevisionId = supplier.bid_revision_id;
		const supplierId = supplier.supplier_id;
		const { category } = supplier;

		delete supplier.category;
		const [err, response] = await this.sbTo(
			this.supabase
				.from('bid_supplier')
				.update(supplier)
				.match({
					bid_revision_id: bidRevisionId,
					supplier_id: supplierId,
					category,
				}),
		);
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async upsertSupplier(supplier) {
		if (!supplier) {
			throw new Error('supplier is required to upsert supplier.');
		}

		const [err, response] = await this.sbTo(this.supabase
			.from('supplier')
			.upsert(supplier, { onConflict: 'name' }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}

	async upsertBidSupplier(supplier) {
		if (!supplier) {
			throw new Error('supplier is required to upsert BidSupplier.');
		}

		const [err, response] = await this.sbTo(this.supabase
			.from('bid_supplier')
			.upsert(supplier));
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}
}
