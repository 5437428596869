export default {
	namespaced: true,
	state: {
		activities: [],
		display: false,
	},
	getters: {
		bidRevisions(state) {
			return state.activities.filter((activity) => activity.type === 'REBID' || activity.type === 'CREATE');
		},
	},
	mutations: {
		addActivity(state, activity) {
			state.activities.push(activity);
		},
		setActivities(state, activities) {
			state.activities = activities;
		},
		toggleDisplay(state) {
			state.display = !state.display;
		},
	},
	actions: {
		async getBidActivities({ commit }, bidId) {
			const { activityRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, activities] = await to(activityRepo.getActivitiesByBidId(bidId));

			if (err) {
				logger.error(err.message);
				return;
			}

			commit('setActivities', activities);
		},

		async getProjectActivities({ commit }, projectId) {
			const { activityRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, activities] = await to(activityRepo.getActivitiesByProjectId(projectId));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setActivities', activities);
		},
		async createActivity({ commit }, newActivity) {
			const { activityRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, activity] = await to(activityRepo.createActivity(newActivity));

			if (err) {
				logger.error(err.message);
				return;
			}

			commit('addActivity', activity);
		},
		async toggleDisplay({ commit }) {
			commit('toggleDisplay');
		},
	},
};
