import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import {
	ValidationObserver,
	ValidationProvider,
	extend,
	localize,
} from 'vee-validate';
import vClickOutside from 'v-click-outside';
import Multiselect from 'vue-multiselect';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import { sync } from 'vuex-router-sync';
import { registerAllModules } from 'handsontable/registry';
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import router from './router';
import store from './store';
import initializeDIContainer from '../ui-registry.mjs';

import './styles/scss/theme.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'quill/dist/quill.core.css';
import 'quill-mention/dist/quill.mention.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'animate.css';

sync(store, router);

// register Handsontable's modules
registerAllModules();
Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.config.productionTip = false;

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

extend('url', {
	params: ['url'],
	validate(value) {
		if (!value || value.length === 0) {
			return true;
		}
		/* eslint-disable no-restricted-syntax */
		try {
			/* eslint-disable no-new */
			new URL(value);
			return true;
		} catch (err) {
			return '{_field_} must be a valid url.';
		}
	},
});

localize('en', en);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('Multiselect', Multiselect);
Vue.component('Apexchart', VueApexCharts);

initializeDIContainer()
	.then(($registry) => {
		store.$cradle = $registry.cradle;

		Vue.mixin({
			methods: {
				$cradle() {
					return $registry.cradle;
				},
			},
		});

		/* @TODO - causes parceljs HMR to fail on error
		logger.addContext({
			environment: 'CURRENT_ENVIRONMENT',
		});
		Vue.config.errorHandler = function handleError(err, vm, info) {
			if (process.env.NODE_ENV === 'development') {
				// eslint-disable no-console
				console.error(err, vm, info);
			}
			//			logger.error(`Unhandled Error: ${err?.message}`);

			const msg = err?.message ? err.message : 'Oh no! An error occured. Try again and if the problem persists, please contact support.';
			store.dispatch('modal/showModal', { title: 'Error', text: msg });
		};
		*/

		new Vue({
			router,
			store,
			render: (h) => h(App),
		}).$mount('#app');
	}).catch((error) => {
		throw new Error(`Problem loading the UI's $registry in main.js: \n ${error.message}`);
	});
