import Settings from '../views/Settings.vue';
import createBreadcrumbs from '../services/breadcrumbs-service.mjs';

function generateBreadcrumbs(route) {
	return createBreadcrumbs(route);
}

export default {
	path: '/settings',
	name: 'Settings',
	component: Settings,
	meta: { authenticate: true },
	children: [
		{
			name: 'Architects',
			path: 'architects',
			component: () => import('../views/Architects.vue'),
			meta: {
				authenticate: true,
				breadcrumbs() {
					return generateBreadcrumbs({
						name: 'Architects',
						destination: 'architects',
					});
				},
			},
		},
		{
			name: 'Structural Engineers',
			path: 'structural-engineers',
			component: () => import('../views/StructuralEngineers.vue'),
			meta: {
				authenticate: true,
				breadcrumbs() {
					return generateBreadcrumbs({
						name: 'Structural Engineers',
						destination: 'structural-engineers',
					});
				},
			},
		},
		{
			name: 'Estimators',
			path: 'estimators',
			component: () => import('../views/Estimators.vue'),
			meta: {
				authenticate: true,
				breadcrumbs() {
					return generateBreadcrumbs({
						name: 'Estimators',
						destination: 'estimators',
					});
				},
			},
		},
	],
};
