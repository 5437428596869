export default class BidCSRepo {
	constructor({ _, supaTo, supabaseClientFactory, supabaseQueryService, to }) {
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this.supabaseQueryService = supabaseQueryService;
		this._ = _;
		this.to = to;
	}

	async getBidCS(bidId) {
		const [err, response] = await this.supaTo(this.supabase.from('bid_cs').select('*').match({ bid_id: bidId }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data[0]', null);
		}
	}

	async upsertBidCS(bidId, bidRevisionId, data) {
		const [err, existingBidCS] = await this.to(this.getBidCS(bidId));

		if (err) {
			throw err;
		}

		if (!existingBidCS) {
			const [err0, response] = await this.to(this.createBidScope(bidId, bidRevisionId, data));
			if (err0) {
				throw err0;
			} else {
				return response;
			}
		}

		const [err1, response1] = await this.supaTo(this.supabase.from('bid_cs').update({ cs_sheet: data }).match({ bid_id: bidId }));

		if (err1) {
			throw err1;
		}

		return response1;
	}

	async createBidScope(bidId, bidRevisionId, data) {
		const [err, response] = await this.supaTo(
			this.supabase.from('bid_cs')
				.insert({ bid_id: bidId, bid_revision_id: bidRevisionId, cs_sheet: data }),
		);

		if (err) {
			throw err;
		} else {
			return this._.get(response, 'data', []);
		}
	}
}
