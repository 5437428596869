<template>
<div class="main has-navbar-vertical-aside navbar-vertical-aside-show-xl">
	<div
		:class="leftNavCssClassObj"
	>
		<top-bar />
		<left-nav />
		<main
			id="content"
			role="main"
			class="main pointer-event splitted-content-main"
		>
			<div
				class="splitted-content-fluid content-space"
				:class="viewContainerClass"
			>
				<global-alerter />
				<slot />
			</div>
			<right-nav />
		</main>
	</div>
</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import LeftNav from '../../components/LeftNav.vue';
import RightNav from '../../components/RightNav.vue';
import TopBar from '../../components/TopBar.vue';
import isMobileOrTablet from '../../mixins/isMobileOrTablet';
import GlobalAlerter from '../../components/GlobalAlerter.vue';

export default {
	name: 'DefaultLayout',
	components: { LeftNav, RightNav, TopBar, GlobalAlerter },
	mixins: [isMobileOrTablet],
	computed: {
		...mapState('leftNav', ['navState']),
		...mapState('rightNavbarStore', ['display']),
		...mapGetters('authentication', ['authenticated', 'initialRoute']),
		leftNavCssClassObj() {
			return {
				'has-navbar-vertical-aside navbar-vertical-aside-show-xl': this
					.authenticated,
				'navbar-vertical-aside-mini-mode': this.navState === 'MINI',
			};
		},
		viewContainerClass() {
			return this.display && !this.isMobileOrTablet ? 'right-nav-active' : '';
		},
	},
	watch: {
		$route() {
			if (this.display) {
				this.setDisplay(false);
			}
		},
	},
	mounted() {
	},
	methods: {
		...mapActions('authentication', ['login', 'saveUser']),
		...mapMutations('leftNav', ['setNav']),
		...mapMutations('rightNavbarStore', ['setDisplay']),
		toggleLeftNav() {
			this.$root.$emit('nav-toggle');
		},
	},
};
</script>
