<template>
<div class="content container-fluid">
	<loader v-if="loading" />
	<div
		v-if="error"
		class="row align-items-sm-center py-sm-10"
	>
		<div class="col-sm-6 col-md-4 text-center text-sm-left">
			<div
				class="alert alert-danger"
				role="alert"
			>
				<h3 class="alert-heading">
					<i class="tio-warning mt-1 mr-1" />Error
				</h3>

				<p class="text-inherit">
					We were unable to log you in at this time. Please try again and if problem persists, contact support.
				</p>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from '../components/Loader.vue';

export default {
	name: 'Boostrap',
	components: { Loader },
	data() {
		return {
			loading: true,
			error: false,
		};
	},
	computed: {
		...mapGetters('authentication', ['authenticated', 'initialRoute']),
	},
	async created() {
		// @TODO - fix page refresh with we don't see if we alrady have auth....
		return this.attemptLogin();
	},
	methods: {
		...mapActions('authentication', ['login', 'saveUser']),
		async attemptLogin() {
			const { to } = this.$cradle();
			this.loading = true;

			const [err] = await to(this.login());
			if (err) {
				this.showError();
				return;
			}

			const initialPath = this.$store.getters['authentication/initialRoute'];
			const [errS] = await to(this.saveUser());
			if (errS) {
				this.showError();
				return;
			}

			this.loading = false;
			this.$router.push(initialPath);
		},
		showError() {
			this.loading = false;
			this.error = true;
		},
	},
};
</script>
