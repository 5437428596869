import projectService from '../../services/project-service.mjs';

export default {
	namespaced: true,
	state: {
		...projectService.state,
		projects: [],
		query: {
			filters: {},
			page: 1,
			limit: 9999,
			totalRows: 0,
			from: 0,
			to: 9999,
			sort: {
				by: '',
				desc: false,
			},
		},
		availableFilters: [],
		defaultFilters: [
			{
				name: 'All Projects',
			},
		],
		columns: [],
		loaded: false,
		filterGroups: [
			{
				name: 'General Details',
				show: true,
				render: true,
				fields: [
					{
						name: 'Project Number',
						filterName: 'project_number',
						key: 'custom1',
						dataType: 'string',
						filterType: 'fuzzy match',
						display: true,
						sortable: true,
					},
					{
						name: 'Name',
						filterName: 'name',
						key: 'name',
						dataType: 'string',
						filterType: 'fuzzy match',
						display: true,
						sortable: true,
					},
					{
						name: 'Status',
						filterName: 'status',
						key: 'custom2',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'types/getProjectStatuses',
					},
					{
						name: 'Type',
						filterName: 'type',
						key: 'custom3',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'types/getProjectTypes',
					},
					{
						name: 'Description',
						filterName: 'description',
						key: 'custom4',
						dataType: 'text',
						filterType: 'fuzzy match',
						display: true,
						sortable: true,
					},
					{
						name: 'Region Name',
						filterName: 'region_name',
						key: 'region_name',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'region/getRegions',
					},
					{
						name: 'General Contract Name',
						filterName: 'general_contractor_name',
						key: 'general_contractor_name',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'generalContractor/getGeneralContractors',
					},
					{
						name: 'On Hold',
						filterName: 'on_hold',
						key: 'on_hold',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						display: true,
						sortable: true,
						tdClass: 'text-center',
					},
					{
						name: 'Date of Last Report',
						filterName: 'date_of_last_report',
						key: 'date_of_last_report',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
				],
			},
			{
				name: 'Billed to Date',
				show: false,
				render: true,
				fields: [
					{
						name: 'Work Billed to Date',
						filterName: 'work_billed_to_date',
						key: 'work_billed_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						sortable: true,
						display: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Budget',
				show: false,
				render: true,
				fields: [
					{
						name: 'Last Cost Update Date',
						filterName: 'last_cost_update_date',
						key: 'last_cost_update_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
					{
						name: 'Budget Remaining',
						filterName: 'budget_remaining',
						key: 'budget_remaining',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Budget Percent Utilized',
						filterName: 'budget_percent_utilized',
						key: 'budget_percent_utilized',
						dataType: 'percent',
						filterType: 'between percent',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Budget Progress',
						filterName: 'budget_progress',
						key: 'custom6',
						dataType: 'number',
						filterType: 'between numbers',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Stored Material',
						filterName: 'stored_material',
						key: 'stored_material',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Cost at Completion',
						filterName: 'cost_at_completion',
						key: 'cost_at_completion',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Cost to Complete',
						filterName: 'cost_to_complete',
						key: 'cost_to_complete',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Labor Hours to Complete',
						filterName: 'labor_hours_to_complete',
						key: 'labor_hours_to_complete',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Commitment',
				show: false,
				render: true,
				fields: [
					{
						name: 'Original Commitment',
						filterName: 'original_commitment',
						key: 'original_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Pending Commitment Changes',
						filterName: 'pending_commitment_changes',
						key: 'pending_commitment_changes',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Approved Commitment Changes',
						filterName: 'approved_commitment_changes',
						key: 'approved_commitment_changes',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Revised Commitment',
						filterName: 'revised_commitment',
						key: 'revised_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Commitment Invoiced',
						filterName: 'commitment_invoiced',
						key: 'commitment_invoiced',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Labor Commitment',
						filterName: 'total_labor_commitment',
						key: 'total_labor_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Material Commitment',
						filterName: 'total_material_commitment',
						key: 'total_material_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Subcontact Commitment',
						filterName: 'total_subcontact_commitment',
						key: 'total_subcontact_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Equipment Commitment',
						filterName: 'total_equipment_commitment',
						key: 'total_equipment_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Overhead Commitment',
						filterName: 'total_overhead_commitment',
						key: 'total_overhead_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Other Commitment',
						filterName: 'total_other_commitment',
						key: 'total_other_commitment',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Labor Commitment Invoiced',
						filterName: 'total_labor_commitment_invoiced',
						key: 'total_labor_commitment_invoiced',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Material Commitment Invoiced',
						filterName: 'total_material_commitment_invoiced',
						key: 'total_material_commitment_invoiced',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Subcontract Commitment Invoiced',
						filterName: 'total_subcontract_commitment_invoiced',
						key: 'total_subcontract_commitment_invoiced',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Equipment Commitment Invoiced',
						filterName: 'total_equipment_commitment_invoiced',
						key: 'total_equipment_commitment_invoiced',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Overhead Commitment Invoiced',
						filterName: 'total_overhead_commitment_invoiced',
						key: 'total_overhead_commitment_invoiced',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Other Commitment Invoiced',
						filterName: 'total_other_commitment_invoiced',
						key: 'total_other_commitment_invoiced',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Contracted Details',
				show: false,
				render: true,
				fields: [
					{
						name: 'Original Contract Amount',
						filterName: 'original_contract_amount',
						key: 'original_contract_amount',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Revised Contract Amount',
						filterName: 'revised_contract_amount',
						key: 'revised_contract_amount',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Bonded',
						filterName: 'bonded',
						key: 'bonded',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						display: true,
						sortable: true,
						tdClass: 'text-center',
					},
					{
						name: 'Time and Material',
						filterName: 'time_and_material',
						key: 'time_and_material',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						display: true,
						sortable: true,
						tdClass: 'text-center',
					},
					{
						name: 'Certified Project',
						filterName: 'certified_project',
						key: 'certified_project',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						display: true,
						sortable: true,
						tdClass: 'text-center',
					},
					{
						name: 'Contract Date',
						filterName: 'contract_date',
						key: 'contract_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
					{
						name: 'Contract Retainage Percent',
						filterName: 'contract_retainage_percent',
						key: 'contract_retainage_percent',
						dataType: 'percent',
						filterType: 'between percent',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Approved Contract Changes',
						filterName: 'approved_contract_changes',
						key: 'approved_contract_changes',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Retainage Held to Date',
						filterName: 'retainage_held_to_date',
						key: 'retainage_held_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Costs to Date',
				show: false,
				render: true,
				fields: [
					{
						name: 'Cost to Date',
						filterName: 'cost_to_date',
						key: 'cost_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Labor Cost to Date',
						filterName: 'total_labor_cost_to_date',
						key: 'total_labor_cost_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Material Cost to Date',
						filterName: 'total_material_cost_to_date',
						key: 'total_material_cost_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Labor Units to Date',
						filterName: 'total_labor_units_to_date',
						key: 'total_labor_units_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Subcontract Cost to Date',
						filterName: 'total_subcontract_cost_to_date',
						key: 'total_subcontract_cost_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Equipment Cost to Date',
						filterName: 'total_equipment_cost_to_date',
						key: 'total_equipment_cost_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Overhead Cost to Date',
						filterName: 'total_overhead_cost_to_date',
						key: 'total_overhead_cost_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Other Cost to Date',
						filterName: 'total_other_cost_to_date',
						key: 'total_other_cost_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Estimate',
				show: false,
				render: true,
				fields: [
					{
						name: 'Total Estimate',
						filterName: 'total_estimate',
						key: 'total_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Labor Estimate',
						filterName: 'total_labor_estimate',
						key: 'total_labor_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Material Estimate',
						filterName: 'total_material_estimate',
						key: 'total_material_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Retainage Percent',
						filterName: 'retainage_percent',
						key: 'retainage_percent',
						dataType: 'percent',
						filterType: 'between percent',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Original Estimate',
						filterName: 'original_estimate',
						key: 'original_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Pending Estimate Changes',
						filterName: 'pending_estimate_changes',
						key: 'pending_estimate_changes',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Approved Estimate Changes',
						filterName: 'approved_estimate_changes',
						key: 'approved_estimate_changes',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Labor Units Estimate',
						filterName: 'total_labor_units_estimate',
						key: 'total_labor_units_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Subcontract Estimate',
						filterName: 'total_subcontract_estimate',
						key: 'total_subcontract_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Equipment Estimate',
						filterName: 'total_equipment_estimate',
						key: 'total_equipment_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Overhead Estimate',
						filterName: 'total_overhead_estimate',
						key: 'total_overhead_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Other Estimate',
						filterName: 'total_other_estimate',
						key: 'total_other_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Over Under Estimate',
						filterName: 'over_under_estimate',
						key: 'over_under_estimate',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Location',
				show: false,
				render: true,
				fields: [
					{
						name: 'Address',
						filterName: 'address',
						key: 'address',
						dataType: 'text',
						filterType: 'fuzzy match',
						display: true,
						sortable: true,
					},
					{
						name: 'City',
						filterName: 'city',
						key: 'city',
						dataType: 'string',
						filterType: 'fuzzy match',
						display: true,
						sortable: true,
					},
					{
						name: 'State',
						filterName: 'state',
						key: 'state',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'type/getStates',
					},
					{
						name: 'Address 2',
						filterName: 'address_2',
						dataType: 'string',
						filterType: 'fuzzy match',
						display: true,
						sortable: false,
					},
					{
						name: 'Zip Code',
						filterName: 'zip_code',
						dataType: 'number',
						filterType: 'fuzzy match',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Margin',
				show: false,
				render: true,
				fields: [
					{
						name: 'Current Margin',
						filterName: 'current_margin',
						key: 'current_margin',
						dataType: 'percent',
						filterType: 'between percent',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Original Margin',
						filterName: 'original_margin',
						key: 'original_margin',
						dataType: 'percent',
						filterType: 'between percent',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Revised Margin',
						filterName: 'revised_margin',
						key: 'revised_margin',
						dataType: 'percent',
						filterType: 'between percent',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Paid to Date',
				show: false,
				render: true,
				fields: [
					{
						name: 'Payments to Date',
						filterName: 'payments_to_date',
						key: 'payments_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Dollars Paid to Date',
						filterName: 'dollars_paid_to_date',
						key: 'dollars_paid_to_date',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Labor Dollars Paid',
						filterName: 'total_labor_dollars_paid',
						key: 'total_labor_dollars_paid',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Material Dollars Paid',
						filterName: 'total_material_dollars_paid',
						key: 'total_material_dollars_paid',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Subcontract Dollars Paid',
						filterName: 'total_subcontract_dollars_paid',
						key: 'total_subcontract_dollars_paid',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Equipment Dollars Paid',
						filterName: 'total_equipment_dollars_paid',
						key: 'total_equipment_dollars_paid',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Overhead Dollars Paid',
						filterName: 'total_overhead_dollars_paid',
						key: 'total_overhead_dollars_paid',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Total Other Dollars Paid',
						filterName: 'total_other_dollars_paid',
						key: 'total_other_dollars_paid',
						dataType: 'dollar',
						filterType: 'between dollars',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Personnel',
				show: false,
				render: true,
				fields: [
					{
						name: 'Project Manager Name',
						filterName: 'project_manager_name',
						key: 'project_manager_name',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'projectManager/getProjectManagers',
					},
					{
						name: 'RGM Name',
						filterName: 'rgm_name',
						key: 'rgm_name',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'rgm/getRGMS',
					},
					{
						name: 'Site Supervisor Name',
						filterName: 'site_supervisor_name',
						key: 'site_supervisor_name',
						dataType: 'set',
						filterType: 'multi (or)',
						display: true,
						sortable: true,
						getter: 'siteSupervisor/getSiteSupervisors',
					},
				],
			},
			{
				name: 'Schedule',
				show: false,
				render: true,
				fields: [
					{
						name: 'Actual Start Date',
						filterName: 'actual_start_date',
						key: 'actual_start_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
					{
						name: 'Actual Complete Date',
						filterName: 'actual_complete_date',
						key: 'actual_complete_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
					{
						name: 'Estimated Start Date',
						filterName: 'estimated_start_date',
						key: 'estimated_start_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
					{
						name: 'Estimated Complete Date',
						filterName: 'estimated_complete_date',
						key: 'estimated_complete_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
					{
						name: 'Schedule Elapsed Days',
						filterName: 'schedule_elapsed_days',
						key: 'schedule_elapsed_days',
						dataType: 'number',
						filterType: 'between numbers',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Schedule Remaining Days',
						filterName: 'schedule_remaining_days',
						key: 'schedule_remaining_days',
						dataType: 'number',
						filterType: 'between numbers',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Schedule Percent Utilized',
						filterName: 'schedule_percent_utilized',
						key: 'schedule_percent_utilized',
						dataType: 'percent',
						filterType: 'between percent',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Schedule progress',
						filterName: 'schedule_progress',
						key: 'custom7',
						dataType: 'number',
						filterType: 'between numbers',
						display: true,
						sortable: false,
						tdClass: 'text-right',
					},
					{
						name: 'Revised Start Date',
						filterName: 'revised_start_date',
						key: 'revised_start_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
					{
						name: 'Revised Complete Date',
						filterName: 'revised_complete_date',
						key: 'revised_complete_date',
						dataType: 'date',
						filterType: 'between dates',
						display: true,
						sortable: true,
					},
				],
			},
			{
				name: 'Square Footage',
				show: false,
				render: true,
				fields: [
					{
						name: 'Gross SQFT',
						filterName: 'gross_sqft',
						key: 'gross_sqft',
						dataType: 'number',
						filterType: 'between numbers',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Porch SQFT',
						filterName: 'porch_sqft',
						key: 'porch_sqft',
						dataType: 'number',
						filterType: 'between numbers',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
					{
						name: 'Units',
						filterName: 'units',
						key: 'units',
						dataType: 'number',
						filterType: 'between numbers',
						display: true,
						sortable: true,
						tdClass: 'text-right',
					},
				],
			},
		],
		table: 'PROJECTVIEW',
	},
	getters: {},
	mutations: {
		...projectService.mutations,
		insertProject(state, project) {
			state.projects = [...state.projects, project];
		},
	},
	actions: {
		...projectService.actions,
		async createProject({ commit }) {
			commit('setLoadStatus', false);

			const { to, projectRepo, logDnaFactory: logger } = this.$cradle;
			const [err, project] = await to(projectRepo.createProject());
			if (err) {
				logger.error(err.message);
				commit('setLoadStatus', true);
				throw err;
			}
			commit('project/insertProject', project, { root: true });
			commit('setLoadStatus', true);
			return project;
		},
		async getProjects({ commit, state }) {
			const { _, to, projectRepo, logDnaFactory: logger } = this.$cradle;
			const query = _.cloneDeep(state.query);

			const [err, projects] = await to(projectRepo.getProjects(query));
			if (err) {
				logger.error(err.message);
				commit('setProjects', []);
				throw err;
			} else {
				commit('setProjects', projects);
				commit('setTotalRows', projects.length);
			}
		},
		async download({ state, rootState }, name) {
			const { _, to, projectRepo, logDnaFactory: logger } = this.$cradle;
			const sendTo = rootState.authentication.user.email;
			const query = _.cloneDeep(state.query);

			const exceptColumns = ['budget_progress', 'schedule_progress'];
			const columns = state.columns.filter(({ display, filterName }) => {
				if (!exceptColumns.includes(filterName) && display) {
					return true;
				}

				return false;
			}).map(({ filterName }) => filterName);

			const [err] = await to(projectRepo.downloadProjects(sendTo, query, columns, name));
			if (err) {
				logger.error(err.message);
				throw err;
			}
		},
	},
};
