export default {
	namespaced: true,
	state: {
		generalContractors: [],
	},
	getters: {
		getGeneralContractors: (state) => state.generalContractors.map(({ name }) => name),
	},
	mutations: {
		setGeneralContractors(state, generalContractors) {
			generalContractors.sort((a, b) => {
				if (a.name < b.name) { return -1; }
				if (a.name > b.name) { return 1; }
				return 0;
			});
			state.generalContractors = generalContractors;
		},
	},
	actions: {
		async getGeneralContractors({ commit }) {
			const { to, generalContractorRepo, logDnaFactory: logger } = this.$cradle;
			const [err, data] = await to(generalContractorRepo.getAllGeneralContractors());
			if (err) {
				logger.error(err.message);
				commit('setGeneralContractors', []);
				throw err;
			} else {
				commit('setGeneralContractors', data);
			}
		},
	},
};
