<template>
<div>
	<div
		v-if="display"
		class="hs-unfold-overlay"
		style="display: block;"
		@click.prevent="toggleDisplay"
	/>
	<div
		v-if="display"
		class="hs-unfold-content sidebar sidebar-bordered sidebar-box-shadow
		hs-unfold-content-initialized hs-unfold-css-animation animated fadeInRight"
		style="width: 35rem; animation-duration: 300ms;"
		data-hs-target-height="0"
		data-hs-unfold-content=""
		data-hs-unfold-content-animation-in="fadeInRight"
		data-hs-unfold-content-animation-out="fadeOutRight"
	>
		<div class="card card-lg border-0 h-100 rounded-0">
			<div class="card-header">
				<div class="mr-2">
					<h3 class="card-header-title">
						Activity Log
					</h3>
				</div>
				<a
					class="js-hs-unfold-invoker btn btn-icon btn-xs btn-ghost-dark"
					href="javascript:void(0);"
					@click.prevent="toggleDisplay"
				>
					<i class="tio-clear tio-lg" />
				</a>
			</div>
			<div class="card-body activity-log-wrapper">
				<ul
					class="step"
					style=""
				>
					<activity
						v-for="(activity, index) of activitiesWithDividers"
						:key="index"
						:activity="activity"
					/>
				</ul>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import utilitiesMixin from '../../mixins/utilities';

import Activity from './activity.vue';

export default {
	components: { Activity },
	mixins: [utilitiesMixin],
	computed: {
		...mapState('activityLog', ['activities', 'display']),
		activitiesWithDividers() {
			const result = this.activities.map((item, index) => {
				const priorItem = this.activities?.[index - 1];
				const cdtxt = this.dateDiffForHumans(item.date, { addSuffix: true });

				if (priorItem) {
					const pdtxt = this.dateDiffForHumans(priorItem.date, { addSuffix: true });

					if (pdtxt !== cdtxt) {
						item.divider = cdtxt;
					}
				} else {
					item.divider = cdtxt;
				}

				return item;
			});

			return result;
		},
	},
	methods: {
		...mapActions('activityLog', ['toggleDisplay']),
	},
};
</script>
<style scoped>
	.activity-log-wrapper {
		overflow-y: auto;
	}
</style>
