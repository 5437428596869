export default function generateBreadcrumbs(currentPath, startPath = []) {
	let path = currentPath;

	if (!Array.isArray(path)) {
		path = [path];
	}

	const defaultPath = {
		name: 'Dashboard',
		destination: '/',
	};
	return [defaultPath, ...startPath, ...path];
}
