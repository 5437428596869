export default {
	namespaced: true,
	state: {
		uploading: false,
		loading: false,
	},
	mutations: {
		setUploading(state, uploading) {
			state.uploading = uploading;
		},
		setLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async uploadFile({ commit }, { file, resourceId, resourceType, user }) {
			commit('setUploading', true);
			const { to, fileRepo } = this.$cradle;

			const bucket = 'supplier-attachments';
			const [err, filePath] = await to(fileRepo.saveFileToSupabaseStore(file, bucket));
			if (err) {
				throw err;
			}

			const [err1, fileUrl] = await to(fileRepo.getFilePublicUrl(filePath, bucket));
			if (err1) {
				throw err1;
			}

			const fileName = file.name;
			const userId = user.id;

			const [err2] = await to(fileRepo.createFile(fileName, fileUrl, resourceId, resourceType, userId));
			if (err2) {
				throw err2;
			}

			commit('setUploading', false);
		},
		async deleteFile({ commit }, { fileId }) {
			commit('setLoading', true);

			const { to, fileRepo } = this.$cradle;
			const [err] = await to(fileRepo.deleteFile(fileId));
			if (err) {
				throw err;
			}

			commit('setLoading', false);
		},
	},
};
