export default class SupabaseClientUtilsService {
	constructor({ _, typesFile, libDateFns }) {
		this._ = _;
		this.types = typesFile;
		this.libDateFns = libDateFns;
	}

	createQuery(query, params = { from: 0, to: 50, filters: {}, sort: { desc: false, by: '', foreignTable: '' } }) {
		query.range(params.from, params.to);

		this._.forOwn(params.filters, (value, filter) => {
			const valueTypeOf = typeof value.value;
			const valueStartTypeOf = typeof value.value?.start;
			const valueEndTypeOf = typeof value.value?.end;

			const filterTypes = this._.map(this.types.enums.filter_types, (item) => item.name);
			const dataTypes = this._.map(this.types.enums.data_types, (item) => item.name);

			if (filterTypes.includes(value.filterType) && dataTypes.includes(value.dataType)) {
				if (value.filterType === 'between dates') {
					if (value.value?.start !== null && value.value?.start !== undefined && value.value?.start !== '') {
						if (new Date(value.value?.start).toString() !== 'Invalid Date' && valueStartTypeOf === 'string') {
							query.gte(filter, this.libDateFns.format(new Date(value.value.start), 'yyyy-MM-dd'));
						} else {
							throw Error(`start ${filter} filter value is invalid, string expected`);
						}
					}

					if (value.value?.end !== null && value.value.end !== undefined && value.value?.end !== '') {
						if (new Date(value.value?.end).toString() !== 'Invalid Date' && valueEndTypeOf === 'string') {
							query.lte(filter, this.libDateFns.format(new Date(value.value.end), 'yyyy-MM-dd'));
						} else {
							throw Error(`end ${filter} filter value is invalid, string expected`);
						}
					}
				} else if (value.filterType === 'between dollars' || value.filterType === 'between numbers') {
					if (value.value?.start !== null && value.value?.start !== undefined) {
						if (valueStartTypeOf === 'string' || valueStartTypeOf === 'number') {
							query.gte(filter, value.value.start);
						} else {
							throw Error(`start ${filter} filter value is invalid, string or number expected`);
						}
					}
					if (value.value?.end !== null && value.value?.end !== undefined) {
						if (valueEndTypeOf === 'string' || valueEndTypeOf === 'number') {
							query.lte(filter, value.value.end);
						} else {
							throw Error(`end ${filter} filter value is invalid, string or number expected`);
						}
					}
				} else if (value.filterType === 'between percent') {
					if (value.value?.start !== null && value.value?.start !== undefined) {
						if (valueStartTypeOf === 'string' || valueStartTypeOf === 'number') {
							const startValue = Number(value.value.start) / 100;
							query.gte(filter, startValue);
						} else {
							throw Error(`start ${filter} filter value is invalid, string or number expected`);
						}
					}
					if (value.value?.end !== null && value.value?.end !== undefined) {
						if (valueEndTypeOf === 'string' || valueEndTypeOf === 'number') {
							const endValue = Number(value.value.end) / 100;
							query.lte(filter, endValue);
						} else {
							throw Error(`end ${filter} filter value is invalid, string or number expected`);
						}
					}
				} else if (value.filterType === 'fuzzy match') {
					if (value.value !== null && value.value !== undefined) {
						if (valueTypeOf === 'string' || valueTypeOf === 'number') {
							if (value.dataType === 'string' || value.dataType === 'text') {
								query.ilike(filter, `%${value.value}%`);
							} else if (value.dataType === 'number') {
								query.eq(filter, value.value);
							}
						} else {
							throw Error(`${filter} filter value is invalid, string or number expected`);
						}
					}
				} else if (value.filterType === 'multi (or)') {
					if (value.value !== null && value.value !== undefined) {
						if (value.dataType === 'set') {
							if (Array.isArray(value.value)) {
								query.in(filter, value.value);
							} else {
								throw Error(`${filter} filter value is invalid, array expected`);
							}
						} else if (value.dataType === 'number') {
							if (valueTypeOf === 'string' || valueTypeOf === 'number') {
								query.eq(filter, Number(value.value));
							} else {
								throw Error(`${filter} filter value is invalid, string or number expected`);
							}
						} else if (value.dataType === 'string') {
							if (Array.isArray(value.value)) {
								let stringQuery = '';
								for (let index = 0; index < value.value.length; index++) {
									const element = value.value[index];

									if (index === 0) {
										stringQuery = `${filter}.ilike.%${element}%`;
									} else {
										stringQuery = `${stringQuery}, ${filter}.ilike.%${element}%`;
									}
								}

								if (!this._.isEmpty(stringQuery)) {
									query.or(stringQuery);
								}
							} else {
								throw Error(`${filter} filter value is invalid, array expected`);
							}
						}
					}
				} else if (value.filterType === 'YES/NO/EITHER') {
					if (value.value !== null && value.value !== undefined) {
						if (valueTypeOf === 'string') {
							if (value.value === 'Either') {
								query.in(filter, ['No', 'Yes']);
							} else if (value.value === 'Yes' || value.value === 'No') {
								query.eq(filter, value.value);
							}
						} else {
							throw Error(`${filter} filter value is invalid, string expected`);
						}
					}
				}
			} else {
				throw Error('Bad request for filters');
			}
		});

		if (params?.sort?.by.length > 0) {
			const { foreignTable, by, desc } = params.sort;
			query.order(by, { ascending: !desc, foreignTable });
		}

		return query;
	}
}
