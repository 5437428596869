export default class PredictionRepo {
	constructor({ to, supaTo, supabaseClientFactory, _ }) {
		this.to = to;
		this.supaTo = supaTo;
		this._ = _;
		this.supabase = supabaseClientFactory;
	}

	async getPrediction(endpointId, bidRevisionId, type) {
		const [error, data] = await this.supaTo(
			this.supabase.from('prediction').select().match({
				endpoint_id: endpointId,
				bid_revision_id: bidRevisionId,
			})
				.or(`endpoint_id.eq.${endpointId},type.eq.${type}`)
				.order('created_at', { ascending: false })
				.limit(1),
		);
		if (error) {
			throw error;
		}
		if (data.data.length === 0) {
			return { data: {} };
		}

		const d = {
			predictions: [data.data[0].prediction],
			deployedModelId: data.data[0].deployed_model_id,
		};

		return { data: d };
	}

	async getLastPredictionByBidRevisionId(bidRevisionId) {
		const [error, data] = await this.supaTo(
			this.supabase.from('prediction').select().match({
				bid_revision_id: bidRevisionId,
			})
				.order('created_at', { ascending: false })
				.limit(1),
		);
		if (error) {
			throw error;
		}

		return this._.get(data, 'data[0]', null);
	}
}
