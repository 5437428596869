export default {
	namespaced: true,
	state: {
		segments: {
			toSell: {
				label: 'To Sell',
				value: 0,
				color: 'to-sell',
				percentage: 0,
			},
			sold: {
				label: 'Sold',
				value: 0,
				color: 'sold',
				percentage: 0,
			},
			current: {
				label: 'Current',
				value: 0,
				color: 'current',
				percentage: 0,
			},
			original: {
				label: 'Original',
				value: 0,
				color: 'original',
				percentage: 0,
			},
		},
		revenueTargets: [],
		revenueRegions: [],
		regions: [
			'North East',
			'Mid Atlantic',
			'South East',
			'Florida',
			'Texas',
			'Mountain West',
			'South West',
			'S. California',
			'N. California',
		],
		revenueByRegionsData: [
			{
				name: 'to sell',
				color: '#E42F2F',
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
			{
				name: 'sold',
				color: '#00E396',
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
			{
				name: 'current',
				color: '#447dff',
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
			{
				name: 'original',
				color: '#FCBA36',
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
		],
		sheets: [
			{
				name: 'Pursuit Summary 2021',
				sheet_id: '4963189466130308',
				sheet_kind: 'reports',
				sheet_type: 'PURSUIT_SUMMARY_2021',
				year: '2021',
			},
			{
				name: 'Pursuit Summary 2022',
				sheet_id: '7913113665202052',
				sheet_kind: 'reports',
				sheet_type: 'PURSUIT_SUMMARY_2022',
				year: '2022',
			},
			{
				name: 'Pursuit Summary 2023',
				sheet_id: '7874003516843908',
				sheet_kind: 'reports',
				sheet_type: 'PURSUIT_SUMMARY_2023',
				year: '2023',
			},
		],
		years: ['2021', '2022', '2023'],
		activeYear: '2022',
		activeSheet: {},
	},
	mutations: {
		setSegments(state, segments) {
			state.segments = segments;
		},
		setRevenueByRegionsData(state, revenueByRegionsData) {
			state.revenueByRegionsData = revenueByRegionsData;
		},
		setActiveSheet(state, sheet) {
			state.activeSheet = sheet;
		},
		setRevenueTargets(state, revenueTargets) {
			state.revenueTargets = revenueTargets;
		},
		setRevenueRegions(state, revenueRegions) {
			state.revenueRegions = revenueRegions;
		},
		setActiveYear(state, year) {
			state.activeYear = year;
		},
	},
	actions: {
		async initPursuits({ dispatch }) {
			await dispatch('getExecutiveSummaryData');
			await dispatch('loadSegments');
			await dispatch('loadRevenueByRegion');
		},
		async selectActiveSheet({ state, commit, dispatch }, year) {
			const sheet = state.sheets.find(({ year: sheetYear }) => sheetYear === year);
			commit('setActiveSheet', sheet);
			await dispatch('getSheetData');
		},
		async getSheetData({ state }) {
			const { to, pursuitRepo } = this.$cradle;
			const { activeSheet } = state;
			const [err] = await to(pursuitRepo.getSheetData(activeSheet.sheet_type));
			if (err) {
				throw (err);
			}
		},
		async updateSheetData({ state }) {
			const { to, pursuitRepo } = this.$cradle;
			const { activeSheet } = state;
			const [err] = await to(pursuitRepo.updateSheetData(activeSheet.sheet_id, activeSheet.sheet_type, activeSheet.sheet_kind));
			if (err) {
				throw (err);
			}
		},
		async getExecutiveSummaryData({ state, commit }) {
			const { to, pursuitRepo, _ } = this.$cradle;
			const [err, payload] = await to(pursuitRepo.getExecutiveSummaryData());
			if (err) {
				throw (err);
			}

			const toSellIndex = _.findIndex(payload.columns, (column) => column.title === 'Remaining to Sell $');
			const soldIndex = _.findIndex(payload.columns, (column) => column.title === 'Sold Job Billing Projections');
			const currentIndex = _.findIndex(payload.columns, (column) => column.title === 'Current Billing Target');
			const originalIndex = _.findIndex(payload.columns, (column) => column.title === 'Original Billing Target');

			const { years } = state;
			const rows = _.filter(payload.rows, (row) => {
				if (years.includes(row.cells[0].displayValue)) {
					return row;
				}
				return false;
			});

			const revenueTargets = [];
			for (let index = 0; index < rows.length; index++) {
				const revenueTarget = {
					year: _.get(rows[index], 'cells[0].displayValue', null),
					toSell: _.get(rows[index], `cells[${toSellIndex}].value`, null),
					sold: _.get(rows[index], `cells[${soldIndex}].value`, null),
					current: _.get(rows[index], `cells[${currentIndex}].value`, null),
					original: _.get(rows[index], `cells[${originalIndex}].value`, null),
				};

				revenueTargets.push(revenueTarget);
			}

			commit('setRevenueTargets', revenueTargets);

			const revenueRegions = [];
			for (let index = 0; index < payload.rows.length; index++) {
				let regionName = _.trim(_.get(payload.rows[index], 'cells[0].displayValue', null));
				if (['So Cal'].includes(regionName)) {
					regionName = 'S. California';
				}
				if (['Nor Cal'].includes(regionName)) {
					regionName = 'N. California';
				}

				// Exlude regions @TODO: Review this region
				if (!['GC'].includes(regionName)) {
					if (index >= 1 && index <= 8) {
						const revenueRegion = {
							year: '2021',
							region: regionName,
							toSell: _.get(payload.rows[index], `cells[${toSellIndex}].value`, null),
							sold: _.get(payload.rows[index], `cells[${soldIndex}].value`, null),
							current: _.get(payload.rows[index], `cells[${currentIndex}].value`, null),
							original: _.get(payload.rows[index], `cells[${originalIndex}].value`, null),
						};

						revenueRegions.push(revenueRegion);
					} else if (index >= 10 && index <= 19) {
						const revenueRegion = {
							year: '2022',
							region: regionName,
							toSell: _.get(payload.rows[index], `cells[${toSellIndex}].value`, null),
							sold: _.get(payload.rows[index], `cells[${soldIndex}].value`, null),
							current: _.get(payload.rows[index], `cells[${currentIndex}].value`, null),
							original: _.get(payload.rows[index], `cells[${originalIndex}].value`, null),
						};

						revenueRegions.push(revenueRegion);
					} else if (index >= 21 && index <= 29) {
						const revenueRegion = {
							year: '2023',
							region: regionName,
							toSell: _.get(payload.rows[index], `cells[${toSellIndex}].value`, null),
							sold: _.get(payload.rows[index], `cells[${soldIndex}].value`, null),
							current: _.get(payload.rows[index], `cells[${currentIndex}].value`, null),
							original: _.get(payload.rows[index], `cells[${originalIndex}].value`, null),
						};

						revenueRegions.push(revenueRegion);
					}
				}
			}

			commit('setRevenueRegions', revenueRegions);
		},
		async loadSegments({ state, commit }) {
			const { segments, activeYear, revenueTargets } = state;
			const { _ } = this.$cradle;

			const revenue = _.filter(revenueTargets, (target) => target.year === activeYear);

			segments.toSell.value = _.get(revenue, '[0].toSell', null);
			segments.sold.value = _.get(revenue, '[0].sold', null);
			segments.current.value = _.get(revenue, '[0].current', null);
			segments.original.value = _.get(revenue, '[0].original', null);

			commit('setSegments', segments);
		},
		async loadRevenueByRegion({ state, commit }) {
			const { revenueByRegionsData, regions, revenueRegions, activeYear } = state;
			const { _ } = this.$cradle;

			for (let regionIndex = 0; regionIndex < regions.length; regionIndex++) {
				const revenue = _.filter(revenueRegions, (regionItem) => regionItem.year === activeYear
				&& regionItem.region === regions[regionIndex]);

				_.set(revenueByRegionsData, `[0].data[${regionIndex}]`, _.get(revenue, '[0].toSell', 0));
				_.set(revenueByRegionsData, `[1].data[${regionIndex}]`, _.get(revenue, '[0].sold', 0));
				_.set(revenueByRegionsData, `[2].data[${regionIndex}]`, _.get(revenue, '[0].current', 0));
				_.set(revenueByRegionsData, `[3].data[${regionIndex}]`, _.get(revenue, '[0].original', 0));
			}

			commit('setRevenueByRegionsData', revenueByRegionsData);
		},
	},
};
