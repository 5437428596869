const defaultLevels = {
	BASEMENT_1: {
		type: 'BASEMENT_1',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	BASEMENT_2: {
		type: 'BASEMENT_2',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	BASEMENT_3: {
		type: 'BASEMENT_3',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_1: {
		type: 'LEVEL_1',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_2: {
		type: 'LEVEL_2',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_3: {
		type: 'LEVEL_3',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_4: {
		type: 'LEVEL_4',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_5: {
		type: 'LEVEL_5',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_6: {
		type: 'LEVEL_6',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_7: {
		type: 'LEVEL_7',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	LEVEL_8: {
		type: 'LEVEL_8',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
	ROOF: {
		type: 'ROOF',
		included: false,
		number_of_units: 0,
		floor_gross_square_footage: 0,
		porch_gross_square_footage: 0,
		wall_gross_square_footage: 0,
	},
};

export default {
	namespaced: true,
	state: {
		levels: {},
	},
	getters: {},
	mutations: {
		resetLevels(state, _) {
			state.levels = _.cloneDeep(defaultLevels);
		},
		setLevels(state, levels) {
			for (const level of levels) {
				state.levels[level.type] = level;
			}
		},
		setLevel(state, level) {
			state.levels[level.type] = level;
		},
		setLevelId(state, { type, id }) {
			state.levels[type].id = id;
		},
	},
	actions: {
		async resetLevels({ commit }) {
			const { _ } = this.$cradle;
			commit('resetLevels', _);
		},
		async createLevel({ commit }, { buildingId, type }) {
			const { to, levelRepo, logDnaFactory: logger } = this.$cradle;
			const [err, result] = await to(levelRepo.createLevel(buildingId, type));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setLevel', result);
		},
		async updateLevel({ commit }, level) {
			const { to, levelRepo, logDnaFactory: logger } = this.$cradle;
			const [err, result] = await to(levelRepo.updateLevel(level));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setLevel', result);
		},
		async deleteLevel({ commit }, level) {
			const { to, levelRepo, logDnaFactory: logger } = this.$cradle;
			const [err] = await to(levelRepo.deleteLevel(level.id));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setLevel', level);
		},
		async getLevels({ commit }, buildingId) {
			const { to, levelRepo, logDnaFactory: logger } = this.$cradle;
			const [err, result] = await to(levelRepo.getLevels(buildingId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setLevels', result);
		},
	},
};
