export default {
	namespaced: true,
	state: {
		files: [],
		supplier: {},
	},
	mutations: {
		setSupplier(state, supplier) {
			state.supplier = supplier;
		},
		setFiles(state, files) {
			state.files = files;
		},
		setLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async getSupplierAttachments({ state, commit }) {
			commit('setFiles', []);
			commit('file/setLoading', true, { root: true });
			const { to, supplierAttachmentRepo } = this.$cradle;
			const bidRevisionId = state.supplier.bid_revision_id;
			const supplierId = state.supplier.id;

			const [err, data] = await to(supplierAttachmentRepo.getSupplierAttachments(bidRevisionId, supplierId));

			if (err) {
				throw err;
			} else {
				commit('setFiles', data);
			}

			commit('file/setLoading', false, { root: true });
		},
	},
};
