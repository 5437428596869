export default class MaterialPredictionRepo {
	constructor({ to, _, modelData, predictionVersions, costConverterService, predictionRepo }) {
		this.to = to;
		this.costConverter = costConverterService;
		this._ = _;
		this.predictionRepo = predictionRepo;
		this.cheapRegionModelName = 'total_labor_cost_cheap_regions';
		this.cheapRegionModelVersion = predictionVersions[this.cheapRegionModelName].version;
		this.cheapRegionModelData = modelData.models[this.cheapRegionModelName].versions[this.cheapRegionModelVersion];
		this.mediumRegionModelName = 'total_labor_cost_medium_regions';
		this.mediumRegionModelVersion = predictionVersions[this.mediumRegionModelName].version;
		this.mediumRegionModelData = modelData.models[this.mediumRegionModelName].versions[this.mediumRegionModelVersion];
		this.expensiveRegionModelName = 'total_labor_cost_expensive_regions';
		this.expensiveRegionModelVersion = predictionVersions[this.expensiveRegionModelName].version;
		this.expensiveRegionModelData = modelData.models[this.expensiveRegionModelName].versions[this.expensiveRegionModelVersion];
		this.allStates = this.expensiveRegionModelData.states.concat(this.mediumRegionModelData.states, this.cheapRegionModelData.states);
	}

	async getLaborPrediction({ state, bidRevisionId }) {
		let res = {};
		if ([null, undefined].includes(state) || !this.allStates.includes(state)) {
			throw new Error(`A valid state is required for labor cost prediction. Currently the selected state "${state}"`
				+ ' does not have enough data for prediction.');
		}

		if (this.expensiveRegionModelData.states.includes(state)) {
			const [err, _res] = await this.to(this.getLaborExpensiveRegionPrediction(state, bidRevisionId));
			if (err) {
				throw err;
			}
			res = _res;
		} else if (this.mediumRegionModelData.states.includes(state)) {
			const [err, _res] = await this.to(this.getLaborMediumRegionPrediction(state, bidRevisionId));
			if (err) {
				throw err;
			}
			res = _res;
		} else if (this.cheapRegionModelData.states.includes(state)) {
			const [err, _res] = await this.to(this.getLaborCheapRegionPrediction(state, bidRevisionId));
			if (err) {
				throw err;
			}
			res = _res;
		} else {
			throw new Error("State didn't match any models.");
		}
		return res;
	}

	async getLaborExpensiveRegionPrediction(state, bidRevisionId) {
		const [err, res] = await this.to(this._getPredictionByModelData(state, bidRevisionId, this.expensiveRegionModelData,
			this.expensiveRegionModelName, this.expensiveRegionModelVersion));
		if (err) {
			throw err;
		}
		return res;
	}

	async getLaborMediumRegionPrediction(state, bidRevisionId) {
		const [err, res] = await this.to(this._getPredictionByModelData(state, bidRevisionId, this.mediumRegionModelData,
			this.mediumRegionModelName, this.mediumRegionModelVersion));
		if (err) {
			throw err;
		}
		return res;
	}

	async getLaborCheapRegionPrediction(state, bidRevisionId) {
		const [err, res] = await this.to(this._getPredictionByModelData(state, bidRevisionId, this.cheapRegionModelData,
			this.cheapRegionModelName, this.cheapRegionModelVersion));
		if (err) {
			throw err;
		}
		return res;
	}

	async _getPredictionByModelData(state, bidRevisionId, modelData, modelName, modelVersion) {
		const { endpoint_id: endpointId, fred_adjustment_series: fredAdjustmentSeries, adjustment_date: adjustmentDate,
			prediction_interval: predictionInterval, mean, median, deployed_model_id: configDeployedModelId,
			standard_score_95: standardScore95 } = modelData;
		const r = Math.round;

		let warning = null;
		if (modelData.warn_on_states.includes(state)) {
			warning = `State "${state}" has very little data in the model. Prediction may be inaccurate.`;
		}

		const [predictionErr, predictionRes] = await this.to(this._submitPrediction(endpointId, bidRevisionId));
		if (predictionErr) {
			throw predictionErr;
		}
		const { predictions, deployedModelId } = predictionRes;
		if (!predictions) {
			throw new Error('Prediction not available. Please check that gross/porch square footage has been added to buildings.');
		}
		if (deployedModelId !== configDeployedModelId) {
			throw new Error('Prediction model ID does not match model ID in config.');
		}
		const [prediction] = predictions;
		const [costErr, costRes] = await this.to(this.costConverter.convertByDateAndCode(prediction, adjustmentDate, fredAdjustmentSeries));
		if (costErr) {
			throw costErr;
		}
		const [scaledCost, scaledDate] = costRes;
		const adjustedStdv = predictionInterval / 1.96;
		return {
			modelMetadata: {
				modelName,
				modelVersion,
			},
			adjustedStdv,
			meanRange: [r(scaledCost - scaledCost * mean), r(scaledCost + scaledCost * mean)],
			medianRange: [r(scaledCost - scaledCost * median), r(scaledCost + scaledCost * median)],
			scaleDate: new Date(scaledDate),
			scaledPrediction: r(scaledCost),
			scaledRange: [r(scaledCost - scaledCost * predictionInterval), r(scaledCost + scaledCost * predictionInterval)],
			warning,
			standardScore95,
		};
	}

	async _submitPrediction(endpointId, bidRevisionId) {
		const [error, data] = await this.to(this.predictionRepo.getPrediction(endpointId, bidRevisionId, 'LABOR'));
		if (error) {
			throw error;
		} else {
			return data.data;
		}
	}
}
