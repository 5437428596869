export default {
	namespaced: true,
	state: {
		messages: [],
		show: false,
	},
	mutations: {
		setInbox(state, messages) {
			state.messages = messages;
		},
		setShow(state, status) {
			state.show = status;
		},
	},
	actions: {
		async getInboxMessageList({ commit }, { id, type }) {
			const { emailRepo, to, logDnaFactory: logger } = this.$cradle;
			const [err, messages] = await to(emailRepo.getInboxMessageList(id, type));

			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setInbox', messages);
		},
		async toggleDisplay({ commit, state }) {
			commit('setShow', !state.show);
		},
	},
};
