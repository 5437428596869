export default {
	namespaced: true,
	state: {
		alerts: [],
		nextId: 0,
	},
	mutations: {
		createNextId(state) {
			state.nextId += 1;
		},
		removeAlert(state, id) {
			const index = state.alerts.findIndex((item) => item.id === id);
			if (index === -1) {
				return;
			}
			state.alerts.splice(index, 1);
		},
		addAlert(state, alert) {
			state.alerts.push(alert);
		},
	},
	actions: {

		/**
		 * Create Alert
		 * @param {Object} param0
		 * @param {Object} alert
		 * @property {String} alert.type - success or error
		 * @property {String} alert.message
		 * @property {Number} alert.duration - defaults to 5000
		 */

		async createAlert({ commit, dispatch }, alert) {
			alert.id = await dispatch('generateId');
			commit('addAlert', alert);
			await dispatch('alertCloseCountDown', alert);
		},
		async createErrorAlert({ dispatch }, errMsg) {
			await dispatch('createAlert', {
				type: 'error',
				message: errMsg,
			});
		},
		async createSuccessAlert({ dispatch }, successMsg) {
			await dispatch('createAlert', {
				type: 'success',
				message: successMsg,
			});
		},
		async generateId({ commit, state }) {
			const id = state.nextId;
			commit('createNextId');
			return id;
		},
		async alertCloseCountDown({ commit }, { id, duration }) {
			setTimeout(() => {
				commit('removeAlert', id);
			}, duration || 5000);
		},
		async closeAlert({ commit }, id) {
			commit('removeAlert', id);
		},
	},
};
