/* export default function LogDnaFactory({ libLogdna }) {

	return libLogdna.init('befb407c599de92eaa142e40679793c5', {
		app: 'estimating-tool',
	});
	return {};
}
*/
export default function LogDnaFactory() {
	return {
		log: (err) => {
			// eslint-disable-next-line no-console
			console.log(err);
		},
		error: (err) => {
			// eslint-disable-next-line no-console
			console.log(err);
		},
		warn: () => {},
		warning: () => {},
		addContext: () => {},
	};
}
